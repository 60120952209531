import React, { useEffect, useState } from "react";
import "./style.css";
import { addPackage, getPackageCodeDetail, getPackageCodeList, updatePackageServices } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { isAlphabet, isEmpty, isNumber } from "../../../utils/common";
import { AlertWrapper } from "../../../assets/styles/AlerWrapper";


const CreatePackage = () => {
  const[packageName,setPackageName] = useState("");
  const[packageCode,setpackageCode] = useState("");
  const[discription,setDiscription] = useState("");
  const [errors, setErrors] = useState({});
  const [codeList,setCodeList] = useState([]);
  const[key,setKey] = useState([]);
  const[showButton,setShowButton] = useState(true);
  const[packageUuid,setpackageUuid] = useState("");
  const[confirguration,setConfiguration] =useState({});
  const [msg, setMsg] = useState("");

  
      const[show,setShow]=useState('');
     
     const price = {
      Expiry:"",
      packagePrice:""

     }
     const[priceConfig,setPriceConfig] = useState({...price})
     
      
      
      const navigate = useNavigate();
     
      let name, value;
 const handlePrice =(e,key ="")=>{
  name = e.target.name;
    value = e.target.value;
   
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) {
         setErrors({})
        return;
      }
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }
    setPriceConfig({ ...priceConfig, [name]: value });


  }

 const  handleCreate =()=>{
  let error = {};
  if(packageName == ""){
    error.packageName ="Please Enter the Package Name"
  }
  if(discription == "")
  {
  error.discription ="Please Enter the Package Discription"
}
if(priceConfig.Expiry == "")
  {
  error.Expiry ="Please Enter the Expiry Date"
}
if(priceConfig.packagePrice == "")
  {
  error.packagePrice ="Please Enter the Price"
}
setErrors(error);

 const param ={
  packageName: packageName,
  packageCode: packageName,
  packageDescription: discription,
  packageExpiry: priceConfig.Expiry,
  packagePrice: priceConfig.packagePrice,
  "gstPercentage": 0,
  "tdsPercentage": 0,
  "serviceListDTO": {
    "services": [
      "test3"
    ]
  }

  }
  
  
  if(isEmpty(error))
  {addPackage(param).then((resp)=>{
    if(resp?.data?.success){
      navigate('/service', {state:{
        confirguration:confirguration,
        packageName:packageName,
        msg:resp?.data?.msg,
        packageCode:packageCode
       
      }});
      setMsg(resp?.data?.msg);

    }
    else if(resp?.data?.errorCodeList
      ){
      
      setMsg(resp?.data?.errorCodeList[0]);
    }

  })}

 

}

useEffect(()=>{

  getPackageCodeList().then((resp)=>{
    

     const packageCodeList =[];
    const codeList = resp?.data?.data

Object.keys(codeList).map((items,index)=>{
  const lable = codeList[items];
  const obj = {lable:lable, value:items,}
  packageCodeList.push(obj)
 
})
setCodeList(packageCodeList);


  })
  



},[]);
const handleNewPackage =(e)=>{
  const value = e.target.value;
 
      setShow(value)
  if(value === "createPackage"){
    setPackageName('');
    setDiscription('');
    setPriceConfig({
      Expiry:"",
      packagePrice:""
    })
    setShowButton(true);
  
    
    
 }
  //  else {
  //    setPackageName(value);
  //  }

    if(value !== "createPackage"){
      getPackageCodeDetail(value).then((response)=>{
        const codeDetail = response?.data?.metaInfo[value].packageDetails;
        const priceConfiguration = response?.data?.metaInfo[value]?.serviceConfiguration;
         setConfiguration(priceConfiguration)  
      
        setPackageName(codeDetail.packageName);
        setDiscription(codeDetail.packageDescription);
        setpackageUuid(codeDetail.packageUuid);
        setpackageCode(codeDetail.packageCode);
        setPriceConfig({
          Expiry:codeDetail.packageExpiry,
          packagePrice:codeDetail.packagePrice


        })
        
        
        setShow(value);
      

  
      })
      setShowButton(false);  
  
    
    }
   

}


const handleUpdatePackage=()=>{
  let error = {};
  if(packageName == ""){
    error.packageName ="Please Enter the Package Name"
  }
  if(discription == "")
  {
  error.discription ="Please Enter the Package Discription"
}
if(priceConfig.Expiry == "")
  {
  error.Expiry ="Please Enter the Expiry Date"
}
if(priceConfig.packagePrice == "")
  {
  error.packagePrice ="Please Enter the Price"
}
setErrors(error);
  const param ={
    
      packageName: packageName,
      packageCode: packageCode,
      packageDescription: discription,
      packageExpiry: priceConfig.Expiry,
      packagePrice: priceConfig.packagePrice,
      gstPercentage: "3",
      tdsPercentage: "5",
      serviceListDTO: {
        services: [
          " ",
        ]
      },
      packageUuid: packageUuid
    
  
    }

   
    if(isEmpty(error)){
      updatePackageServices(param).then((response)=>{
        setErrors({});
        if(response?.data?.msg){
         
            setMsg("Package Detail Updated successfully");

       

        }
       
  
       })
  

    }


    
}





const handleSkip =()=>{
  let error = {};
  if(packageName == ""){
    error.packageName ="Please Enter the Package Name"
  }
  if(discription == "")
  {
  error.discription ="Please Enter the Package Discription"
}
if(priceConfig.Expiry == "")
  {
  error.Expiry ="Please Enter the Expiry Date"
}
if(priceConfig.packagePrice == "")
  {
  error.packagePrice ="Please Enter the Price"
}
setErrors(error);
 
if(isEmpty(error)){
  navigate('/service', {state:{
    confirguration:confirguration,
    packageCode:packageCode
  
  }});


}
  

}



useEffect(()=>{
  if(msg !== ""){
    setTimeout(()=>{
  setMsg("");
    },5000)
   
  }

},[msg])


  return (   
    <>
     {msg && (
        <AlertWrapper className="success globle">
          <span className="close" onClick={() => setMsg("")}>
            ×
          </span>
          {msg}
        </AlertWrapper>
      )}

      <div className="createpkg_container padding_30">
        <h2>Create/Update Package</h2>

        <div className="container_progress1">
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Package Details</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable">Service Configuration</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" style={{visibility:"hidden"}} />
            <div className="lable">View Package</div>
          </div>{" "}
         
        </div>
        <div className="container mt-20">
          <h4>Package Details</h4>
          <div className="form flex gap-20 mt-10 package_data wrap">
            <div className="container_data_form          ">
              <lable className="filter_text fs">Package name:</lable>
              <div className="mt-5">
          <select value={codeList.label} onChange={handleNewPackage} className="filter_select  input_padding">
                <option selected>select</option>
                 {codeList &&codeList.map((code,index)=>{
                 
                        return(
                           <option value={code.value} key ={index}>{code.lable}</option>
                        )  

                 })}
                  <option value ="createPackage">Create Package+</option>
                </select>
                
              </div>
              {show ? <input className="filter_select mt-10" type="text" name ="packageName" value={packageName} onChange={(e)=>setPackageName(e.target.value)}/>:""}
              {errors.packageName && (
              <p className="error fs-12 mt-10">{errors.packageName}</p>
            )}
            </div>

            <div className="container_data_form">
              <lable className="filter_text fs">Discription:</lable>
              <div className="mt-5">
                <textarea value={discription} onChange={(e)=>setDiscription(e.target.value)} className="filter_select" style={{padding:"10px"}}></textarea>
              </div>
              
            {errors.discription && (
              <p className="error fs-12 ">{errors.discription}</p>
            )}
          
            </div>
           
           
               
            <div className="container_data_form">
              <lable className="filter_text fs">Package Price:</lable>

              <div className="mt-5">
                <input
                type="text"
                name="packagePrice"
                value={priceConfig.packagePrice}
                   onChange={(e)=>handlePrice(e,"number")}
                  className="filter_select"
                  placeholder="Amount"
                  maxLength={5}
                ></input>
              </div>
          
              {errors.packagePrice && (
              <p className="error fs-12 mt-10 ">{errors.packagePrice}</p>
            )}
          
            </div>
           
           
           
            <div className="container_data_form">
              <lable className="filter_text fs">Package Expiry:</lable>
              <div className="mt-5">
                <input
                className="filter_select"
                 name ="Expiry"
                 value ={priceConfig.Expiry}
                 onChange={(e)=>handlePrice(e,"number")}
                 type="number" id="tentacles" min="10" max="100" />

              </div>
            
              {errors.Expiry && (
              <p className="error fs-12 mt-10 ">{errors.Expiry}</p>
            )}

              
            </div>
  
          
          </div>
        {showButton ? (<>
        <div className="container_data_form justify-center-content flex">
        <button onClick={handleCreate} className="userlist_btn mt-10 ">Create Package</button>
        </div>
          
        </>):(<>
        <div className="flex gap-20">     
        <button onClick={handleUpdatePackage} className="userlist_btn mt-10">Update</button>
          <button onClick={handleSkip} className="userlist_btn mt-10">Skip</button>
        </div>
         
        </>)
        
          
}

          
            
        </div>
        
      </div>
    </>
  );
};
export default CreatePackage;
