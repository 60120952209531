
const financeService = "/finance-service";
const auth = "/auth";
const supportServices = "/support-service";
const apiServices = "/api-service";
//const apiServices = "https://api.pay2mobiles.com/api-service";
export default {
 // BASE_URL: "http://165.22.208.28:8080",
// BASE_URL: process.env.REACT_APP_BASE_URL,
   // BASE_URL: "https://crm.pay2mobiles.com",
    BASE_URL: "",
  login: {

      GET_USER_ACCOUNT: financeService+"/api/user/account",
      FORGET_PASSWORD_GENERATE_OPT: auth+"/api/user/account/reset-password/init",
      USER_LOGIN: auth+"/api/authenticate",
      RESET_PASSWORD: auth+"/api/user/account/reset-password/finish",
  
  },
  admin:{
    EDIT_ADMIN_USER: financeService+"/api/user/account",
    CHANGE_PASSWORD : auth+"/api/user/account/change-password"
  },
  users:{
    GET_USER_LIST:financeService+"/api/users/list",
    ADD_USER:financeService+"/api/users",
    EDIT_USER:financeService+"/api/update-users",
    DELETE_USER:financeService+"/api/users",
    ONBORDING_USER:financeService+"/api/aeps/onboarding",
    OTP_VERIFY:financeService+"/api/aeps/verify/otp",
    ADD_BANK_DETAILS:financeService+"/api/kyc/create",
    VERYFY_AADHAR:financeService+"/api/aeps/onboarding",
    USER_SEARCH:financeService+"/api/users/search",
    USER_PPACKAGE_MAPPING:financeService+"/api/user/package/mapping",
    USER_STATUS : financeService+"/api/users/status"
  },
 Overview :{ 
MAIN_TREE_BALANCE :'/finance-service/api/dashboard/total/main/balance',
AEPS_TREE_BALANCE:financeService+"/api/dashboard/total/aeps/balance",
TOTAL_USERS:financeService+"/api/dashboard/total/users",
SETTLEMENT_BALANCE:financeService+"/api/dashboard/settlement/balance",
LIVE_BALANCE:financeService+"/api/dashboard/live/balance",
SERVICE_DETAILS:financeService+"/api/dashboard/service/details",
OUTSTANDING_DETAIL:financeService+"/api/outstanding-detail",
TOTAL_PAYIN_PAYOUT:financeService+"/api/dashboard/transaction/details",

 },
  

  Operators:{
    OPERATORS_LIST:financeService+"/api/operator/filter?",
  },
  transactions:{

    TRANSACTION_LIST:financeService+"/api/report/admin/transactions",
    CHECK_TRANSACTION_STATUS:financeService+"/api/upi/verify-transaction",
  },

wallet:{
  WALLET_SUMMARY:financeService+"/api/wallet/filter/transactions"
},
ADD_DEBIT_CREDIT:{
    CREATE_DEBIT_CREDIT:"finance-service/payback/create",
    USER_DETAIL:financeService+"/api/users/search",
    DEBIT_CREDIT_LIST:financeService+"/payback/list",
    DEBIT_CREDIT_APPROVE:"finance-service/payback/approve"
},
SETTING:{
  CHANGE_PASSWORD:financeService+"/api/user/account/change-password",
  
},
Service:{
  SERVICE_LIST:financeService+"/api/service/filter",
  AEPS_BANK_LIST: financeService+'/api/aeps/bank/list',
},
UpdateService:{
  UPDATE_SERVICE:financeService+"/api/service/update"
},

roleAndPermission:{

  Role_Permission:auth+"/api/frontend/hierarchy/permissions",
  ALL_PERMISSION:auth+"/api/frontend/hierarchy/permissions",
 UPDATE_PERMISSION: auth+"/api/frontend/update/permissions"
  

},
package :{
  PACKAGE_UPDATE:financeService+"/api/user/package/update/packageDetailsService",
  PACKAGE_PRICE_CONFIGURATION_UPDATE:financeService+"/api/user/package/update/priceConfiguration",
  PACKAGE_LST:financeService+"/api/user/package/list/detail",
  CREATE_PACKAGE:financeService+"/api/user/package/create/packageDetailsAndService",
  CREATE_PACKAGE_SERVICES:financeService+"/api/user/package/create/priceConfiguration" ,
  PACKAGE_CODE_LIST:financeService+"/api/user/package/code/list",
  PACKAGE_CODE_DETAIL:financeService+"/api/user/package/code/detail",
  PACKAGE_ROUTE: financeService+"/api/service/routes"  

},        

ticketManagement :{
   GET_TICKET_LIST : supportServices+"/admin/ticket/list",
   CREATE_TICKET : supportServices +"/admin/ticket/create",
   UPDATE_TICKET : supportServices +"/admin/ticket/update",
   GET_CATEGORY : supportServices + "/admin/ticket/category",
   GET_STATUS : supportServices + "/admin/ticket/status",
   GET_REQUESTTYPE : supportServices + "/admin/ticket/request-type",
   GET_PRIORITY : supportServices + "/admin/ticket/priority",
},
chatData: {
  GET_MESSAGE: supportServices+"/chatbox/message",
  SEND_MESSAGE: supportServices+"/chatbox/save",
},
sendMoney:{
  SEND_MONEY_URL: financeService+'/api/payout',
},
api: {
  GET_API_USER_LIST: apiServices+`/user/list`,
  CREATE_VENDOR_USER: apiServices+`/user/create`,
  VENDOR_TRANSACTION_LIST: apiServices+`/transaction/list`,
}
};
