import React, { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../Components/Input/Input";
import { aepsVerifyOtp, verificationAadhar } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { isAlphabet, isEmpty, isIdPattern, isNumber } from "../../utils/common";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/verification_code.json";

import OtpInput from "react-otp-input";
import { AlertWrapper } from "../../Components/Modal/style";
import { useSelector } from "react-redux";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const Verification = () => {
  const initialValues = {
    pannumber: "",
    nameonpan: "",
    adharnumber: "",
    nameonadhar: "",
  };

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;



  
  const [detail, setDetail] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpParam, setOtpParam] = useState({});
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [screen, setScreen] = useState(true);
  const [otpMsg, setOtpMsg] = useState("");
  const [message, setMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);


  const {user} = useSelector((state)=> state.user)

  const handleData = async (e) => {
    setOtpParam("");
    setOtp("")
    let error = {};
    if (detail.nameonpan === "") {
      error.nameOnPan = "Please enter your name on Pan number";
    }
    if (detail.pannumber === "") {
      error.panNumber = "Please enter your Pan number";
    } else if (detail.pannumber.length < 10) {
      error.panNumber = "Pan number can't be less than 10";
    }
    if (detail.nameonadhar === "") {
      error.nameOnAdhar = "Please enter your name on Aadhar";
    }
    if (detail.adharnumber === "") {
      error.adharNumber = "Please enter your Aadhar number";
    } else if (detail.adharnumber.length < 12) {
      error.adharNumber = "Aadhar number can't be less than 12";
    }
    setErrors(error);
    if (isEmpty(error)) {
      let latitude = "17.4442806";
      let longitude = "78.4809119";
      let consent = "Y";

      const param = {
        aadhaar: detail?.adharnumber,
        email: state?.userData?.email,
        latitude: latitude,
        longitude: longitude,
        mobile: state?.userData?.userName,
        pan: detail?.pannumber,
        consent: consent,
      };

      setLoading(true);
      verificationAadhar(param).then((resp) => {
        setLoading(false);

        if (resp.data.success === true) {
          setTimer(120);
          setScreen(false);
          setMessage({
            msg: "OTP sent on your registered mobile linked to Aadhar card",
            type: "success",
          });

          const datas = {
            userName: state?.userData?.userName,
            hash: resp.data.data.hash,
            otpReferenceId: resp.data.data.otpReferenceID,
            otpSendAgain: param,
          };
          setOtpParam(datas);
        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }
          setMessage({ msg: msg, type: "error" });
        }
      });
    }
  };

  const handleAdhaarData = async (e) => {
    if (detail.nameonadhar === "") {
      setErrors((prev) => ({
        ...prev,
        nameOnAdhar: "Please enter your name on Aadhar",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        nameOnAdhar: "",
      }));
    }
    if (detail.adharnumber === "") {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Please enter your Aadhaar number",
      }));
    } else if (detail.adharnumber.length < 12) {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Pan number can't be less than 10",
      }));
    }
  };

  let name, value;
  const handleInput = (e, key = "") => {
    name = e.target.name;
    value = e.target.value;
    setErrors({});
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    } else if (key === "textNum") {
      if (!isIdPattern(value) && !isEmpty(value)) {
        return;
      }
    }

    if (name === "pannumber") {
      let upperCase = value.toUpperCase();
      setDetail({ ...detail, [name]: upperCase });
    } else {
      setDetail({ ...detail, [name]: value });
    }
  };

  const skipAepsAuthentication = async (e) => {
    let param = {
      mobile: state?.userData?.userName,
      otp: null,
      hash: "",
      otpReferenceID: "",
    };

    setLoading(true);
    aepsVerifyOtp(param).then((resp) => {
      setDisableBtn(true);
      
      if (resp.data.success === true) {
        setMessage({ msg: resp?.data?.data, type: "success" });

        setTimeout(() => {
          if(state?.userData?.userType==="ROLE_RETAILER"){
            navigate("/retailerList")
          }else{
            navigate("/usersList");
          }
          setLoading(false);
          setDisableBtn(false);
        }, 2000);
      } else {
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        setMessage({ msg: msg, type: "error" });
        setDisableBtn(false);
        setLoading(false);
      }
    });
  };

  const otp_timer = () => {
    const myTimeout = setTimeout(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
        otp_timer();
      } else {
        clearTimeout(myTimeout);
      }
    }, 1000);
  };

  useEffect(() => {
    if (timer > 119) {
      otp_timer();
    }
  }, [timer]);

  const sendOTP = async () => {
    setOtpMsg("");
    if (isEmpty(otp) || otp.length < 6) {
      setOtpMsg("Pease enter valid otp");
      return;
    }
    let param = {
      mobile: otpParam.userName,
      otp: otp,
      hash: otpParam.hash,
      otpReferenceID: otpParam.otpReferenceId,
    };

    setLoading(true);
    aepsVerifyOtp(param).then((resp) => {
      setDisableBtn(true);
     
      if (resp.data.success === true) {
        setMessage({ msg: "User onboarding is done successfully", type: "success" });
        setTimeout(() => {
          if(user.userType==="ROLE_DISTRIBUTOR"){
            navigate("/retailerList")
          }else{
            navigate("/usersList");
          }
          
          setLoading(false);
          setDisableBtn(false);
        }, 2000);
      } else {
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        setOtpMsg(msg);
        setLoading(false);
        setDisableBtn(false);
      }
    });
  };

  const handleOtpData =(e) =>{
    const value = e.target.value;
      if (!isNumber(value) && !isEmpty(value)) return;
      setOtp(e.target.value);
    
  }

  useEffect(() => {
    if (!isEmpty(message)) {
      setTimeout(() => {
        setMessage({});
      }, 4000);
    }
  });
  return (
    <div className="main_container">
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      {
        <div className="container_form">
          <div className="container_progress1">
            <div className="round round-adduser">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-1" />
              <div className="lable">Retailer Detail</div>
            </div>
            <div className="round round-addBank">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-2" />
              <div className="lable ">Select Package</div>
            </div>
            <div
              className={`round  ${
                state?.skip === "SkipKey"
                  ? "round-addBankBlue"
                  : "round-addBank"
              }`}
              // className="round round-addBank"
            >
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-3" />
              <div className="lable">Bank Detail</div>
            </div>{" "}
            <div className="round round-addBankBlue">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-4" />
              <div className="lable lable-last">ID Verification</div>
            </div>
          </div>
          {screen && (
            <>
          <div className="shadowBox">
              <div className="">
                <div className="">
                  <h3>Pan Details</h3>
                </div>
              </div>
              <div className="id_verification role_responsive">
                <div className="verification">
                  <div className="labelname">
                    <label>Pan Card Number</label>
                    <span className="required"> *</span>
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="pannumber"
                      pattern="[0-9]*"
                      maxLength={10}
                      placeholder="Enter your pan number"
                      value={detail.pannumber}
                      onChange={(e) => handleInput(e, "textNum")}
                    />

                    {errors.panNumber && (
                      <p className="error mt-10 fs-12">{errors.panNumber}</p>
                    )}
                  </div>
                </div>
                <div className="verification">
                  <div className="labelname">
                    <label>Name on Pan Card</label>
                    <span className="required"> *</span>
                  </div>
                  <Input
                    type="text"
                    name="nameonpan"
                    placeholder="Enter your name on pan card"
                    value={detail.nameonpan}
                    onChange={(e) => handleInput(e, "text")}
                  />
                  {errors.nameOnPan && (
                    <p className="error mt-10 fs-12">{errors.nameOnPan}</p>
                  )}
                </div>
              </div>
              </div>
              <div className="shadowBox">
              <div className=" ">
                <div className="">
                  <h3>Aadhar Details</h3>
                </div>
              </div>
              <div className="id_verification role_responsive">
                <div className="verification">
                  <div className="labelname">
                    <label>Aadhar Card Number</label>
                    <span className="required"> *</span>
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="adharnumber"
                      pattern="[0-9]*"
                      maxLength={12}
                      placeholder="Enter your aadhar number"
                      value={detail.adharnumber}
                      onChange={(e) => handleInput(e, "number")}
                    />

                    {errors.adharNumber && (
                      <p className="error mt-10 fs-12">{errors.adharNumber}</p>
                    )}
                  </div>
                </div>
                <div className="verification">
                  <div className="labelname">
                    <label>Name on Aadhar Card</label>
                    <span className="required"> *</span>
                  </div>
                  <Input
                    type="text"
                    name="nameonadhar"
                    placeholder="Enter your name on aadhar card"
                    value={detail.nameonadhar}
                    onChange={(e) => handleInput(e, "text")}
                  />
                  {errors.nameOnAdhar && (
                    <p className="error mt-10 fs-12">{errors.nameOnAdhar}</p>
                  )}
                </div>
              </div>

              <div className="id_verification role_responsive">
                <div className="verification">
                  <button
                    className="submit_btn"
                    onClick={() => {
                      handleData();
                      // navigate("/otpverification");
                    }}
                  >
                    Submit
                  </button>
                </div>

                <div className="verification flex gap-20 btn_margin">
                  <button
                    style={{ width: "155px" }}
                    className="submit_btn "
                    onClick={() => {
                      // handleData();
                      navigate("/bankdetails", { state: state.userData});
                    }}
                  >
                    Back
                  </button>
                  <button
                    style={{ width: "155px" }}
                    className="submit_btn"
                    disabled={disableBtn}
                    onClick={() => {
                      skipAepsAuthentication();
                    }}
                  >
                    Skip & Finish
                  </button>
                </div>
              </div>
              </div>

            </>
          )}
        </div>
      }
      {!screen && (
        <>
          <div className="text-center">
            <div className="container_lottie">
              <Lottie
                options={defaultOptions}
                height={190}
                width={190}
              ></Lottie>
            </div>
            <p className=""> Please enter the otp sent to your mobile</p>
            <div className="otp_container">
              <OtpInput
                value={otp}
                type="number"
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  background: " #c8c7c7 0% 0% no-repeat padding-box",
                  border: "5px",
                  opacity: 1,
                }}

                onChange={setOtp}
                // onChange={(e) => handleOtpData(e)}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
              {!isEmpty(otpMsg) && (
                <p className="error mt-10 fs-12">{otpMsg}</p>
              )}
            </div>
            <div className="timer send-again-otp fs-12">
           <p className="my-10 ">Didn't get otp | {(timer >1)?<span>Resend in: {timer} Sec</span>:
           <button
          className="resendOtp"
            onClick={handleData}>Resend Otp</button>}
             </p>
            <button
              className="submit_btn otp-button"
              disabled={disableBtn}
              onClick={sendOTP} >
              Sumbit
            </button>
                    
          </div>
          </div>
        </>
      )}
      {loading && <GloabalLoader />}
    </div>
  );
};

export default Verification;