import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'


const ProtectedRoute = ({Component,permission = []}) => {


    // const {user} = useSelector((state)=> state.user)
    
    useEffect(() => {
        let user = sessionStorage.getItem('token');

        if(!user){
            <Navigate to={"/"}/>
        }
    })


    

  return (
    <div>
        <Component permission ={permission} />
    </div>
  )
}

export default ProtectedRoute