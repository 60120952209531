import React, { useContext, useEffect, useState } from "react";
import "./styles/overview.scss";
import TotalMembers from "./TotalMembers";
import AmountOverview from "./AmountOverview";
import PaymentCards from "./PaymentCards";
import { getServiceDetail, getTotalPayinPauout, getUserAccount } from "../../utils/api";
import DatePicker from "react-datepicker";
import { getDateFromDateObj } from "../../utils/common";
import { useSelector } from "react-redux";
import { UserContextData } from "../../App";
require('react-datepicker/dist/react-datepicker.css')


const Overview = ({permission,}) => {
  
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [optionValue, setOptionValue] = useState("TODAY")
    const [customDate, setCustomDate] = useState(false)
    const [serviceData, setServiceData] = useState({})
    const [totalPayinPayout, setTotalPayinPayout] = useState({})
    const { user } = useSelector((state) => state.user);

  const userUpdate = useContext(UserContextData)
    const handleOptionValue = (e) => {
        const optionValue = e.target.value;
        setOptionValue(optionValue)
        setCustomDate(optionValue === "CUSTOM")
        setEndDate("")
        setStartDate("")

    }
    const serviceListData = () => {
        let _fromDate = getDateFromDateObj(startDate, "YYYY-MM-DD")
        let _toDate = getDateFromDateObj(endDate, "YYYY-MM-DD")

        if ((getDateFromDateObj(startDate, "YYYY-MM-DD") === "Invalid date") || (getDateFromDateObj(endDate, "YYYY-MM-DD") === "Invalid date") || (optionValue !== "CUSTOM")) {
            _fromDate = "";
            _toDate = "";
        }

        const param = {
            txnFilter: optionValue,
            fromDate: _fromDate,
            toDate: _toDate,

        }

        getServiceDetail(param).then((resp) => {

            setServiceData(resp?.data?.metaInfo?.serviceListData);
        })
    }
    useEffect(() => {
        serviceListData()
        totalPayout()
    }, [optionValue,userUpdate])


    const totalPayout = () =>{

        let _fromDate = getDateFromDateObj(startDate, "YYYY-MM-DD")
        let _toDate = getDateFromDateObj(endDate, "YYYY-MM-DD")

        if ((getDateFromDateObj(startDate, "YYYY-MM-DD") === "Invalid date") || (getDateFromDateObj(endDate, "YYYY-MM-DD") === "Invalid date") || (optionValue !== "CUSTOM")) {
            _fromDate = "";
            _toDate = "";
        }

        const param = {
            txnFilter: optionValue,
            fromDate: _fromDate,
            toDate: _toDate,

        }
        getTotalPayinPauout(param).then((resp) =>{
        
            if(resp?.data.success){
                setTotalPayinPayout(resp?.data?.metaInfo?.transactionAmount)
            }else{
                setTotalPayinPayout({})
            }
           
        })
      }

    return (
        <div className="overview_container">
            <div className="overview_top_container">
                <div className="ov_head d-flex">
                    <h1 className="page-title">Dashboard</h1>
                    <select value={optionValue} onChange={handleOptionValue} className="overview_data">
                        <option value="TODAY">Today</option>
                        <option value="YESTERDAY">Yesterday</option>
                        <option value="WEEK">Weekely</option>
                        <option value="MONTH">Monthly</option>
                        <option value="YEAR">Yearly</option>
                        <option value="CUSTOM">Custom</option>

                    </select>
                </div>
                {customDate &&
                    <div className="flex gap10 justify-end mt-10">
                        <div className="input-field">
                            <div className="flex">
                                <DatePicker
                                    showIcon
                                    className="date-picker2 date_type"
                                    placeholderText="From Date"
                                    selected={startDate}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={date => setStartDate(date)}
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="input-field">
                            <div className="flex" >

                                <DatePicker
                                    showIcon
                                    className="date-picker2 date_type"
                                    placeholderText="To Date"
                                    selected={endDate}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={date => setEndDate(date)}
                                    maxDate={new Date()}

                                />
                            </div>
                        </div>
                        <button className="userlist_btn"
                            onClick={serviceListData}
                        >Search</button>
                    </div>
                }
                <div className="content_wrapper">
                    <div className="content_right">
                        <AmountOverview  />
                        {user?.userType !== "ROLE_DISTRIBUTOR" &&
                        <TotalMembers totalPayinPayout={totalPayinPayout}/>}
                        <PaymentCards serviceData={serviceData} />
                    </div>
                    {/* {user?.userType !== "ROLE_DISTRIBUTOR" && <div className="content_left">
                       <TotalMembers totalPayinPayout={totalPayinPayout}/>
                    </div>} */}
                </div>
            </div>

        </div>
    );
};

export default Overview;
