import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Pagination from "../../Components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import { getWalletList, searchUser } from "../../utils/api";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
import { getDateFromDateObj } from "../../utils/common";
import { TableWrapper } from "../../Components/styledConstant";
import { useNavigate } from "react-router-dom";
require("react-datepicker/dist/react-datepicker.css");

const options = [
  ,
  { value: "MAIN_WALLET", label: "Main Wallet" },
  { value: "AEPS_WALLET", label: "Aeps Wallet" },
];

const WalletSummary = () => {
  const [walletData, setWalletData] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElement, setTotalElement] = useState("");
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("MAIN_WALLET");
  const [txnType, setTransactionType] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState("");
  const [userName, setUserName] = useState("");
  const [userUuid, setUserUuid] = useState("");
  const [transactionId, settransactionId] = useState("");
  const [showfilter, setShowFilter] = useState("");
  const link = useRef();
  const navigate = useNavigate();

  const numberReg = /^[0-9]+$/;
  const findUserName = (e) => {
    const value = e.target.value;

    if (!numberReg.test(value) && value) {
      return;
    } else {
      setUserName(value);
      if (value.length === 10) {
        searchUser(value).then((resp) => {
          if (resp?.data?.success) {
            setUserUuid(resp?.data?.data?.uuid);
          } else {
            setUserUuid("null");
          }
        });
      }
    }
  };
  const handleApiData = async (e, key) => {
    let _fromdate = getDateFromDateObj(fromDate, "YYYY-MM-DD");
    let _todate = getDateFromDateObj(toDate, "YYYY-MM-DD");

    if (
      getDateFromDateObj(fromDate, "YYYY-MM-DD") === "Invalid date" ||
      getDateFromDateObj(toDate, "YYYY-MM-DD") === "Invalid date"
    ) {
      _fromdate = "";
      _todate = "";
    }
    setLoading(true);

    const param = {
      fromDate: _fromdate,
      pagination: {
        pageNo: currPage,
        pageSize: pageSize,
      },

      txnType: txnType,
      toDate: _todate,
      walletType: dropdownValue,
      status: status,
      userUuid: userUuid,
      transactionId: transactionId,
    };
    if (key == "CSV" || key == "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      param.pagination.pageSize = totalElement;
    }
    if (key === "search") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    if (key === "refresh") {
      dataRefresh();
      param.fromDate = "";
      param.serviceName = "";
      param.toDate = "";
      param.txnType = "";
      param.toDate = "";
      param.status = "";
      param.userName = "";
      param.userUuid = "";
      param.transactionId = "";
      param.pagination.pageNo = 0;
      setCurrPage(0);
    }
    if (key === "PDF") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    }
    getWalletList(param).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {
        setLoading(false);
        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          walletPdfData(resp?.data?.data?.content);
        } else {
          setWalletData(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);

          setTotalElement(resp?.data?.data?.totalElements);
        }
      }
    });
  };

  useEffect(() => {
    handleApiData(null, null);
  }, [dropdownValue, currPage, txnType, status]);

  const walletPdfData = (data = []) => {
    let _width = 600; // Width of the custom size
    const height = 297;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", // units: mm, cm, in, px
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });

    //Add image in PDF header
    const imgWidth = 25; // Adjust the width of the logo
    const imgHeight = 9; // Adjust the height of the logo
    const leftXPos = 14; // Adjust the X position
    const yPos = 2; // Adjust the Y position
    doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "Wallet Summary";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Download Date
    var currentDate = new Date();
    var formattedDate = [
      currentDate.getDate(),
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    ]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("-");

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);
    // Create a table from API data
    const tableData = [];

    data.forEach((item, index) => {
      let commission = (Math.round(item?.commission * 100) / 100).toFixed(2);
      let gst = (Math.round(item?.gst * 100) / 100).toFixed(2);
      let tds = (Math.round(item?.tds * 100) / 100).toFixed(2);
      let finalAmount = (Math.round(item?.finalTxnAmount * 100) / 100).toFixed(
        2
      );
      let openingAmount = (Math.round(item?.openingAmount * 100) / 100).toFixed(
        2
      );
      let currentAmount = (Math.round(item?.currentAmount * 100) / 100).toFixed(
        2
      );

      const rowData = [
        index + 1,
        item?.userFullName,
        item?.userId,
        item?.transactionId,
        item?.transactionAmout,
        item?.charges,
        commission,
        gst,
        tds,
        finalAmount,
        openingAmount,
        currentAmount,
        item.createdDate,
        item?.transactionType,
        item?.serviceType,
        item?.txnStatus,
        item?.remarks,
      ]; // Customize as needed
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [
        [
          "S.No",
          "Retailer Name",
          "Retailer Mobile No.",
          "Transaction Id",
          "Transaction Amount",
          "Charges",
          "Commission",
          "GST",
          "TDS",
          "Final Amount",
          "Opening Amount",
          "Closing Amount",
          "Created Date",
          "Transaction Type",
          "Service type",
          "Status",
          "Remark",
        ],
      ], // Customize table header
      body: tableData,
    });

    doc.save("WalletSummary.pdf");
  };

  const dataRefresh = () => {
    setStartDate("");
    setEndDate("");
    setStatus("");
    setTransactionType("");
    setUserUuid("");
    setUserName("");
    settransactionId("");
    setDropdownValue("MAIN_WALLET");
  };

  const handleTransactionType = (e) => {
    setTransactionType(e.target.value);
    setCurrPage(0);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setCurrPage(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiData();
    }
  };

  const navigationDaital = (item) => {
    navigate(`/walletTransactions/${item?.transactionId}`, {
      state: { detail: item, type: "Wallet" },
    });
  };

  return (
    <div style={{ padding: "30px" }}>
      <div className="transaction_main_container">
        <div className="userlist_head">
          <div className="userlist_heading">
            <div className="userlist_left">
              <label>Wallet List</label>
            </div>
            <div className="userlist_right">
              {downloadLoader !== "PDF" ? (
                <button
                  className="pdf"
                  onClick={(e) => handleApiData(e, "PDF")}
                >
                  PDF
                </button>
              ) : (
                <button className="pdf">
                  <div className="btn-loader">
                    <i class="fa-solid fa-spinner"></i>
                  </div>
                </button>
              )}

              {downloadLoader !== "CSV" ? (
                <button
                  className="xls"
                  onClick={(e) => handleApiData(e, "CSV")}
                >
                  CSV
                </button>
              ) : (
                <button className="xls">
                  <div className="btn-loader">
                    <i class="fa-solid fa-spinner"></i>
                  </div>
                </button>
              )}
              <CSVLink
                ref={link}
                data={pdfData}
                filename="TransactionList.csv"
              ></CSVLink>
            </div>
            <div className="userlist_filter">
              <button
                className="filter fs-16"
                onClick={(e) =>
                  showfilter === "active"
                    ? setShowFilter("")
                    : setShowFilter("active")
                }
              >
                <i class="fa-solid fa-filter"></i>
              </button>
            </div>
          </div>
        </div>
        <FilterWrapper className={`display_data ${showfilter}`}>
          <div className="filterBox">
            <div>
              <div className="flex align-items gap-15">
                <div className="input-field">
                  <input
                    type="text"
                    name="userName"
                    placeholder="Mobile Number"
                    value={userName}
                    maxLength={10}
                    onChange={findUserName}
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    name="transactionId"
                    placeholder="Transaction Id"
                    value={transactionId}
                    onChange={(e) => settransactionId(e.target.value)}
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>

                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={dropdownValue}
                    onChange={(e) => setDropdownValue(e.target.value)}
                  >
                    <option value="MAIN_WALLET">Main Wallet</option>
                    <option value="AEPS_WALLET">Aeps Wallet</option>
                  </select>
                </div>

                <div className="input-field">
                  <div className="flex">
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="From Date"
                      selected={fromDate}
                      selectsStart
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={(date) => setStartDate(date)}
                      onKeyDown={(e) => e.preventDefault()}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="input-field">
                  <div className="flex">
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="To Date"
                      selected={toDate}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      onChange={(date) => setEndDate(date)}
                      onKeyDown={(e) => e.preventDefault()}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="input-field">
                  <select
                    value={txnType}
                    onChange={handleTransactionType}
                    className="input_select_field"
                  >
                    <option value={""}>Transaction Type</option>
                    <option value="DEBIT">Debit</option>
                    <option value="CREDIT">Credit</option>
                  </select>
                </div>
                <div className="input-field">
                  <select
                    value={status}
                    onChange={handleStatus}
                    className="input_select_field"
                  >
                    <option value={""}>Status</option>
                    <option value="SUCCESS">Success</option>
                    <option value="FAIL">Fail</option>
                    <option value="PENDING">Pending</option>
                    <option value="REFUND">Refund</option>
                  </select>
                </div>
                <div className="button">
                  <button
                    className="userlist_btn"
                    onClick={(e) => handleApiData(e, "search")}
                  >
                    Search
                  </button>
                </div>
                <div className="button">
                  <button
                    className="refresh-btn"
                    onClick={(e) => handleApiData(e, "refresh")}
                  >
                    <i className="fa fa-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FilterWrapper>
      </div>

      {walletData && (
        <div className="table-loader">
          {loading && <LocalLoader className="tableLoader" />}
          <TableWrapper className="table-card-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Retailer Name</th>
                  <th>Retailer Mobile No.</th>
                  <th>Transaction Id</th>
                  <th>Transaction Amount</th>
                  <th>Charges</th>
                  <th>Commission</th>
                  <th>GST</th>
                  <th>TDS</th>
                  <th>Final Amount</th>
                  <th>Opening Amount</th>
                  <th>Closing Amount</th>
                  <th>Created Date</th>
                  <th>Transaction Type</th>
                  <th>Service type</th>
                  <th>Status</th>
                  <th>View Invoice</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {walletData &&
                  walletData?.map((item, index) => {
                    const [date, time] = item?.createdDate.split("T");
                    let newTime = time.slice(0, 8);
                    let commision = item?.commission;

                    return (
                      <tr key={item.transactionId}>
                        <td className="td-none">
                          <span className="card-th">Retailer Name</span>
                          <span>{item?.userFullName}</span>
                        </td>

                        <td>
                          <span className="card-th">Retailer Mobile No.</span>
                          <span>{item?.userId}</span>
                        </td>

                        <td>
                          <span className="card-th">Transaction Id</span>
                          <span>{item?.transactionId || "-"}</span>
                        </td>
                        <td>
                          <span className="card-th">Transaction Amount</span>
                          <span>₹ {item?.transactionAmout}</span>
                        </td>
                        <td>
                          <span className="card-th">Charges</span>
                          <span>{item?.charges}</span>
                        </td>
                        <td>
                          <span className="card-th">Commission</span>
                          <span>
                            {(Math.round(commision * 100) / 100).toFixed(2)}
                          </span>
                        </td>

                        <td>
                          <span className="card-th">GST</span>
                          <span>
                            {(Math.round(item?.gst * 100) / 100).toFixed(2)}
                          </span>
                        </td>
                        <td>
                          <span className="card-th">TDS</span>
                          <span>
                            {(Math.round(item?.tds * 100) / 100).toFixed(2)}
                          </span>
                        </td>

                        <td>
                          <span className="card-th">Final Amount</span>
                          <span>
                            {(
                              Math.round(item?.finalTxnAmount * 100) / 100
                            ).toFixed(2)}
                          </span>
                        </td>
                        <td>
                          <span className="card-th">Opening Amount</span>
                          <span>₹ {item?.openingAmount}</span>
                        </td>
                        <td>
                          <span className="card-th">Closing Amount</span>
                          <span>₹ {item?.currentAmount}</span>
                        </td>
                        <td>
                          <span className="card-th">Created Date</span>
                          <span>{date + " " + " " + newTime}</span>
                        </td>

                        <td>
                          <span className="card-th">Transaction Type</span>
                          <span>{item?.transactionType}</span>
                        </td>
                        <td>
                          <span className="card-th">Service Type</span>
                          <span>{item?.serviceType}</span>
                        </td>
                        <td>
                          <span className="card-th">Status</span>
                          <span>
                            <div
                              className={`${
                                item?.txnStatus == "SUCCESS"
                                  ? "active_status"
                                  : item.txnStatus == "REFUND"
                                  ? "refund_status"
                                  : "inactive_status"
                              }`}
                            >
                              {item.txnStatus}
                            </div>
                          </span>
                        </td>

                        <td className="user_action_btn button">
                          <span className="card-th">View Invoice</span>
                          <span>
                            <button onClick={() => navigationDaital(item)}>
                              <i
                                class="fa-regular fa-eye fs-16 update"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </span>
                        </td>

                        <td>
                          <span className="card-th">Remark</span>
                          <span>{item?.remarks}</span>
                        </td>
                      </tr>
                    );
                  })}

                {!loading && walletData && walletData.length < 1 && (
                  <tr>
                    <td
                      colSpan={12}
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableWrapper>
        </div>
      )}

      {totalPages > 1 && (
        <Pagination
          items={walletData}
          itemsPerPage={pageSize}
          setitemsPerPage={setPageSize}
          currentPage={currPage}
          setcurrentPage={setCurrPage}
          totalPages={totalPages}
          forcePage={currPage}
        />
      )}
    </div>
  );
};

export default WalletSummary;
