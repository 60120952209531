import React,{useState} from 'react'
import "../Settings/style.scss";

export default function BBPSRechargeCommission() {

  const dataset = [
    {SNo : "1",SName:"EMI",Rtcommission:"100",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "2",SName:"Broadband",Rtcommission:"10",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "3",SName:"Electricity",Rtcommission:"50",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "4",SName:"Water",Rtcommission:"40",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "5",SName:"Insurance",Rtcommission:"80",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "6",SName:"Water",Rtcommission:"40",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "7",SName:"Insurance",Rtcommission:"80",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "8",SName:"Water",Rtcommission:"40",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "9",SName:"Insurance",Rtcommission:"80",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "10",SName:"Water",Rtcommission:"40",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "11",SName:"Insurance",Rtcommission:"80",Mdcommission:"200",Shcommission:"150",flat:"5%"}
];


  const datatable = dataset.map((data)=>{
    return(
      <tr>
        <td>{data.SNo}</td>
        <td>{data.SName}</td>
        <td>{data.Rtcommission}</td>
        <td>{data.Mdcommission}</td>
        <td>{data.Shcommission}</td>
        <td>{data.flat}</td>
        <td>
          <button className='btn_update'>Update</button>
        </td>
      </tr>
    )
    
  })
  
  return (
    <div>
        <div className='heading_data'>
          <div className='heading_row'>
             <strong>BBPS Recharge Commission</strong>
             <div className='btn_pdf'>
             <button className='btn_data'>PDF</button>
             </div>
             <div className='btn_XLS'>
             <button className='btn_data'>XLS</button>
             </div>
             
          </div>
       
        </div>
        <div className='main-container'>
        <div className="flex-container">
      <div className="row_field">
          <p className='lable-text'>Filter Record</p>
      </div>
      <div className="row_field"> 
      <div className='filter-row'>
      <select style={{width:"138px"}}>
            <option>Operator</option>
          </select>
          <input type='text' placeholder='enter member id'></input>
          <button className='btn-search'>Search</button>
        </div>  
          <div className='package_detail'>
            
            <p className='lable-text'>Package Type</p>
            
            <select>
            <option>Retailer</option>
          </select>         
          <button className='btn-save'>Save</button>  
          
          
          </div>        
      </div>
    </div>
        </div>
       
    <div className='bbpscomission_table'>
        
      <table id="customers">
          <tr>
            <th>S.No.</th>
            <th>Service Name</th>
            <th>RT Comission</th>
            <th>MD Comission</th>
            <th>SH Comission</th>
            <th>Flat?</th>
            <th>Action</th>
          </tr>
          <tbody style={{alignItems:"center"}}>{datatable}</tbody>

          {/* <tbody>
                                {apiData
                                    ? createServiceListItems()
                                    : "No DATA  AVAILABLE"}
                            </tbody> */}
                            </table>
    </div>
    </div>
  )
}
