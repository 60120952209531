import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from 'redux-thunk';
import authSlice from "./authSlice";
import userSlice from "./userSlice";


export default configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice
    },
})
