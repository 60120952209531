import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import scanner1 from "../../assets/images/scanner1.png";
import scanner2 from "../../assets/images/scanner2.png";
import scanner3 from "../../assets/images/scanner3.png";
import scanner4 from "../../assets/images/scanner4.png";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/fingerprintscan.json";

const BiometricVerification = () => {
  let color1 = "rgb(175, 175, 32)";
  const [bgColor, setBgColor] = useState(color1);
  const [show, setshow] = useState(false);

  const navigate = useNavigate();

  const changeColor = () => {
    let purple = "#A020F0";
    setBgColor(purple);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="main_container">
      <div className="container1">
        <h1>Create New Retailer</h1>
      </div>

      <div className="container_biometric">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label for="checkbox"></label>
            </div>
            <div
              style={{
                backgroundColor: "#CDCDCD",
                height: "2px",
                marginTop: "-33px",
                width: "300px",
                border: "1px dashed",
              }}
            />
            <div style={{ marginTop: "4%", fontSize: "12px", width: "20%" }}>
              Retailer Detail
            </div>
          </div>
          <div className="round round-verification">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label for="checkbox"></label>
            </div>
            <div
              style={{
                backgroundColor: "#CDCDCD",
                height: "2px",
                marginTop: "-33px",
                width: "300px",
                border: "1px dashed",
              }}
            />
            <div style={{ marginTop: "4%", fontSize: "12px", width: "20%" }}>
              ID Verification
            </div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label for="checkbox"></label>
            </div>
            <div
              style={{
                backgroundColor: "#CDCDCD",
                height: "3px",
                marginTop: "-33px",
                border: "1px dashed",
                width: "0px",
              }}
            />
            <div style={{ marginTop: "4%", fontSize: "12px", width: "20%" }}>
              Biometric Verification
            </div>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <button
              className="btn_heading"
              style={{ fontSize: "15px", marginTop: "5%" }}
            >
              Biometric Verification
            </button>
            <p style={{ fontSize: "15px", marginTop: "7%" }}>
              Select device for fingerprint verification
            </p>
          </div>
        </div>
        <div className="role">
          <div className="scanner">
            <div
              className="scanner1"
              style={{ textAlign: "center" }}
              onClick={() => {
                setshow(!show);
              }}
            >
              <img
                src={scanner1}
                style={{ marginTop: "20%", height: "69px", width: "84px" }}
              />
              <p style={{ fontSize: "15px", color: "black" }}>Mantra</p>
            </div>
            <div
              className="scanner1"
              style={{ textAlign: "center" }}
              onClick={() => {
                setshow(!show);
              }}
            >
              <img
                src={scanner2}
                style={{ marginTop: "20%", height: "69px", width: "84px" }}
              />
              <p style={{ fontSize: "15px", color: "black" }}>Morpho</p>
            </div>
            <div
              className="scanner1"
              style={{ textAlign: "center" }}
              onClick={() => {
                setshow(!show);
              }}
            >
              <img
                src={scanner3}
                style={{ marginTop: "20%", height: "69px", width: "84px" }}
              />
              <p style={{ fontSize: "15px", color: "black" }}>Tatvik</p>
            </div>
            <div
              className="scanner1"
              style={{ textAlign: "center" }}
              onClick={() => {
                setshow(!show);
              }}
            >
              <img
                src={scanner4}
                style={{ marginTop: "20%", height: "69px", width: "84px" }}
              />
              <p style={{ fontSize: "15px", color: "black" }}>Mantra</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="msg">
            <p style={{ fontSize: "15px", marginTop: "2%" }}>
              Place your finger on the fingerprint reader
            </p>
          </div>
        </div>

        {show && (
          <div className="container_lottie">
            <Lottie options={defaultOptions} height={232} width={232}></Lottie>
          </div>
        )}
        <div className="container">
          <div className="create_container">
            <button
              className="submit_btn"
              onClick={() => {
                navigate("/fingerprintmsg");
              }}
            >
              Sumbit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BiometricVerification;
