import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../Components/Input/Input";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp";
import modalpopup from "../../assets/images/modalPopubg.svg"
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";
import { changeAdminPassword } from "../../utils/api";
import { isEmpty } from "../../utils/common";

const ChangePassword = () => {

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const INIT_ERRORS = {
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  };

  const [changePasswordData, setChangePasswordData] = useState(initialValues);
  const [errors, setErrors] = useState(INIT_ERRORS);
  const [passwordType, setpasswordType] = useState("password");
  const [passwordChanged, setPasswordChanged] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
  };
  const passwordChange = async (e) => {
    const err ={}
    if(changePasswordData.currentPassword === "") {
      setErrors((prev) => ({
        ...prev,
        currentpassword: "Please enter current password",
      }));
      err.currentpassword ="kk"

    }
    if(changePasswordData.newPassword === "") {
      setErrors((prev) => ({
        ...prev,
        newpassword: "Please enter your new password",
      }));
      err.currentpassword ="kk"
    }
    if (changePasswordData.confirmPassword === "") {
      setErrors((prev) => ({
        ...prev,
        confirmpassword: "Please enter your confirm password",
      }));
      err.currentpassword ="kk"
    }else if (changePasswordData.newPassword !== changePasswordData.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmpassword: "New password and Confirm password are not matched",
      }));
      err.currentpassword ="kk"
    }
    if(isEmpty(err)){

      changeAdminPassword(changePasswordData).then((resp) => {
              
        if (resp?.data?.success === true) {
            setPasswordChanged({msg:"Password changed successfully",type:"SUCCESS"})
        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.message;
          }
          setPasswordChanged({msg:msg,type:"errorbutton"})
        }
      });
    }
  };

  let name, value;
  const handleInput = (e) => {
    setErrors({});
    name = e.target.name;
    value = e.target.value;

    setChangePasswordData({ ...changePasswordData, [name]: value });
  };
 

  const handleTogglePassword = () => {
    if (passwordType === "password") {
      setpasswordType("text");
      return;
    }
    setpasswordType("password");
  };
  return (
    <div className="main_container">
      <div className="container_form d-flex justify-center password_margin">
        <div className="change_password gap-20">
          <div
            className="role "
            style={{
              width: "100% !important",
              marginTop: "0px !important",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="retailer ">
              <button
                
                className="justify-center fw-bold fs-20 change_Password_heading"
              >
                Change Password
              </button>
            </div>
          </div>
          <div className="role changesPass">
            <div className="retailer">
              <div className="labelname">
                <label>Current Password</label>
                <span className="required"> *</span>
              </div>
              <div className="">
                <Input
                  className="input-width"
                  type="text"
                  placeholder="enter you current password"
                  name="currentPassword"
                  value={changePasswordData.currentPassword}
                  onChange={handleInput}
                />

                {errors.currentpassword && (
                  <p className="error fs-12 mt-10">{errors.currentpassword}</p>
                )}
              </div>
            </div>
          </div>

          <div className="role changesPass">
            <div className="retailer">
              <div className="labelname">
                <label>New Password</label>
                <span className="required"> *</span>
              </div>
              <div style={{ position: "relative" }}>
                <Input
                  className="inputtype"
                  type={passwordType}
                  placeholder="enter you new password"
                  name="newPassword"
                  value={changePasswordData.newPassword}
                  onChange={handleInput}
                />

                <button className="eye_icon" onClick={handleTogglePassword}>
                  {passwordType === "password" ? (
                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  )}
                </button>

                {errors.newpassword && (
                  <p className="error fs-12 mt-10">{errors.newpassword}</p>
                )}
              </div>
            </div>
          </div>
          <div className="role changesPass">
            <div className="retailer">
              <div className="labelname">
                <label>Confirm Password</label>
                <span className="required"> *</span>
              </div>

              <Input
                type="text"
                placeholder="enter you confirm password"
                name="confirmPassword"
                value={changePasswordData.confirmPassword}
                onChange={handleInput}
              />
              {errors.confirmpassword && (
                <p className="error fs-12 mt-10">{errors.confirmpassword}</p>
              )}
            </div>
          </div>

          <div className="role changesPass">
            <div className="retailer flex">
              <button
                className="btn_submit"
                style={{
                  color: "white",
                  backgroundColor: "#2ea251",
                  height: "40px",
                  width: "100%",
                  border: "none",
                  marginBottom: "20px",
                  borderRadius:"5px",
                }}
                onClick={() => {
                  passwordChange();
                }}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>

      {!isEmpty(passwordChanged) && (
        <ModalPopUp>
          <div
            className={
              `${passwordChanged.type} && ${
                passwordChanged.type === "errorbutton"
              }`
                ? "error-popup"
                : "modal-popup"
            }
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
            }}
          >
            <>
              {passwordChanged.type === "SUCCESS" && (
                <>
                  <div>
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">Done!</label>
                  </div>
                </>
              )}
              <div className={`flex justify-center ${
                    (passwordChanged.type === "errorbutton")?"mt-30":""
                  }`}>
                <label className="fs-20">{passwordChanged.msg}</label>
              </div>
              <div className="flex justify-center gap-10 mt-16">
                <button
                  className={` ${
                    (passwordChanged.type === "errorbutton") ?"error_button":"confirm_button"
                  }`}
                  onClick={() => navigate("/")}
                >
                  Close
                </button>
              </div>
            </>
          </div>
        </ModalPopUp>
      )}
    </div>
  );
};
export default ChangePassword;
