
import styled from 'styled-components';
export const TableWrapper = styled.div`
	overflow: auto;
	border: 1px solid #00297024;
	border-radius: 10px;
	font-size: 11px;
	/* position: relative; */
    min-height: 100px;
	&.mobile-plan-table {
		max-height: 300px;
	}
	.table {
		width: 100%;
		border-collapse: collapse;
		box-shadow: 0px 2px 3px #0029700d;
		tbody {
			tr {
				border-bottom: 1px solid #00297024;

				&:last-child {
					border-bottom: none;
				}
			}
		}
		th,
		td {
			padding: 10px 10px;
			text-align: center;
			text-transform: capitalize;
            color: black;
			&.text-right {
				text-align: right;
			}
			&.text-center {
				text-align: center;
			}
			&.text-left {
				text-align: left;
			}
            span{
                color: black;
            }
		}
		th {
			background-color: #E0EAB9;
			border: none;
			white-space: nowrap;
			font-weight: 500;

			&.remark {
				padding: 0 60px;
			}
		}
		td {
			white-space: nowrap;
			.select-link > div {
				display: inline-block;

				color: #00baf2;
				border: 2px solid #00baf2;
				padding: 5px 15px;
				text-align: center;
				min-width: 100px;
				border-radius: 2px;
				white-space: nowrap;
				cursor: pointer;
			}
			&.remark {
				white-space: normal;
				text-align: center;
			}
			&.fail {
				color: red;
			}
			&.success {
				color: green;
			}
			.image-with-text {
				display: flex;
				align-items: center;
				img {
					margin-right: 10px;
				}
			}
		}
		.card-th{
			display: none;
		}
	}
	@media only screen and (max-width: 767px){
		padding: 20px;
		margin-inline: -20px;
		&.table-card-wrapper{
			border: none;
		
	
			.table{
				
				thead{
					display: none;
				}
				tbody{
                    tr{
				
						margin: 0px auto 20px;
					  
							border-bottom: none;
							background-color: #f9f9f9;
							border-radius: 10px;
							padding: 20px 10px;
							box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
						display: block;
						td{
							padding: 4px 10px;
							display: flex;
							justify-content: space-between;
							&>span.card-th{
                               display: block;
							   font-weight: 600;
                               color: black;
    
							}
							&>span:last-child{
								font-weight: normal;
                                color: black;
							}
							&.td-none{
								display: none;
							}
						}
					}
				}

			}
		}

	}
	
`;