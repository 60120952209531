/* eslint-disable */
// Your code here
import axios from "axios";
import { getObjectValue,isEmpty,
} from "../utils/common";
import { getAuthToken,} from "./common.js";


class Request  {
  constructor(dispatch, successFn, errorFn, authorize = true) {
    this.authorize = authorize;
    this.successFn = typeof successFn === "function" ? successFn : () => {};
    this.errorFn = typeof errorFn === "function" ? errorFn : () => {};
    this.dispatch = typeof dispatch === "function" ? dispatch : () => {};
  }

  /**
   * Make GET Requests
   * @param {string} url
   * @param {object} params
   */
  get(url, params = {}, headers_ ={}) {
    let options ;
    if(isEmpty(headers_)){
      options = {
        headers: {
          Authorization: getAuthToken(),
          "api-Authorization": getAuthToken("api-Authorization"),
          "Access-Control-Allow-Origin": "*",
        },
      };
    }else{
      options = headers_;
    }
    
    return axios
      .get(url, options)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess);
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error, "response.data", null);
        const headers = getObjectValue(error, "response.headers", null);
        const status = getObjectValue(error, "response.status", null);
        this.errorFn(data, headers, status);
        return error;
      });
  }

  /**
   * Make POST Requests
   * @param {string} url
   * @param {object} params
   */
  post(url, params,headers_ ={}) { 
    
    let options ;
    if(isEmpty(headers_)){
      options = {
        headers: {
          Authorization: getAuthToken(),
          "api-Authorization": getAuthToken("api-Authorization"),
          "Access-Control-Allow-Origin": "*",
          
        },
      };
    }else{
      options = headers_;
    }

    return axios
      .post(url, params, options)
      
      .then((response) => {
        const data = getObjectValue(response, "data", null);
       const headers = getObjectValue(response, "headers", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess);
       
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error, "response.data", null);
        const headers = getObjectValue(error, "response.headers", null);
        const status = getObjectValue(error, "response.status", null);
        this.errorFn(data, headers, status);
       
        return error;
      });
  }

  put(url, params, headers_ ={}) {
    
    let options ;
    if(isEmpty(headers_)){
      options = {
        headers: {
          Authorization: getAuthToken(),
          "api-Authorization": getAuthToken("api-Authorization"),
          "Access-Control-Allow-Origin": "*",
        },
      };
    }else{
      options = headers_;
    }
    
    return axios
      .put(url, params, options)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        this.successFn(data);
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(response, "data", null);

        this.errorFn(data);
        return error;
      });
  }

  delete(url, params = {}, headers_ ={}) {
    let options ;
    if(isEmpty(headers_)){
      options = {
        headers: {
          Authorization: getAuthToken(),
          "api-Authorization": getAuthToken("api-Authorization"),
          "Access-Control-Allow-Origin": "*",
        },
      };
    }else{
      options = headers_;
    }
    
    return axios
      .delete(url, options)
      .then((response) => {
        const data = getObjectValue(response, "data", null);
        const headers = getObjectValue(response, "headers", null);
        const isSuccess = true;
        this.successFn(data, headers, isSuccess);
        
        return response;
      })
      .catch((error) => {
        const data = getObjectValue(error, "response.data", null);
        const headers = getObjectValue(error, "response.headers", null);
        const status = getObjectValue(error, "response.status", null);
        this.errorFn(data, headers, status);
      
        return error;
      });
  }

}

export default Request;
