import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { bankDetailsKyc, getBankList } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { isAlphabet, isEmpty, isIdPattern, isNumber } from "../../utils/common";
import { AlertWrapper } from "../../Components/Modal/style";

export default function BankDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  const userData = location.state;

  const InitialValues = {
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    outletId: "",
    userName: userData?.userName,
  };


  const [bankData, setBankData] = useState(InitialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableBtn,setDisableBtn] = useState(false);
  const [message, setMessage] = useState({});
  const [bankList, setBankList] = useState([])


  useEffect(() =>{
    setBankData(InitialValues);
    getBankList().then((resp) =>{
    
        setBankList(resp?.data?.data)
    })
  },[userData])


  const userBankDetail = async (e) => {

    let regifsc = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    let regpan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/);
  const error ={};
     

    if (bankData.userName ===undefined || bankData.userName === "") {
      error.userName = "Please enter your Mobile Number";
     
    } else if (bankData?.userName?.length < 10) {
      error.userName = "Mobile number can't be less than 10 digits";
    
    }else if (/\s/.test(bankData.userName)) {
      error.userName = "Please enter valid mobile number";
      
    }
  
    if (bankData.bankName === "") {
      error.bankname = "Please enter your Bank Name";
      
    }
    
    if (bankData.accountNumber === "") {
      error.accountnumber =  "Please enter your Account Number";
     
    } else if (bankData?.accountNumber.length < 6) {
      error.accountnumber =  "Bank account number can't be less than 6 digits";
    
    }

    if (bankData.ifscCode === "") {
      error.ifsccode = "Please Enter IFSC Code";
      
    } else if (regifsc.test(bankData.ifscCode) != true) {
      error.ifsccode =  "Enter the valid format of IFSC Code";
     
    } 


    setErrors(error);

    if(isEmpty(error)){
     
      setLoading(true);
      bankDetailsKyc(bankData).then((resp) => {
        setDisableBtn(true);
       
        if (resp?.data?.success === true) {
          setMessage({msg:"User bank details is done successfully."
          ,type:"success"});
        
          setTimeout(() =>{
            navigate("/Verification", {state:{userData:userData,skip:""}});
            setDisableBtn(false);
            setLoading(false);
          },2000)
          
        }else{
          setLoading(false);
         
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }
          setMessage({msg:msg,type:"error"})
          setDisableBtn(false)
        }
      });
    
    }

    
};
 
  const handleInput = (e, key = "") => {
    setErrors({});
    let name = e.target.name;
    let value = e.target.value;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    } else if (key === "textNum") {
      if (!isIdPattern(value) && !isEmpty(value)) {
        return;
      }
    }
    if (name === "panNo" || name === "ifscCode") {
      let upperCase = value.toUpperCase();
      setBankData({ ...bankData, [name]: upperCase });
    } else {
      setBankData({ ...bankData, [name]: value });
    }

    //setBankData({ ...bankData, [name]: value });
  };

  useEffect(() =>{
    if(!isEmpty(message)){
      setTimeout(() =>{
        setMessage({});
      },4000)
    }
  });

  return (
    <div className="main_container">
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      <div className="container_form">
        
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round round-addBank">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable">Select Package</div>
          </div>
          <div className="round round-selectpackage">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" style={{backgroundColor:"#0000ff"}} className="active"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable">Bank Detail</div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">ID Verification</div>
          </div>
        </div>
       
  <div className="shadowBox">
        <div className="">
          <div className="">
            <h3>Bank Details</h3>
          </div>
        </div>

      
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>
                Mobile No<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="mobile"
                placeholder="Please enter registered mobile no."
                name="userName"
                maxLength={10}
                value={bankData.userName}
                disabled={true}
               // onChange={(e) => handleInput(e, "number")}
              ></input>
            </div>
            {errors.userName && (
                <p className="error fs-12">{errors.userName}</p>
              )}
          </div>
          {/* <div className="retailer">
            <div className="labelname">
              <label>
                Bank Name<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter Bank name."
                name="bankName"
                value={bankData.bankName}
                onChange={(e) => handleInput(e, "text")}
              ></input>
            </div>

            {errors.bankname && (
                <p className="error fs-12">{errors.bankname}</p>
              )}
          </div> */}
            <div className="retailer">
              <div className="labelname">
                <label>Bank Name</label>
                <span className="required"> *</span>
              </div>
              <div className="input-field">
              <select
                  className="input_select_field"
                  name="bankName"
                  value={bankData.bankName}
                  onChange={(e) => handleInput(e, "text")}
                >
                  <option value={""}>Select Bank</option>
                  {
                   !isEmpty(bankList) && bankList.map((val) =>{
                     return( <option value={val?.bankName}>{val?.bankName}</option>)
                   })
                  }
                 
                </select>
              {errors?.bankname && (
                <p className="error fs-12 mt-10">{errors?.bankname}</p>
              )}
            </div>
            </div>
          
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>
                Account No.<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter bank account number"
                name="accountNumber"
                value={bankData.accountNumber}
                onChange={(e) => handleInput(e, "number")}
                maxLength={16}
              ></input>
            </div>
            {errors.accountnumber && (
                <p className="error fs-12">{errors.accountnumber}</p>
              )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                IFSC<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter IFSC code"
                maxLength={11}
                name="ifscCode"
                value={bankData.ifscCode}
                onChange={(e) => handleInput(e, "textNum")}
              ></input>
            </div>
            {errors.ifsccode && (
                <p className="error fs-12">{errors.ifsccode}</p>
              )}
          </div>
        </div>

   

        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <button
                className="submit_btn submit_btn_responsive"
                disabled={disableBtn}
                onClick={userBankDetail}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="retailer">
            <div className="labelname gap-20">
              
              <button
                className="submit_btn submit_btn_responsive"
                onClick={() => {
                  navigate("/Verification",{ state: {userData:userData,skip:"SkipKey"} });
                }}
              >
                Skip 
              </button>
            </div>
            
          </div>
        </div>
        </div>
      </div>
      {loading && <GloabalLoader />}
    </div>
  );
}
