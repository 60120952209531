import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers : {
        getUser: (state,action) => {
            state.user = action.payload;
        },
        deleteUser: (state) => {
            state.user = null
        }
    }
});

export const { getUser, deleteUser } = userSlice.actions;

export default userSlice.reducer;