import React, { useEffect, useState } from "react";
import totalmember from "../../assets/images/total_member.png";
import { getOutstandDetail, serviceList } from "../../utils/api";
import "../../pages/overview/styles/overview.scss";
import upArrow from "../../assets/images/upArrow.svg";
import down from "../../assets/images/upArrowgreen.svg";

export default function TotalMembers({totalPayinPayout}) {
  const [isToggled, setIsToggled] = useState(false);
  const [services, setServices] = useState([]);
  const [outstandDetails, setOutstandDetail] = useState({});

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleservicesData = async (e) => {
    const param = {
      fromDate: "",
      pageNo: 0,
      pageSize: 20,
      serviceName: "",
      toDate: "",
    };

    serviceList(param).then((resp) => {
      if (resp?.data?.success) {
        setServices(resp?.data?.data?.content);
      } else {
      }
    });
  };

  useEffect(() =>{
    getOutstandDetail().then((resp) =>{
        
        if (resp?.data?.success) {
            setOutstandDetail(resp?.data?.data);
          } 
    })
},[]);

  return (
    <>
      {/* <div className="total_members">
                <div className='total_members_pic-with_right_text'>
                    <div className='pic'>
                        <img src={totalmember}></img>
                    </div>
                    <div className='text-info'>
                        <span>Total Members</span>
                        <strong>0</strong>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>AEPS</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>Aadhar Pay</strong>
                        <div className={`custom-toggle toggle_button`} >
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>Recharge</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>Bill</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>DMR</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>DMT</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>Payout</strong>
                        <div className={`custom-toggle toggle_button`} >
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
                <div className="members-list-wrapper">
                    <div className="members-list">
                        <strong>UTI Pan</strong>
                        <div className={`custom-toggle toggle_button`}>
                            <div className="slider"></div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="payment-cards totalRecord">
        <Card icon={upArrow} title=" Total Outstanding" cls={"red"} amount={outstandDetails?.totalOutStanding || 0} count="0" />
        <Card icon={down} title="Total Deposit"cls={"green"} amount={outstandDetails?.totalDeposit || 0} count="0" />
        <Card icon={upArrow} title=" Total Payout " cls={"red"} amount={totalPayinPayout?.payOutAmount || 0} count="0" />
        <Card icon={down} title="Total Payin" cls={"green"} amount={totalPayinPayout?.payInAmount || 0} count="0" />
        
    </div>
    </>
  );
}


const Card = ({icon="",title="",amount=0,count=0 , cls=""}) =>{

    return(
        <div className={`payment-card ${cls}`}>
        <div className="flex justify-between">
          <div className="payment-logo">
            <img src={icon}></img>
          </div>
          <div className="payment-title" style={{ fontWeight: "600" }}>
          {title}
        </div>
        </div>

        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12" style={{marginTop:"12px"}}>
              <span style={{ color: "black" }}>Amount : </span>₹ {amount}
            </div>
            {/* <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
             {count}
            </div> */}
          </div>
        </div>
      </div>
    )

}