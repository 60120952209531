import Request from "./Request";
import urls from "./urls";

const apiRequest = new Request(
  () => {},
  () => {},
  () => {}
);


export const getUserLogin = (params) => {
 
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.USER_LOGIN}`,
    params
  );
};
export const LoginPasswordReset = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.RESET_PASSWORD}`,
    params
  );
};

export const getUserAccount = (params) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.login.GET_USER_ACCOUNT}`,
    params
  );
};

export const getUserList = (params,pagination) => {
  const {currentPage,pageSize} = pagination;
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.GET_USER_LIST}?pageNo=${currentPage}&pageSize=${pageSize}`,
    params
  );
};

export const forgetPasswordGenerateOtp = (params,) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.FORGET_PASSWORD_GENERATE_OPT}`,
    params
  );
};
export const updateUserDaital = (params,) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.EDIT_USER}`,
    params
  );
};

export const  deteleUserFromList = (id) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.users.DELETE_USER}/${id}`,
  );
};

export const  userStatusUpdate = (id,status) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.users.USER_STATUS}/${id}/${status}`,
  );
};

export const addUser = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.ADD_USER}`,
    params
  );
};

export const changeAdminPassword = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.admin.CHANGE_PASSWORD}`,
    params
  );
};
export const verificationAadhar = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.VERYFY_AADHAR}`,
    params
  );
};
export const userPackageMapping = (userId,pkgCode) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.USER_PPACKAGE_MAPPING}?userId=${userId}&pkgCode=${pkgCode}`,
    {}
  );
};
export const aepsVerifyOtp = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.OTP_VERIFY}`,
    params
  );
};
export const bankDetailsKyc = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.users.ADD_BANK_DETAILS}`,
    params
  );
};


export const getMainTreeBalance = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.MAIN_TREE_BALANCE}`,
  );
};
export const getOutstandDetail = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.OUTSTANDING_DETAIL}`,
  );
};
export const getTotalPayinPauout = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.Overview.TOTAL_PAYIN_PAYOUT}`,
    params
  );
};

export const getAepsTreeBalance = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.AEPS_TREE_BALANCE}`,
  );
};
export const getUsersData = (userType) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.TOTAL_USERS}?userType=${userType}`,
  );
};
export const getsettlementBalance = (txnFilter) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.SETTLEMENT_BALANCE}?txnFilter=${txnFilter}`,
  );
};
export const getLiveBalance = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.Overview.LIVE_BALANCE}`,
  );
};
export const getServiceDetail = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.Overview.SERVICE_DETAILS}`,params
  );
};

export const operatorList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.Operators.OPERATORS_LIST}`,
    params
  );
};
export const serviceList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.Service.SERVICE_LIST}`,
    params
  );
};
export const updateServiceStatus = (params) =>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.UpdateService.UPDATE_SERVICE}`,
    params
  );
};


export const searchUser = (mobile, value = false) => {
      
  return apiRequest.get(
    `${urls.BASE_URL + urls.users.USER_SEARCH}/${mobile}?isWalletRequired=${value}`,
  )
}
export const debitCreditList = (params) => {
return apiRequest.post(
    `${urls.BASE_URL + urls.ADD_DEBIT_CREDIT.DEBIT_CREDIT_LIST}`,params
  )
}
export const getTransactionList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.transactions.TRANSACTION_LIST}`,
    params

  );
};

export const checkTransactionStatus = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.transactions.CHECK_TRANSACTION_STATUS}`,
    params

  );
};
export const getWalletList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.wallet.WALLET_SUMMARY}`,
    params

  );
};
export const getDebit = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.ADD_DEBIT_CREDIT.DEBIT}`,
    params

  );
};
export const getCredit = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.ADD_DEBIT_CREDIT.CREDIT}`

  );
};

export const createDebitCredit = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.ADD_DEBIT_CREDIT.CREATE_DEBIT_CREDIT}`,
    params

  );
};
export const getDebitCreditApprove = (param,status) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.ADD_DEBIT_CREDIT.DEBIT_CREDIT_APPROVE}?txnId=${param}&status=${status}`,
  );

  
};
export const rolePermission = (role_code) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.roleAndPermission.Role_Permission}/${role_code}`,
  )
}
export const getRoleAndPermissionList = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.roleAndPermission.ALL_PERMISSION}`,
  )
}

export const updatePermissionList = (params) =>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.roleAndPermission.UPDATE_PERMISSION}`,
    params
  );
};
export const PackageList = (params) =>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.package.PACKAGE_UPDATE}?pkgCode=DEFAULT&serviceCode=PAY_OUT&amount=1001&route=NEFT`,
   
  );
};
export const getPackageList = () =>{
  return apiRequest.get(
    `${urls.BASE_URL + urls.package.PACKAGE_LST}`
   
  );
};

export const addPackage =(params)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.package.CREATE_PACKAGE}`,
    params
   
  );

};
export const addPackageServices =(params,pkgCode)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.package.CREATE_PACKAGE_SERVICES}?pkgCode=${pkgCode}`,
    params
   
  );
};
export const getPackageCodeList = () =>{
  return apiRequest.get(
    `${urls.BASE_URL + urls.package.PACKAGE_CODE_LIST}`
   
  );
};
export const getPackageCodeDetail = (packageUuid) =>{
  return apiRequest.get(
    `${urls.BASE_URL + urls.package.PACKAGE_CODE_DETAIL}?packageUuid=${packageUuid}`
   
  );
};
export const updatePackageServices =(param)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.package.PACKAGE_UPDATE}`,
    param 
   
  );
};
export const updateConfigurationServices =(param)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.package.PACKAGE_PRICE_CONFIGURATION_UPDATE}`,
    param 
   
  );
};

export const getRoute = (serviceType) =>{
  return apiRequest.get(
    `${urls.BASE_URL + urls.package.PACKAGE_ROUTE}?serviceType=${serviceType}`
   
  );
};

export const getBankList = () => {

  return apiRequest.get(`${urls.BASE_URL}${urls.Service.AEPS_BANK_LIST}`);
};

export const updateAdminProfile = (params) => {
  return apiRequest.put(
      `${urls.BASE_URL + urls.admin.EDIT_ADMIN_USER}`,params
    )
  }

export const tickectList =(param,page,pagesize)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.ticketManagement.GET_TICKET_LIST}?pageNo=${page}&pageSize=${pagesize}`,
    param 
   
  );
};
export const createTicket =(param)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.ticketManagement.CREATE_TICKET}`,
    param,
  );
};

export const updateTicket =(param)=>{
  return apiRequest.post(
    `${urls.BASE_URL + urls.ticketManagement.UPDATE_TICKET}`,
    param,
  );
};
export const getCategoryList = () => {
  return apiRequest.get(`${urls.BASE_URL}${urls.ticketManagement.GET_CATEGORY}`);
};
export const getTicketStatus = () => {
  return apiRequest.get(`${urls.BASE_URL}${urls.ticketManagement.GET_STATUS}`);
};
export const getTicketRequestType = () => {
  return apiRequest.get(`${urls.BASE_URL}${urls.ticketManagement.GET_REQUESTTYPE}`);
};

export const getTicketPriority = () => {
  return apiRequest.get(`${urls.BASE_URL}${urls.ticketManagement.GET_PRIORITY}`);
};
export const getChatMessage = (ticketId,toId,currPage,pageSize) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.chatData.GET_MESSAGE}?ticketId=${ticketId}&pageNo=${currPage}&pageSize=${pageSize}&toId=${toId}`
  );
};
export const sendChatMessage = (param) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.chatData.SEND_MESSAGE}`, param
  );
};


export const sendMoneyRequest = (params) => {
	return apiRequest.post(`${urls.BASE_URL}${urls.sendMoney.SEND_MONEY_URL}`, params);
};
export const getApiUserList = ({pageSize = 10,pageNo = 0}) => {
	return apiRequest.get(`${urls.BASE_URL}${urls.api.GET_API_USER_LIST}?pageNo=${pageNo}&pageSize=${pageSize}`);
};
export const createVendorUser = (params) => {
	return apiRequest.post(`${urls.BASE_URL}${urls.api.CREATE_VENDOR_USER}`,params);
};

export const getVendorTransctionList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL}${urls.api.VENDOR_TRANSACTION_LIST}`,
    params
  );
  }

