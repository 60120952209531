import React from "react";

const Reset = () => {
  return (
    <div className="right">
      <div className="img">
        <img src={"bg"} alt="waves" />
      </div>

      <div className="login_form_wrapper">
        <div className="login_form">
          <div className="logo">
            <img src={"logo"} alt="logo" />
          </div>
          <div className="form">
            <p className="fs-20 mt-20 fw-700">Reset Your Password.</p>

            {<p className="fs-16 mt-14">You can reset your password here.</p>}

            {/* login fields  */}

            <>
              <div className="input_field otp_field mt-30">
                <input
                  type="password"
                  placeholder="* * * * *"
                  style={{ paddingLeft: "10px" }}
                />
                {/* <div className="icon">
                                    <i className="fa-solid fa-mobile-screen-button"></i>
                                </div> */}
                <p className="fs-12 grey_font">
                  New Password <span className="required">*</span>
                </p>
              </div>
              {/* {errors.userId && <p className="error fs-12 mt-10">{errors.userId}</p>} */}
            </>

            <>
              <div className="input_field otp_field mt-30">
                <input
                  type="password"
                  placeholder="* * * * *"
                  style={{ paddingLeft: "10px" }}
                />
                {/* <div className="icon">
                                    <i className="fa-solid fa-mobile-screen-button"></i>
                                </div> */}
                <p className="fs-12 grey_font">
                  Confirm Password <span className="required">*</span>
                </p>
              </div>
              {/* {errors.password && <p className="error fs-12 mt-10">{errors.password}</p>} */}
            </>

            <div className="actions mt-30">
              <button className="login_btn btn pointer">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
