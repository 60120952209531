import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import LocalLoader from "../../Components/Loader/LocalLoader.js";
import DatePicker from "react-datepicker";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import Pagination from "../../Components/Pagination/Pagination.jsx";
import { TableWrapper } from "../../Components/styledConstant.js";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp.jsx";
import {
  getCategoryList,
  getTicketPriority,
  getTicketStatus,
  tickectList,
} from "../../utils/api.js";
import { getDateFromDateObj, isEmpty, isNumber } from "../../utils/common.js";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData1 from "../../assets/lotties/question.json";
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";

const TicketList = () => {
  const [ticketList, setTicketList] = useState([]);
  const [ticketIdData, setTicketIdData] = useState("");
  const [downloadLoader, setDownloadLoader] = useState("");
  const [showfilter, setShowFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pdfData, setPdfData] = useState([]);
  const [totalElements, setTotalElements] = useState("");
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [categoryList, setcategoryList] = useState({});
  const [ticketPriority, setTicketPriority] = useState([]);
  const [ticketPriorityFilter, setTicketPriorityFilter] = useState("");
  const [categoryListFilter, setCategoryListFilter] = useState("");
  const [editTicket, setEditTicket] = useState({});

  const [tab, setTab] = useState("");
  const navigate = useNavigate();


  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
  };
 

  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");
  const link = useRef();
  const handleCreateTicket = () => {
    navigate("/createticket");
  };
  const handleViewTicket = (ticketId) => {
    navigate("/viewticket", { state: ticketId });
  };
  const updateTicket = (id) => {
    setEditTicket(id)
    
  };
  useEffect(() => {
    ticketListData();
  }, [currPage,tab,categoryListFilter,ticketPriorityFilter]);

  const ticketListData = (key = "") => {
    let _fromdate = getDateFromDateObj(startDate, "YYYY-MM-DD");
    let _todate = getDateFromDateObj(endDate, "YYYY-MM-DD");

    if (
      getDateFromDateObj(startDate, "YYYY-MM-DD") === "Invalid date" ||
      getDateFromDateObj(endDate, "YYYY-MM-DD") === "Invalid date"
    ) {
      _fromdate = "";
      _todate = "";
    }

    let param = {
      dateFrom: _fromdate,
      dateTo: _todate,
      // pagination: {
      //   pageNo: currPage,
      //   pageSize: pageSize,
      // },
      ticketId: ticketIdData,
      commentId: "",
      category: categoryListFilter,
      status: tab,
      project: "",
      priority : ticketPriorityFilter
    };

    if (key === "CSV" || key === "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      // param.pagination.pageSize = totalElements;
      // param.pagination.pageNo = 0;
      // setPageSize(totalElements);
      setCurrPage(0);
      
    }
    
    if (key === "search") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    if (key === "refresh") {
      dataRefresh();
      param.ticketId = null;
      param.dateFrom = null;
      param.dateTo = null;
      param.category = null;
      param.status = null;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    let size = pageSize
    if(key === "PDF" || key == "CSV"){
      size = totalElements
    }
    tickectList(param, currPage, size ).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {
        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);
          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          ticketLisPdfData(resp?.data?.data?.content);
        } else {
          setTicketList(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
        }
      } else {
        setTicketList([]);

        setTotalPages(null);

        if (resp?.data?.errorCodeList.length < 1) {
          setMsgError(resp?.data?.msg);
        } else {
          setMsgError(resp?.data?.errorCodeList[0]);
        }
      }
    });
  };

  const ticketLisPdfData = (data = []) => {
    let _width = 400; 
    const height = 297;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", 
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });

    const imgWidth = 25;
    const imgHeight = 9;
    const leftXPos = 14;
    const yPos = 2;
    const text = "ticket List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const rightXPos = pdfWidth - dateWidth - 15;
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];

    data.forEach((item, index) => {
      const dateTimeString = item.createdAt;
      let [date, time] = dateTimeString.split("T");
      let newTime = time.slice(0, 8);
      const updatedateTimeString = item.updatedAt;
      let [updateDate, updateTime] = updatedateTimeString.split("T");
      let newUpdateTime = time.slice(0, 8);
      const rowData = [
        index + 1,
        item?.ticketId,
        item?.requesterName,
        item?.assigneeName,
        item?.emailId,
        date + " " + newTime,
        item?.requestType,
        item?.category,
        item?.description,
        updateDate + " " + newUpdateTime,
        item?.status,
        item?.priority,        
        item?.summary,
       
      ]; // Customize as needed
      tableData.push(rowData);
    });
    doc.autoTable({
      head: [
        [
          "S.No",
          "Ticket Id",
          "Requester Name",
          "Assignee Name",
          "Email Id",
          "Created Date",
          "Request Type",
          "Category",
          "Description",
          "Update Date",
          "Status",
          "Priority",         ,         
          "Summary",        
        ],
      ], // Customize table header
      body: tableData,
    });

    doc.save("TicketList.pdf");
  };

  useEffect(() => {
    getCategoryList().then((resp) => {
      setcategoryList(resp?.data?.data);
    });
    getTicketPriority().then((resp) => {
      setTicketPriority(resp?.data?.data);
    });
  },[]);



  const handleDetailData = (e, key = "") => {
    const value = e.target.value;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
      setTicketIdData(e.target.value);
    }
    
  }

  
  const dataRefresh = () => {
    setStartDate("");
    setEndDate("");
    setTicketIdData("");
    setTicketPriorityFilter("");
    setCategoryListFilter("")
    setCurrPage(0);
    setTab("")
  };
  return (
    <>
      <div className="userlisthead_container">
        <div style={{ padding: "30px" }}>
          <div className="transaction_main_container">
            <div className="userlist_head">
              <div className="userlist_heading">
                <div className="userlist_left">
                  <label>Ticket</label>
                </div>

                <div className="userlist_right">
                  {downloadLoader !== "PDF" ? (
                    <button
                      className="pdf"
                      onClick={(e) => ticketListData("PDF")}
                    >
                      PDF
                    </button>
                  ) : (
                    <button className="pdf">
                      <div className="btn-loader">
                        <i class="fa-solid fa-spinner"></i>
                      </div>
                    </button>
                  )}
                  {downloadLoader !== "CSV" ? (
                    <button
                      className="xls"
                      onClick={(e) => ticketListData("CSV")}
                    >
                      CSV
                    </button>
                  ) : (
                    <button className="xls">
                      <div className="btn-loader">
                        <i class="fa-solid fa-spinner"></i>
                      </div>
                    </button>
                  )}
                  <CSVLink
                    ref={link}
                    data={pdfData}
                    filename="TicketList.csv"
                  ></CSVLink>
                </div>
                <div className="userlist_filter">
                  <button
                    className="filter fs-16"
                    onClick={(e) =>
                      showfilter === "active"
                        ? setShowFilter("")
                        : setShowFilter("active")
                    }
                  >
                    <i class="fa-solid fa-filter"></i>
                  </button>
                </div>
              </div>
            </div>

            <FilterWrapper className={`display_data ${showfilter}`}>
              <div className="filterBox">
                <div className="ticket_wrapper">
                  <div className="flex justify-between ticket_list_wrapper">
                    <ul className="flex align-item-center gap-10 ticket_list">
                      <div className="flex gap-10">
                        <li
                          className={`fw mg-left18 ${tab === "" && "active"}`}
                          onClick={() => setTab("")}
                        >
                          All
                        </li>
                        <li
                          className={`fw mg-left18 ${tab === "OPEN" && "active"}`}
                          onClick={() => setTab("OPEN")}
                        >
                          Open Tickets
                        </li>
                      </div>
                      <div className="flex gap-10">
                        <li
                          className={`fw mg-left18 ${tab === "CLOSE" && "active"}`}
                          onClick={() => setTab("CLOSE")}
                        >
                          Closed Tickets
                        </li>
                        <li
                          className={`fw mg-left18 ${tab === "REOPEN" && "active"}`}
                          onClick={() => setTab("REOPEN")}
                        >
                          Reopen Tickets
                        </li>
                      </div>
                    </ul>
                    <div className="flex gap-10">
                      <button
                        className="ticket_btn"
                        style={{display:"inline-block",marginLeft:"auto"}}
                        onClick={handleCreateTicket}
                      >
                        Create Ticket
                      </button>
                      {/* <button className="ticket_btn" onClick={handleViewTicket}>
                      View Ticket
                    </button> */}
                    </div>
                  </div>

                  <div className="flex align-items gap-10 mt-10">
                    <div>
                      <input
                        type="text"
                        name="ticketIdData"
                        placeholder="Enter ticket id"
                        value={ticketIdData}
                        onChange={(e) => handleDetailData(e, "number")}
                        
                      />
                    </div>
                    <div className="input-field">
                      <select
                        className="input_select_field"
                        value={categoryListFilter}
                        onChange={(e) => setCategoryListFilter(e.target.value)}
                      >
                        <option value={""}>Select Category</option>
                        {!isEmpty(categoryList) &&
                          categoryList.map((val) => {
                            return (
                              <option value={val?.categoryName}>
                                {val?.categoryName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="input-field">
                      <select className="input_select_field"
                      value={ticketPriorityFilter}
                      onChange={(e) => setTicketPriorityFilter(e.target.value)}>
                        <option value={""}>Select Priority</option>
                        {!isEmpty(ticketPriority) &&
                          ticketPriority.map((val) => {
                            return (
                              <option value={val}>
                                {val}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    <div className="input-field">
                      <div className="flex">
                        <DatePicker
                          showIcon
                          className="date-picker2 date_type"
                          placeholderText="From Date"
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={(date) => setStartDate(date)}
                          maxDate={new Date()}
                          
                        />
                      </div>
                    </div>
                    <div className="input-field">
                      <div className="flex">
                        <DatePicker
                          showIcon
                          className="date-picker2 date_type"
                          placeholderText="To Date"
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={(date) => setEndDate(date)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className=" button">
                      <button className="ticket_btn"
                      onClick={(e) => ticketListData(e, "search")}>Search</button>
                    </div>

                    <div className=" button">
                      <button className="refresh-btn"
                      onClick={() => ticketListData("refresh")}>
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </FilterWrapper>
          </div>

          <div className="table-loader">
          {loading && <LocalLoader className="tableLoader" />}
            <TableWrapper className="table-card-wrapper">
             
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Ticket Id</th>
                    <th>Requester Name</th>
                    <th>Assignee Name</th>
                    <th>Email Id</th>
                    <th>Created Date</th>
                    <th>Request type</th>
                    <th>Issue Category</th>
                    <th>Description</th>
                    <th>Last Update</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <th>Description</th>
                    <th>View Ticket</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketList &&
                    ticketList?.map((item, index) => {
                      const [date, time] = item.createdAt.split("T");
                      let newTime = time.slice(0, 8);
                      const [updatedate, updatetTime] =
                        item.updatedAt.split("T");
                      let UpdateNewTime = updatetTime.slice(0, 8);

                      return (
                        <tr key={item.transactionId}>
                          <td className="td-none">
                            <span className="card-th">S.No</span>
                            <span>{currPage * pageSize + index + 1}</span>
                          </td>

                          <td>
                            <span className="card-th">Ticket Id</span>
                            <span>{item?.ticketId}</span>
                          </td>

                          <td>
                            <span className="card-th">Requester Name</span>
                            <span>{item?.requesterName || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Assignee Name</span>
                            <span>{item?.assigneeName || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Email Id</span>
                            <span>{item?.emailId || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Created Date</span>
                            <span>{date + " " + newTime || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Request type</span>
                            <span>{item?.requestType || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Issue Category</span>
                            <span>{item?.category || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Description</span>
                            <span>{item?.description || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Last Update</span>
                            <span>
                              {updatedate + " " + UpdateNewTime || "-"}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">Status</span>
                            <span>{item?.status || "-"}</span>
                          </td>
                          
                          <td>
                            <span className="card-th">Priority</span>
                            <span>{item?.priority || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Description</span>
                            <span>{item?.description || "-"}</span>
                          </td>
                          <td className="user_action_btn">
                            <span className="card-th">View Ticket</span>
                            <span>
                              {" "}
                              <button onClick={() => handleViewTicket(item)}>
                                <i class="fa-regular fa-eye fs-16 update"></i>
                              </button>
                            </span>
                          </td>
                          <td className="user_action_btn">
                            <span className="card-th">Actions</span>
                            <span className="center">
                              <button
                                className=""
                                // style={{ marginRight: "12px" }}

                                onClick={() => updateTicket(item)}
                              >
                                <i class="fa-solid fa-pen-to-square update"></i>
                              </button>
                            </span>
                          </td>
                        </tr>
                      );
                    })}

                  {!loading && ticketList.length < 1 && (
                    <tr>
                      <td
                        colSpan={12}
                        style={{
                          color: "red",
                          textAlign: "center",
                          height: "140px",
                        }}
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </div>

          {totalPages > 1 && (
            <Pagination
              items={ticketList}
              itemsPerPage={pageSize}
              setitemsPerPage={setPageSize}
              currentPage={currPage}
              setcurrentPage={setCurrPage}
              totalPages={totalPages}
              forcePage={currPage}
            />
          )}
        </div>
      </div>


      {!isEmpty(editTicket) && (
        <ModalPopUp>
          <div
            className="modal-popup"
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
            }}
          >
            <div className="mt-20">
              <Lottie options={defaultOptions1} height={80} width={80}></Lottie>
            </div>

            <div className="flex justify-center ">
              <label className="fs25 blue_font fw-bold">Are you sure!</label>
            </div>
            <div className="flex justify-center">
              <label>You want to Edit Ticket.</label>
            </div>
            <div className="flex justify-center gap-10 mt-30">
              <button
                onClick={() => setEditTicket({})}
                className="cancel_button"
              >
                No, Cancel it
              </button>
              <button
              onClick={() =>
                navigate("/createticket", {state: { editTicket: editTicket, key: "editTicket" },
                })
              }
                
                className="confirm_button"
              >
                Yes, I am sure!
              </button>
            </div>
          </div>
        </ModalPopUp>
      )}
    </>
  );
};
export default TicketList;
