import React from "react";
import IconAeps from "../../assets/images/IconAeps";
import Recharge from "../../assets/images/prepaid_recharge.svg";
import Bills from "../../assets/images/Bills.jpg";
import payout from "../../assets/images/Payout.jpg";
import utiregistration from "../../assets/images/UTI_Registration.jpg";
import dmr from "../../assets/images/DMR.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { isEmpty } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import IconAddmoney from "../../assets/images/addmoney.svg";

export default function PaymentCards(serviceData) {
  const [bppsRechargeCount, setBbpsRechargeCount] = useState(0);
  const [sumBbpsRecharge, setSumBbpsRecharge] = useState(0);
  const navigate = useNavigate();
 


  let aepsamount =
    serviceData?.serviceData?.aepsTransaction?.cash_withdrawal?.amount;
  let sendmoney = serviceData?.serviceData?.moneyTransfer?.send_money?.amount;
  let upipay = serviceData?.serviceData?.moneyTransfer?.upi_pay?.amount;
  let payOut = serviceData?.serviceData?.moneyTransfer?.pay_out?.amount;
  let settlement = serviceData?.serviceData?.moneyTransfer?.settlement?.amount;
  let prepaidRecharge =
    serviceData?.serviceData?.rechargeCollection?.prepaid_recharge?.amount;
  let prepaidRechargeCount =
    serviceData?.serviceData?.rechargeCollection?.prepaid_recharge?.count;

    let addmoney = serviceData?.serviceData?.addMoney?.amount;

  let bbpsDataSum = serviceData?.serviceData?.bbpsCollection;
    

  const sumOfBbpsRecharge = () => {
    if (!isEmpty(bbpsDataSum)) {
      let bbpsdata = Object.values(bbpsDataSum);
      let sum = 0,
        count = 0;
      bbpsdata.map((val) => {
       
        sum +=  val.amount;
        count += val.count;

      });
      
      let finalSum = sum ;
      let finalCount = count;
      setSumBbpsRecharge(finalSum);
      setBbpsRechargeCount(finalCount);     
      return finalSum,finalCount;     
    } 
    else {
      setBbpsRechargeCount(0)
      setSumBbpsRecharge(0)
    } 
   
  };
 
  useEffect(() => {
    sumOfBbpsRecharge();
    // sumOfPrepaidRecharge();
  },[serviceData]);

  return (
    <div className="payment-cards">
<div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"SEND_MONEY"}})}>
        <div className="flex justify-between">
        <div className="payment-logo">
            <img src={dmr}></img>
          </div>
        <div className="payment-title" style={{ fontWeight: "600" }}>
          Send Money
        </div>
        </div>
      
        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(sendmoney)
                ? "0"
                : (Math.round(sendmoney * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(
                serviceData?.serviceData?.moneyTransfer?.send_money?.count
              )
                ? "0"
                : serviceData?.serviceData?.moneyTransfer?.send_money?.count}
            </div>
          </div>
          
        </div>
      </div>
      
      <div className="payment-card"
      onClick={(e) =>navigate("/transactions",{state:{type:"UPI_PAY"}}) }>
        <div className="flex justify-between">
        <div className="payment-logo">
            <img src={dmr}></img>
          </div>
        <div className="payment-title" style={{ fontWeight: "600" }}>
        UPI Pay
          
        </div>
        </div>
      
        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(upipay)
                ? "0"
                : (Math.round(upipay * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(
                serviceData?.serviceData?.moneyTransfer?.upi_pay?.count
              )
                ? "0"
                : serviceData?.serviceData?.moneyTransfer?.upi_pay?.count}
            </div>
          </div>
          
        </div>
      </div>


      <div className="payment-card cursor"
      onClick={(e) => navigate("/transactions",{state:{type:"AEPS"}})}
      >
        <div className="flex justify-between">
          <div className="payment-logo">
            <IconAeps />
          </div>
          <div
            className="payment-title"
            style={{ fontWeight: "600" }}
          >
            AEPS
          </div>
        </div>

        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹
              {isEmpty(aepsamount)
                ? "0"
                : (Math.round(aepsamount * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(
                serviceData?.serviceData?.aepsTransaction?.cash_withdrawal
                  ?.count
              )
                ? "0"
                : serviceData?.serviceData?.aepsTransaction?.cash_withdrawal
                    ?.count}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="payment-card "
       onClick={(e) => navigate("/transactions",{state:{type:"BBPS"}})}
      >
        <div className="flex justify-between">
          <div className="payment-logo">
            <img src={AadharIcon}></img>
          </div>
          <div
            className="payment-title"
            style={{ fontWeight: "600" }}
          >
            Credit Card
          </div>
        </div>

        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(creditCardBill)
                ? "0"
                : (Math.round(creditCardBill * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(
                serviceData?.serviceData?.bbpsCollection?.credit_card_bill
                  ?.count
              )
                ? "0"
                : serviceData?.serviceData?.bbpsCollection?.credit_card_bill
                    ?.count}
            </div>
          </div>
        </div>
      </div> */}

      <div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"PREPAID_RECHARGE"}})}>
        <div className="flex justify-between">
          <div className="payment-logo">
            <img src={Recharge}></img>
          </div>
          <div
            className="payment-title"
            style={{ fontWeight: "600" }}
          >
            Prepaid Recharge
          </div>
        </div>

        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(prepaidRecharge)
                ? "0"
                : (Math.round(prepaidRecharge * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(prepaidRechargeCount) ? "0" : prepaidRechargeCount}
            </div>
          </div>
        </div>
      </div>
      <div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"BBPS"}})}>
        <div className="flex justify-between">
          <div className="payment-logo">
            <img src={Bills}></img>
          </div>
          <div
            className="payment-title"
            style={{ fontWeight: "600" }}
          >
            BBPS
          </div>
        </div>
        <div className="bottom-info">
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(sumBbpsRecharge)
                ? "0"
                : (Math.round(sumBbpsRecharge * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(bppsRechargeCount) ? "0" : bppsRechargeCount}
            </div>
          </div>
        </div>
      </div>

      <div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"PAY_OUT"}})}>
        <div className="flex justify-between">
        <div className="payment-logo">
            <img src={payout}></img>
          </div>
        <div className="payment-title" style={{ fontWeight: "600" }}>
          Payout
        </div>
        </div>
    
        <div className="bottom-info">
          
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(payOut)
                ? "0"
                : (Math.round(payOut * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count : </span>
              {isEmpty(serviceData?.serviceData?.moneyTransfer?.pay_out?.count)
                ? "0"
                : serviceData?.serviceData?.moneyTransfer?.pay_out?.count}
            </div>
          </div>
        </div>
      </div>

    


      <div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"SETTLEMENT"}})}>
        <div className="flex justify-between">
        <div className="payment-logo">
            <img src={utiregistration}></img>
          </div>
        <div className="payment-title" style={{ fontWeight: "600" }}>
          Settlement Balance
        </div>
        </div>
      
        <div className="bottom-info">
          
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(settlement)
                ? "0"
                : (Math.round(settlement * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count :</span>
              {isEmpty(
                serviceData?.serviceData?.moneyTransfer?.settlement?.count
              )
                ? "0"
                : serviceData?.serviceData?.moneyTransfer?.settlement?.count}
            </div>
          </div>
        </div>
      </div>
      <div className="payment-card"
      onClick={(e) => navigate("/transactions",{state:{type:"PAYMENT_GATEWAY"}})}>
        <div className="flex justify-between">
        <div className="payment-logo">
        <img src={IconAddmoney}></img>
          </div>
        <div className="payment-title" style={{ fontWeight: "600" }}>
         Add Money
        </div>
        </div>
      
        <div className="bottom-info">
          
          <div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Amount : </span>₹{" "}
              {isEmpty(addmoney)
                ? "0"
                : (Math.round(addmoney * 100) / 100).toFixed(2)}
            </div>
            <div className="payment-count fs-12">
              <span style={{ color: "black" }}>Count :</span>
              {isEmpty(
                serviceData?.serviceData?.addMoney?.count
              )
                ? "0"
                : serviceData?.serviceData?.addMoney?.count}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
