import React, { useEffect, useState } from "react";

import "./Aside.scss";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { FaBars } from "react-icons/fa";
import { getBankList, sendMoneyRequest } from "../../utils/api";
import {
  isAlphabet,
  isEmpty,
  isNumber,
  numberToWords,
} from "../../utils/common";
import Spinner from "../spinner/Spinner";
import { AlertWrapper } from "../Modal/style";
import Receipt from "../Receipt/Receipt";

const initialFormData = {
  accountNumber: "",
  beneficiaryID: "",
  beneficiaryName: "",
  ifscCode: "",
  merchantId: "",
  mobileNumber: "",
  remittanceAmount: "",
  latitude: "24.034401",
  longitude: "74.780403",
  remarks: " ",
  route: "IMPS",
  mode: "YES_BANK",

};
const initialFormDataErr = {
  accountNumber: "",
  beneficiaryID: "",
  beneficiaryName: "",
  ifscCode: "",
  merchantId: "",
  mobileNumber: "",
  remittanceAmount: "",
  mode: "",
};
function AsideLeft({ data = [], close = () => {}, refresh = () => {} }) {
  const [fromData, setFromData] = useState({ ...initialFormData });
  const [fromDataErr, setFromDataErr] = useState(initialFormDataErr);
  const [bankList, setBankList] = useState([]);
  const [loding, setLoding] = useState(false);
  const [cls, setCls] = useState("");
  const [message, setMessage] = useState({});
  const [responceData, setResponceData] = useState({});
  const closeBox = () => {
    setCls(" active wait");
    setTimeout(() => {
      close(false);
    }, 300);
  };

  useEffect(() => {
    getBankList().then((resp) => {
      setBankList(resp?.data?.data);
    });
    setTimeout(() => {
      setCls("active");
    });
  }, []);

  let regifsc = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
  const onSubmit = () => {
    let error = {};

    if (isEmpty(fromData.mode)) {
      error = { ...error, mode: "Please select debit account" };
    }

    if (isEmpty(fromData.beneficiaryName)) {
      error = { ...error, beneficiaryName: "Please enter beneficiary name" };
    }
    if (isEmpty(fromData.accountNumber)) {
      error = { ...error, accountNumber: "Please enter account number" };
    }
    if (isEmpty(fromData.bankName)) {
      error = { ...error, bankName: "Please select bank" };
    }
    if (isEmpty(fromData.ifscCode)) {
      error = { ...error, ifscCode: "Please enter ifsc" };
    } else if (regifsc.test(fromData.ifscCode) != true) {
      error = { ...error, ifscCode: "Please enter the valid format of ifsc" };
    }
    if (isEmpty(fromData.remittanceAmount)) {
      error = { ...error, remittanceAmount: "Please enter amount" };
    }
    setFromDataErr({ ...fromDataErr, ...error });

    if (isEmpty(error)) {
      const params = {
        name: fromData.beneficiaryName,
        accountNumber: fromData.accountNumber,
        bankIfsc: fromData.ifscCode,
        transferMode: fromData.route,
        transferAmount: fromData.remittanceAmount,
        latitude: "24.034401",
        longitude: "74.780403",
        remarks: fromData.remarks,
        txnMode: "PAY_OUT",
        txnFlag: fromData.mode,
      };
      setLoding(true);

      sendMoneyRequest(params).then((response) => {

        setLoding(false);
        if (response?.data?.success) {
          setResponceData(response?.data?.data);
          setFromData({ ...initialFormData });
          refresh("refresh");
        } else {
          let msg = "";
          if (response?.data?.errorCodeList.length > 0) {
            msg = response?.data?.errorCodeList[0];
          } else {
            msg = response?.data?.msg;
          }
          setMessage({ msg: msg, type: "error" });
        }
      });
    }
  };

  function onchangeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "beneficiaryName") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }
    if (name === "accountNumber" || name === "remittanceAmount") {
      if (!isNumber(value) && !isEmpty(value)) return;
      if (value == "0") return;
    }

    if (name === "remittanceAmount" && value > 500000) {
      return;
    }

    if (name === "ifscCode") {
      let upperCase = value.toUpperCase();
      setFromData({ ...fromData, [name]: upperCase });
    } else {
      setFromData({ ...fromData, [name]: value });
    }

    setFromDataErr({ ...fromDataErr, [name]: "" });
  }

  const routeHandler = (routeType) => {
    setFromData({ ...fromData, route: routeType });
    setFromDataErr({ ...fromDataErr, route: "" });
  };

  const onCloseModal = () => {
    setResponceData({});
  };

  return (
    <aside className={`aside-payout flex ${cls} `}>
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="grow-1" onClick={() => closeBox()}></div>

      <div className="aside">
        <FilterWrapper style={{ height: "auto" }} className={`display_data`}>
          <div className="filterBox">
            <div>
              <div className="top flex gap-10 justify-between align-items closeButton">
                <FaBars onClick={() => closeBox()} />
              </div>
              <div className="flex align-items gap-20">
                <div className="input-field">
                  <select
                    className="input_select_field"
                    name="mode"
                    value={fromData.mode}
                    onChange={onchangeHandler} 
                  >

                    <option value="YES_BANK">Yes Bank</option>
                    <option value="DOISM">DPay</option>
                  </select>

                  <label htmlFor="">Select Debit Account</label>
                  {fromDataErr.mode && (
                    <p className="error fs-12 mt-10">{fromDataErr.mode}</p>
                  )}
             
                </div>
              

                <div className="input-field">
                  <input
                    type="text"
                    name="beneficiaryName"
                    value={fromData?.beneficiaryName}
                    onChange={onchangeHandler}
                  />
                  <label htmlFor="">Beneficiary Name</label>
                  {fromDataErr.beneficiaryName && (
                    <p className="error fs-12 mt-10">
                      {fromDataErr.beneficiaryName}
                    </p>
                  )}
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    name="accountNumber"
                    value={fromData?.accountNumber}
                    onChange={onchangeHandler}
                  />
                  <label htmlFor="">Account Number</label>
                  {fromDataErr.accountNumber && (
                    <p className="error fs-12 mt-10">
                      {fromDataErr.accountNumber}
                    </p>
                  )}
                </div>

                <div className="input-field">
                  <select
                    className="input_select_field"
                    name="bankName"
                    value={fromData?.bankName}
                    onChange={onchangeHandler}
                  >
                    <option value="">Select Bank</option>
                    {bankList &&
                      bankList.map((val) => {
                        return (
                          <option value={val?.bankName}>{val?.bankName}</option>
                        );
                      })}
                  </select>
                  <label htmlFor="">Bank Name</label>
                  {fromDataErr.bankName && (
                    <p className="error fs-12 mt-10">{fromDataErr.bankName}</p>
                  )}
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    name="ifscCode"
                    value={fromData?.ifscCode}
                    onChange={onchangeHandler}
                  />
                  <label htmlFor="">IFSC</label>
                  {fromDataErr.ifscCode && (
                    <p className="error fs-12 mt-10">{fromDataErr.ifscCode}</p>
                  )}
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    name="remittanceAmount"
                    maxLength={100000}
                    value={fromData?.remittanceAmount}
                    onChange={onchangeHandler}
                  />
                  <label htmlFor="">Amount</label>
                  {fromDataErr.remittanceAmount && (
                    <p className="error fs-12 mt-10">
                      {fromDataErr.remittanceAmount}
                    </p>
                  )}

                  {(!isEmpty(fromData?.remittanceAmount) ||
                    fromData?.remittanceAmount != "0") && (
                    <p className="fs-12 mt-10" style={{ color: "#333" }}>
                      {" "}
                      {numberToWords(fromData?.remittanceAmount)}
                    </p>
                  )}
                </div>
                <div className="tabs flex">
                  <button
                    onClick={() => routeHandler("IMPS")}
                    className={fromData.route === "IMPS" && "active"}
                  >
                    IMPS
                  </button>
                  <button
                    onClick={() => routeHandler("NEFT")}
                    className={fromData.route === "NEFT" && "active"}
                  >
                    NEFT
                  </button>
                  <button
                    onClick={() => routeHandler("RTGS")}
                    className={fromData.route === "RTGS" && "active"}
                  >
                    RTGS
                  </button>
                </div>

                <p className="msg-text">
                  Max ₹5 lakh per transaction real time credit, work 24X7{" "}
                </p>

                <div className="input-field">
                  <input
                    type="text"
                    name="remarks"
                    value={fromData?.remarks}
                    onChange={onchangeHandler}
                  />
                  <label htmlFor="">Remarks(optional)</label>
                </div>

                <div className="input-field">
                  {!loding ? (
                    <button
                      className="userlist_btn "
                      style={{ width: "100%" }}
                      onClick={onSubmit}
                    >
                      Proceed
                    </button>
                  ) : (
                    <button
                      className="userlist_btn flex justify-center align-item "
                      style={{ width: "100%", gap: "10px" }}
                      onClick={onSubmit}
                    >
                      <Spinner /> <p>Processing</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FilterWrapper>
        {!isEmpty(responceData) && (
          <Receipt modalData={responceData} onCloseModal={onCloseModal} />
        )}
      </div>
    </aside>
  );
}

export default AsideLeft;
