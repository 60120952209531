import React, { useEffect, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";

import { useRef } from "react";
import "../Transactions/loaderstyle.css";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  checkTransactionStatus,
  getTransactionList,
  getVendorTransctionList,
  searchUser,
} from "../../utils/api";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
import {
  getDateFromDateObj,
  inputNumber,
  isEmpty,
  isNumber,
} from "../../utils/common";
import { TableWrapper } from "../../Components/styledConstant";
import { useLocation, useNavigate } from "react-router-dom";
import raiseTicket from "../../assets/images/raise_ticket.svg";
import AsideLeft from "../../Components/AsideLeft/AsideLeft";
import { useSelector } from "react-redux";
import { AlertWrapper } from "../../Components/Modal/style";
require("react-datepicker/dist/react-datepicker.css");

const VendorTransaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [totalElements,setTotalElements] = useState(10);


  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [selectOperator, setSelectOperator] = useState("");

  const [selectStatus, setSelectStatus] = useState("");

  const link = useRef();
  const [downloadLoader, setDownloadLoader] = useState("");
  const [showfilter, setShowFilter] = useState("");
  const [checkStatusMsg, setCheckStatusMsg] = useState({});

  const navigate = useNavigate();

  const [payBox, setPayBox] = useState(false);

  const { user } = useSelector((state) => state.user);

  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const numberReg = /^[0-9]+$/;

  const transactionData = (key = "") => {

    let payload = {
      pagination: {
        pageNo: currPage,
        pageSize: pageSize,
      },
    };
   
    if (key === "CSV" || key === "PDF") {
      payload = {
        pagination: {
          pageNo: 0,
          pageSize: totalElements,
        },
      };
      if (key === "CSV") {
        setDownloadLoader("CSV")
      } else {
        setDownloadLoader("PDF")
      }
    
    }else{
      setLoading(true);
    }
    getVendorTransctionList(payload).then((resp) => {
      setLoading(false);
      setDownloadLoader("")
      if (resp?.data?.message === "SUCCESS") {
        if(key===""){
          setTransactions(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
      
      }else{
       
        setPdfData(resp?.data?.data?.content);

        if(key==="csv"){
          
          setTimeout(() => {
            const csvlink = link.current;
					csvlink.link.click();
       
          }, 1);
        } else if(key==="pdf"){
          setDownloadLoader("PDF")
          setTimeout(() => {
            pdfDownload(resp?.data?.data?.content);
       
          }, 1);
         
        }

					
      }
      }
    });
  
 
  };

  const location = useLocation();
 

  useEffect(() => {

      transactionData();
   
  }, [currPage,]);

  const handleOpertaorChange = (e) => {
    setStartDate("");
    setEndDate("");
    setSelectOperator(e.target.value);
    setCurrPage(0);
  };
 

 

  const changeStatus = (e) => {
    setSelectStatus(e.target.value);
    setCurrPage(0);
    // setTimeout(() => {
    //   transactionData("refreshData");
    // });
  };

  const navigationDaital = (item) => {
    navigate(`/transactions/${item?.transactionId}`, {
      state: { detail: item, type: "Transaction" },
    });
  };

  const checkStatus = (transactionId) => {
    const param = {
      transactionId: transactionId,
    };
    checkTransactionStatus(param).then((resp) => {
     
      if (resp?.data?.success) {
        setCheckStatusMsg({ msg: resp?.data?.msg, type: "success" });
      } else {
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        setCheckStatusMsg({ msg: msg, type: "error" });
        transactionData();
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setCheckStatusMsg({});
    }, 5000);
  }, []);



  const pdfDownload = (data) => {
		const tableHeader = [
			{
				userId: 'User Id',
				txnId: 'Transaction Id',
				createdDate: 'Date',
				orderId: 'Order Id',
				subscribedService: 'Subscribed Service',
				subscribedSubService:'Subscribed Sub Service',
				status: 'Status',
				txnAmount: 'Amount',
				openingBalance: 'Opening Amount',
				closingBalance: 'Closing Amount',
			},
		];

		pdfData(data, tableHeader, 'Vendor-Transaction-History',300);
	};

  return (
    <div className="transactions_container">
      <div style={{ padding: "30px" }}>
        <div
          className="transaction_main_container"
          style={{ marginTop: "-10px" }}
        >
          <div className="userlist_head">
            <div className="userlist_heading">
              <div className="userlist_left">
                <label>Vendor Transactions</label>
              </div>
              <div className="userlist_right">
                {/* {user?.userName === "8800578866" && (
                  <button
                    style={{ marginRight: "8px" }}
                    className="pdf"
                    onClick={(e) => setPayBox(true)}
                  >
                    Payout
                  </button>
                )} */}
                {downloadLoader !== "PDF" ? (
                  <button
                    className="pdf"
                    onClick={() => transactionData("PDF")}
                  >
                    PDF
                  </button>
                ) : (
                  <button className="pdf">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}

                {downloadLoader !== "CSV" ? (
                  <button
                    className="xls"
                    onClick={() => transactionData("CSV")}
                  >
                    CSV
                  </button>
                ) : (
                  <button className="xls">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="TransactionList.csv"
                ></CSVLink>
              </div>
              <div className="userlist_filter">
                <button
                  className="filter fs-16"
                  onClick={(e) =>
                    showfilter === "active"
                      ? setShowFilter("")
                      : setShowFilter("active")
                  }
                >
                  <i class="fa-solid fa-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <FilterWrapper
            style={{ height: "auto" }}
            className={`display_data ${showfilter}`}
          >
            <div className="filterBox">
              <div>
                <div className="flex align-items gap-15">
                  <div className="input-field">
                    <select
                      className="input_select_field"
                      value={selectStatus}
                      onChange={(e) => changeStatus(e)}
                    >
                      <option value={""}>Select Status</option>
                    
                    </select>
                  </div>

                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="From Date"
                        selected={fromDate}
                        selectsStart
                        valueDefault={null}
                        startDate={fromDate}
                        endDate={toDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setStartDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="To Date"
                        selected={toDate}
                        selectsEnd
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setEndDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>

                  {/* <div className=" button">
                    <button
                      className="userlist_btn"
                      onClick={() => transactionData("search")}
                      style={{ width: "100px" }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="button">
                    <button
                      className="refresh-btn"
                      style={{ width: "60px" }}
                      onClick={() => transactionData("refresh")}
                    >
                      <i className="fa fa-refresh"></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </FilterWrapper>
        </div>

        {transactions && (
          <div className="table-loader">
               {loading && <LocalLoader className="tableLoader" />}
            <TableWrapper className="table-card-wrapper">
           
              <table className="table">
                <thead>
                  <tr>
                    {/* <td>Sr. No.</td> */}

                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Order Id</th>
                    <th>Subscribed Service</th>
                    <th>Subscribed Sub Service</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Opening Balance</th>
                    <th>Closing Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(transactions) &&
                    transactions.map((item, index) => {
                      const inputDate = new Date(item?.createdDate);

                      const formattedDate = inputDate.toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      });

                      let color = "";
                      if (item?.status?.toUpperCase() === "SUCCESS") {
                        color = "success";
                      } else if (item?.status?.toUpperCase() === "REFUND") {
                        color = "yellow";
                      } else {
                        color = "error";
                      }

                      return (
                        <tr key={index}>
                          {/* <td>
                            <span className="card-th">Sr. No.</span>
                            <span className="card-td">
                              {paginastion.currentPage * paginastion.pageSize +
                                index +
                                1}
                            </span>
                          </td> */}
                          {/* <td>{item?.userId}</td> */}
                          <td>
                            <span className="card-th">Transaction ID</span>
                            <span className="card-td">{item?.txnId}</span>
                          </td>
                          <td>
                            <span className="card-th">Date</span>
                            <span className="card-td">{formattedDate}</span>
                          </td>
                          <td>
                            <span className="card-th">Order Id</span>
                            <span
                              className="link card-td"
                              onClick={() => navigationDaital(item)}
                            >
                              {item?.orderId}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">Subscribed Service</span>
                            <span className="card-td">
                              {item?.subscribedService}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">
                              Subscribed Sub Service
                            </span>
                            <span className="card-td">
                              {item?.subscribedSubService || "-"}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">Status</span>
                            <span className={"card-td " + color}>
                              {item?.status}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">Amount</span>
                            <span className="card-td">{item?.txnAmount}</span>
                          </td>
                          <td>
                            <span className="card-th">Opening Balance</span>
                            <span className="card-td">
                              {item?.openingBalance}
                            </span>
                          </td>
                          <td>
                            <span className="card-th">Closing Balance</span>
                            <span className="card-td">
                              {item?.closingBalance}
                            </span>
                          </td>
                        </tr>
                      );
                    })}

                  {isEmpty(transactions) && !loading && (
                    <tr style={{ textAlign: "center", }}>
                      <td colspan="100%" style={{ textAlign: "center", color: "red" }}>
                        No Transaction Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </div>
        )}

        {!isEmpty(checkStatusMsg) && (
          <AlertWrapper className={`${checkStatusMsg.type} globle`}>
            <span className="close" onClick={() => setCheckStatusMsg({})}>
              ×
            </span>
            {checkStatusMsg.msg}
          </AlertWrapper>
        )}

        {payBox && <AsideLeft close={setPayBox} refresh={transactionData} />}
        {totalPages > 1 && (
          <Pagination
            items={transactions}
            itemsPerPage={pageSize}
            setitemsPerPage={setPageSize}
            currentPage={currPage}
            setcurrentPage={setCurrPage}
            totalPages={totalPages}
            forcePage={currPage}
          />
        )}
      </div>
    </div>
  );
};

export default VendorTransaction;
