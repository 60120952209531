import React, { useEffect, useState } from "react";
import { BsUiRadios } from "react-icons/bs";
import "./style.scss";
import "../NewMember/RoleManagmentAndPermission/style.scss";
import uploadImage from "../../assets/images/uploadImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import { isAlphabet, isEmpty, isNumber } from "../../utils/common";
import { AlertWrapper } from "../../Components/Modal/style";
import {
  createTicket,
  getCategoryList,
  getTicketPriority,
  getTicketRequestType,
  getTicketStatus,
  searchUser,
  updateTicket,
} from "../../utils/api";

const CreateTicket = () => {
  const initialValues = {
    category: "",
    requestType: "",
    summary: "",
    email: "",
    priority: "",
    assignee: "",
    description: "",
    txnRefId: "",
    reamrks: "",
    ticketStatus: "",
    requesterName:"",
    assigneeName:"",
  };


 
  const [ticketData, setTicketData] = useState({ ...initialValues });
  const [imagePreview, setImagePreview] = useState("");
  const [errors, setErrors] = useState({});
  const [assigneeName, setAssigneeName] = useState("");
  const [userUuid, setUserUuid] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [categoryList, setcategoryList] = useState({});
  const [ticketStatus, setTicketStatus] = useState([]);
  const [ticketRequestTyppe, setTicketRequestTyppe] = useState([]);
  const [ticketPriority, setTicketPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const transactionData = location?.state;

  let ticketDataList = {};
  let key = "";
  if (!isEmpty(location.state)) {
    ticketDataList = location.state.editTicket;
    key = location.state.key;
  }


  useEffect(() => {
    getCategoryList().then((resp) => {
      setcategoryList(resp?.data?.data);
    });
    getTicketStatus().then((resp) => {
      setTicketStatus(resp?.data?.data);
    });

    getTicketRequestType().then((resp) => {
      setTicketRequestTyppe(resp?.data?.data);
    });
    getTicketPriority().then((resp) => {
      setTicketPriority(resp?.data?.data);
    });
  }, []);

  //const key = updatedata.key;

  useState(() => {
    if (!isEmpty(key)) {
      setTicketData({
        category: ticketDataList?.category,
        requestType: ticketDataList?.requestType,
        summary: ticketDataList?.summary,
        email: ticketDataList?.emailId,
        priority: ticketDataList?.priority,
        assignee: ticketDataList?.assigneeName,
        description: ticketDataList?.description,
        txnRefId: ticketDataList?.txnRefId,
        reamrks: ticketDataList?.adminRemarks,
        ticketStatus: ticketDataList?.status,
        ticketId:ticketDataList?.ticketId,
      });
      setAssigneeName(ticketDataList?.assigneeMobile)
    } else {
      setTicketData({ ...initialValues });
      setAssigneeName("")
    }
  }, [key]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleInput = (e, key = "") => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setErrors({});
    if (key === "number") {
      value = e.target.value.trim();
    }

    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }

    setTicketData({ ...ticketData, [name]: value });
  };
  //find userName
  const numberReg = /^[0-9]+$/;
  const findUserName = (e) => {
    setUserResponse("")
    const value = e.target.value;

    if (!numberReg.test(value) && value) {
      return;
    } else {
      setAssigneeName(value);
      if (value.length === 10) {
        searchUser(value).then((resp) => {
          if (resp?.data?.success) {
            setUserResponse(resp?.data?.data?.firstName +" " +resp?.data?.data?.lastName)
            setUserUuid(resp?.data?.data?.uuid);
          } else {
            setUserUuid("null");
            setUserResponse("No use found")
          }
        });
      }
    }
  };
  const handlecreateTicketData = async () => {
    let err = {};
    if (ticketData?.category === "") {
      err.category = "Please select category";
    }
    if (ticketData?.requestType === "") {
      err.requestType = "Please select request type";
    }
   
    if (ticketData?.email === "") {
      err.email = "Please enter email id.";
    } else if (!isValidEmail(ticketData.email)) {
      err.email = "Please enter valid email";
    }
    if (ticketData?.priority === "") {
      err.priority = "Please select priority";
    }
    if (ticketData?.summary === "") {
      err.summary = "Please enter summary ";
    }
    if (ticketData?.txnRefId === "") {
      err.txnRefId = "Please enter reference transaction id.";
    }
    if (ticketData?.description === "") {
      err.description = "Please enter description";
    }
    if (ticketData?.ticketStatus === "") {
      err.ticketStatus = "Please select status";
    }
    setErrors(err);
    if (isEmpty(err)) {
      setDisableBtn(true)
      setLoading(true);
      const data = new FormData();
      data.append(
        "ticketRequest",
        JSON.stringify({
          ticketId:ticketData?.ticketId,
          category: ticketData?.category,
          requestType: ticketData?.requestType,
          requesterName :ticketData?.requesterName,
          email:ticketData?.email,
          assignee: userUuid,
          priority: ticketData?.priority,
          txnRefId: ticketData?.txnRefId,
          summary: ticketData?.summary,
          description: ticketData?.description,  
          adminRemarks: ticketData?.reamrks,
          status :ticketData?.ticketStatus,
        })
      );
      data.append("file", imagePreview);

      if (key !== "editTicket") {
        setLoading(true)
        createTicket(data).then((resp) => {
          if (resp?.data?.success === true) {
            setMessage({
              msg: "Ticket created successfully.",
              type: "success",
            });
            setTimeout(()=>{
              navigate("/ticketList");
            },3000)
            
          } else {
            setLoading(false);
            let msg = "";
            if (resp?.data?.errorCodeList.length > 0) {
              msg = resp?.data?.errorCodeList[0];
            } else {
              msg = resp?.data?.msg;
            }
            setMessage({ msg: msg, type: "error" });
            setDisableBtn(false);
          }
        });
      } else {
        setLoading(true)
        updateTicket(data).then((resp) => {
          if (resp?.data?.success === true) {
            setMessage({
              msg: "Ticket updated successfully.",
              type: "success",
            });
            setTimeout(()=>{
              navigate("/ticketList");
            },3000)
            
          } else {
            setLoading(false);
            let msg = "";
            if (resp?.data?.errorCodeList.length > 0) {
              msg = resp?.data?.errorCodeList[0];
            } else {
              msg = resp?.data?.msg;
            }
            setMessage({ msg: msg, type: "error" });
            setDisableBtn(false);
          }
        });
      }
    }
  };

  return (
    <>
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="ticket_main_container">
        <div className="flex edit_operator_heading">
          <label>Ticket Management</label>
        </div>
        <div className="ticket_container pd-30">
          <label className="fw-600 fs-20">Create Ticket</label>

          <div className="ticket_management flex gap-20 wrap">
            <div className="ticket_input-field  w-22">
              <label>Category</label>
              <select
                className="ticket_input_select_field"
                value={ticketData?.category}
                name="category"
                onChange={key!=="editTicket"?handleInput:() =>{}}
                disabled={key==="editTicket"?true:false}
              >
                <option value={""}>Select Category</option>
                {!isEmpty(categoryList) &&
                  categoryList.map((val) => {
                    return <option value={val?.categoryName}>{val?.categoryName}</option>;
                  })}
              </select>
              {errors.category && (
                <p className="error fs-12 mt-10">{errors.category}</p>
              )}
            </div>
            <div className="ticket_input-field  w-22">
              <label>Request Type</label>
              <select
                className="ticket_input_select_field"
                value={ticketData?.requestType}
                name="requestType"
                onChange={key!=="editTicket"?handleInput:() =>{}}              
                disabled={key==="editTicket"?true:false}
              >
                <option value={""}>Select Request type</option>

                {!isEmpty(ticketRequestTyppe) &&
                  ticketRequestTyppe.map((val) => {
                    return <option value={val}>{val}</option>;
                  })}
              </select>
              {errors.requestType && (
                <p className="error fs-12 mt-10">{errors.requestType}</p>
              )}
            </div>
            {/* <div className="ticket_input-field  w-22">
              <label>Requester Name</label>
              <input
                type="text"
                name="requesterName"
                placeholder="Enter request name"
                value={ticketData?.requesterName}
                onChange={(e) => handleInput(e, "text")}
              ></input>
              {errors.requesterName && (
                <p className="error fs-12 mt-10">{errors.requesterName}</p>
              )}
            </div> */}
             <div className="ticket_input-field  w-22">
              <label>Assignee Username</label>
              <input
                type="text"
                name="assigneeName"
                placeholder="Enter assignee name"
                value={assigneeName}
                onChange={findUserName}
                maxLength={10}
              ></input>
              {errors.assigneeName && (
                <p className="error fs-12 mt-10">{errors.assigneeName}</p>
              )}

              {userResponse &&
                <p className="blue_font fs-12 fw-bold">{userResponse}</p>}
            
            </div>
            <div className="ticket_input-field  w-22">
              <label>Email ID</label>
              <input
                type="text"
                name="email"
                value={ticketData?.email}
                placeholder="Enter email id"
                onChange={(e) => handleInput(e, "email")}
              ></input>
              {errors.email && (
                <p className="error fs-12 mt-10">{errors.email}</p>
              )}
            </div>
           
            <div className="ticket_input-field  w-22">
              <label>Ticket status</label>
              <select
                className="ticket_input_select_field"
                value={ticketData?.ticketStatus}
                name="ticketStatus"
                onChange={handleInput}
              >
                <option value={""}>Select Status</option>

                {!isEmpty(ticketStatus) &&
                  ticketStatus.map((val) => {
                    return (
                      <option value={val?.statusName}>{val?.statusName}</option>
                    );
                  })}
              </select>
              {errors.ticketStatus && (
                <p className="error fs-12 mt-10">{errors.ticketStatus}</p>
              )}
            </div>
            <div className="ticket_input-field  w-22">
              <label>Priority</label>
              <select
                className="ticket_input_select_field"
                value={ticketData?.priority}
                name="priority"
                onChange={handleInput}
              >
                <option value={""}>Select priority</option>

                {
                   !isEmpty(ticketPriority) && ticketPriority.map((val) =>{
                     return( <option value={val}>{val}</option>)
                   })
                  }
              </select>
              {errors.priority && (
                <p className="error fs-12 mt-10">{errors.priority}</p>
              )}
            </div>
            <div className="ticket_input-field   w-22">
              <label>Reference Id</label>
              <input
                type="text"
                name="txnRefId"
                placeholder="Type here ..."
                value={ticketData?.txnRefId}
                onChange={(e) => {
                  handleInput(e);
                }}
                // disabled={key==="editTicket"?true:false}
              ></input>
              {errors.txnRefId && (
                <p className="error fs-12 mt-10">{errors.txnRefId}</p>
              )}
            </div>
            <div className="ticket_input-field  w-22">
              <label>Summary</label>
              <input
                type="text"
                name="summary"
                placeholder="Type here...."
                value={ticketData?.summary}
                onChange={(e) => {
                  handleInput(e);
                }}
              ></input>
              {errors.summary && (
                <p className="error fs-12 mt-10">{errors.summary}</p>
              )}
            </div>
            <div className="ticket_input-field w50">
              <label>Description</label>
              <textarea
                type="text"
                name="description"
                placeholder="Type here..."
                value={ticketData?.description}
                onChange={(e) => handleInput(e, "text")}
              ></textarea>
              {errors.description && (
                <p className="error fs-12 mt-10">{errors.description}</p>
              )}
            </div>
            {/* <div className="ticket_input-field w-40">
              <label>Remark</label>
              <input
                type="text"
                name="reamrks"
                placeholder="Enter remarks"
                value={ticketData?.reamrks}
                onChange={(e) => handleInput(e, "text")}
              ></input>
              {errors.reamrks && (
                <p className="error fs-12 mt-10">{errors.reamrks}</p>
              )}
            </div> */}
            
            {(key !== "editTicket") && <div className="ticket_input-field w-22">
              <label>Select file</label>
              <input
                type="file"
                name="image"
                placeholder="Enter remarks"
                onChange={handleImageChange}
              ></input>
            </div>}
            {imagePreview && (
              <div cl>
                <p>Image :</p>
                <img               
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}

            <div className="ticket_input-field w-100 mt-20">
              <div className="button">
                <button
                className={assigneeName ? 'ticket_input-field_button' : 'ticket_input-field_button_disable'}
                disabled={!assigneeName}
                  onClick={() => {
                    handlecreateTicketData();
                  }}
                >
                  {(key==="editTicket") ?"Update":"Submit"

                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateTicket;
