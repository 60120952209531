import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  deteleUserFromList,
  getUserList,
  searchUser,
  userStatusUpdate,
} from "../../utils/api";
import {
  getDateFromDateObj,
  isAlphabet,
  isEmpty,
  isNumber,

} from "../../utils/common";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp.jsx";
import LocalLoader from "../../Components/Loader/LocalLoader.js";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import userMainBalance from "../../assets/images/userMainBalance.svg";
import userAepsBalance from "../../assets/images/userAepsBalance.svg";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData1 from "../../assets/lotties/question.json";
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";
import DatePicker from "react-datepicker";
import { TableWrapper } from "../../Components/styledConstant.js";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
require("react-datepicker/dist/react-datepicker.css");

const RetailerList = ({ permission }) => {
  const [apiData, setApiData] = useState([]);
  const [detailData, setDetailData] = useState("");
  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pdfData, setPdfData] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userBalanceData, setUserBalanceData] = useState();
  const [deleteUsers, setDeleteUsers] = useState(null);
  const [userDeleted, setUSerDeleted] = useState(false);
  const [userStatus, setUserStatus] = useState({});
  const [updatedUserStatus, setUpdatedUserStatus] = useState(false);
  let [errorMessage, setErrorMsg] = useState(false);
  let [editUserData, setEditUserData] = useState();
  const [loginUserType, setLoginUserTye] = useState("");
  const [showfilter, setShowFilter] = useState("");

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
  };

  const link = useRef();
  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const navigate = useNavigate();

  let _fromDate = "";
  let _toDate = "";

  if (startDate !== "" && endDate !== "") {
    _fromDate = getDateFromDateObj(startDate, "YYYY-MM-DD");
    _toDate = getDateFromDateObj(endDate, "YYYY-MM-DD");
  }
  //API to fetch userlist

  const handleApiData = async (e, key) => {
    let error = {};

    if (name.length < 3) {
      error.name = "Name cannot be less than 3 letters";
    }
    const param = {
      fromDate: _fromDate,
      // role: "PTM_RETAILER",
      toDate: _toDate,
      username: detailData,
      name: name,
      userType: "ROLE_RETAILER",
    };

    const pagination = {
      currentPage: currPage,
      pageSize: pageSize,
    };
    if (key === "CSV" || key === "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      pagination.pageSize = totalElements;
      pagination.currentPage = 0;
    }
    if (key === "search") {
      pagination.currentPage = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    if (key === "refresh") {
      dataRefresh();
      param.username = "";
      param.fromDate = "";

      param.toDate = "";
      param.name = "";
      param.userType = "ROLE_RETAILER";
      pagination.currentPage = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }

    getUserList(param, pagination).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data.success) {
        if (key === "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          userlistPdfData(resp?.data?.data?.content);
        } else {
          setApiData(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
          // setCurrPage(resp?.data?.data?.number)
        }
      }
    });
  };
  useEffect(() => {
    handleApiData();
  }, [currPage, userType]);

  //API to Export PDF file

  const userlistPdfData = (data = []) => {
    let _width = 350; // Width of the custom size
    const height = 297;
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", // units: mm, cm, in, px
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });
    //Add image in PDF header
    const imgWidth = 25;
    const imgHeight = 9;
    const leftXPos = 14;
    const yPos = 2;
    doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "User List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);
    const generalFontSize = 12;
    doc.setFontSize(generalFontSize);
    // doc.setMargins(0,0,0,0);
    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];
    data.forEach((item, index) => {
      const dateTimeString = item.createdDate;
      let [date, time] = dateTimeString.split("T");
      let newTime = time.slice(0, 8);

      const rowData = [
        index + 1,
        item.firstName + " " + item.lastName,
        item.userName,
        item.email,
        item.address1,
        item.status,
        date + "" + newTime,
        item.userType,
      ]; // Customize as needed
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [
        [
          "S.No",
          "Name",
          "Mobile No.",
          "Email",
          "Address",
          "Status",
          "Created Date",
          "UserType",
        ],
      ], // Customize table header
      body: tableData,
    });

    doc.save("RetailerList.pdf");
  };

  //delete User

  const deletUserData = (userId) => {
    deteleUserFromList(userId).then((resp) => {
      if (resp?.data?.success) {
        setUSerDeleted(true);
      } else {
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          setErrorMsg(true);
        } else {
          setErrorMsg(true);
        }
      }
    });
  };

  const refreshDataDelete = () => {
    setUSerDeleted(false);
    setDeleteUsers(null);
    handleApiData();
  };
  const refrshSttausData = () => {
    setUpdatedUserStatus(false);
    setUserStatus({});
    handleApiData();
  };
  const deleteUser = (userId) => {
    setDeleteUsers(userId);
  };
  const viewBalance = async (userId) => {
    searchUser(userId).then((resp) => {
      if (resp?.data?.success) {
        setUserBalanceData({ success: resp?.data?.data });
      } else {
        // alert(resp?.data?.msg)
        if (resp?.data?.errorCodeList.length > 0) {
          setUserBalanceData({ fail: resp?.data?.errorCodeList[0] });
        } else {
          setUserBalanceData({ fail: resp?.data?.msg });
        }
      }
    });
  };

  const handleDetailData = (e, key = "") => {
    const value = e.target.value;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
      setDetailData(e.target.value);
    }
    if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) return;
      setName(e.target.value);
    }
  };

  const editUser = (item) => {
    setEditUserData(item);
  };
  const dataRefresh = () => {
    setStartDate("");
    setEndDate("");
    setDetailData("");
    setName("");
    setUserType("");
    setCurrPage(0);
  };

  const statusRefessh = () => {
    updateUserStatusData(userStatus);
    setUserStatus({});
  };
  const updateUserStatus = async (userId, status) => {
    setUserStatus({ userId, status });
  };
  const updateUserStatusData = (statusUpdate) => {
    userStatusUpdate(statusUpdate?.userId, statusUpdate?.status).then(
      (resp) => {
        if (resp?.data?.success) {
          setUpdatedUserStatus({
            msg: "User's Status changed Successfully",
            type: "SUCCESS",
          });
        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }

          setUpdatedUserStatus({ msg: msg, type: "errorbutton" });
        }
      }
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiData();
    }
  };

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (loginUserType === "" && !isEmpty(user?.userType)) {
      setLoginUserTye(user?.userType);
    }
  }, [user]);

  return (
    <>
      <div className="userlisthead_container">
        <div style={{ padding: "30px" }}>
          <div className="transaction_main_container">
            <div className="userlist_head">
              <div className="userlist_heading">
                <div className="userlist_left">
                  <label>Retailers List</label>
                </div>

                <div className="userlist_right">
                  <button
                    style={{ width: "110px", marginRight: "10px" }}
                    className="userlist_btn"
                    onClick={() => navigate("/addUser")}
                  >
                    Add New User
                  </button>
                  {downloadLoader !== "PDF" ? (
                    <button
                      className="pdf"
                      onClick={(e) => handleApiData(e, "PDF")}
                    >
                      PDF
                    </button>
                  ) : (
                    <button className="pdf">
                      <div className="btn-loader">
                        <i class="fa-solid fa-spinner"></i>
                      </div>
                    </button>
                  )}
                  {downloadLoader !== "CSV" ? (
                    <button
                      className="xls"
                      onClick={(e) => handleApiData(e, "CSV")}
                    >
                      CSV
                    </button>
                  ) : (
                    <button className="xls">
                      <div className="btn-loader">
                        <i class="fa-solid fa-spinner"></i>
                      </div>
                    </button>
                  )}
                  <CSVLink
                    ref={link}
                    data={pdfData}
                    filename="Userlist.csv"
                  ></CSVLink>
                </div>
                <div className="userlist_filter">
                  <button
                    className="filter fs-16"
                    onClick={(e) =>
                      showfilter === "active"
                        ? setShowFilter("")
                        : setShowFilter("active")
                    }
                  >
                    <i class="fa-solid fa-filter"></i>
                  </button>
                </div>
              </div>
            </div>

            <FilterWrapper className={`display_data ${showfilter}`}>
              <div className="filterBox">
                <div>
                  <div className="flex align-items gap-20">
                    <div>
                      <input
                        type="text"
                        name="detailData"
                        placeholder="Enter mobile number"
                        value={detailData}
                        onChange={(e) => handleDetailData(e, "number")}
                        maxLength={10}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter username"
                        value={name}
                        onChange={(e) => handleDetailData(e, "text")}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="input-field">
                      <div className="flex">
                        <DatePicker
                          showIcon
                          className="date-picker2 date_type"
                          placeholderText="From Date"
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={(date) => setStartDate(date)}
                          maxDate={new Date()}
                          customHeader={({
                            date,
                            decreaseMonth,
                            prevMonthButtonDisabled,
                          }) => (
                            <div>
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                Previous Month
                              </button>
                              <span>
                                {date.toLocaleString("en-US", {
                                  month: "long",
                                  year: "numeric",
                                })}
                              </span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="input-field">
                      <div className="flex">
                        <DatePicker
                          showIcon
                          className="date-picker2 date_type"
                          placeholderText="To Date"
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={(date) => setEndDate(date)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className=" button">
                      <button
                        className="userlist_btn"
                        onClick={(e) => handleApiData(e, "search")}
                      >
                        Search
                      </button>
                    </div>

                    <div className=" button">
                      <button
                        className="refresh-btn"
                        onClick={(e) => handleApiData(e, "refresh")}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </FilterWrapper>
          </div>
          {apiData && (
            <div className="table-loader">
              {loading && <LocalLoader className="tableLoader" />}
              <TableWrapper className="table-card-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile No.</th>
                      <th>Address</th>
                      <th>Outstanding Balance</th>
                      <th>Deposit Balance</th>
                      <th>Aadhar Verified</th>
                      <th>PAN Verified</th>
                      <th>AEPS Enabled</th>
                      <th>User Type</th>
                      <th>Created Date</th>
                      <th>Status</th>
                      {loginUserType === "ROLE_ADMIN" && (
                        <>
                          <th>View Balance</th>
                        </>
                      )}{" "}
                      {(permission.includes("MANAGE_USERS_DELETE") ||
                        permission.includes("MANAGE_USERS_EDIT")) && (
                        <th>Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {apiData &&
                      apiData?.map((item, index) => {
                        const dateTimeString = item.createdDate;
                        let [date, time] = dateTimeString.split("T");
                        let newTime = time.slice(0, 8);

                        return (
                          <tr key={item.transactionId}>
                            <td className="td-none">
                              <span className="card-th">Sr No</span>
                              <span>{currPage * pageSize + index + 1}</span>
                            </td>

                            <td>
                              <span className="card-th">Name</span>
                              <span>
                                {item?.firstName + " " + item?.lastName}
                              </span>
                            </td>

                            <td>
                              <span className="card-th">Email</span>
                              <span>{item?.email}</span>
                            </td>
                            <td>
                              <span className="card-th">Mobile No.</span>
                              <span>{item?.userName}</span>
                            </td>
                            <td>
                              <span className="card-th">Address</span>
                              <span>{item?.address1}</span>
                            </td>
                            <td>
                              <span className="card-th">
                                Outstanding Balance
                              </span>
                              <span>{item?.outstandingBalance}</span>
                            </td>

                            <td>
                              <span className="card-th">Deposit Balance</span>
                              <span>{item?.depositBalance}</span>
                            </td>
                            <td>
                              <span className="card-th">Aadhar Verified</span>
                              <span>
                                {item?.isAadhaarVerified ? "YES" : "NO"}
                              </span>
                            </td>
                            <td>
                              <span className="card-th">PAN Verified</span>
                              <span>{item?.isPanVerified ? "YES" : "NO"}</span>
                            </td>
                            <td>
                              <span className="card-th">AEPS Enabled</span>
                              <span>{item?.isAepsEnabled ? "YES" : "NO"}</span>
                            </td>

                            <td>
                              <span className="card-th">User Type</span>
                              <span>{item?.userType}</span>
                            </td>
                            <td>
                              <span className="card-th">Created Date</span>
                              <span>{date + " " + newTime}</span>
                            </td>
                            <td>
                              <span className="card-th">Status</span>
                              <span>
                                <div
                                  className={`${
                                    item.status == "ACTIVE"
                                      ? "active_status"
                                      : "inactive_status"
                                  }`}
                                >
                                  {item?.status}
                                </div>
                              </span>
                            </td>

                            {/* {loginUserType === "ROLE_ADMIN" && (} */}
                            {true && (
                              <>
                                <td className="user_action_btn">
                                  <span className="card-th">View Balance</span>
                                  <span>
                                    {" "}
                                    <button
                                      onClick={() => viewBalance(item.userName)}
                                    >
                                      <i class="fa-regular fa-eye fs-16 update"></i>
                                    </button>
                                  </span>
                                </td>
                              </>
                            )}
                            <td className="user_action_btn ">
                              <span className="card-th">Actions</span>
                              <span className="center">
                                <div
                                  className={`custom-toggle toggle_button ${
                                    item.status === "ACTIVE" ? "active" : ""
                                  }`}
                                  style={{ margin: "0", marginRight: "12px" }}
                                  onClick={() =>
                                    permission.includes("MANAGE_USERS_EDIT") &&
                                    updateUserStatus(item.uuid, item.status)
                                  }
                                >
                                  <div className="slider"></div>
                                </div>
                                {permission.includes("MANAGE_USERS_EDIT") && (
                                  <button
                                    className=""
                                    style={{ marginRight: "12px" }}
                                    onClick={() => editUser(item)}
                                  >
                                    <i class="fa-solid fa-pen-to-square update"></i>
                                  </button>
                                )}
                                {permission.includes("MANAGE_USERS_DELETE") && (
                                  <button
                                    onClick={() => deleteUser(item.userName)}
                                    style={{ marginRight: "10px" }}
                                  >
                                    <i class="fa-solid fa-trash delete"></i>
                                  </button>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}

                    {!loading && apiData && apiData.length < 1 && (
                      <tr>
                        <td
                          colSpan={12}
                          style={{ textAlign: "center", color: "red" }}
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          )}

          {totalPages > 1 && (
            <Pagination
              items={apiData}
              itemsPerPage={pageSize}
              setitemsPerPage={setPageSize}
              currentPage={currPage}
              setcurrentPage={setCurrPage}
              totalPages={totalPages}
              forcePage={currPage}
            />
          )}
        </div>
        {userBalanceData && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
              }}
            >
              <div
                className="flex justify-center mr-20"
                style={{ color: "#8B9CA8" }}
                onClick={() => setUserBalanceData("")}
              >
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    display: "inline-block",
                    maxWidth: "40px",
                    marginLeft: " auto",
                    fontSize: "25px",
                  }}
                ></i>
              </div>
              {!isEmpty(
                userBalanceData?.success?.mainBalance ||
                  !isEmpty(userBalanceData?.success?.aepsBalance)
              ) ? (
                <>
                  <div
                    className="flex justify-center gap-10 fs25"
                    style={{ color: "#002970" }}
                  >
                    <h4>{userBalanceData?.success?.firstName}</h4>
                    <h4>{userBalanceData?.success?.lastName}</h4>
                  </div>
                  <div className="flex justify-center gap40 mt-16">
                    <div
                      className="flex gap-15"
                      style={{ paddingRight: "10px" }}
                    >
                      <div>
                        <img src={userMainBalance}></img>
                      </div>
                      <div className="flex column">
                        <label className="fw-600 fs-20">Main Balance</label>
                        <label className="fs-16">
                          {" "}
                          ₹{userBalanceData?.success?.mainBalance}
                        </label>
                      </div>
                    </div>
                    {!isEmpty(userBalanceData?.success?.aepsBalance) ? (
                      <div
                        className="flex gap-15"
                        style={{
                          borderLeft: "1px dotted #8B9CA8",
                          paddingLeft: "10px",
                        }}
                      >
                        <div className="mt-10">
                          <img src={userAepsBalance}></img>
                        </div>
                        <div className="flex column">
                          <label className="fw-600 fs-20">AEPS Balance</label>
                          <label className="fs-16">
                            ₹{userBalanceData?.success?.aepsBalance}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="flex justify-center"
                  style={{ marginTop: "45px" }}
                >
                  <h4>User doesn't have Wallet</h4>
                  {/* <h4>{userBalanceData?.fail}</h4> */}
                </div>
              )}
            </div>
          </ModalPopUp>
        )}

        {!isEmpty(deleteUsers) && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                height: "270px",
              }}
            >
              <div className="mt-20">
                <Lottie
                  options={defaultOptions1}
                  height={80}
                  width={80}
                ></Lottie>
              </div>

              <div className="flex justify-center ">
                <label className="fs25 blue_font fw-bold">Are you sure!</label>
              </div>
              <div className="flex justify-center">
                <label>You want to delete this user ?</label>
              </div>
              <div className="flex justify-center gap-10 mt-30">
                <button
                  onClick={() => setDeleteUsers(null)}
                  className="cancel_button"
                >
                  No, Cancel it
                </button>
                <button
                  onClick={() => deletUserData(deleteUsers)}
                  className="confirm_button"
                >
                  Yes, I am sure!
                </button>
              </div>
            </div>
          </ModalPopUp>
        )}

        {userDeleted && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                height: "270px",
              }}
            >
              {userDeleted && (
                <>
                  <div className="mt-20">
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>

                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">Done!</label>
                  </div>
                  <div className="flex justify-center">
                    <label>User deleted successfully</label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      onClick={() => {
                        refreshDataDelete();
                      }}
                      className="confirm_button"
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
              {errorMessage && (
                <>
                  <div className="mt-20">
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>

                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">Fail !</label>
                  </div>
                  <div className="flex justify-center">
                    <label>
                      User not deleted successfully.
                      <br /> Please try again.
                    </label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      onClick={() => {
                        setDeleteUsers(null);
                      }}
                      className="confirm_button"
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </div>
          </ModalPopUp>
        )}

        {!isEmpty(userStatus) && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
              }}
            >
              <div>
                <Lottie
                  options={defaultOptions1}
                  height={80}
                  width={80}
                ></Lottie>
              </div>

              <div className="flex justify-center ">
                <label className="fs25 blue_font fw-bold">Are you sure!</label>
              </div>
              <div className="flex justify-center">
                <label>You want to update this user's status ?</label>
              </div>
              <div className="flex justify-center gap-10 mt-30">
                <button
                  onClick={() => setUserStatus({})}
                  className="cancel_button"
                >
                  No, Cancel it
                </button>
                <button
                  onClick={() => statusRefessh()}
                  className="confirm_button"
                >
                  Yes, I am sure!
                </button>
              </div>
            </div>
          </ModalPopUp>
        )}

        {updatedUserStatus && (
          <ModalPopUp>
            <div
              className={
                `${updatedUserStatus.type} && ${
                  updatedUserStatus.type === "errorbutton"
                }`
                  ? "error-popup"
                  : "modal-popup"
              }
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
              }}
            >
              <>
                {updatedUserStatus.type === "SUCCESS" && (
                  <>
                    <div>
                      <Lottie
                        options={defaultOptions2}
                        height={80}
                        width={80}
                      ></Lottie>
                    </div>

                    <div className="flex justify-center ">
                      <label className="fs25 blue_font fw-bold">Done!</label>
                    </div>
                  </>
                )}
                <div
                  className={`flex justify-center ${
                    updatedUserStatus.type === "errorbutton" ? "mt-30" : ""
                  }`}
                >
                  <label>{updatedUserStatus.msg}</label>
                </div>
                <div className="flex justify-center gap-10 mt-16">
                  {updatedUserStatus.type === "SUCCESS" && (
                    <button
                      className="confirm_button"
                      onClick={() => refrshSttausData()}
                    >
                      Close
                    </button>
                  )}

                  {updatedUserStatus.type === "errorbutton" && (
                    <button
                      className="error_button "
                      onClick={() => refrshSttausData("")}
                    >
                      OK
                    </button>
                  )}
                </div>
              </>
            </div>
          </ModalPopUp>
        )}

        {editUserData && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                // height: "270px",
              }}
            >
              {!isEmpty(editUserData?.screenCode === "PACKAGE_ASSIGNED") && (
                <>
                  <div>
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      Package Assigned to the user!
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>Do you want to complete the remaining steps ?</label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      className="cancel_button"
                      onClick={() => setEditUserData("")}
                    >
                      No, Cancel it
                    </button>
                    <button
                      className="confirm_button"
                      onClick={() =>
                        navigate("/bankdetails", { state: editUserData })
                      }
                    >
                      Yes, I am sure!
                    </button>
                  </div>
                </>
              )}
              {!isEmpty(editUserData?.screenCode === "USER_REGISTRATION") && (
                <>
                  <div className="mt-20">
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      This user's registration is done!
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>Now you select package.</label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      className="cancel_button"
                      onClick={() => setEditUserData("")}
                    >
                      No, Cancel it
                    </button>
                    <button
                      className="confirm_button"
                      onClick={() =>
                        navigate("/select-package", { state: editUserData })
                      }
                    >
                      Yes, I am sure!
                    </button>
                  </div>
                </>
              )}
              {!isEmpty(
                editUserData?.screenCode === "USER_REGISTRATION" &&
                  editUserData?.userType === "ROLE_DISTRIBUTOR"
              ) ||
                (!isEmpty(
                  editUserData?.screenCode === "USER_REGISTRATION" &&
                    editUserData?.userType === "ROLE_SUBADMIN"
                ) && (
                  <>
                    <div className="mt-20">
                      <Lottie
                        options={defaultOptions2}
                        height={80}
                        width={80}
                      ></Lottie>
                    </div>
                    <div className="flex justify-center ">
                      <label className="fs25 blue_font fw-bold">
                        This user's registration is done!
                      </label>
                    </div>
                    <div className="flex justify-center">
                      <label>Do you want edit details of the user?</label>
                    </div>
                    <div className="flex justify-center gap-10 mt-30">
                      <button
                        className="cancel_button"
                        onClick={() => setEditUserData("")}
                      >
                        No, Cancel it
                      </button>
                      <button
                        className="confirm_button"
                        onClick={() =>
                          navigate("/editUser", { state: editUserData })
                        }
                      >
                        Yes, I am sure!
                      </button>
                    </div>
                  </>
                ))}
              {!isEmpty(editUserData?.screenCode === "USER_KYC") && (
                <>
                  <div className="mt-20">
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      This user's KYC is done !
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>Now Do you to do OTP Verification?</label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      className="cancel_button"
                      onClick={() => setEditUserData("")}
                    >
                      No, Cancel it
                    </button>
                    <button
                      className="confirm_button"
                      onClick={() =>
                        navigate("/Verification", {
                          state: { userData: editUserData, skip: "" },
                        })
                      }
                    >
                      Yes, I am sure!
                    </button>
                  </div>
                </>
              )}
              {!isEmpty(editUserData?.screenCode === "AEPS_ONBOARDING") && (
                <>
                  <div className="mt-20">
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      This user's onboarding is completed !
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>Do you to edit basic details?</label>
                  </div>
                  <div className="flex justify-center gap-10 mt-30">
                    <button
                      className="cancel_button"
                      onClick={() => setEditUserData("")}
                    >
                      No, Cancel it
                    </button>
                    <button
                      className="confirm_button"
                      onClick={() =>
                        navigate("/editUser", { state: editUserData })
                      }
                    >
                      Yes, I am sure!
                    </button>
                  </div>
                </>
              )}
            </div>
          </ModalPopUp>
        )}
      </div>
    </>
  );
};
export default RetailerList;
