import React from 'react';
import "../Settings/style.scss";
export default function RechrageCommission() {

  const dataset = [
    {SNo : "1",SName:"EMI",Rtcommission:"100",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "2",SName:"Broadband",Rtcommission:"10",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "3",SName:"Electricity",Rtcommission:"50",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "4",SName:"Water",Rtcommission:"40",Mdcommission:"200",Shcommission:"150",flat:"5%"},
    {SNo : "5",SName:"Insurance",Rtcommission:"80",Mdcommission:"200",Shcommission:"150",flat:"5%"}
];



  const datatable = dataset.map((data)=>{
    return(
      <tr>
        <td>{data.SNo}</td>
        <td>{data.SName}</td>
        <td>{data.Rtcommission}</td>
        <td>{data.Mdcommission}</td>
        <td>{data.Shcommission}</td>
        <td>{data.flat}</td>
        <td>{data.Action}</td>
      </tr>
    )
    
  })

  return (
    <div>
       <div className='heading_data'>
          <div className='heading_row'>
             <strong>BBPS Recharge Commission</strong>
             <div className='btn_pdf'>
             <button className='btn_data'>PDF</button>
             </div>
             <div className='btn_XLS'>
             <button className='btn_data'>XLS</button>
             </div>
             
          </div>
       
        </div>
        <div className='main-container'>
        <div className="flex-container">
      <div className="row_field">
          <strong>Filter Record</strong>
      </div>
      <div className="row_field">   
          <select>
            <option>Operator</option>
          </select>
          <input type='text' placeholder='enter member id'></input>
          <button>Search</button>
          <div className='package_detail'>
          <strong>Package Type</strong>
          </div>
          <select>
            <option>Retailer</option>
          </select>         
          <button>Save</button>          
      </div>
    </div>
        </div>
        <div className='table_data'>
        <table id="customers">
          <tr style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <th>S.No</th>
            <th>Operator Name</th>
            <th>Service Type</th>
            <th>Commission</th>
            <th>TDS</th>
            <th>Flat</th>
          </tr>
          <tbody style={{alignItems:"center"}}>{datatable}</tbody>

          {/* <tbody>
                                {apiData
                                    ? createServiceListItems()
                                    : "No DATA  AVAILABLE"}
                            </tbody> */}
        </table>
      </div>
    </div>
  )
}
