import React from 'react'
import "./input.scss"
import search from '../../assets/images/search.svg'

const Input = (props) => {

  function clickHandler(){
    props.click();
  }
  return (
    <div className={props?.className}>
        <input {...props} style={{width: "330px"}} autoComplete="new-password"/>
        {props?.className === "searchUser" &&
       <div className='searchImg' 
       onClick={clickHandler}><img src={search} alt="search" /></div>}
        
    </div>
  )
}

export default Input