import React, { useState } from "react";
import Input from "../../Components/Input/Input";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUserDaital } from "../../utils/api";

import { isEmpty } from "../../utils/common";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";

const EditUserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;

  const [userData, setUserData] = useState({ ...item });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [editUserData, setEditUserData] = useState({});

  const handleFormData = async (e) => {
    let error = {};
    if (userData.address1 === "") {
      error.address1 = "Please enter your address";
    } else if (userData.address1.length < 5) {
      error.address1 = "Address can't be less than 5 letters";
    }
    if (errors.dob === "") {
      error.dob = "Please enter your date of birth";
    }
    if (userData.phoneNumber === "") {
      error.phoneNumber = "Please enter your date of birth";
    }
    if (userData.email === "") {
      error.email = "Please enter your email";
    }
    if (userData.firstName === "") {
      error.firstName = "Please enter your first name";
    }
    if (userData.lastName === "") {
      error.lastName = "Please enter your last name";
    }
    if (userData.password === "") {
      error.password = "Please enter your password";
    }
    if (userData.pincode === "") {
      error.pincode = "Please enter your pincode";
    } else if (userData.pincode.length < 6) {
      error.pincode = "Pincode can't be less than 6";
    }
    if (userData.userName === "") {
      error.userName = "Please enter your username";
    } else if (userData.userName.length < 10) {
      error.userName = "Username can't be less than 10";
    }
    setErrors(error);
    if (isEmpty(error)) {
      setLoading(true);
      let params = userData;

      updateUserDaital(params).then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          setEditUserData({
            msg: "User details updated Successfully",
            type: "SUCCESS",
          });
        } else {
          let msg = "";
          if (response?.data?.errorCodeList.length > 0) {
            msg = response?.data?.errorCodeList[0];
          } else {
            msg = response?.data?.msg;
          }
          setEditUserData({ msg: msg, type: "errorbutton" });
        }
      });
    }
  };

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setErrors({});
    setUserData({ ...userData, [name]: value });
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
  };

  const userNavigate = () => {
    if (userData.userType === "ROLE_RETAILER") {
      navigate("/retailerList");
    } else {
      navigate("/usersList");
    }
  };
  return (
    <div className="main_container" style={{ padding: "60px 40px 40px" }}>
      <FilterWrapper>
        <div>
          <label
            style={{
              color: "rgb(240 241 243)",
              fontSize: "25px",
              fontWeight: "bold",
              fontFamily: "'Cabin', sans-serif",
            }}
          >
            Edit Details
          </label>
        </div>
      </FilterWrapper>
      <div className="container_form">
        <div className="role">
          <div className="retailer">
            <label className="label_head">Personal Details</label>
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>UserName</label>
              <span className="required">*</span>
            </div>
            <Input
              type="text"
              placeholder="enter you username"
              name="userName"
              value={userData.userName}
            />
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Firstname</label>
              <span className="required">*</span>
            </div>
            <div>
              <Input
                className="inputtype"
                type="text"
                placeholder="enter you firstName"
                name="firstName"
                value={userData.firstName}
                onChange={handleInput}
              />
              {errors.firstName && (
                <p className="error fs-12 mt-10">{errors.firstName}</p>
              )}
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Lastname</label>
              <span className="required">*</span>
            </div>
            <Input
              className="inputtype"
              type="text"
              placeholder="enter you lastName"
              name="lastName"
              value={userData.lastName}
              onChange={handleInput}
            />
            {errors.lastName && (
              <p className="error fs-12 mt-10">{errors.lastName}</p>
            )}
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>DOB</label>
              <span className="required">*</span>
            </div>
            <div>
              <Input type="text" name="dob" value={userData.dob} />
            </div>
          </div>
        </div>

        <div className="role">
          <div className="retailer">
            <label className="label_head">Contact Details</label>
          </div>
        </div>

        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Contact Number</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you mobile number"
                name="phoneNumber"
                value={userData.userName}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Email Address</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="enter you email"
              name="email"
              value={userData.email}
              // onChange={handleInput}
            />
            {errors.email && (
              <p className="error fs-12 mt-10">{errors.email}</p>
            )}
          </div>
        </div>
        {/* Address Details */}
        <div className="role">
          <div className="retailer">
            <label className="label_head">Address Details</label>
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Address1</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you address"
                name="address1"
                value={userData.address1}
                onChange={handleInput}
              />
              {errors.address1 && (
                <p className="error fs-12 mt-10">{errors.address1}</p>
              )}
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Address2</label>
            </div>
            <Input
              type="text"
              placeholder="enter you address2"
              name="address2"
              value={userData.address2}
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Landmark</label>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you landmark"
                name="landmark"
                value={userData.landmark}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Picode</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="enter you pincode"
              name="pincode"
              value={userData.pincode}
              onChange={handleInput}
              maxLength={6}
            />{" "}
            {errors.pincode && (
              <p className="error fs-12 mt-10">{errors.pincode}</p>
            )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <button
              className="submit_btn"
              onClick={() => {
                handleFormData();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {!isEmpty(editUserData) && (
        <ModalPopUp>
          ""
          <div
            className={
              `${editUserData.type} && ${editUserData.type === "errorbutton"}`
                ? "error-popup"
                : "modal-popup"
            }
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
            }}
          >
            <>
              {editUserData.type === "SUCCESS" && (
                <>
                  <div>
                    <Lottie
                      options={defaultOptions2}
                      height={80}
                      width={80}
                    ></Lottie>
                  </div>

                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">Done!</label>
                  </div>
                </>
              )}
              <div
                className={`flex justify-center ${
                  editUserData.type === "errorbutton" ? "mt-30" : ""
                }`}
              >
                <label>{editUserData.msg}</label>
              </div>
              <div className="flex justify-center gap-10 mt-16">
                {editUserData.type === "SUCCESS" && (
                  <button
                    className="confirm_button"
                    onClick={() => userNavigate()}
                  >
                    Close
                  </button>
                )}

                {editUserData.type === "errorbutton" && (
                  <button
                    className="error_button "
                    onClick={() => setEditUserData("")}
                  >
                    OK
                  </button>
                )}
              </div>
            </>
          </div>
        </ModalPopUp>
      )}
    </div>
  );
};

export default EditUserForm;
