import { FaUser, FaLock, FaMoneyBill } from "react-icons/fa";
import { BsGrid3X3Gap } from "react-icons/bs";
import { GoSettings } from "react-icons/go";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineBell, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaListOl } from "react-icons/fa";
import users from "../../assets/images/users.svg"
import packages from "../../assets/images/package.svg"
import setting from "../../assets/images/settings.svg"
import wallet from "../../assets/images/wallet.svg"
import transaction from "../../assets/images/transactions.svg"
import operator from "../../assets/images/operator.svg"
import support from "../../assets/images/ticket_management.svg";
import subuser from "../../assets/images/subuser.svg"
export const sidemenuContast = [
    {
        path: "/",
        name: "Dashboard",
        icon: <BsGrid3X3Gap />,
        Permissions:"OVERVIEW",
        role:"ROLE_ADMIN",
        
        
    },
    {
        path: "/users",
        name: "Users",
        icon: <img src={users}></img>,
        exact: true,
        id: "Users",
        Permissions:"USERS",
        role:"ROLE_ADMIN",
        subRoutes: [
            {
                path: "/retailerList",
                name: "Manage Retailer ",
                icon: <img src = {subuser} className="user_icon"></img>,
                Permissions:"MANAGE_RETAILER",
                role:"ROLE_ADMIN",
            },
            {
                path: "/usersList",
                name: "Manage Users ",
                icon: <img src = {subuser} className="user_icon"></img>,
                Permissions:"MANAGE_USERS",
                role:"ROLE_ADMIN",
                
             
            },

            // {
            //     // path: "/addnewmember",
            //     path:"/addUser",
            //     name: "Add New Memeber ",
            //     icon: <FaUser />,
            //     Permissions:"ADD_MEMBER"
            // },
           
            // {
            //     path: "/freememberidplan",
            //     name: "Free Member Id Plan",
            //     icon: <FaUser />,
            //     Permissions:"FREE_MEMBER_PLAN"
            // },
            // {
            //     path: "/merchantcommissionlist",
            //     name: "Merchant Commission List",
            //     icon: <FaUser />,
            //     Permissions:"MERCHANT_COMMISSION"
            // },

          
            
            {
                path: "/permisionManagement",
                name: "Manage View",
                icon: <FaUser />,
                Permissions:"ROLE_PERMISSION_UI",
                role:"ROLE_ADMIN"
            },

          
            {
                path: "/ApiPermisionManagement",
                name: "Manage Api",
                icon: <FaUser />,
                Permissions:"ROLE_PERMISSION_API",
               role:"ROLE_ADMIN"

            },
        ],
    },
   
   
    {
        path: "/PackageManagement",
        name: "Package Management",
        icon: <img src={packages}></img>,
        Permissions:"MANAGE_PACKAGE",
        role:"ROLE_ADMIN",
        subRoutes:[
            {
                path: "/PackageMnagementList ",
                name:"Package Management List",
                icon: <FaUser />,
                Permissions:"MANAGE_PACKAGE_LIST",
                role:"ROLE_ADMIN"
            },
            {
                path: "/CreatePackage",
                name:"ADD Package",
                icon: <FaUser />,
                Permissions:"ADD_MANAGE_PACKAGE",
               role:"ROLE_ADMIN"
            },


        ]

    },
    // {
    //     path: "/mapping",
    //     name: "User Mapping",
    //     icon: <VscGraph />,
    //     exact: true,
    
    // },
  
    {
        path: "/operators",
        name: "Operators List ",
        icon: <img src={operator}></img>,
        Permissions:"OPERATOR_LIST",
        role:"ROLE_ADMIN"
    },
    {
        path: "/transactions",
        name: "Transactions",
        icon: <img src={transaction}></img>,
        Permissions:"TRANSACTION",
        role:"ROLE_ADMIN"
    },
    {
        path: "/serviceList",
        name: "Services List",
        icon: <AiOutlineQuestionCircle />,
        Permissions:"",
        role:"ROLE_ADMIN"
    },

    {
        path: "/wallet",
        name: "WALLET",
        icon: <img src={wallet}></img>,
        exact: true,
        id: "Wallet",
        Permissions:"WALLET",
        role:"ROLE_ADMIN",
        subRoutes: [
            {
                path: "/walletTransactions",
                name: "Wallet Summary ",
                icon: <FaUser />,
                Permissions:"WALLET_SUMMARY",
                role:"ROLE_ADMIN",
            },
            {
                path: "/DebitCreditList",
                name: "Manage Debit Credit",
                icon: <FaLock />,
                Permissions:"WALLET_SUMMARY_ACTION",
                role:"ROLE_ADMIN",
            },
            {
                path: "/deposit",
                name: "Deposit",
                icon: <FaLock />,
                Permissions:"VENDORS",
                role:"ROLE_ADMIN",
            },
            // // {
            // //     path: "/addcredit",
            // //     name: "Add Credit/Deduct Balance",
            // //     icon: <FaLock />,
            // //     Permissions:"CREDIT/DEDUCT"
            // // },
        ],
    },
    {
        path: "/vendor",
        name: "Vendors",
        icon: <img src={users}></img>,
        exact: true,
        id: "vendor",
        Permissions:"VENDORS",
        role:"ROLE_ADMIN",
        subRoutes: [
            {
                path: "/vendor-list",
                name: "Vendors List",
                icon: <FaUser />,
                Permissions:"WALLET_SUMMARY",
                role:"ROLE_ADMIN",
            },
            {
                path: "/vendor-transaction",
                name: "Vendor Transaction",
                icon: <img src={transaction}></img>,
                Permissions:"WALLET_SUMMARY",
                role:"ROLE_ADMIN",
            },
           
         
            {
                path: "/vendor-fund-request",
                name: "Manage Fund Request",
                icon: <FaLock />,
                role:"ROLE_ADMIN"
            },
        ],
    },
    {
        path: "/settings",
        name: "Settings",
        icon: <img src={setting}></img>,
        exact: true,
        id: "Settings",
        role:"ROLE_ADMIN",
        Permissions:"SETTINGS",
        subRoutes: [
            {
                path: "/changePassword",
                name: "Change Password ",
                icon: <FaUser />,
                Permissions:"CHANGE_PASSWORD",
                role:"ROLE_ADMIN"
            },

            // {
            //     path: "/holdbalance",
            //     name: "Hold Balance",
            //     icon: <FaLock />,
            //     Permissions:"HOLD_BALANCE"
            // },
            // {
            //     path: "/rechargecommission",
            //     name: "Recharge Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"RECHARGE_COMMISSION"
            // },
            // {
            //     path: "/bbpsrechargecommision",
            //     name: "BBPS Recharge Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"BBPS_RECHARGE_COMMISSION"
            // },
            // {
            //     path: "/billpaycommision",
            //     name: "Bill Pay Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"BILL_PAY_COMMISSION"
            // },
            // {
            //     path: "/aepscommission",
            //     name: "AEPS Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"AEPS_COMMISSION"
            // },
            // {
            //     path: "/adharpaycomission",
            //     name: "Adhar Pay Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"ADHAR_PAY_COMMISSION"
            // },
            // {
            //     path: "/adharpaysetting",
            //     name: "Adhar Pay Setting",
            //     icon: <FaMoneyBill />,
            //     Permissions:"ADHAR_PAY_SETTING"
            // },
            // {
            //     path: "/aepscommission",
            //     name: "AEPS Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:""
            // },
            // {
            //     path: "/expresspayoutsurcharge",
            //     name: "Express Payout Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"EXPRESS_PAYOUT_SURCHARGE"
            // },
            // {
            //     path: "/expresspayoutcomission",
            //     name: "Express Payout Commissione",
            //     icon: <FaMoneyBill />,
            //     Permissions:"EXPRESS_PAYOUT_COMMISSION"
            // },
            // {
            //     path: "/payoutsurcharge",
            //     name: "Payout Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"PAYOUT_SURCHARGE"
            // },
            // {
            //     path: "/uttpansurcharge",
            //     name: "UTT Pan Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UTT_SURCHARGE"
            // },
            // {
            //     path: "/uttpancouponsurcharge",
            //     name: "UTT Pan coupon Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UTT_COUPON_SURCHARGE"
            // },
            // {
            //     path: "/upisurcharge",
            //     name: "UPI Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UPI_SURCHARGE"
            // },
            // {
            //     path: "/upicomission",
            //     name: "UPI Comission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UPI_COMMISSION"
            // },
            // {
            //     path: "/slider",
            //     name: "Slider",
            //     icon: <FaMoneyBill />,
            //     Permissions:"SLIDER"
            // },
            // {
            //     path: "/news",
            //     name: "News",
            //     icon: <FaMoneyBill />,
            //     Permissions:"NEWS"
            // },                

            // {
            //     path: "/rechargeplan",
            //     name: "Recharge Plan",
            //     icon: <FaMoneyBill />,
            //     Permissions:"RECHARGE_PLAN"

            // },
            // {
            //     path: "/ministatementcomission",
            //     name: "Mini Statement Comission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MINI_STATEMENT_COMMISSION"
            // },
            // {
            //     path: "/memberidplan",
            //     name: "Member Id Plan",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MEMBER_ID_PLAN"
            // },
            // {
            //     path: "/movetomember",
            //     name: "Move To Member",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MOVE_TO_MEMBER"
            // },
            // {
            //     path: "/statemaster",
            //     name: "State Master",
            //     icon: <FaMoneyBill />,
            //     Permissions:"STATE_MASTER"
            // },
            // {
            //     path: "/citymaster",
            //     name: "City Master",
            //     icon: <FaMoneyBill />,
            //     Permissions:"CITY_MASTER"
            // },

        ],
    },

    {
        path: "/support",
        name: "Support Management",
        icon: <img src={support}></img>,
        exact: true,
        id: "ticketmanagement",
        role:"ROLE_ADMIN",
        Permissions:"SETTINGS",
        subRoutes: [
            {
                path: "/ticketList",
                name: "Ticket Management",
                icon:  <img src={support}></img>,
                Permissions:"CHANGE_PASSWORD",
                role:"ROLE_ADMIN"
            },
            // {
            //     path: "/ticketList",
            //     name: "Ticket",
            //     icon: <FaUser />,
            //     Permissions:"CHANGE_PASSWORD",
            //     role:"ROLE_ADMIN"
            // },

            // {
            //     path: "/holdbalance",
            //     name: "Hold Balance",
            //     icon: <FaLock />,
            //     Permissions:"HOLD_BALANCE"
            // },
            // {
            //     path: "/rechargecommission",
            //     name: "Recharge Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"RECHARGE_COMMISSION"
            // },
            // {
            //     path: "/bbpsrechargecommision",
            //     name: "BBPS Recharge Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"BBPS_RECHARGE_COMMISSION"
            // },
            // {
            //     path: "/billpaycommision",
            //     name: "Bill Pay Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"BILL_PAY_COMMISSION"
            // },
            // {
            //     path: "/aepscommission",
            //     name: "AEPS Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"AEPS_COMMISSION"
            // },
            // {
            //     path: "/adharpaycomission",
            //     name: "Adhar Pay Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"ADHAR_PAY_COMMISSION"
            // },
            // {
            //     path: "/adharpaysetting",
            //     name: "Adhar Pay Setting",
            //     icon: <FaMoneyBill />,
            //     Permissions:"ADHAR_PAY_SETTING"
            // },
            // {
            //     path: "/aepscommission",
            //     name: "AEPS Commission",
            //     icon: <FaMoneyBill />,
            //     Permissions:""
            // },
            // {
            //     path: "/expresspayoutsurcharge",
            //     name: "Express Payout Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"EXPRESS_PAYOUT_SURCHARGE"
            // },
            // {
            //     path: "/expresspayoutcomission",
            //     name: "Express Payout Commissione",
            //     icon: <FaMoneyBill />,
            //     Permissions:"EXPRESS_PAYOUT_COMMISSION"
            // },
            // {
            //     path: "/payoutsurcharge",
            //     name: "Payout Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"PAYOUT_SURCHARGE"
            // },
            // {
            //     path: "/uttpansurcharge",
            //     name: "UTT Pan Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UTT_SURCHARGE"
            // },
            // {
            //     path: "/uttpancouponsurcharge",
            //     name: "UTT Pan coupon Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UTT_COUPON_SURCHARGE"
            // },
            // {
            //     path: "/upisurcharge",
            //     name: "UPI Surcharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UPI_SURCHARGE"
            // },
            // {
            //     path: "/upicomission",
            //     name: "UPI Comission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"UPI_COMMISSION"
            // },
            // {
            //     path: "/slider",
            //     name: "Slider",
            //     icon: <FaMoneyBill />,
            //     Permissions:"SLIDER"
            // },
            // {
            //     path: "/news",
            //     name: "News",
            //     icon: <FaMoneyBill />,
            //     Permissions:"NEWS"
            // },                

            // {
            //     path: "/rechargeplan",
            //     name: "Recharge Plan",
            //     icon: <FaMoneyBill />,
            //     Permissions:"RECHARGE_PLAN"

            // },
            // {
            //     path: "/ministatementcomission",
            //     name: "Mini Statement Comission",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MINI_STATEMENT_COMMISSION"
            // },
            // {
            //     path: "/memberidplan",
            //     name: "Member Id Plan",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MEMBER_ID_PLAN"
            // },
            // {
            //     path: "/movetomember",
            //     name: "Move To Member",
            //     icon: <FaMoneyBill />,
            //     Permissions:"MOVE_TO_MEMBER"
            // },
            // {
            //     path: "/statemaster",
            //     name: "State Master",
            //     icon: <FaMoneyBill />,
            //     Permissions:"STATE_MASTER"
            // },
            // {
            //     path: "/citymaster",
            //     name: "City Master",
            //     icon: <FaMoneyBill />,
            //     Permissions:"CITY_MASTER"
            // },

        ],
    },
    {
        path: "/reports",
        name: "Reports",
        icon: <GoSettings />,
        exact: true,
        id: "Reports",
        subRoutes: [

            // {
            //     path: "/exportreports",
            //     name: "Export Reports",
            //     icon: <FaUser />,
            //     Permissions:"EXP_REPORTS"
            // },

            {
                path: "/exportreports",
                name: "Export Reports",
                icon: <FaUser />,
                Permissions:"EXP_REPORTS"
            },

            {
                path: "/aepshistory",
                name: "AEPS History",
                icon: <FaLock />,
                Permissions:"AEPS_HISTORY"
            },

            // {
            //     path: "/adharpayhistory",
            //     name: "Adhar Pay History",
            //     icon: <FaMoneyBill />,
            //     Permissions:"ADHAR_HISTORY"
            // },
            // {
            //     path: "/recharge",
            //     name: "Recharge",
            //     icon: <FaMoneyBill />,
            //     Permissions:"RECHRGE"
            // },
            // {
            //     path: "/bbps",
            //     name: "BBPS",
            //     icon: <FaMoneyBill />,
            //     Permissions:"BBPS"
            // },
            // {
            //     path: "/bills",
            //     name: "Bills",
            //     icon: <FaMoneyBill />,
            //     permission:"BILLS"
            // },
            // {
            //     path: "/adminbalance",
            //     name: "Admin + Balance",
            //     icon: <FaMoneyBill />,
            //     permissions:"ADMIN_BAL"
            // },
            // {
            //     path: "/expresspayout",
            //     name: "Express Payout",
            //     icon: <FaMoneyBill />,
            //     permissions:"EXPRESS_PAYOUT"
            // },
            // {
            //     path: "/payout",
            //     name: "Payout",
            //     icon: <FaMoneyBill />,
            //     permissions:"PAYOUT"
            // },
            // {
            //     path: "/utiregistrationpanhistory",
            //     name: "UTI Registration Pan History",
            //     icon: <FaMoneyBill />,
            //     permissions:"UTI_REGISTRATION_HISTORY"
            // },
            // {
            //     path: "/utipancouponhistory",
            //     name: "UTI Pan Coupon History",
            //     icon: <FaMoneyBill />,
            //     permissions:"MEMBER_REPORT"
            // },
            // {
            //     path: "/virtualaccountreport",
            //     name: "Virtual Account Report",
            //     icon: <FaMoneyBill />,
            //     permissions:"VIRTUAL_REPORT"
            // },
            // {
            //     path: "/upicollectionreport",
            //     name: "UPI Collection Report",
            //     icon: <FaMoneyBill />,
            //     permissions:"UPI_COLLECTION_REPORT"
            // },
            // {
            //     path: "/memberidreport",
            //     name: "Member Id Report",
            //     icon: <FaMoneyBill />,
            //     permissions:"MEMBER_REPORT"
            // },
            // {
            //     path: "/tdsreport",
            //     name: "TDS Report",
            //     icon: <FaMoneyBill />, 
            //     permissions:"TDS_REPORT"

            // },

        ],
    },

    {
        path: "/helpandsupport",
        name: "Help & Support",
        icon: <AiOutlineQuestionCircle />,
    },

    // {
    //     path: "/logout",
    //     name: "Logout",
    //     icon: <FiLogOut />,
    // },
];