import React, { useEffect, useState } from "react";
import Input from "../../Components/Input/Input";
import { useNavigate } from "react-router-dom";

import { addUser, createVendorUser } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import {
  IndianStates,
  isAlphabet,
  isEmpty,
  isNumber,
} from "../../utils/common";

import { AlertWrapper } from "../../Components/Modal/style";
import { useSelector } from "react-redux";

require("react-datepicker/dist/react-datepicker.css");

const orgList = [
    {
        label:"Agriculture",
        value:"Agriculture"
    },
    {
        label:"Manufacturing",
        value:"Manufacturing"
    },
    {
        label:"Retail",
        value:"Retail"
    },
    {
        label:"Healthcare",
        value:"Healthcare"
    },
    {
        label:"Information Technology",
        value:"Information Technology"
    },
    {
        label:"Finance and Banking",
        value:"Finance and Banking"
    },
    {
        label:"Education",
        value:"Education"
    },
    {
        label:"Hospitality",
        value:"Hospitality"
    },
    {
        label:"Transportation and Logistics",
        value:"Transportation and Logistics"
    },
    {
        label:"Government",
        value:"Government"
    },
]

const AddVendor = () => {
  const initialValues = {
    userName: "",
    orgName: "",
    orgAddress: "",
    orgEmail: "",
    orgPinCode: "",
    orgContactNumber: "",
    orgType: "",
    panNumber: "",
    gstNumber: "",
    address: "",
    landmark: "",
    district: "",
    state: "",
  };
  const [userData, setUserData] = useState({ ...initialValues });
  const [errors, setErrors] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [districtsList, setDistrictsList] = useState([]);
  const navigate = useNavigate();
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleFormData = () => {
    let error = {};

    if (userData.userName === "") {
      error.userName = "Please enter your username";
    } else if (userData?.userName.length < 10) {
      error.userName = "Username can't be less than 10 digits";
    }
    if (userData?.orgName === "") {
      error.orgName = "Please enter your first name";
    }
    if (userData?.panNumber === "") {
      error.panNumber = "Please enter PAN number";
    }else if(userData?.panNumber.length > 10){
        error.panNumber = "Please enter valid PAN number";
    }
    if (userData.gstNumber === "") {
      error.gstNumber = "Please enter your GST number";
    }
    if (userData.orgType === "") {
      error.orgType = "Please enter select organisation type";
    }

    if (userData?.orgContactNumber === "") {
      error.orgContactNumber = "Please enter mobile number";
    } else if (userData.orgContactNumber.length > 10) {
      error.orgContactNumber = "Please enter valid mobile number";
    }

    if (userData.orgEmail === "") {
      error.orgEmail = "Please enter Email";
    }
    if (!isValidEmail(userData.orgEmail)) {
      error.orgEmail = "Please enter valid email";
    }
    if (userData.address === "") {
      error.address = "Please enter address";
    }

    if (userData?.state === "") {
      error.state = "Please select state";
    }

    if (userData?.orgPinCode === "") {
      error.orgPinCode = "Please enter your pincode";
    } else if (userData.orgPinCode.length < 6) {
      error.orgPinCode = "Pincode can't be less than 6 digits";
    }

    if (userData?.district === "") {
      error.district = "Please select district";
    }

    setErrors({ ...errors, ...error });

    if (isEmpty(error)) {
      setDisableBtn(true);
      setLoading(true);

      const address = `${userData.address}, ${!isEmpty(userData.landmark)? userData.landmark+ ",":""} ${userData.district}, ${userData.state}`;

      const params = {
        userId: userData.userName,
        orgName: userData.orgName,
        orgAddress: address,
        orgEmail: userData.orgEmail,
        orgPinCode: userData.orgPinCode,
        orgContactNumber: userData?.orgContactNumber,
        orgType: userData?.orgType,
        panNumber: userData.panNumber,
        gstNumber: userData.gstNumber,
      };
      createVendorUser(params).then((resp) => {
        setDisableBtn(false);
        setLoading(false);
        
        if (resp?.data?.code === "0000") {

            setMessage({msg:"User registered successfully.", type:"success"})
                 
            setTimeout(() => {
                navigate("/vendor-list");
                setDisableBtn(false)
                setLoading(false);
            }, 2000);

            setUserData({...initialValues});
            setDistrictsList([]);
          } else {
            
            let msg = "";
            if (resp?.data?.errorCodeList.length > 0) {
              msg = resp?.data?.errorCodeList[0];
            } else {
              msg = resp?.data?.message;
            }
            setMessage({msg:msg, type:"error"});
         
           
          }


      });
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setErrors({ ...errors, [name]: "" });
  

    if (
      name === "userName" ||
      name === "orgContactNumber" ||
      name === "orgPinCode"
    ) {
      if (!isNumber(value) && !isEmpty(value)) return;
      value = e.target.value.trim();
    }
    if (name === "panNumber") {
      value = e.target.value.toUpperCase();
    }

    //   if ( || (name === "orgName")) {
    //     if (!isAlphabet(value) && !isEmpty(value))  return;

    // }

    setUserData({ ...userData, [name]: value });
  };

  const { user } = useSelector((state) => state.user);

  const stateHandler = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setErrors({ ...errors, [name]: "" });
    setUserData({ ...userData, [name]: value });

    IndianStates.map((val) => {
      if (val?.name === e.target.value) {
        setDistrictsList(val.districts);
      }
    });
  };
useEffect(() =>{
   setTimeout(() =>{
    setMessage({})
   },2000)
},[message])
  return (
    <>
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="main_container">
        <div className="container1">
          <label>Create New Vendor</label>
        </div>
        <div className={`container_form `}>
          <div className="shadowBox">
          <div className="">
            <div className="">
              <h3>Personal Details</h3>
            </div>
          </div>

          <div className="role role_responsive">
            <div className="retailer">
              <div className="labelname">
                <label>Username</label>
                <span className="required"> *</span>
              </div>
              <Input
                type="text"
                maxLength={10}
                placeholder="Enter your  mobile number"
                name="userName"
                value={userData?.userName}
                onChange={handleInput}
                className=""
              />
              {errors.userName && (
                <p className="error fs-12 mt-10">{errors.userName}</p>
              )}
            </div>

            <div className="retailer">
              <div className="labelname">
                <label>Organisation Name</label>
                <span className="required"> *</span>
              </div>
              <div>
                <Input
                  className="inputtype"
                  type="text"
                  placeholder="Enter your organisation name"
                  name="orgName"
                  value={userData?.orgName}
                  onChange={handleInput}
                />
                {errors.orgName && (
                  <p className="error fs-12 mt-10">{errors?.orgName}</p>
                )}
              </div>
            </div>
          </div>

          <div className="role role_responsive ">
            <div className="retailer">
              <div className="labelname">
                <label>PAN Number</label>
                <span className="required"> *</span>
              </div>

              <Input
                type="text"
                maxLength={10}
                placeholder="Enter PAN number"
                name="panNumber"
                value={userData?.panNumber}
                onChange={handleInput}
              />
              {errors?.panNumber && (
                <p className="error fs-12 mt-10">{errors?.panNumber}</p>
              )}
            </div>
            <div className="retailer">
              <div className="labelname">
                <label>GST Number</label>
                <span className="required"> *</span>
              </div>
              <Input
                type="text"
                placeholder="Enter GST number"
                name="gstNumber"
                maxLength={15}
                value={userData?.gstNumber}
                onChange={handleInput}
              />
              {errors?.gstNumber && (
                <p className="error fs-12 mt-10">{errors?.gstNumber}</p>
              )}
            </div>
          </div>

          <div className="role role_responsive">
            <div className="retailer">
              <div className="labelname">
                <label>Organisation Type</label>
                <span className="required"> *</span>
              </div>
              <div className="input-field">
                <select
                  className="input_select_field"
                  value={userData?.orgType}
                  name="orgType"
                  onChange={handleInput}
                >
                  <option value={""}>Select Organisation Type</option>
                  {orgList &&
                    orgList.map((val, index) => {
                      return (
                        <option key={index} value={val.value}>
                          {val.label}
                        </option>
                      );
                    })}
                </select>
                {errors?.orgType && (
                  <p className="error fs-12 mt-10">{errors?.orgType}</p>
                )}
              </div>
            </div>
          </div>
          </div>
 <div className="shadowBox">
          <div className=" ">
            <div className="">
              <h3>Contact Details</h3>
            </div>
          </div>
          <div className="role role_responsive ">
            <div className="retailer">
              <div className="labelname">
                <label>Organisation Contact Number</label>
                <span className="required"> *</span>
              </div>

              <Input
                type="text"
                maxLength={10}
                placeholder="Enter mobile number"
                name="orgContactNumber"
                value={userData.orgContactNumber}
                onChange={handleInput}
              />
              {errors.orgContactNumber && (
                <p className="error fs-12 mt-10">{errors.orgContactNumber}</p>
              )}
            </div>
            <div className="retailer">
              <div className="labelname">
                <label>Organisation Email Address</label>
                <span className="required"> *</span>
              </div>
              <Input
                type="text"
                placeholder="Enter email address"
                name="orgEmail"
                value={userData.orgEmail}
                onChange={handleInput}
              />
              {errors.orgEmail && (
                <p className="error fs-12 mt-10">{errors.orgEmail}</p>
              )}
            </div>
          </div>
          </div>
          {/* Address Details */}
          <div className="shadowBox">
          <div className="">
            <div className="">
              <h3>Address Details</h3>
            </div>
          </div>
          <div className="role role_responsive">
            <div className="retailer">
              <div className="labelname">
                <label>Address</label>
                <span className="required"> *</span>
              </div>
              <Input
                type="text"
                placeholder="Enter address"
                name="address"
                value={userData?.address}
                onChange={handleInput}
              />
              {errors.address && (
                <p className="error fs-12 mt-10">{errors.address}</p>
              )}
            </div>
            <div className="retailer">
              <div className="labelname">
                <label>Landmark</label>
              </div>
              <div>
                <Input
                  type="text"
                  placeholder="Enter landmark"
                  name="landmark"
                  value={userData.landmark}
                  onChange={handleInput}
                />
              </div>
            </div>
          </div>
          <div className="role role_responsive">
            <div className="retailer">
              <div className="labelname">
                <label>State</label>
                <span className="required"> *</span>
              </div>
              <div className="input-field">
                <select
                  className="input_select_field"
                  value={userData?.state}
                  name="state"
                  onChange={stateHandler}
                >
                  <option value={""}>Select State</option>
                  {IndianStates &&
                    IndianStates.map((val, index) => {
                      return (
                        <option key={index} value={val.name}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
                {errors.state && (
                  <p className="error fs-12 mt-10">{errors.state}</p>
                )}
              </div>
            </div>
            <div className="retailer">
              <div className="labelname">
                <label>District</label>
                <span className="required"> *</span>
              </div>
              <div className="input-field">
                <select
                  className="input_select_field"
                  value={userData?.district}
                  name="district"
                  onChange={handleInput}
                >
                  <option value={""}>Select District</option>
                  {!isEmpty(districtsList) &&
                    districtsList.map((val) => {
                      return <option value={val}>{val}</option>;
                    })}
                </select>
                {errors?.district && (
                  <p className="error fs-12 mt-10">{errors?.district}</p>
                )}
              </div>
            </div>
          </div>
          <div className="role role_responsive">
            <div className="retailer">
              <div className="labelname">
                <label>Organisation Pincode</label>
                <span className="required"> *</span>
              </div>
              <Input
                type="text"
                placeholder="Enter pincode"
                name="orgPinCode"
                maxLength={6}
                value={userData?.orgPinCode}
                onChange={handleInput}
              />
              {errors?.orgPinCode && (
                <p className="error fs-12 mt-10">{errors?.orgPinCode}</p>
              )}
            </div>
          </div>
          <div className="role role_responsive">
            <div className="retailer">
              <button
                className="submit_btn "
                disabled={disableBtn}
                onClick={handleFormData}
              >
                Submit
              </button>
            </div>
          </div>
          </div>
        </div>
        <>{loading && <GloabalLoader />}</>
      </div>
    </>
  );
};
export default AddVendor;
