import React, { useContext, useEffect, useState } from "react";
import walletamount from "../../assets/images/wallet_amount.png";
import virtualamount from "../../assets/images/virtual_amount.png";
import upiamount from "../../assets/images/upi_amount.png";
import totalmember from "../../assets/images/userslogo.svg"
import { getAepsTreeBalance, getMainTreeBalance, getUsersData, getsettlementBalance } from "../../utils/api";
import { isEmpty } from "../../utils/common";
import { useSelector } from "react-redux";
import { UserContextData } from "../../App";


export default function AmountOverview() {

    const [usersData, setUsersData] = useState('');
    const [treeBalanceAeps, setTreebalanceAeps] = useState('');
    const [treeBalanceMain, setTreebalanceMain] = useState('');
    const [loginUserType, setLoginUserTye] = useState("");

    const userUpdate = useContext(UserContextData)
    useEffect(() =>{
        getMainTreeBalance().then((resp) =>{
            if(resp?.data?.success){
                setTreebalanceMain(resp?.data?.data?.MAIN_WALLET)
            }

        });

        getAepsTreeBalance().then((resp) =>{
            if(resp?.data?.success){
              
                setTreebalanceAeps(resp?.data?.data?.AEPS_WALLET)
                
            }

        });
       
        
    },[userUpdate])

    const { user } = useSelector((state) => state.user);
  

  
  useEffect(() =>{

    if((loginUserType === "") &&  !isEmpty(user?.userType)){
      setLoginUserTye(user?.userType);
    
    }

  },[user])




    useEffect(() =>{
       if(!isEmpty(loginUserType)  ){ 
        getUsersData(user?.userType).then((resp) =>{
            if(resp?.data?.success){             
                setUsersData(resp?.data?.data);             
            }
     
    
        });
    }

    
   
    },[loginUserType,userUpdate])

    return(
        <div className="amount_overview">
            <div className='amount_overview_pic_with_right_text'>
                <div className='pic'>
                    <img src={walletamount}></img>
                </div>
                <div className='text-info'>
                    <span>Main Wallet Amount</span>
                    <strong className="fs-18">₹{isEmpty(treeBalanceMain)
                    ?"0":
                    treeBalanceMain}
                    </strong>
                </div>
            </div>
            <div className='amount_overview_pic_with_right_text'>
            <div className='pic'>
                    <img src={virtualamount}></img>
                </div>
                <div className='text-info'>
                    <span>AEPS Wallet Amount</span>
                    <strong className="fs-18">₹ {isEmpty(treeBalanceAeps)
                    ?"0":
                    (treeBalanceAeps)}
                    </strong>
                </div>
            </div>
            <div className='amount_overview_pic_with_right_text'>
            <div className='pic'>
                    <img src={totalmember}></img>
                </div>
                <div className='text-info'>
                    <span>Total Active Retailers</span>
                    <strong className="fs-18">{isEmpty(usersData.activeUsers)
                    ?"0":
                    (usersData.activeUsers)}
                    </strong>
                    
                </div>
            </div>
            <div className='amount_overview_pic_with_right_text'>
            <div className='pic'>
                    <img src={totalmember}></img>
                </div>
                <div className='text-info'>
                    <span>Total Inactive Retailers</span>
                    <strong className="fs-18"> {isEmpty(usersData.inactiveUsers)
                    ?"0":
                    (usersData.inactiveUsers)}
                    </strong>
                    
                </div>
            </div>
        </div>
    )
}