import React, { useEffect, useState } from "react";
import uploadImage from "../../assets/images/uploadImage.png";
import senderImage from "../../assets/images/senderImage.png";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getChatMessage, sendChatMessage, updateTicket } from "../../utils/api";
import { useSelector } from "react-redux";
import { AlertWrapper } from "../../Components/Modal/style";
import { isEmpty } from "../../utils/common";

const ViewTicket = () => {
  // chat message

  const [chat, setChat] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const { user } = useSelector((state) => state.user);

  const location = useLocation();
  const tickeListData = location.state;
  const [updateTicketData, setUpdateTicketData] = useState({
    ...tickeListData,
  });
  const [action, setAction] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  const [date, time] = tickeListData.createdAt.split("T");
  let newTime = time.slice(0, 8);
  const [updatedate, updatetTime] = tickeListData.updatedAt.split("T");
  let UpdateNewTime = updatetTime.slice(0, 8);
  const [selectedFile, setSelectedFile] = useState(null);


  const getChatMessageRequest = () => {
    const assigneeToUse = (tickeListData?.assignee) !== (user?.uuid)
    ? tickeListData?.assignee
    : tickeListData?.createdBy;
    getChatMessage(
      tickeListData?.ticketId,
      assigneeToUse,
      currPage,
      pageSize,
    ).then((res) => {
      if (res?.data?.data) {
        setChat(res?.data?.data?.content);
      
      }
    });
  };

  const sendChatMessageRequest = (e) => {
    const assigneeToUse = (tickeListData?.assignee) !== (user?.uuid)
    ? tickeListData?.assignee
    : tickeListData?.createdBy;
    var formData = new FormData();
    formData.append("from", user?.uuid);
    formData.append("to", assigneeToUse);
    formData.append("message", chatMessage);
    formData.append("ticketId", updateTicketData?.ticketId);
    formData.append("attachment", selectedFile);

    sendChatMessage(formData).then((res) => {
      if (res?.data?.code === "0000") {
        setChatMessage("");
        getChatMessageRequest();
      }
    });
  };

  const handleUpdateTicketData = async () => {
    const data = new FormData();
    data.append(
      "ticketRequest",
      JSON.stringify({
        ticketId:updateTicketData?.ticketId,
        category: updateTicketData?.category,
        requestType: updateTicketData?.requestType,
        requesterName :updateTicketData?.requesterName,
        email:updateTicketData?.emailId,
        assignee: updateTicketData?.assignee,
        priority: updateTicketData?.priority,
        txnRefId: updateTicketData?.referenceId,
        summary: updateTicketData?.summary,
        description: updateTicketData?.description,  
        adminRemarks: updateTicketData?.reamrks,
        status :action,
      })
    );
    data.append("file", imagePreview);

    updateTicket(data).then((resp) => {
      if (resp?.data?.success === true) {
        setMessage({
          msg: "Ticket updated successfully.",
          type: "success",
        });
        setTimeout(()=>{
          navigate("/ticketList");
        },3000)
      } else {
        setLoading(false);
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        setMessage({ msg: msg, type: "error" });
        setDisableBtn(false);
      }
    });
  };


  useEffect(() => {
    const timer = setInterval(() => {
        getChatMessageRequest();
    }, 3000)
    return () => {
        clearInterval(timer);
    }
}, [user?.uuid])

  useEffect(() => {
    getChatMessageRequest();
  }, [user?.uuid]);

  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };



  const handleChange = (e) => {
    setChatMessage(e.target.value);
    if (e.keyCode == 13 && e.shiftKey == false) {
        sendChatMessageRequest(e);
        scrollToBottom()
    }
}


useEffect(() => {
    scrollToBottom()
}, [chat.length])

const scrollToBottom = () => {
    const data = document.querySelector('.chat_scroll_container');
    data.scrollTo(0, data.scrollHeight)
}
const handleKeyDown = (e) => {
  if (e.key === "Enter") {
    sendChatMessageRequest();
  }
};

  return (
    <>
    {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="userlisthead_container">
        <div className="view_ticket_Container">
          <strong>View Ticket</strong>

          <div className="flex align-item-center view_tickets_wrapper">
            <div className="view_ticket_wrapper">
              <div>
                <h3>Ticket Chat Details</h3>
                <div className="flex gap-20 mt-10 maxheight-500">
                  <div className="view_ticket_details">
                    <ul className="ticket_detail">
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Ticket ID</span>
                        <span className="ml-10">
                          {tickeListData?.ticketId || "-"}
                        </span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Name</span>
                        <span className="ml-10">{tickeListData?.requesterName||"-"}</span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600">Mobile No.</span>
                        <span className="ml-10">{tickeListData?.assigneeMobile||"-"}</span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Ticket Date</span>
                        <span className="ml-10">{date + " " + newTime}</span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600">Issue Category</span>
                        <span className="ml-10">{tickeListData?.category}</span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600">Request Type</span>
                        <span className="ml-10">
                          {tickeListData?.requestType}
                        </span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Description</span>
                        <span className="ml-10">
                          {tickeListData?.description}
                        </span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600">Issue Status</span>
                        <span>
                          {tickeListData?.status}
                        </span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Last Update</span>
                        <span className="ml-10">
                          {updatedate + " " + UpdateNewTime}
                        </span>
                      </li>
                      <li className="mg-bottom17 flex justify-between">
                        <span className="fw-600 ">Action</span>
                        <span>
                          {" "}
                          <select
                            name="status"
                            value={tickeListData?.status}
                            onChange={(e) => setAction(e.target.value)}
                          >
                            <option value={""}>Select Action</option>
                            <option value="OPEN">OPEN</option>
                            <option value="PENDING">PENDING</option>
                            <option value="CLOSE">CLOSE</option>
                          </select>
                        </span>
                      </li>
                      <li className="flex justify-center ">
                        <span>
                          <button
                            className="ticket_btn"
                            onClick={() => {
                              handleUpdateTicketData();
                            }}
                          >
                            Update
                          </button>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="chat_container">
              <div className="chat_scroll_container">
                {chat.map((val) => {
           
                     if(val?.fromId !== user?.uuid){
                      return(<div className="flex gap-10 mt-5">
                      <div className="sender_pic"></div>
                      <div className="mx-width reciver_path chat_msg fs-13">
                        {val?.message}
                      </div>
                    </div>)
                     }
                     if(val?.fromId === user?.uuid){
                      return(

                        <div
                        className="flex  mt-5"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div className="mx-width sender_path pd-10 fs-13">
                          {val?.message}
                        </div>
                        <div className="pic flex align-item-center">
                          <img className="senderImage" src={senderImage}></img>
                        </div>
                      </div>
                    )
                     }
                })}
                
              </div>
              <div className="chat_footer">
                <textarea
                  type="text"
                  className="chat_footer_text w-100"
                  placeholder="Type Your Msg Here..."
                  value={chatMessage}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onKeyDown={handleKeyDown}
                ></textarea>
                <div className=" ">
                  {/* <img
                    className="position_chat"
                    src={smile}
                    style={{ left: "20px" }}
                  ></img> */}
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileInput" className="customFileInput flex gap-10">
                    <img
                      src={uploadImage}
                      alt="Upload"
                      className="customFileIcon"
                    />
                    {selectedFile && <p>{selectedFile.name}</p>}
                  </label>
                  <button
                    className="ticket_btn"
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "5px",
                    }}
                    onClick={() => {
                      sendChatMessageRequest();
                    }}                   
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewTicket;
