import React from 'react'
import { useState, useEffect } from 'react';
import Input from '../../Components/Input/Input';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { updateAdminProfile } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import { AlertWrapper } from '../../Components/Modal/style';

const EditProfile = () => {
    const location = useLocation();
    const user = location.state;
    const [apiUserData, setApiUserData] = useState({...user});
    const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
    const navigate = useNavigate();
    


    useEffect(() => {
        // getUserData();
    }, []);

    const updatetUserData = async () => {

        updateAdminProfile(apiUserData).then((resp) => {
            if (resp?.data?.success === true) {
              setMessage({
                msg: "User updated Successfully",
                type: "success",
              });
              setTimeout(()=>{
                navigate("/");
              },3000)
              
            } else {
              setLoading(false);
              let msg = "";
              if (resp?.data?.errorCodeList.length > 0) {
                msg = resp?.data?.errorCodeList[0];
              } else {
                msg = resp?.data?.msg;
              }
              setMessage({ msg: msg, type: "error" });
              setDisableBtn(false);
            }
          });
      
    };


   
    const handleInput = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setApiUserData({ ...apiUserData, [name]: value });
    };
    return (
        <>
        {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
        <div className="admin_profile_container">
            <div className="admin_profile_header">
                <h2>Admin Profile</h2>
            </div>

            <div className="admin_profile_main">
                <div className="admin_profile_row ">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Personal Details
                        </label>
                    </div>

                </div>
                <div className="admin_profile_row role_responsive">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                UserName
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you username"
                                name="userName"
                                value={apiUserData?.userName}

                            />
                        </div>

                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                DOB
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you dob"
                                name="dob"
                                value={"03/03/1989"}

                            />

                        </div>
                    </div>
                </div>
                <div className="admin_profile_row role_responsive">
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Firstname
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input className="inputtype"
                                type="text"
                                placeholder="enter you firstName"
                                name="firstName"
                                value={apiUserData?.firstName}
                            />
                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Lastname
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input className="inputtype"
                            type="text"
                            placeholder="enter you lastName"
                            name="lastName"
                            value={apiUserData?.lastName}                          
                            />

                    </div>
                </div>

                {/* Contact Infomation */}


                <div className="admin_profile_row ">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Contact Details
                        </label>
                    </div>

                </div>

                <div className="admin_profile_row role_responsive">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Contact Number
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you mobile number"
                                name="phoneNumber"
                                value={apiUserData?.userName}
                            />

                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Email Address
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you email"
                            name="email"
                            value={apiUserData?.email}
                        />

                    </div>
                </div>


                {/* Address Details */}

                <div className="admin_profile_row ">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Address Details
                        </label>
                    </div>

                </div>

                <div className="admin_profile_row role_responsive">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Address1
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you address"
                                name="address1"
                                value={apiUserData?.address1}
                                onChange={(e) => handleInput(e, "text")}
                            />

                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Address2
                            </label>

                        </div>
                        <Input
                            type="text"
                            placeholder="enter you address2"
                            name="address2"
                            value={apiUserData?.address2}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className="admin_profile_row role_responsive">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Landmark
                            </label>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you landmark"
                                name="landmark"
                                value={apiUserData?.landmark}
                                onChange={handleInput}
                            />
                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Picode
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you pincode"
                            name="pincode"
                            value={apiUserData?.pincode}
                            onChange={handleInput}
                        />

                    </div>
                </div>
                <div className="admin_profile_row admin_margin">
                    <div className="admin_profile_column">
                        <button onClick={() => {updatetUserData()}}
                            className="submit_btn"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default EditProfile