import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar.jsx";
import ProtectedRoute from "./Components/ProtectedRoute.js";
import SideBar from "./Components/Sidebar/Sidebar.jsx";
import Login from "./pages/loginRegister/Login.jsx";
import Operators from "./pages/Operators/Operators.jsx";
import Overview from "./pages/overview/Overview.jsx";
import Services from "./pages/ServiceList/Services.jsx";
import Transactions from "./pages/Transactions/Transactions.jsx";
import AddUser from "./pages/Users/AddUser.jsx";
import EditUser from "./pages/Users/EditUser.jsx";
import UsersList from "./pages/Users/UsersList.jsx";
import AddCredit from "./pages/Wallet/AddDebitCredit.jsx";
import WalletSummary from "./pages/Wallet/WalletSummary.jsx";
import { login } from "./store/authSlice.js";
import { getUser } from "./store/userSlice.js";
import Profile from "./pages/AdminProfile/Profile.jsx";
import EditProfile from "./pages/AdminProfile/EditProfile.jsx";
import ChangePassword from "./pages/Settings/ChangePassword.jsx";
import Verification from "./pages/Users/Verification.jsx";
import OtpVerification from "./pages/Users/OtpVerification.jsx";
import MobileVerificationMsg from "./pages/Users/MobileVerificationMsg.jsx";
import BiometricVerification from "./pages/Users/BiometricVerification.jsx";
import FingerPrint from "./pages/Users/FingerPrint.jsx";
import FingerPrintMsg from "./pages/Users/FingerPrintMsg.jsx";
import RechrageCommission from "./pages/Settings/RechrageCommission.jsx";
import BBPSRechargeCommission from "./pages/Settings/BBPSRechargeCommission.jsx";
import NewSubadmin from "./pages/NewMember/NewSubadmin.jsx";
import FreeMemberIdPlan from "./pages/NewMember/FreeMemberIdPlan.jsx";
import AddNewMember from "./pages/NewMember/AddNewMember.jsx";
import MemberList from "./pages/NewMember/MemberList.jsx";
import ClientDetails from "./pages/NewMember/ClientDetails.jsx";
import BankDetails from "./pages/NewMember/BankDetails.jsx";
import EditService from "./pages/ServiceList/EditService.jsx";
import EditOperator from "./pages/Operators/EditOperator.jsx";
import MerchantCommissionList from "./pages/NewMember/MerchantCommissionList.jsx";
import AddNewMerchant from "./pages/NewMember/AddNewMerchant.jsx";
import HelpandSupport from "./pages/Help&Support/HelpandSupport.jsx";
import EditHelpSupport from "./pages/Help&Support/EditHelpSupport.jsx";
import CreateTicket from "./pages/TicketManagement/CreateTicket.jsx";
import TicketList from "./pages/TicketManagement/TicketList.jsx";
import ViewTicket from "./pages/TicketManagement/ViewTicket.jsx";
import PermissionManagement from "./pages/NewMember/RoleManagmentAndPermission/PermissionManagement.jsx";
import { getUserAccount } from "./utils/api.js";
import DebitCreditList from "./pages/Wallet/DebitCreditList.js";
import ApiPermission from "./pages/NewMember/ApiPermissionManagement/ApiPermission.jsx";
import PackageManagementList from "./pages/NewMember/PackageMnagement/PackageList.jsx";
import SelectPackage from "./pages/Users/SelectPackeg.jsx";
import CreatePackage from "./pages/NewMember/PackageMnagement/ManagePackage.jsx";
import Service from "./pages/NewMember/PackageMnagement/Service.jsx";
import RetailerList from "./pages/Users/RetailerList.jsx";
import PaymentReceive from "./pages/PaymentReceive/PaymentReceive.jsx";
import Invoice from "./pages/Wallet/Invoice.jsx";
import Vendor from "./pages/Vendor/Vendor.jsx";
import VendorTransaction from "./pages/Vendor/VendorTransaction.jsx";
import AddVendor from "./pages/Vendor/AddVendor.jsx";
import ManageFundRequest from "./pages/Vendor/ManageFundRequest.jsx";

export const UserContextData = createContext();
function App() {
  const dispatch = useDispatch();

  const [isAuth, setIsAuth] = useState(null);
  let auth = JSON.parse(sessionStorage.getItem("token"));
  let authData = useSelector((state) => state.auth.auth);
  const [isOpen, setIsOpen] = useState(true);
  const [permission, setPermission] = useState([]);
  const [siedBarLeft, setSiedBarLeft] = useState("");

  useEffect(() => {
    if (auth) {
      dispatch(login(auth));
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  const handleUserData = async () => {
    if (authData) {
      getUserAccount().then((resp) => {
        // setLoading(false)
        let userInfo = resp?.data?.data;

        dispatch(getUser(userInfo));
      });
    }
  };

  useEffect(() => {
    handleUserData();
  }, [authData]);

  const expTime = () => {
    const timeOut = setTimeout(() => {
      const time = sessionStorage.getItem("timer");
      if (time > 0) {
        sessionStorage.setItem("timer", parseInt(time) - 1);

        expTime();
      } else {
        clearTimeout(timeOut);
        sessionStorage.clear();
      }
    }, 1000);
  };

  return (
    <BrowserRouter>
   
      {!auth && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}

      {authData && (
        <UserContextData.Provider
        //  value={{upadteData}}
        >
          <SideBar
            siedBarLeft={siedBarLeft}
            setSiedBarLeft={setSiedBarLeft}
            setPermission={setPermission}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <main className={`${isOpen ? "main_off" : "main_on"} `}>
              <Navbar
                setSiedBarLeft={setSiedBarLeft}
                permission={permission}
                // setUpdateData={setUpdateData} upadteData={upadteData}
              />

              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute
                      Component={Overview}
                      permission={permission}
                    />
                  }
                />
                {permission.includes("MANAGE_USERS") && (
                  <>
                    <Route
                      path="/usersList"
                      element={<ProtectedRoute Component={UsersList} />}
                    />
                  </>
                )}

                {permission.includes("MANAGE_RETAILER") ? (
                  <>
                    <Route
                      path="/retailerList"
                      element={
                        <ProtectedRoute
                          Component={RetailerList}
                          permission={permission}
                        />
                      }
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute
                          Component={Overview}
                          permission={permission}
                        ></ProtectedRoute>
                      }
                    />
                  </>
                )}

                <Route
                  path="/addnewmember"
                  element={<ProtectedRoute Component={AddNewMember} />}
                />
                <Route
                  path="/memberlist"
                  element={<ProtectedRoute Component={MemberList} />}
                />
                <Route
                  path="/clientdetails"
                  element={<ProtectedRoute Component={ClientDetails} />}
                />
                <Route
                  path="/newsubadmin"
                  element={<ProtectedRoute Component={NewSubadmin} />}
                />
                <Route
                  path="/freememberidplan"
                  element={<ProtectedRoute Component={FreeMemberIdPlan} />}
                />
                <Route
                  path="/merchantcommissionlist"
                  element={
                    <ProtectedRoute Component={MerchantCommissionList} />
                  }
                />

                {permission.includes("ROLE_PERMISSION_API") && (
                  <>
                    <Route
                      path="/permisionManagement"
                      element={
                        <ProtectedRoute Component={PermissionManagement} />
                      }
                    />
                  </>
                )}

                {permission.includes("ROLE_PERMISSION_API") && (
                  <>
                    <Route
                      path="/ApiPermisionManagement"
                      element={<ProtectedRoute Component={ApiPermission} />}
                    />
                  </>
                )}

                {permission.includes("MANAGE_PACKAGE_LIST") && (
                  <>
                    <Route
                      path="/PackageMnagementList"
                      element={
                        <ProtectedRoute Component={PackageManagementList} />
                      }
                    />
                  </>
                )}
                {permission.includes("ADD_MANAGE_PACKAGE") && (
                  <>
                    <Route
                      path="/CreatePackage"
                      element={<ProtectedRoute Component={CreatePackage} />}
                    />
                  </>
                )}
                {permission.includes("ADD_MANAGE_PACKAGE") && (
                  <>
                    <Route
                      path="/service"
                      element={<ProtectedRoute Component={Service} />}
                    />
                  </>
                )}

                <Route
                  path="/addnewmerchant"
                  element={<ProtectedRoute Component={AddNewMerchant} />}
                />

                {permission.includes("ADD_MEMBER") && (
                  <>
                    <Route
                      path="/addUser"
                      element={<ProtectedRoute Component={AddUser} />}
                    />
                  </>
                )}
                {permission.includes("MANAGE_USERS_EDIT") && (
                  <>
                    <Route
                      path="/editUser"
                      element={<ProtectedRoute Component={EditUser} />}
                    />
                  </>
                )}
                {/* <Route
                path="/featuremanagement"
                element={<ProtectedRoute Component={FeatureManagement} />}
              /> */}
                {/* <Route
                path="/addnewservice"
                element={<ProtectedRoute Component={AddNewService} />}
              /> */}
                {permission.includes("CREDIT/DEDUCT") && (
                  <>
                    <Route
                      path="/addcredit"
                      element={<ProtectedRoute Component={AddCredit} />}
                    />
                  </>
                )}
                {permission.includes("CREDIT/DEDUCT") && (
                  <>
                    <Route
                      path="/deposit"
                      element={<ProtectedRoute Component={PaymentReceive} />}
                    />
                  </>
                )}

                {permission.includes("WALLET") &&
                permission.includes("WALLET_SUMMARY_ACTION") ? (
                  <>
                    <Route
                      path="/DebitCreditList"
                      element={
                        <ProtectedRoute
                          Component={DebitCreditList}
                          permission={permission}
                        />
                      }
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute
                          Component={Overview}
                          permission={permission}
                        ></ProtectedRoute>
                      }
                    />
                  </>
                )}
                {permission.includes("TRANSACTION") && (
                  <>
                    <Route
                      path="/transactions"
                      element={<ProtectedRoute Component={Transactions} />}
                    />
                    <Route
                      path="/transactions/:invoice"
                      element={<ProtectedRoute Component={Invoice} />}
                    />
                  </>
                )}
                {permission.includes("OPERATOR_LIST") && (
                  <>
                    <Route
                      path="/operators"
                      element={<ProtectedRoute Component={Operators} />}
                    />
                  </>
                )}
                <Route
                  path="/editoperatorList"
                  element={<ProtectedRoute Component={EditOperator} />}
                />

                <Route
                  path="/serviceList"
                  element={<ProtectedRoute Component={Services} />}
                />

                <Route
                  path="/editserviceList"
                  element={<ProtectedRoute Component={EditService} />}
                />
                {permission.includes("WALLET_SUMMARY") && (
                  <>
                    <Route
                      path="/walletTransactions"
                      element={<ProtectedRoute Component={WalletSummary} />}
                    />
                    <Route
                      path="/walletTransactions/:invoice"
                      element={<ProtectedRoute Component={Invoice} />}
                    />
                  </>
                )}
                <Route
                  path="/profile"
                  element={<ProtectedRoute Component={Profile} />}
                />
                <Route
                  path="/editProfile"
                  element={<ProtectedRoute Component={EditProfile} />}
                />
                {permission.includes("CHANGE_PASSWORD") && (
                  <>
                    <Route
                      path="/changePassword"
                      element={<ProtectedRoute Component={ChangePassword} />}
                    />
                  </>
                )}
                <Route
                  path="/rechargecommission"
                  element={<ProtectedRoute Component={RechrageCommission} />}
                />

                <Route
                  path="/bbpsrechargecommision"
                  element={
                    <ProtectedRoute Component={BBPSRechargeCommission} />
                  }
                />
                <Route
                  path="/verification"
                  element={<ProtectedRoute Component={Verification} />}
                />

                <Route
                  path="/otpverification"
                  element={<ProtectedRoute Component={OtpVerification} />}
                />
                <Route
                  path="/mobilverificationmsg"
                  element={<ProtectedRoute Component={MobileVerificationMsg} />}
                />
                <Route
                  path="/biometricverification"
                  element={<ProtectedRoute Component={BiometricVerification} />}
                />
                {/* <Route
                                path="/fingerprint"
                                element={
                                    <ProtectedRoute Component={FingerPrint} />
                                }
                            /> */}
                <Route
                  path="/fingerprintmsg"
                  element={<ProtectedRoute Component={FingerPrintMsg} />}
                />
                <Route
                  path="/bankdetails"
                  element={<ProtectedRoute Component={BankDetails} />}
                />

                <Route
                  path="/helpandsupport"
                  element={<ProtectedRoute Component={HelpandSupport} />}
                />
                <Route
                  path="/edithelpandsupport"
                  element={<ProtectedRoute Component={EditHelpSupport} />}
                />
                <Route
                  path="/createticket"
                  element={<ProtectedRoute Component={CreateTicket} />}
                />
                <Route
                  path="/ticketList"
                  element={<ProtectedRoute Component={TicketList} />}
                />

                <Route
                  path="/viewticket"
                  element={<ProtectedRoute Component={ViewTicket} />}
                />
                <Route
                  path="/select-package"
                  element={<ProtectedRoute Component={SelectPackage} />}
                />
                <Route
                  path="/permissionManagement"
                  element={<ProtectedRoute Component={PermissionManagement} />}
                />

                <Route
                  path="/vendor-list"
                  element={<ProtectedRoute Component={Vendor} />}
                />
                <Route
                  path="/vendor-transaction"
                  element={<ProtectedRoute Component={VendorTransaction} />}
                />
                <Route
                  path="/addVendor"
                  element={<ProtectedRoute Component={AddVendor} />}
                />
                <Route
                  path="/vendor-fund-request"
                  element={<ProtectedRoute Component={ManageFundRequest} />}
                />
              </Routes>
            </main>
          </SideBar>
        </UserContextData.Provider>
      )}
    </BrowserRouter>
  );
}
export default App;
