import styled from "styled-components";

export const ModalPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgb(225 225 225 / 14%);
  border-radius: 10px;
  backdrop-filter: brightness(0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  

  .modal-popup {
    position: relative;
    width: 480px;
   min-height: 230px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 50px #0029702e;
    border-radius: 10px;
    padding: 20px;
    opacity: 1;
    text-align: center;
  }
  .error-popup {
    position: relative;
    min-width: 330px;
    min-height: 150px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 50px #0029702e;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    opacity: 1;
  }
 

  .modal_backgroud {
    background: url(../../../assets/images/subadmin_bg.png) no-repeat right
      bottom #ffffff;
  }

  @media only screen and (max-width: 767px) {
    .modal-popup {
      margin: 10px;
      position: relative;
      width: auto;
      height: auto;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 50px #0029702e;
      border-radius: 10px;
      opacity: 1;
      padding: 5px;
    }
  }
`;
