import React from "react";
import { FilterWrapper } from "../../../assets/styles/FilterStyle";
import ApiPermissionList from "./ApiPemissionList";
const ApiPermission = ()=>{
   
   
    return(
        <>
        <div className="transaction_main_container" style={{ padding: "30px" }}>
        <div className="transaction_head">
          <div className="transaction_heading" style={{ alignItems: "center" }}>
            <div className="transaction_left">
              <label>Manage Api Permissions</label>
            </div>
          </div>
        </div>
        <FilterWrapper>
          <div className=" flex filterBox">
            <div className="flex align-items">
              <lable
                className="filter-text"
                style={{ marginRight: "19px", fontWeight: "bold" }}
              >
                Select Role
              </lable>
              <div className="input-field">
                <select
                 // onChange={handleRole}
                  className="input_select_field"
                  style={{
                    border: "none",
                    height: "32px",
                    backgroundColor: "#F4F8F8",
                  }}
                >
                  <option selected disabled value="">
                    SELECT ROLE
                  </option>
                  <option value="ROLE_ADMIN">ADMIN</option>
                  <option value="ROLE_SUB_ADMIN">SUB ADMIN</option>
                  <option value="ROLE_RETAILER">RETAILER</option>
                </select>
              </div>
            </div>
          </div>
        </FilterWrapper>

        <div className="container">
        <h1 className="main_heading">API Permission List</h1>
       <ApiPermissionList/>
        </div>


        
        <button className="userlist_btn mt-20">
          Update
        </button>
      </div>
          
        </>
    )
};
export default ApiPermission;