import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/success.json";

const FingerPrintMsg = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  useEffect(() => {
    setTimeout(() => {
      navigate("/usersList");
    }, 10000);
  }, []);
  return (
    <div className="container_biometric">
      <div style={{ marginTop: "13%" }}></div>
      <div className="container_progress1">
        <div className="round round-adduser">
          <div>
            <input type="checkbox" checked id="checkbox" />
            <label htmlFor="checkbox"></label>
          </div>
          <div
            style={{
              backgroundColor: "#CDCDCD",
              height: "2px",
              marginTop: "-20px",
              width: "345px",
              border: "2px",
              marginLeft: "5%",
            }}
          />
          <div
            style={{
              marginTop: "6%",
              fontSize: "12px",
              width: "30%",
              fontFamily: "'Cabin', sans-serif",
            }}
          >
            Retailer Detail
          </div>
        </div>
        <div className="round round-verification">
          <div>
            <input type="checkbox" checked id="checkbox" />
            <label htmlFor="checkbox"></label>
          </div>
          <div
            style={{
              backgroundColor: "#CDCDCD",
              height: "2px",
              marginTop: "-20px",
              width: "345px",
              border: "2px",
              marginLeft: "5%",
            }}
          />
          <div
            style={{
              marginTop: "6%",
              fontSize: "12px",
              width: "30%",
              fontFamily: "'Cabin', sans-serif",
            }}
          >
            ID Verification
          </div>
        </div>{" "}
        <div className="round round-otpverification">
          <div>
            <input type="checkbox" checked id="checkbox" />
            <label htmlFor="checkbox"></label>
          </div>
          <div
            style={{
              backgroundColor: "#CDCDCD",
              height: "3px",
              marginTop: "-20px",
              border: "1px dashed",
              width: "0px",
              marginLeft: "5%",
            }}
          />
          <div
            style={{
              marginTop: "18%",
              fontSize: "12px",
              width: "30%",
              fontFamily: "'Cabin', sans-serif",
            }}
          >
            Otp Verification
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Lottie_Container">
          <Lottie options={defaultOptions} height={190} width={190}></Lottie>
        </div>
      </div>

      <div className="container">
        <div className="msg_success">
          <p style={{ fontSize: "13px", fontFamily: "'Cabin', sans-serif" }}>
            Retailer Onboarding done successfully
          </p>
        </div>
      </div>
    </div>
  );
};
export default FingerPrintMsg;
