import React from 'react'
import { useLocation } from 'react-router-dom';

function EditOperator() {

    const location = useLocation();
    const operatorlistData = location.state
   

    return (
        <div className='edit_operator_list_main_container'>
            <div className='edit_operator_heading'>
                <label>Edit Operator List</label>
            </div>
            <div className='edit_operator_container'>
                <div className='edit_operator'>
                    <div className='edit_operator_left_container'>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <text>Operator Information</text>
                                </div>
                            </div>

                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Operator Name <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                <input type='text' 
                                placeholder='please enter your first name'
                                value={operatorlistData?.operatorName}></input>
                                </div>
                                 
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Operator Code <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                <input type='text' 
                                placeholder='please enter your first name'
                                value={operatorlistData?.operatorCode}>
                                    
                                </input>
                                </div>
                                 
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Operator Id <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                <input type='text' 
                                placeholder='please enter your first name'
                                value={operatorlistData?.operatorId}></input>
                                </div>
                                 
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Operator Type <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                <input type='text' 
                                placeholder='please enter your first name'
                                value={operatorlistData?.operatorType}></input>
                                </div>
                                 
                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                <button>Update Operator</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


        </div>

    )
}

export default EditOperator
