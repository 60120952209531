import React from "react";
import "../AdminProfile/style/admin.scss";
import { useState, useEffect } from "react";
import Input from "../../Components/Input/Input";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Profile = () => {
  const [userType, setUserType] = useState("");

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);


  useEffect(() => {
    if (user?.userType === "ROLE_DISTRIBUTOR") {
      setUserType("Distributor");
    } else if (user?.userType === "ROLE_ADMIN") {
      setUserType("Admin");
    } else if (user?.userType === "ROLE_SUBADMIN") {
      setUserType("Sub Admin");
    }
  }, [user]);

  return (
    <div className="admin_profile_container">
      <div className="admin_profile_header">
        <h2>{userType + " "}Profile</h2>
      </div>
      <div className="shadowBox">
        <div className="">
          <div className="">
            <h3>Personal Details</h3>
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer ">
            <div className="labelname">
              <label>UserName</label>
              <span className="required"> *</span>
            </div>

            <Input
              type="text"
              placeholder="enter you username"
              name="userName"
              value={user?.userName}
            />
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Firstname</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                className="inputtype"
                type="text"
                placeholder="enter you firstName"
                name="firstName"
                value={user?.firstName}
              />
            </div>
          </div>
        </div>
        <div className="role role_responsive">
          
          <div className="retailer">
            <div className="labelname">
              <label>Lastname</label>
              <span className="required"> *</span>
            </div>
            <Input
              className="inputtype"
              type="text"
              placeholder="enter you lastName"
              name="lastName"
              value={user?.lastName}
            />
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>DOB</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you dob"
                name="dob"
                value={"03/03/1989"}
              />
            </div>
          </div>
        </div>
        <div className="role role_responsive">
          
        </div>

        {/* Contact Infomation */}
        </div>
        <div className="shadowBox">

        <div className="">
          <div className="">
            <h3>Contact Details</h3>
          </div>
        </div>

        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Contact Number</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you mobile number"
                name="phoneNumber"
                value={user?.userName}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Email Address</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="enter you email"
              name="email"
              value={user?.email}
            />
          </div>
        </div>

        {/* Address Details */}
        </div>
        <div className="shadowBox">

        <div className="">
          <div className="">
            <h3>Address Details</h3>
          </div>
        </div>

        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Address1</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you address"
                name="address1"
                value={user?.address1}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Address2</label>
            </div>
            <Input
              type="text"
              placeholder="enter you address2"
              name="address2"
              value={user?.address2}
            />
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <div className="labelname">
              <label>Landmark</label>
            </div>
            <div>
              <Input
                type="text"
                placeholder="enter you landmark"
                name="landmark"
                value={user?.landmark}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Picode</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="enter you pincode"
              name="pincode"
              value={user?.pincode}
            />
          </div>
        </div>
        <div className="role role_responsive">
          <div className="retailer">
            <button
              className="submit_btn submit_btn_responsive"
              onClick={() => {
                navigate("/");
              }}
            >
              Close
            </button>
          </div>
          <div className="retailer">
            <button
              className="submit_btn submit_btn_responsive"
              onClick={() => navigate("/editprofile", { state: user })}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
