import React, { useEffect, useState } from "react";

import "../../Users/style.scss";
import "./style.css";
import "../RoleManagmentAndPermission/style.scss";
import { PackageList, getPackageList } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../utils/common";

const PackageManagementList = () => {
  const [isActive, setIsActive] = useState(false);
  const [drawerShow, setDrawerShow] = useState("");
  const [packageData, setPackageData] = useState([]);
  const navigate = useNavigate();

  const handlePackage = (key) => {
    if (drawerShow === key) {
      setDrawerShow("");
    } else {
      setDrawerShow(key);
    }

    setIsActive((current) => !current);
  };

  const packageDetail = () => {
    getPackageList().then((resp) => {
      if (resp?.data?.success) setPackageData(resp?.data?.data);
    });
  };
  useEffect(() => {
    packageDetail();
  }, []);
  const handelCreate = () => {
    navigate("/CreatePackage");
  };



  return (
    <>
      <div className="transaction_main_container" style={{ padding: "30px" }}>
        <div className="transaction_head">
          <div className="transaction_heading" style={{ alignItems: "center" }}>
            <div className="transaction_left">
              <label>All Packages List</label>
            </div>
            <div>
              <button onClick={handelCreate} className="userlist_btn">
                Create Package
              </button>
            </div>
          </div>
        </div>
        {packageData &&
          packageData.map((items) => {
            return (
              <div className="container mt-20">
                <div className="packageconstiner  flex justify-between">
                  <div className="flex gap60 align-items package_container">
                    <h4>{items?.packageDetails?.packageName}</h4>
                    <p>{items?.packageDetails?.packageDescription}</p>
                  </div>
                  <div className="flex  gap60 align-items package_container">
                    <div>
                      <h6>Expire Date:</h6>
                      <p>{items?.packageDetails?.packageExpiry} months</p>
                    </div>
                    <div className="flex">
                      <div>
                        <h6>Ammount</h6>
                        <p>₹{items?.packageDetails?.packagePrice}/mo</p>
                      </div>

                      <div
                        className={isActive ? "transform" : ""}
                        onClick={() =>
                          handlePackage(items?.packageDetails?.packageName)
                        }
                      >
                        <i class="fa-solid fa-angle-down pointer"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {drawerShow === items?.packageDetails?.packageName ? (
                  <>
                    {!isEmpty(items?.serviceConfiguration) && (
                      <>
                        <div className="flex check_Box_Conatiner"></div>
                        <div className="transaction_table">
                          <div>
                            <div class="table">
                              <table id="package">
                                <th>Service Name</th>
                                <th>Service Model</th>
                                <th>Range</th>
                                <th>Commission Type</th>
                                <th>Route</th>
                                <th>Net Commission</th>
                                <th>Hierarchy Comission</th>

                                {items.serviceConfiguration &&
                                  items.serviceConfiguration.map(
                                    (details, index) => {
                                      return (
                                        <tbody className="tbody">
                                          <tr className="border">
                                            <td
                                              rowspan={20}
                                              style={{ paddingLeft: "20px" }}
                                            >
                                              {details?.serviceName}
                                            </td>
                                            <td rowspan={20}>
                                              {" "}
                                              {details?.serviceModel}
                                            </td>
                                          </tr>
                                          {details.priceConfiguration &&
                                            details.priceConfiguration.map(
                                              (priceDetails) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {priceDetails?.minAmount}{" "}
                                                      -{" "}
                                                      {priceDetails?.maxAmount}
                                                    </td>
                                                    <td>
                                                      {priceDetails?.feeType}
                                                    </td>
                                                    <td>
                                                      {priceDetails?.route}
                                                    </td>
                                                    <td>{priceDetails?.fee}</td>
                                                    <td>
                                                      <div>
                                                        <th>Admin</th>
                                                        <th>Retailer</th>
                                                        <th>Distributer</th>
                                                      </div>

                                                      {priceDetails.hierarchyDistributions &&
                                                        priceDetails.hierarchyDistributions.map(
                                                          (hirarchy) => {
                                                            return (
                                                              <td className="border_none mt-5">
                                                                Com:{" "}
                                                                {
                                                                  hirarchy?.splitFee
                                                                }
                                                              </td>
                                                            );
                                                          }
                                                        )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      );
                                    }
                                  )}
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
export default PackageManagementList;
