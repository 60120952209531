import React, { useEffect, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";

import { useRef } from "react";
import "./loaderstyle.css";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { getTransactionList, searchUser } from "../../utils/api";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
import {
  getDateFromDateObj,
  isEmpty,
} from "../../utils/common";
import { TableWrapper } from "../../Components/styledConstant";
import { useLocation, useNavigate } from "react-router-dom";
import raiseTicket from "../../assets/images/raise_ticket.svg";
import AsideLeft from "../../Components/AsideLeft/AsideLeft";
import { useSelector } from "react-redux";
import { AlertWrapper } from "../../Components/Modal/style";
require("react-datepicker/dist/react-datepicker.css");



const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [service, setService] = useState("");
  const [msgError, setMsgError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [selectOperator, setSelectOperator] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [selectType, setSelectType] = useState("Select");
  const [selectMode, setSelectMode] = useState("Select");
  const [transactionId, settransactionId] = useState("");
  const [userName, setUserName] = useState("");
  const [userUuid, setUserUuid] = useState("");
  const [refTransactionId, setREfTransactionId] = useState("");
  const link = useRef();
  const [downloadLoader, setDownloadLoader] = useState("");
  const [showfilter, setShowFilter] = useState("");
  const [checkStatusMsg, setCheckStatusMsg] = useState({});

  const navigate = useNavigate();

  const [payBox, setPayBox] = useState(false);


  const{ user } = useSelector((state) =>state.user);

  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const numberReg = /^[0-9]+$/;
  const findUserName = (e) => {
    const value = e.target.value;
    setUserUuid("")
    if (!numberReg.test(value) && value) {
      return;
    } else {
      setUserName(value);
      if (value.length === 10) {
        searchUser(value).then((resp) => {
          if (resp?.data?.success) {
            setUserUuid(resp?.data?.data?.uuid);
          } else {
            setUserUuid("sxnsb");
          }
        });
      }
    }
  };

  const handleCreateTicket = (userId) => {
    navigate("/createticket",{ state:userId});
  };
  const transactionData = (key = "") => {
    let _fromdate = getDateFromDateObj(fromDate, "YYYY-MM-DD");
    let _todate = getDateFromDateObj(toDate, "YYYY-MM-DD");

    if (
      getDateFromDateObj(fromDate, "YYYY-MM-DD") === "Invalid date" ||
      getDateFromDateObj(toDate, "YYYY-MM-DD") === "Invalid date"
    ) {
      _fromdate = "";
      _todate = "";
    }

    setLoading(true);
    let param = {
      fromDate: _fromdate,
      toDate: _todate,
      pagination: {
        pageNo: currPage,
        pageSize: pageSize,
      },
      userUuid: userUuid,
      serviceType: selectOperator,
      transactionId: transactionId,
      status: selectStatus,
      refTransactionId: refTransactionId,
    };

    if (key === "CSV" || key === "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      param.pagination.pageSize = totalElements;
      param.pagination.pageNo = 0;
      setCurrPage(0);
      // param.serviceType = ""
    }
    if (key === "search" || key === "refreshData") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    if (key === "refresh") {
      dataRefresh();
      param.fromDate = "";
      param.serviceType = "";
      param.transactionId = "";
      param.toDate = "";
      param.userName = "";
      param.refTransactionId = "";
      param.userUuid = "";
      param.pagination.pageNo = 0;
      setCurrPage(0);
    }
    getTransactionList(param).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {
        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);
          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          transactionPdfData(resp?.data?.data?.content);
        } else {
          setTransactions(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
          // setCurrPage(resp?.data?.data?.number)
        }
      } else {
        setTransactions([]);

        setTotalPages(null);

        if (resp?.data?.errorCodeList.length < 1) {
          setMsgError(resp?.data?.msg);
        } else {
          setMsgError(resp?.data?.errorCodeList[0]);
        }
      }
    });
  };

  const location = useLocation();
  const serviceType = location.state;

  useEffect(() => {
    if (!isEmpty(selectOperator)) {
      transactionData();
    } else if (!isEmpty(serviceType)) {
      setSelectOperator(serviceType?.type);
    } else {
      setSelectOperator("UPI_PAY");
    }
  }, [serviceType, selectOperator, currPage, selectStatus]);

  const handleOpertaorChange = (e) => {
    setStartDate("");
    setEndDate("");
    setSelectOperator(e.target.value);
    setCurrPage(0);
  };
  const dataRefresh = () => {
    setSelectOperator("");
    settransactionId("");
    setREfTransactionId("");
    setSelectStatus("");
    setSelectType("Select");
    setSelectMode("");
    setService("");
    setStartDate("");
    setEndDate("");
    setUserUuid("");
    setUserName("");
  };

  const transactionPdfData = (data = []) => {
    let _width = 400; // Width of the custom size
    const height = 297;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", // units: mm, cm, in, px
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });

    const imgWidth = 25;
    const imgHeight = 9; // Adjust the height of the logo
    const leftXPos = 14; // Adjust the X position
    const yPos = 2; // Adjust the Y position
    //   doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "Transaction List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];

    data.forEach((item, index) => {
      const dateTimeString = item.transactionDate;
      let [date, time] = dateTimeString.split("T");
      let newTime = time.slice(0, 8);
      const rowData = [
        index + 1,
        item.userFullName,
        item.userPhoneNumber,
        item.serviceType === "PREPAID_RECHARGE"
          ? item.rechargedNumber
          : item.serviceType === "AEPS"
          ? item.serviceType
          : item.serviceType === "SEND_MONEY"
          ? item.accountHolderName
          : item.serviceType === "BBPS"
          ? item.accountNumber
          : item.rechargedNumber,
        item.serviceType === "PREPAID_RECHARGE"
          ? item.operatorCode
          : item.serviceType === "DTH"
          ? item.operatorCode
          : item.serviceType === "AEPS"
          ? item.orderId
          : item.refTransactionId,
        item.serviceType === "PREPAID_RECHARGE"
          ? item.transactionId
          : item.serviceType === "DTH"
          ? item.transactionId
          : item.serviceType === "AEPS"
          ? item.transactionId
          : item === "SEND_MONEY" || item === "BBPS"
          ? item.transactionId
          : item.accountIfscCode,
        date + " " + newTime,
        item?.amount,
        item?.status,
        item?.serviceType,
        item?.route,
      ]; // Customize as needed
      tableData.push(rowData);
    });
    doc.autoTable({
      head: [
        [
          "S.No",
          "Retailer Name",
          "Retailer No.",
          selectOperator === "PREPAID_RECHARGE"
            ? "Recharge Number"
            : selectOperator === "AEPS"
            ? "Service Type"
            : "Account Number",

          selectOperator === "PREPAID_RECHARGE"
            ? "Operator Code"
            : selectOperator === "DTH"
            ? "Operator Code"
            :  "Ref. TransactionId",
          selectOperator === "PREPAID_RECHARGE"
            ? "Transaction Id"
            : selectOperator === "DTH"
            ? "Transaction Id"
            : selectOperator === "AEPS"
            ? "Merchant Transaction Id"
            : selectOperator === "SEND_MONEY" || selectOperator === "BBPS"
            ? "Transaction Id"
            : "Account IFSC Code",
          "Created Date",
          "Amount",
          "Status",
          "Service Type",
          "Route",
        ],
      ], // Customize table header
      body: tableData,
    });

    doc.save("TransactionList.pdf");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      transactionData();
    }
  };

  const changeStatus = (e) => {
    setSelectStatus(e.target.value);
    setCurrPage(0);
 
  };

  const navigationDaital = (item) => {
    navigate(`/transactions/${item?.transactionId}`, { state: { detail: item,type:"Transaction"} });
  };



  const checkStatus = (transactionId) => {
    const param = {
      transactionId:transactionId
    }
   
  };

useEffect(() =>{
  setTimeout(() =>{
    setCheckStatusMsg({})
  },5000);
},[]);
  return (
    <div className="transactions_container">
    <div style={{ padding: "30px" }}>
      <div
        className="transaction_main_container"
        style={{ marginTop: "-10px" }}
      >
        <div className="userlist_head">
          <div className="userlist_heading">
            <div className="userlist_left">
              <label>Transactions</label>
            </div>
            <div className="userlist_right">
           {user?.userName === "8800578866" && <button
            style={{marginRight:"8px"}}
                  className="pdf"
                  onClick={(e) => setPayBox(true)}
                >
                  Payout
                </button> }
              {downloadLoader !== "PDF" ? (
                <button className="pdf" onClick={() => transactionData("PDF")}>
                  PDF
                </button>
              ) : (
                <button className="pdf">
                  <div className="btn-loader">
                    <i class="fa-solid fa-spinner"></i>
                  </div>
                </button>
              )}

              {downloadLoader !== "CSV" ? (
                <button className="xls" onClick={() => transactionData("CSV")}>
                  CSV
                </button>
              ) : (
                <button className="xls">
                  <div className="btn-loader">
                    <i class="fa-solid fa-spinner"></i>
                  </div>
                </button>
              )}
              <CSVLink
                ref={link}
                data={pdfData}
                filename="TransactionList.csv"
              ></CSVLink>
            </div>
            <div className="userlist_filter">
              <button
                className="filter fs-16"
                onClick={(e) =>
                  showfilter === "active"
                    ? setShowFilter("")
                    : setShowFilter("active")
                }
              >
                <i class="fa-solid fa-filter"></i>
              </button>
            </div>
          </div>
        </div>

        <FilterWrapper
          style={{ height: "auto" }}
          className={`display_data ${showfilter}`}
        >
          <div className="filterBox">
            <div>
              <div className="flex align-items gap-15">
                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={selectOperator}
                    onChange={handleOpertaorChange}
                  >
                    <option value="PREPAID_RECHARGE">Prepaid Recharge</option>
                    {/* <option value="DTH">DTH</option> */}
                    <option value="AEPS">AEPS</option>
                    <option value="SEND_MONEY">Send-Money</option>
                    <option value="SETTLEMENT">Settlement</option>
                    {/* <option value="DTH">DTH</option> */}
                    <option value="BBPS">BBPS</option>
                    <option value="PAY_OUT">Payout</option>
                    <option value="PAYMENT_GATEWAY">Add Money</option>
                    <option value="UPI_PAY">UPI Pay</option>
                  </select>
                </div>
               
                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={selectStatus}
                    onChange={(e) => changeStatus(e)}
                  >
                    <option value={""}>Select Status</option>
                    <option value="SUCCESS">Success</option>
                    <option value="FAIL">Fail</option>
                    <option value="INITIATED">Pending</option>
                  </select>
                </div>

                <div className="">
                  <input
                    type="text"
                    name="userName"
                    placeholder="Mobile Number"
                    value={userName}
                    maxLength={10}
                    onChange={findUserName}
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>

                <div className="input-field">
                  <div className="flex">
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="From Date"
                      selected={fromDate}
                      selectsStart
                      valueDefault={null}
                      startDate={fromDate}
                      endDate={toDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(date) => setStartDate(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="input-field">
                  <div className="flex">
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="To Date"
                      selected={toDate}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(date) => setEndDate(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>

                <div className="">
                  <input
                    type="text"
                    name="refTransactionId"
                    placeholder="Ref Transaction Id"
                    value={refTransactionId}
                    onChange={(e) => setREfTransactionId(e.target.value)}
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>
                <div className="">
                  <input
                    type="text"
                    name="transactionId"
                    placeholder="Transaction Id"
                    value={transactionId}
                    onChange={(e) => settransactionId(e.target.value)}
                    onKeyDown={handleKeyDown}
                  ></input>
                </div>
                <div className=" button">
                  <button
                    className="userlist_btn"
                    onClick={() => transactionData("search")}
                    style={{ width: "100px" }}
                  >
                    Search
                  </button>
                </div>
                <div className="button">
                  <button
                    className="refresh-btn"
                    style={{ width: "60px" }}
                    onClick={() => transactionData("refresh")}
                  >
                    <i className="fa fa-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FilterWrapper>
      </div>

      {transactions && (
        <div className="table-loader">
           {loading && <LocalLoader className="tableLoader" />}
          <TableWrapper className="table-card-wrapper">
           
            <table className="table">
              <thead>
                <tr>
                  <th>Reatiler Name</th>
                  <th>Reatiler No.</th>
                  <th>
                    {selectOperator === "PREPAID_RECHARGE"
                      ? "Recharge Number"
                      : selectOperator === "AEPS" || selectOperator === "BBPS"
                      ? "Account Number"
                      : "Account Holder Name"}
                  </th>
                  {(selectOperator === "SEND_MONEY" || selectOperator === "PAY_OUT") && <th>Account Number </th>}
                  {(selectOperator === "SEND_MONEY" || selectOperator === "PAY_OUT") && <th>Account IFSC Code </th>}

                  {selectOperator !== "AEPS" || selectOperator === "BBPS" && (
                    <th>
                      {selectOperator === "PREPAID_RECHARGE"
                        ? "Operator Code"
                        : selectOperator === "DTH"
                        ? "Operator Code"
                        : selectOperator === "AEPS"
                        ? "Order Id"
                        : "Account IFSC Code"}
                    </th>
                  )}
                  <th>Ref. TransactionId</th>
                  <th>
                    {
                      selectOperator === "AEPS"
                        ? "Merchant Transaction Id"
                        : "Transaction Id"
                      // selectOperator === "DTH"
                      // ? "Transaction Id"
                      // : selectOperator === "AEPS"
                      // ? "Merchant Transaction Id"
                      // : selectOperator === "SEND_MONEY"
                      // ? "Transaction Id"
                      // : selectOperator === "PAY_OUT"
                      // ? "Transaction Id"
                      // : "Account IFSC Code"
                    }
                  </th>

                  <th>Date and Time</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Service Type</th>
                  <th>Route</th>
                  <th>View Invoice</th>
                  <th>Raise Ticket</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions?.map((item) => {
                    const dateTimeString = item.transactionDate;
                    let [date, time] = dateTimeString.split("T");
                    let newTime = time.slice(0, 8);
                    return (
                      <tr key={Math.random() * 1000000}>
                        <td>
                          <span className="card-th">Retailer Name</span>
                          <span>{item?.userFullName}</span>
                        </td>
                        <td>
                          <span className="card-th">Retailer No.</span>
                          <span>{item?.userPhoneNumber}</span>
                        </td>
                        <td>
                          <span className="card-th">
                            {selectOperator === "PREPAID_RECHARGE"
                              ? "Recharge Number"
                              : selectOperator === "AEPS" || selectOperator === "BBPS"
                              ? "Account Number"
                              : "Account Holder Name"}
                          </span>
                          <span>
                            {selectOperator === "PREPAID_RECHARGE"
                              ? item.rechargedNumber
                              : selectOperator === "DTH"
                              ? item.rechargedNumber
                              : selectOperator === "AEPS" || selectOperator ==="BBPS"
                              ? item.accountNumber
                              : item.accountHolderName}
                          </span>
                        </td>
                        {(selectOperator === "SEND_MONEY" ||  selectOperator === "PAY_OUT") && (
                          <td>
                            <span className="card-th">Account Number</span>
                            <span>{item.accountNumber}</span>
                          </td>
                        )}
                        {(selectOperator === "SEND_MONEY" || selectOperator === "PAY_OUT") && (
                          <td>
                            <span className="card-th">Account IFSC Code</span>
                            <span>{item.accountIfscCode}</span>
                          </td>
                        )}
                        {selectOperator !== "AEPS" || selectOperator === "BBPS" &&  (
                          <td>
                            <span className="card-th">
                              {selectOperator === "PREPAID_RECHARGE"
                                ? "Operator Code"
                                : selectOperator === "DTH"
                                ? "Operator Code"
                                : selectOperator === "AEPS"
                                ? "Order Id"
                                : "Account IFSC Code"}
                            </span>
                            <span>
                              {selectOperator === "PREPAID_RECHARGE"
                                ? item.operatorCode
                                : selectOperator === "DTH"
                                ? item.operatorCode
                                : item.accountIfscCode}
                            </span>
                          </td>
                        )}
                        <td>
                          <span className="card-th">Ref. TransactionId</span>
                          <span>{item?.refTransactionId || "-"}</span>
                        </td>

                        <td>
                          <span className="card-th">
                            {selectOperator === "PREPAID_RECHARGE"
                              ? "Transaction Id"
                              : selectOperator === "DTH"
                              ? "Transaction Id"
                              : selectOperator === "AEPS"
                              ? "Merchant Transaction Id"
                              : selectOperator === "SEND_MONEY"
                              ? "Transaction Id"
                              : "Account IFSC Code"}
                          </span>
                          <span>{item?.transactionId || "-"}</span>
                        </td>
                        <td>
                          <span className="card-th">Created Date</span>
                          <span>{date + " " + newTime}</span>
                        </td>
                        <td>
                          <span className="card-th">Amount</span>
                          <span>₹ {item.amount}</span>{" "}
                        </td>
                        <td>
                          <span className="card-th">Status</span>
                          <span>
                            <div
                            className={` user_action_btn ${item.status == "SUCCESS"
                            ? "active_status"
                            : "inactive_status"
                            }`}
                              
                            >
                              {item.status ==="INITIATED" ? "INITIATED":item?.status}
                             {(item.status ==="PENDING") && (selectOperator === "UPI_PAY") && <span><button 
                             style={{marginLeft:"4px"}}
                             onClick={() =>{checkStatus(item?.transactionId)}}>
                             <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                            </span>}
                            </div>
                          </span>
                        </td>

                        <td>
                          <span className="card-th">Service Type</span>
                          <span>{item?.serviceType}</span>
                        </td>
                        <td>
                          <span className="card-th">Route</span>
                          <span>{item?.route}</span>
                        </td>
                        <td className="user_action_btn button">
                          <span className="card-th">View Invoice</span>
                          <span><button onClick={() =>navigationDaital(item)}>
                            <i class="fa-regular fa-eye fs-16 update" aria-hidden="true"></i>
                            </button>
                            </span>
                        </td>
                        <td>
                          <span className="card-th">Raise ticket</span>
                          <span><img src={raiseTicket}
                         
                          onClick={() => handleCreateTicket(item)}
                          ></img></span>
                        </td>
                      </tr>
                    );
                  })}

                {!loading && transactions && transactions.length < 1 && (
                  <>
                    <tr>
                      <td
                        colSpan={12}
                        style={{
                          color: "red",
                          textAlign: "center",
                          height: "140px",
                        }}
                      >
                        No Data Found
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </TableWrapper>
        </div>
      )}

{!isEmpty(checkStatusMsg) && (
       <AlertWrapper className={`${checkStatusMsg.type} globle`}>
       <span className="close" onClick={() => setCheckStatusMsg({})}>
         ×
       </span>
       {checkStatusMsg.msg}
     </AlertWrapper>
      )}

     
{ payBox && <AsideLeft  close = {setPayBox} refresh={transactionData}/>}
      {totalPages > 1 && (
        <Pagination
          items={transactions}
          itemsPerPage={pageSize}
          setitemsPerPage={setPageSize}
          currentPage={currPage}
          setcurrentPage={setCurrPage}
          totalPages={totalPages}
          forcePage={currPage}
        />
      )}
    </div>
    </div>
  );
};

export default TransactionTable;
