
import React from "react";

import EditUserForm from "./EditUserForm";


const EditUser = () => {
  
    return (
        <EditUserForm/>
    );
};

export default EditUser;