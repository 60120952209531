import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination.jsx";
import "../Users/style.scss";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import "sweetalert2/dist/sweetalert2.min.css";
import { getApiUserList } from "../../utils/api.js";
import {

  isEmpty,

} from "../../utils/common.js";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp.jsx";
import LocalLoader from "../../Components/Loader/LocalLoader.js";
import { FilterWrapper } from "../../assets/styles/FilterStyle.js";
import userMainBalance from "../../assets/images/userMainBalance.svg";
import userAepsBalance from "../../assets/images/userAepsBalance.svg";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData1 from "../../assets/lotties/question.json";
import Lottie from "react-lottie";
import { TableWrapper } from "../../Components/styledConstant.js";
import { useSelector } from "react-redux";
require("react-datepicker/dist/react-datepicker.css");

const Vendor = () => {
  const [apiData, setApiData] = useState([]);

  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pdfData, setPdfData] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState("");
  const [loading, setLoading] = useState(false);
  const [userBalanceData, setUserBalanceData] = useState();

  const [deleteUsers, setDeleteUsers] = useState(null);
  const [loginUserType, setLoginUserTye] = useState("");
  const [showfilter, setShowFilter] = useState("");

  const link = useRef();

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
  };
 
  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const navigate = useNavigate();



  const handleApiData = async (key) => {
    if (key === "CSV") {
      
        setDownloadLoader("CSV");
      } else if(key === "PDF") {
        setDownloadLoader("PDF");
      }else{
        setDownloadLoader("");
        setLoading(true);
      }
    getApiUserList(pageSize, currPage).then((resp) => {
        setLoading(false);     
      if (resp?.data.success) {
   
        if (key === "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          userlistPdfData(resp?.data?.data?.content);
        } else {
          setApiData(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
          
        }
      }
    });
  };
  useEffect(() => {
    handleApiData("");
  }, [currPage]);

  //API to Export PDF file

  const userlistPdfData = (data = []) => {
    let _width = 350; // Width of the custom size
    const height = 297;
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", // units: mm, cm, in, px
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });
    //Add image in PDF header
    const imgWidth = 25;
    const imgHeight = 9;
    const leftXPos = 14;
    const yPos = 2;
    doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "User List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);
    const generalFontSize = 12;
    doc.setFontSize(generalFontSize);
    // doc.setMargins(0,0,0,0);
    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];
    data.forEach((item, index) => {
      

      const rowData = [
        index + 1,
     
        item.orgName,
        item.orgContactNumber,
        item.orgEmail,
        item.orgType,
        item.orgAddress,
        item.orgPinCode,
        item.panNumber,
        item.gstNumber,
      ]; // Customize as needed
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [
        [
            "Sr No.",
         
            "Organization Name",
            "Organization Contact Number",
            "Organization Email",
            "Organization Type",
            "Organization Address",
            "Organization PinCode",
            "PAN Number",
            "GST Number"
        ],
      ], 
      body: tableData,
    });

    doc.save("apiUserlist.pdf");
  };


  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (loginUserType === "" && !isEmpty(user?.userType)) {
      setLoginUserTye(user?.userType);
    }
  }, [user]);
  return (
    <div className="userlisthead_container">
      <div style={{ padding: "30px" }}>
        <div className="transaction_main_container">
          <div className="userlist_head">
            <div className="userlist_heading">
              <div className="userlist_left">
                <label>Vendors List</label>
              </div>
              <div className="userlist_right">
                {downloadLoader !== "PDF" ? (
                  <button
                    className="pdf"
                    onClick={(e) => handleApiData("PDF")}
                  >
                    PDF
                  </button>
                ) : (
                  <button className="pdf">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                {downloadLoader !== "CSV" ? (
                  <button
                    className="xls"
                    onClick={(e) => handleApiData("CSV")}
                  >
                    CSV
                  </button>
                ) : (
                  <button className="xls">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="ApiUserlist.csv"
                ></CSVLink>
              </div>
              <div className="userlist_filter">
                <button
                  className="filter fs-16"
                  onClick={(e) =>
                    showfilter === "active"
                      ? setShowFilter("")
                      : setShowFilter("active")
                  }
                >
                  <i class="fa-solid fa-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <FilterWrapper
            style={{ height: "auto" }}
            className={`display_data ${showfilter}`}
          >
            <div className="filterBox">
              <div>
                <div className="flex align-items gap-20">
                  <div className="button">
                    <button
                      className="userlist_btn"
                      onClick={() => navigate("/addVendor")}
                    >
                      Add New Vendor
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FilterWrapper>
        </div>

        {apiData && (
          <div className="table-loader">
           {loading && <LocalLoader className="tableLoader" />}
            <TableWrapper className="table-card-wrapper">
             
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    {/* <th>userId</th> */}
                    <th>Organization Name</th>
                    <th>Organization Contact Number</th>
                    <th>Organization Email</th>
                    <th>Organization Type</th>
                    <th>Organization Address</th>
                    <th>Organization PinCode</th>
                    <th>PAN Number</th>
                    <th>GST Number</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData &&
                    apiData?.map((item, index) => {
                      return (
                        <tr key={item.transactionId}>
                          <td className="td-none">
                            <span className="card-th">Sr No</span>
                            <span>{currPage * pageSize + index + 1}</span>
                          </td>

                          {/* <td>
                            <span className="card-th">userId</span>
                            <span>
                              {item?.userId || "-"}
                            </span>
                          </td> */}
                          <td>
                            <span className="card-th">Organization Name</span>
                            <span>
                              {item?.orgName || "-"}
                            </span>
                          </td>

                          <td>
                            <span className="card-th">Organization Contact Number</span>
                            <span>{item?.orgContactNumber || "-"}</span>
                          </td>
                         
                          <td>
                            <span className="card-th">Organization Email</span>
                            <span>{item?.orgEmail || "-"}</span>
                          </td>

                          <td>
                            <span className="card-th">Organization Type</span>
                            <span>{item?.orgType || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Organization Address</span>
                            <span>{item?.orgAddress || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">Organization PIN Code</span>
                            <span>{item?.orgPinCode || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">PAN Number</span>
                            <span>{item?.panNumber || "-"}</span>
                          </td>
                          <td>
                            <span className="card-th">GST Number</span>
                            <span>{item?.gstNumber || "-"}</span>
                          </td>
                         
                      
                          {/* {loginUserType === "ROLE_ADMIN" && (
                            <>
                              {" "}
                              <td className="user_action_btn">
                                <span className="card-th">View Balance</span>
                                <span>
                                  {" "}
                                  <button
                                    onClick={() => viewBalance(item.userName)}
                                  >
                                    <i class="fa-regular fa-eye fs-16 update"></i>
                                  </button>
                                </span>
                              </td>
                              <td className="user_action_btn">
                                <span className="card-th">Actions</span>
                                <span className="center">
                                  <div
                                    className={`custom-toggle toggle_button ${
                                      item.status === "ACTIVE" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      updateUserStatus(item.uuid, item.status)
                                    }
                                    styled={{
                                      margin: "0",
                                      marginRight: "12px",
                                    }}
                                  >
                                    <div className="slider"></div>
                                  </div>

                                  <button
                                    className=""
                                    style={{ marginRight: "12px" }}
                                    onClick={() => editUser(item)}
                                  >
                                    <i class="fa-solid fa-pen-to-square update"></i>
                                  </button>
                                   <button
                                    onClick={() => deleteUser(item.userName)}
                                    style={{ marginRight: "8px" }}
                                  >
                                    <i class="fa-solid fa-trash delete"></i>
                                  </button> 
                                </span>
                              </td>
                            </>
                          )} */}
                        </tr>
                      );
                    })}

                  {!loading && apiData && apiData.length < 1 && (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </div>
        )}

        {totalPages > 1 && (
          <Pagination
            items={apiData}
            itemsPerPage={pageSize}
            setitemsPerPage={setPageSize}
            currentPage={currPage}
            setcurrentPage={setCurrPage}
            totalPages={totalPages}
            forcePage={currPage}
          />
        )}
      </div>
      {userBalanceData && (
        <ModalPopUp>
          <div
            className="modal-popup"
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
            }}
          >
            <div
              className="flex justify-center mr-20"
              style={{ color: "#8B9CA8" }}
              onClick={() => setUserBalanceData("")}
            >
              <i
                class="fa-solid fa-xmark"
                style={{
                  display: "inline-block",
                  maxWidth: "40px",
                  marginLeft: " auto",
                  fontSize: "25px",
                }}
              ></i>
            </div>
            {!isEmpty(
              userBalanceData?.success?.mainBalance ||
                !isEmpty(userBalanceData?.success?.aepsBalance)
            ) ? (
              <>
                <div
                  className="flex justify-center gap-10 fs25"
                  style={{ color: "#002970" }}
                >
                  <h4>{userBalanceData?.success?.firstName}</h4>
                  <h4>{userBalanceData?.success?.lastName}</h4>
                </div>
                <div className="flex justify-center gap40 mt-16">
                  <div className="flex gap-15" style={{ paddingRight: "40px" }}>
                    <div>
                      <img src={userMainBalance}></img>
                    </div>
                    <div className="flex column">
                      <label className="fw-600 fs-20">Main Balance</label>
                      <label className="fs-16">
                        ₹{userBalanceData?.success?.mainBalance}
                      </label>
                    </div>
                  </div>
                  {!isEmpty(userBalanceData?.success?.aepsBalance) ? (
                    <div
                      className="flex gap-15"
                      style={{
                        borderLeft: "1px dotted #8B9CA8",
                        paddingLeft: "40px",
                      }}
                    >
                      <div className="mt-10">
                        <img src={userAepsBalance}></img>
                      </div>
                      <div className="flex column">
                        <label className="fw-600 fs-20">AEPS Balance</label>
                        <label className="fs-16">
                          ₹{userBalanceData?.success?.aepsBalance}
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div
                className="flex justify-center"
                style={{ marginTop: "45px" }}
              >
                <h4>User doesn't have Wallet</h4>
               
              </div>
            )}
          </div>
        </ModalPopUp>
      )}

      {!isEmpty(deleteUsers) && (
        <ModalPopUp>
          <div
            className="modal-popup"
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
              height: "270px",
            }}
          >
            <div className="mt-20">
              <Lottie options={defaultOptions1} height={80} width={80}></Lottie>
            </div>

            <div className="flex justify-center ">
              <label className="fs25 blue_font fw-bold">Are you sure!</label>
            </div>
            <div className="flex justify-center">
              <label>You want to delete this user?</label>
            </div>
            <div className="flex justify-center gap-10 mt-30">
              <button
                onClick={() => setDeleteUsers(null)}
                style={{
                  height: "45px",
                  width: "150px",
                  backgroundColor: "#EDFAFE",
                  color: "#00286F",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                No, Cancel it
              </button>
            
            </div>
          </div>
        </ModalPopUp>
      )}


     
    </div>
  );
};

export default Vendor;
