import React, { useEffect, useState } from "react";

import "./style.scss";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { getPackageList, userPackageMapping } from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/common";
import Pagination from "../../Components/Pagination/Pagination";
import { AlertWrapper } from "../../Components/Modal/style";


function SelectPackage() {
  const [pkgCod, setPkgCod] = useState("DEFAULT");
  const [pagination, setPagination] = useState({totalPage:1, pageSize:5});
  const [currentPage, setCurrentPage] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [drawerShow, setDrawerShow] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [message, setMessage] = useState({});
  const [disableBtn,setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const responsedata = location.state;
  const SubmitHandaler = () => {
    setLoading(true);
    userPackageMapping(responsedata?.uuid, pkgCod).then((resp) => {
      
      setDisableBtn(true)
      if (resp?.data?.success) {
        setMessage({msg:"User package selection captured successfully",type:"success"});
       
        setTimeout(() =>{
           navigate("/bankdetails", { state: responsedata });
           setDisableBtn(false);
           setLoading(false);
        },2000)
        
      } else {
        setLoading(false);
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        setMessage({msg:msg,type:"error"})
        setDisableBtn(false);
       
      }
    });
  };


   
  const handlePackage = (key) => {
    if(drawerShow===key){
      setDrawerShow("");
    }else{
      setDrawerShow(key);
    }
   
    setIsActive((current) => !current);
  };

  const packageDetail = () => {
    setLoading(true);
    getPackageList().then((resp) => {
    
      setLoading(false);
      
      if (resp?.data?.success) 

      setPackageData(resp?.data?.data);
      let lentgth = resp?.data?.data?.length;
     
      let totalPage = Math.ceil(lentgth/pagination.pageSize);
      setPagination({...pagination,totalPage:totalPage});
   
      
    });
  };
  useEffect(() => {
    packageDetail();
  }, []);


  useEffect(() =>{
    if(!isEmpty(message)){
      setTimeout(() =>{
        setMessage({});
      },4000)
    }
  });


  return (
    <div className="main_container">

{!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      <div className="data_details">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label
                htmlFor="checkbox"
                style={{ backgroundColor: "#0000ff" }}
              ></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable ">Select Package</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable ">Bank Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">ID Verification</div>
          </div>
        </div>
        <div className="packege-wrapper">
          <h2>Select Package</h2>
         
          {packageData &&
            packageData.slice((currentPage*pagination.pageSize), (currentPage*pagination.pageSize+pagination.pageSize)).map((items) => {
              return (
                <div className="container mt-20    ">
                  <div className="packageconstiner  flex justify-between">
                    <div className="flex gap60 align-items package_container">
                      <div className="flex gap60 package_gap ">
                      <input type="radio" className="selectPackage" value={items?.packageDetails?.packageName} name="package" checked={pkgCod===items?.packageDetails?.packageName?true:false}
                      onChange={(e) => setPkgCod(e.target.value)} />
                      <h4>{items?.packageDetails?.packageName}</h4>
                      </div>
                      <p>{items?.packageDetails?.packageDescription}</p>
                    </div>
                    <div className="flex gap60 align-items package_container">
                      <div>
                        <h6>Expire Date:</h6>
                        <p>{items?.packageDetails?.packageExpiry} months</p>
                      </div>
                  <div className="flex">
                      <div>
                        <h6>Ammount</h6>
                        <p>₹{items?.packageDetails?.packagePrice}/mo</p>
                      </div>

                      <div
                        className={isActive ? "transform" : ""}
                        onClick={() =>
                          handlePackage(items?.packageDetails?.packageName)
                        }
                      >
                        <i class="fa-solid fa-angle-down pointer"></i>
                      </div>
                      </div>
                    </div>
                  </div>

                  {drawerShow === items?.packageDetails?.packageName ? (
                    <>
                      {!isEmpty(items?.serviceConfiguration) && (
                        <>
                          <div className="flex check_Box_Conatiner"></div>
                          <div className="transaction_table">
                            <div>
                              <div class="table">
                                <table id="customers">
                                  <th>Service Name</th>
                                  <th>Service Model</th>
                                  <th>Range</th>
                                  <th>Commission Type</th>
                                  <th>Net Commission</th>
                                  <th>Hierarchy Comission</th>

                                  {items.serviceConfiguration &&
                                    items.serviceConfiguration.map(
                                      (details, index) => {
                                        return (
                                          <tbody className="tbody">
                                            <tr className="border">
                                              <td
                                                rowspan={20}
                                                style={{ paddingLeft: "20px" }}
                                              >
                                                {details?.serviceName}
                                              </td>
                                              <td rowspan={20}>
                                                {" "}
                                                {details?.serviceModel}
                                              </td>
                                            </tr>
                                            {details.priceConfiguration &&
                                              details.priceConfiguration.map(
                                                (priceDetails) => {
                                                  return (
                                                    <tr>
                                                      <td>
                                                        {
                                                          priceDetails?.minAmount
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                          priceDetails?.maxAmount
                                                        }
                                                      </td>
                                                      <td>
                                                        {priceDetails?.feeType}
                                                      </td>
                                                      <td>
                                                        {priceDetails?.fee}
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <th>Admin</th>
                                                          <th>Retailer</th>
                                                          <th>Distributer</th>
                                                        </div>

                                                        {priceDetails.hierarchyDistributions &&
                                                          priceDetails.hierarchyDistributions.map(
                                                            (hirarchy) => {
                                                              return (
                                                                <td className="border_none mt-5">
                                                                  Com:{" "}
                                                                  {
                                                                    hirarchy?.splitFee
                                                                  }
                                                                </td>
                                                              );
                                                            }
                                                          )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        );
                                      }
                                    )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      )}{" "}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}

          <div>
          {pagination.totalPage > 1 && <Pagination
                       items ={packageData}
                        itemsPerPage={pagination.pageSize}
                        pageSize={pagination.pageSize}
                        currentPage={pagination.currentPage}
                        setcurrentPage={setCurrentPage}
                        totalPages={pagination.totalPage}
                     
                    />}
                    <div className="btn_style">
                    <button className="submit_btn " style={{marginTop:"12px",maxWidth:"200px"}}
             disabled={disableBtn}
            onClick={SubmitHandaler}>
              Submit
            </button>
                    </div>
            
          </div>
        </div>
      </div>
      {loading && <GloabalLoader />}
    </div>
  );
}

export default SelectPackage;
