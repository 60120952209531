import React, { useEffect, useRef, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import { deleteUser } from "../../store/userSlice";
import headerBg from "../../assets/images/background_image.png";
import "../Navbar/Navbar.scss";
import membericon from "../../assets/images/member_icon.png";
import wallet from "../../assets/images/wallet.png";
import { isEmpty } from "../../utils/common";
import { getLiveBalance, rolePermission } from "../../utils/api";
import mobileViewtoggle from "../../assets/images/mobileviewtoggle.svg";
import { ModalPopUp } from "../ModalPopUp/ModalPopUp";
import modalpopup from "../../assets/images/modalPopubg.svg";

const Navbar = ({ setSiedBarLeft,setUpdateData ,upadteData}) => {


    const [isMenuHidden, setIsMenuHidden] = useState(false)
    const [liveBalance, setLiveBalance] = useState("");
    const [logoutUser, setLogoutUser] = useState(false);
    const [userType, setUserType] = useState("");


  const navigate = useNavigate();
  const dispatch = useDispatch();


    const { user } = useSelector((state) => state.user);

   useEffect(() =>{
      if(user?.userType === "ROLE_DISTRIBUTOR"){
        setUserType("Distributor");
      }else if(user?.userType === "ROLE_ADMIN"){
        setUserType("Admin");
      }else if(user?.userType === "ROLE_SUBADMIN"){
        setUserType("Sub Admin");
      }
   },[user]);

  const ref = useRef();
  useEffect(() => {
    setIsMenuHidden(false);
  }, [window.location.pathname]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuHidden && ref.current && !ref.current.contains(e.target)) {
        setIsMenuHidden(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  });


  useEffect(() => {
    if (isEmpty(user)) {
    }
  }, [user]);

  useEffect(() => {
    getLiveBalance().then((resp) => {
      if (resp?.data?.success) {
        setLiveBalance(resp?.data?.data?.liveBalance);
      }
    });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      const token = JSON.parse(sessionStorage.getItem("token"));

      const token_ = token?.id_token;
      // const token_  = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4ODAwNTc4ODY2IiwiYXV0aCI6IlJPTEVfQURNSU4iLCJ1c2VySWQiOiI4NzJjN2UwOC0xNjgyLTRjOGYtODk0Mi04ZWFkMTMxYjliNmUiLCJpc090cFZhbGlkYXRlZCI6ZmFsc2UsImV4cCI6MTY5NDg0NTExM30.HD_Eeg2G-KUQzUVryiE5J_YzGjn6IG2BojcvcBjopGBVWjPUE6eRA7yJtBqPHqsF_2G9ad0hCg_pV85_N3lFUg";

      var base64Url = token_.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      jsonPayload = JSON.parse(jsonPayload);
      let expirytime = jsonPayload.exp;

      let expirationDate = new Date(expirytime * 1000);

      const Intervalid = setInterval(() => {
        try {
          if (expirationDate) {
            if (expirationDate <= Date.now()) {
              setLogoutUser(true);
              clearInterval(Intervalid);
            }
          } else {
            console.error("Token is not valid");
          }
        } catch (error) {
          console.error("An error occurred:", error.message);
        }
      }, 6000);
    }

   
  }, [sessionStorage.getItem("token")]);

  const sessionLogout = () => {
    sessionStorage.clear();
    dispatch(logout());
    dispatch(deleteUser());
    navigate("/");
  };

  return (
    <div className="navbar_container noPrint">
      <div className="navbar-bg">
      
      </div>
      <div className="navbar_wrapper">
        <div className="navbar_left">
          <div className="navbar_pic-with_right_text gap-25">
            <div className="member_icon flex align-items">
              <div className="images">
                <img src={membericon}></img>
              </div>
              <div className="text-info">
                <span>User Type</span>
                <strong>{userType}</strong>
              </div>
            </div>
            {
              <div className="flex align-items">
                <div className="navbar_pic_text flex align-items">
                  <div className="images" style={{ marginLeft: "20px" }}>
                    <img src={wallet}></img>
                  </div>
                  <div className="text-info">
                    <span>Live Balance</span>
                    <strong>
                      ₹ {isEmpty(liveBalance) ? "0" : liveBalance}
                    </strong>
                  </div>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <button
                    className="update-wallet-btn"
                    onClick={() => getLiveBalance()}
                  >
                    <i className="fa fa-refresh"></i>Update
                  </button>
                </div>
              </div>
            }
          </div>
          {/* <div className='navbar_pic-with_right_text'>
                        <button className='update-wallet-btn'>
                            <i className="fa fa-refresh"></i>Update
                        </button>
                    </div> */}
        </div>
        <div className="navbar_right">
          <div className="mobile-view flex align-items">
            <div
              className="mobile_view_toggle flex"
              onClick={() => setSiedBarLeft("active")}
            >
              <img src={mobileViewtoggle}></img>
            </div>
            <div className="flex align-items">
              {/* <div>
                <button className="update-wallet-btn .mobile_view_toggle"
                onClick={() =>getLiveBalance()}>
                  <i className="fa fa-refresh"></i>Update
                </button>
              </div> */}
              <div
                ref={ref}
                className="navbar_pic-with_right_text"
                onClick={() =>
                  isMenuHidden ? setIsMenuHidden(false) : setIsMenuHidden(true)
                }
              >
                <img src={membericon}></img>
                <div className="text-info user-text">
                  <span>Welcome</span>
                  <div className="text_with_down_arrow">
                    <strong>
                      {user?.firstName} {user?.lastName}{" "}
                    </strong>
                  </div>
                </div>
                <i className="fa-solid fa-angle-down pointer"></i>
                {
                  <div class={`card ${isMenuHidden} `}>
                    <ul className="list-item" style={{ padding: "0" }}>
                      <li
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        Profile
                      </li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {logoutUser && (
        <ModalPopUp>
          <div
            className="modal-popup"
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
              height: "170px",
            }}
          >
            <>
              <div className="flex justify-center mt-30">
                <label className="fs-20 ">Your session is expired</label>
              </div>
              <div className="flex justify-center gap-10 mt-30">
                <button
                  className="confirm_button"
                  onClick={() => sessionLogout()}
                >
                  OK
                </button>
              </div>
            </>
          </div>
        </ModalPopUp>
      )}
    </div>
  );
};

export default Navbar;
