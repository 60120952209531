import React, { useRef } from "react";
import { InvoiceWrapper } from "./styles/style";
import logo from "../../assets/images/logoBlack.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/common";
import "./Invoice.scss";

function Invoice() {
  const link = useRef();
  const location = useLocation();
  const { detail ,type } = location.state;

  let inputDateString = type === "Wallet"? detail?.createdDate:detail?.transactionDate;
 
  const inputDate = new Date(inputDateString);

  const formattedDate = inputDate.toLocaleString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });

  const date = formattedDate.split(",")?.[0];
  const time = formattedDate.split(",")?.[1];

  const navigate = useNavigate();
  const backHandler =() =>{
    if(type === "Wallet"){
      navigate(`/walletTransactions`)
    }
    if(type === "Transaction"){
      navigate(`/transactions`)
    }
    
  }

  return (
    <>
      <InvoiceWrapper>
        <div className=" invoiceHeader text-center flex justify-between">
          <div className="flex gap-10">
            <div onClick={backHandler}
            className="noPrint"
            style={{cursor:"pointer"}}
            ><i class="fa-solid fa-arrow-left"></i></div>
          <strong>{`${type} Invoice`}</strong>

          </div>
          
          <div class=" button noPrint" >
            <button class="userlist_btn"
            onClick={() =>window.print()}
            
            >Print</button>
          </div>
        </div>

        <div className="invoiceWrapper">
          <div className="TransactionDaitalsWrapper flex justify-center">
            <div className="TransactionDaitals">
              <div className="top text-center">
                <h2>Pay2Mobiles</h2>
                <h4>WEBETECHIES PRIVATE LIMITED</h4>
                <p>
                  Ganga Bhawan, 1193/21 market, opposite sector 21 , Gurugram,
                  India,
                  <br /> Haryana +1 762-500-4104
                </p>
              </div>

              <div className="middle">
                <div>
                  <div className="flex justify-between date">
                    <div>Date & Time</div>
                    <div>{date + " "+time}</div>
                  </div>
                </div>
                {type === "Wallet" &&<>
                <div className="flex justify-between row">
                  <div>
                    <strong>Status</strong>
                  </div>
                  <div>
                    <strong
                      style={detail?.txnStatus?.toUpperCase() === "SUCCESS"?{color:"#04a204"}: detail?.txnStatus?.toUpperCase() === "INITIATED"? {color:"#b1b108"} : {color:"red"}}
                    >{detail?.txnStatus}</strong>
                  </div>
                </div>
                <div className="flex justify-between row">
                  <div>Retailer Name</div>
                  <div>{detail?.userFullName}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Retailer Mobile No.</div>
                  <div>{detail?.userPhoneNumber}</div>
                </div>

               <div className="flex justify-between row">
                  <div>Transaction ID</div>
                  <div>{detail?.transactionId}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Transaction Amount</div>
                  <div>₹ {detail?.transactionAmout}</div>
                </div>
               
                <div className="flex justify-between row">
                  <div>Charges</div>
                  <div>₹ {detail?.charges}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Commission</div>
                  <div>₹ {detail?.commission}</div>
                </div>
                <div className="flex justify-between row">
                  <div>GST</div>
                  <div>₹ {detail?.gst}</div>
                </div>
                <div className="flex justify-between row">
                  <div>DTS</div>
                  <div>₹ {detail?.tds}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Final Amount</div>
                  <div>₹ {detail?.finalTxnAmount}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Opening Amount</div>
                  <div>₹ {detail?.openingAmount}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Closing Balance</div>
                  <div>₹ {detail?.currentAmount}</div>
                </div>
                <div className="flex justify-between row">
                  <div>	Transaction Type</div>
                  <div> {detail?.transactionType}</div>
                </div>
                <div className="flex justify-between row">
                  <div>	Service Type </div>
                  <div> {detail?.serviceType}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Remark</div>
                  <div> {detail?.remarks}</div>
                </div>
                </>}
              {type === "Transaction" &&<>  
              <div className="flex justify-between row">
                  {!isEmpty(detail?.status) &&  <div>
                    <strong>Status</strong>
                  </div>}
                  <div>
                    <strong
                    style={detail?.status.toUpperCase() === "SUCCESS"?{color:"#04a204"}: detail?.status.toUpperCase() === "INITIATED"? {color:"#b1b108"} : {color:"red"}}
                    
                    >{detail?.status}</strong>
                  </div>
                </div>
                <div className="flex justify-between row">
                  <div>Retailer Name</div>
                  <div>{detail?.userFullName}</div>
                </div>
                <div className="flex justify-between row">
                  <div>Retailer Mobile No.</div>
                  <div>{detail?.userPhoneNumber}</div>
                </div>
            
                <div className="flex justify-between row">
                  <div>Transaction Amount</div>
                  <div>₹ {detail?.amount}</div>
                </div>
                
                {!isEmpty(detail?.accountHolderName) && <div className="flex justify-between row">
                  <div>	Account Holder Name </div>
                  <div> {detail?.accountHolderName}</div>
                </div>}
             
                {!isEmpty(detail?.accountNumber) && <div className="flex justify-between row">
                  <div>	Account Number</div>
                  <div> {detail?.accountNumber}</div>
                </div>}
                {!isEmpty(detail?.accountIfscCode) && <div className="flex justify-between row">
                  <div>	Account Ifsc Code </div>
                  <div> {detail?.accountIfscCode}</div>
                </div>}
                {!isEmpty(detail?.rechargedNumber) && <div className="flex justify-between row">
                  <div>	Recharge Number</div>
                  <div> {detail?.rechargedNumber}</div>
                </div>}
                {!isEmpty(detail?.serviceType) && <div className="flex justify-between row">
                  <div>	Service Type </div>
                  <div> {detail?.serviceType}</div>
                </div>}
                {!isEmpty(detail?.serviceType) && <div className="flex justify-between row">
                  <div>	Route </div>
                  <div> {detail?.route}</div>
                </div>}
                <div className="flex justify-between row">
                  <div>Transaction ID</div>
                  <div>{detail?.transactionId}</div>
                </div>
             
             
                {!isEmpty(detail?.remarks) && <div className="flex justify-between row">
                  <div>Remark</div>
                  <div> {detail?.remarks}</div>
                </div>}
                </>}
                <div className="flex justify-between row amount">
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </InvoiceWrapper>
    </>
  );
}

export default Invoice;
