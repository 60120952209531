import React, { useEffect, useState } from "react";

const SubAdminPermission = ({
  heading,
  role,
  index,
  checkValue,
  setCheckValue,
  permissionList,
  userRole,
 

}) => {
  
  const [isActive, setIsActive] = useState(false);
   const [drawerShow, setDrawerShow] = useState(false);   
  useEffect(() =>{
    const updateValue = [];
    permissionList.forEach((element) => {
      let val = element.split("/")[1];
      if(!updateValue.includes(val)){
        updateValue.push(val);
      }
    });
    
      setCheckValue([...updateValue])
   
    ;
  },[permissionList])
  const handleChecked = (event,value) => {
    var updatedList = [...checkValue,];
    if(!updatedList.includes(value)){
      updatedList = [...checkValue, value];
    }else{
      updatedList.splice(checkValue.indexOf(value), 1);
    }
    setCheckValue(updatedList);
  };
  const handlePermission = (e) => {
    setIsActive((current) => !current);
    setDrawerShow(!drawerShow);
  };

  useEffect(() =>{
    setDrawerShow(false);
  },[userRole])


  return (
    <>
      <div
        key={index}
        className={isActive ? "bg sub_container" : "sub_container"}
        onClick={handlePermission}
      >
        <h2 className="sub_text">{heading}</h2>

        <div className={isActive ? "transform" : ""} onClick={handlePermission}>
          <i class="fa-solid fa-angle-down pointer"></i>
        </div>
      </div>
      {drawerShow ? (
        <div className="checkbox_container flex">
          <div className="flex  row  permission_list_data">
            {role &&
              role.map((val, i) => {
                return (
                  <div className="flex  align-item-center gap" key={i}>
                    {permissionList.includes(heading+"/"+val.navName) ? (
                      <input
                        name="checkValue"
                        id={heading + "/" + val.navName}
                        value={val.navName}
                        onChange={(e) =>handleChecked(e,val.navName)}
                        className="checkbox_input"
                        defaultChecked={true}
                        style={{ width: "11px" }}
                        type="checkbox"
                      ></input>
                    ) : (
                      <input
                        name="checkValue"
                        value={val.navName}
                        id={heading + "/" + val.navName}
                        onChange={(e) =>handleChecked(e,val.navName)}
                        className="checkbox_input"
                        style={{ width: "11px" }}
                        type="checkbox"
                        defaultChecked={false}
                      ></input>
                    )}
                    <div>
                      <label
                        htmlFor={heading + "/" + val.navName}
                        className="fs"
                      >
                        {val.navName}{" "}
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default SubAdminPermission;
