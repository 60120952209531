import React, { useEffect, useState } from "react"; 

import { addPackageServices, getRoute, rolePermission, updateConfigurationServices } from "../../../utils/api";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";

import { isEmpty } from "../../../utils/common";
import Swal from "sweetalert2";
import { AlertWrapper } from "../../../assets/styles/AlerWrapper";

const Service = () => {
  const [showDrawer, setshowDrawer] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const state = location.state;
  const [showHide, setShowHide] = useState(true);
  const [serviceRoute, setServiceRoute] = useState("route");
  
  
  
  const packagename = state?.packageName;
  const[configuration,setConfiguration] = useState(state?.confirguration[0])


const navigate = useNavigate();

  const handleRange = (i) => {
    setshowDrawer(!showDrawer);

    setIsActive((current) => !current);
  };

  // const handleRange = (key) => {
  //   if(showDrawer===key){
  //     setshowDrawer("");
  //   }else{
  //     setshowDrawer(key);
  //   }

  //   setIsActive((current) => !current);
  // };

  const [serviceForm, setServiceForm] = useState({
    serviceName: "",
    serviceModel: "",
    priceConfiguration: [
      {
        minAmount: "",
        maxAmount: "",
        feeType: "",
        route: "",
        fee: "",
        rangeId: "",
        isRangeCheckRequired: true,
        hierarchyDistributions: [
          {
            splitFee: "",
            userType: "",
          },
        ],
      },
    ],
  });
  const [errors, setErrors] = useState([]);
  const [congifErrors, setCongifErrors] = useState([]);
  const [msg, setMsg] = useState("");
  const[range,setRange] = useState(true);
  const [routeValue,setRouteValue] = useState({});
  const[prepRecharge,setPrepRecharge] =  useState([]);
  const[sendMoney,setSendMoney] = useState([]);
const [bbps,setBbps] = useState([]);
const [payOut,setPayout] = useState([]);
const [aeps,setAeps] = useState([]);
const [addMoney,setAddMoney] = useState([]);

  useEffect(()=>{
    if(state?.msg){
      setMsg(state?.msg)
    }
    
  },[state?.msg])
 useEffect(() => {
    if(serviceRoute === "route"){
      if (serviceForm.serviceName === 'PREPAID_RECHARGE') {
        getRoute(serviceForm.serviceName).then((res) => {
       
          setRouteValue(res?.data?.data);
          setPrepRecharge(Object.keys(res?.data?.data));
        });
      } else if (serviceForm.serviceName === 'SEND_MONEY') {
        getRoute(serviceForm.serviceName).then((res) => {
         
          setRouteValue(res?.data?.data);
          setSendMoney(Object.keys(res?.data?.data));
        });
      }
    else if(serviceForm.serviceName === 'BBPS'){
      
      getRoute(serviceForm.serviceName).then((res) => {
     
        setRouteValue(res?.data?.data);
        setBbps(Object.keys(res?.data?.data));
      });
    }
    else if(serviceForm.serviceName === 'PAY_OUT'){
      
      getRoute(serviceForm.serviceName).then((res) => {
       
        setRouteValue(res?.data?.data);
      setPayout(Object.keys(res?.data?.data));
      });
    }
      else if(serviceForm.serviceName === 'AEPS'){

        getRoute(serviceForm.serviceName).then((res) => {
        
          setRouteValue(res?.data?.data);
        setAeps(Object.keys(res?.data?.data));
        });
      }
      else if(serviceForm.serviceName === 'ADD_MONEY'){

        getRoute(serviceForm.serviceName).then((res) => {
        
          setRouteValue(res?.data?.data);
          setAddMoney(Object.keys(res?.data?.data));
        });
      }
      
      


    }
    
  }, [serviceRoute,serviceForm.serviceName]);

  const ServiceformConfig = (e) => {
          
    if(e.target.name ==="serviceName"){
    setTimeout(() =>{
      setServiceRoute("route")
    })
    }
    setServiceForm({ ...serviceForm, [e.target.name]: e.target.value });
    if(e.target.value == "NON_CHARGEABLE"){
      setRange(false);
    }
    else{
      setRange(true);
    }

    if((e.target.name === "route" && serviceRoute !== "route")){
      setServiceRoute(e.target.value)

    }  else{
      setServiceRoute(e.target.value);
    }

   





  };

  const handleChange = (e, i, j,key ="") => {
    const clonedData = { ...serviceForm };
    

    if (j !== undefined) {
      clonedData["priceConfiguration"][i]["hierarchyDistributions"][j][
        e.target.name
      ] = e.target.value;
    } else {
      clonedData["priceConfiguration"][i][e.target.name] = e.target.value;
    }

    setServiceForm(clonedData);
  };
  
  
  
  
  
  const handleUpdateChange = (e, i, j) => {
    const clonedData = {...configuration};

    if (j !== undefined) {
      clonedData["priceConfiguration"][i]["hierarchyDistributions"][j][
        e.target.name
      ] = e.target.value;
    } else {
      clonedData["priceConfiguration"][i][e.target.name] = e.target.value;
    }

    setConfiguration(clonedData);
  };

  const handleCreateServices = () => {
    
    let error = {};

    if (serviceForm.serviceName === "") {
      error.serviceName = "Please Select Service Name";
    }
    if (serviceForm.serviceModel === "") {
      error.serviceModel = "Please Select Service Model";
    }
   
    const errorArr = [];

   // const errorArr = [];

    {
     
      var minAmount;
     
      serviceForm?.priceConfiguration?.map((data, index) => {

        const conFigError ={};

        minAmount = data?.maxAmount;

        if (data.minAmount === "") {
          conFigError.minAmount = "Please Select Minimum Amount";
        }
        if (data?.maxAmount === "") {
          conFigError.maxAmount = "Please Select Max Amount";
        }
        if (data?.minAmount > data?.maxAmount) {
          conFigError.minAmount = "Min Amount can not be less than Max Amount";
        }
        else if(index > 0 && minAmount > data?.maxAmount){
            conFigError.minAmount = "Min Amount can not be less than Max Amount";

        }
        // else if(index > 0 && minAmount > data?.maxAmount){
        //     conFigError.minAmount = "Min Amount can not be less than Max Amount";
        // }
        if (data?.feeType === "") {
               conFigError.feeType = "Please Select Commission Type";
        }

        if (data?.fee === "") {
          conFigError.fee = "Please Enter Net Comission";
        }
        const hirearachyerrorArr = [];
        {
          data?.hierarchyDistributions.map((items, j) => {
            const hirerarchyError ={};
            if (items?.splitFee === "") {
              hirerarchyError.splitFee = "Please Enter Role Wise Comission";
            }

            if (items.userType === "") {
              hirerarchyError.userType = "Please Enter Role";
            }

if(!isEmpty(hirerarchyError)){
  hirearachyerrorArr.push(hirerarchyError);
        

}

          
        
        
        
          });

          conFigError.subErr = hirearachyerrorArr;
        
        }

        errorArr.push(conFigError);
      
      });

      setCongifErrors(errorArr);
      
 
    }

    const checkConfigError = [];
    errorArr.map((val) =>{
     
      if(typeof val ==="object"){
  
      // !isEmpty(val?.subErr) && val?.subErr.map((value)=>{

         
      //     if(!isEmpty(value)){
          
      //       checkConfigError.push(false);
           
      //     }

      //   });
    if(!isEmpty(val?.subErr) && val?.subErr.length > 0){
      checkConfigError.push(false)

    }

      }else{
        if(!isEmpty(val)){
          
          checkConfigError.push(false);
         
        }
      }


     
  

     


    });
   
   
   

  

    
    if (isEmpty(error) && checkConfigError.length ===0) {

      let a = "";
      for (let i = 0; i < serviceForm.priceConfiguration.length; i++) {
        let sum = 0;
        for (
          let j = 0;
          j < serviceForm.priceConfiguration[i].hierarchyDistributions.length;
          j++
        ) {
          sum += Number(
            serviceForm.priceConfiguration[i].hierarchyDistributions[j].splitFee
          );
        }
        if (
          serviceForm.priceConfiguration[i].feeType == "FLAT" &&
          Number(serviceForm.priceConfiguration[i].fee) < sum
        ) {
          a = 2;

          Swal.fire({
            timer: 2000,
            icon: "warning",
            title: `Net commission can not be less  in Range ${i + 1}`,
          });
          return false;
        }
      }

    
        if (serviceForm?.priceConfiguration?.length > 1) {
        
          for (let i = 1; i < serviceForm?.priceConfiguration?.length; i++) {

           if (
              Number(serviceForm?.priceConfiguration[i-1]?.maxAmount) >=
               Number(serviceForm?.priceConfiguration[i]?.minAmount) && serviceForm?.priceConfiguration[i]?.route != serviceForm?.priceConfiguration[i-1]?.route
            ) {
              a = 1;
              Swal.fire({
                timer: 2000,
                icon: "warning",
                title: `Enter valid Range range ${i+1}`,
              });
              return false;
            }
          
          
          }
        }

      

      

      if (a !== "") {
        return;
      }
      addPackageServices(serviceForm, packagename).then((res) => {
            
     
        if (res?.data?.success) {
          setServiceForm({
            serviceName: "",
            serviceModel: "",
            priceConfiguration: [
              {
                minAmount: "",
                maxAmount: "",
                feeType: "",
                route: "",
                fee: "",
                isRangeCheckRequired: true,
                hierarchyDistributions: [
                  {
                    splitFee: "",
                    userType: "",
                  },
                ],
              },
            ],
          });
          setMsg("Package Created Succesfully");
          navigate('/PackageMnagementList');
        }
      });
      setErrors({});
    }

    setErrors(error);


  };

  function handleRole(columnId) {
    const obj = {
      splitFee: "",
      userType: "",
    };
    // let newColums = {...serviceForm.priceConfiguration}; // Create a copy of the state using spread operator

    let newColums = serviceForm.priceConfiguration;
    newColums[columnId].hierarchyDistributions.push(obj); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state


  }

  function handleRoleMinus(columnId) {
    let newColums = serviceForm.priceConfiguration;
    newColums[columnId].hierarchyDistributions.pop(); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state

  }

  function handleUpdateRole(columnId) {
    const obj = {
      splitFee: "",
      userType: "",
    };
    // let newColums = {...serviceForm.priceConfiguration}; // Create a copy of the state using spread operator

    let newColums = state?.confirguration[0].priceConfiguration;
    newColums[columnId].hierarchyDistributions.push(obj); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state


  }
  function handleupdateRoleMinus(columnId){
    let newColums = state?.confirguration[0].priceConfiguration;
    newColums[columnId].hierarchyDistributions.pop(); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state
  }



  function handleAddRange(e) {
    e.preventDefault();
    const obj = {
      minAmount: "",
      maxAmount: "",
      feeType: "",
      route: "",
      fee: "",
      isRangeCheckRequired: true,
      hierarchyDistributions: [
        {
          splitFee: "",
          userType: "",
        },
      ],
    };
    let newColums = serviceForm;
    newColums.priceConfiguration.push(obj); // Add the new item
    setServiceForm({ ...serviceForm });

  }
  function handleMinusRange() {
  
    
    let  newColums = serviceForm;
 
    newColums.priceConfiguration.pop(); // Add the new item
    setServiceForm({ ...serviceForm });

  }

 
 function handleUpdateMinusRange(){
  let  newColums = state?.confirguration[0];
 
    newColums.priceConfiguration.pop(); // Add the new item
    setServiceForm({ ...serviceForm });

 }
  const handleBack = () => {
    navigate("/CreatePackage");
  };

   const handleUpdateConfiguration = (counts,role)=>{
 const  param ={
  packageCode: state?.packageCode,
  serviceName: state?.confirguration[0]?.serviceName,
  serviceModel: state?.confirguration[0]?.serviceModel,
  minAmount: counts?.minAmount,
  maxAmount: counts?.maxAmount,
  fee:counts?.fee,
  route: counts?.route,
  rangeId:counts?.rangeId,
  hierarchyDistributions: [
    {
      splitFee: role?.splitFee,
      userType: role?.userType
    }
  ]
    }

    updateConfigurationServices(param).then((resp)=>{
    

    })

   }
   const handleClick =()=>{
    navigate('/PackageManagement');
   }

   function handleUpdateRange(e) {
    e.preventDefault();
    const obj = {
      minAmount: "",
      maxAmount: "",
      feeType: "",
      route: "",
      fee: "",
      rangeId:"",
      isRangeCheckRequired: true,
      hierarchyDistributions: [
        {
          splitFee: "",
          userType: "",
        },
      ],
    };
    let newColums = state?.confirguration[0];
    newColums.priceConfiguration.push(obj); // Add the new item
    setServiceForm({ ...serviceForm });

  }




   useEffect(()=>{
    if(msg !== ""){
      setTimeout(()=>{
    setMsg("");
      },5000)
     
    }
  
  },[msg])



  return (
    <>
      {msg && (
        <AlertWrapper className="success globle">
          <span className="close" onClick={() => setMsg("")}>
            ×
          </span>
          {msg}
        </AlertWrapper>
      )}

      {serviceForm && isEmpty(state?.confirguration) && (
        <div className="createpkg_container padding_30">
          <div onClick={handleBack} className="flex align-items gap10 cursor">
            <i class="fa-solid fa-arrow-left-long"></i>
            <p>Back</p>
          </div>
          <h2>Create Package</h2>
          <div className="container_progress1">
            <div className="round round-adduser">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-1" />
              <div className="lable">Package Details</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-2 " />
              <div className="lable">Service Configuration</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-2" style={{ visibility: "hidden" }} />
              <div className="lable fs-20 bold">View Package</div>
            </div>{" "}
          </div>
          <div className="mt-20">
            <div className="mt-20">
              <h4>Service And Price Confguration</h4>
              <div className="flex gap-20">
                <div>
                  <lable className="filter_text fs">Service Name</lable>

                  <div className="">
                    <select
                      name="serviceName"
                      onChange={(e) => ServiceformConfig(e)}
                      className="filter_select "
                    >
                      <option selected disabled>
                        SELECT SERVICES
                      </option>
                      <option value="PAY_OUT">PAY OUT</option>
                      <option value="AEPS">AEPS</option>
                      <option value="BBPS">BBPS</option>
                      <option value="SEND_MONEY">SEND MONEY</option>
                      <option value="PREPAID_RECHARGE">
                        PREPAID RECHARGE
                      </option>
                    </select>
                  </div>
                  {errors.serviceName && (
                    <p className="error fs-12 mt-10">{errors.serviceName}</p>
                  )}
                </div>

                <div>
                  <lable className="filter_text fs">Service Model</lable>

                  <div>
                    <select
                      name="serviceModel"
                      onChange={(e) => ServiceformConfig(e)}
                      className="filter_select "
                    >
                      <option disabled selected value="">
                        SELECT MODEL
                      </option>
                      <option value="CHARGEABLE">CHARGABLE</option>
                      <option value="NON_CHARGEABLE">NON CHARGABLE</option>
                    </select>
                  </div>
                  {errors.serviceModel && (
                    <p className="error fs-12 mt-10">{errors.serviceModel}</p>
                  )}
                </div>


              
            

            
            
            
            
              </div>

              { range ? serviceForm &&
                serviceForm?.priceConfiguration?.map((counts, index) => {
                  return (
                    <div className="container  mt-20" key={index}>
                    
                    <div>
                  <lable className="filter_text fs">Route</lable>

                  <div>
                    <select
                      name="route"
                      onChange={(e) => handleChange(e, index)}
                      className="filter_select "
                      
                    >
                      <option  selected={serviceRoute === "route"} value="route">SELECT ROUTE</option>
                      
                     {/* <option value="va" selected={serviceRoute !== "route"}>ggg</option> */}
                     {serviceForm.serviceName === 'PREPAID_RECHARGE'
              ? prepRecharge.map((item, index) => (
                  <option key={index} value={routeValue[item]}>
                    {item}
                  </option>
                ))
              : serviceForm.serviceName === 'SEND_MONEY'
              ? sendMoney.map((item, index) => (
                  <option key={index} value={routeValue[item]}>
                    {item}
                  </option>
                ))
              :serviceForm.serviceName === 'PAY_OUT'
              ? payOut?.map((item, index) => (
                  <option key={index} value={routeValue[item]}>
                    {item}
                  </option>
                )):serviceForm.serviceName === 'AEPS'
                ? aeps?.map((item, index) => (
                    <option key={index} value={routeValue[item]}>
                      {item}
                    </option>
                  )):serviceForm.serviceName === 'BBPS'
                  ? bbps?.map((item, index) => (
                      <option key={index} value={routeValue[item]}>
                        {item}
                      </option>
                    )):serviceForm.serviceName === 'ADD_MONEY'
                    ? addMoney?.map((item, index) => (
                        <option key={index} value={routeValue[item]}>
                          {item}
                        </option>
                      )) : null}
                    </select>
                  </div>
                  {errors.route && (
                    <p className="error fs-12 mt-10">{errors.route}</p>
                  )}
                </div>
            
                    
                    
                    
                    
                    
                    
                      <div className="flex gap-20 align-items">
                        <div>
                          <lable className="filter_text fs">
                            Range:{index + 1}
                          </lable>
                          <div className="flex mt-5 gap10">
                            <div>
                              <input
                                type="number"
                                name="minAmount"
                                value={state?.configuration?.minAmount}
                                onChange={(e) => handleChange(e, index)}
                                className="filter_select"
                                placeholder="MIN AMOUNT"
                                min={1}
                                required
                              ></input>{" "}
                              {congifErrors[index]?.minAmount && (
                                <p className="error fs-12 mt-10">
                                  {congifErrors[index]?.minAmount}
                                </p>
                              )}
                              {/* {errors && errors.map((error,index)=>{
                            
                            return(
                              <>
                               
                              </>
                          

                            )
                          
                         
                          
                          }) } */}
                            </div>

                            <div>
                              <input
                                type="number"
                                name="maxAmount"
                                value={counts.maxAmount}
                                onChange={(e) => handleChange(e, index)}
                                className="filter_select"
                                placeholder="MAX AMOUNT"
                                required
                              ></input>
                              {congifErrors[index]?.maxAmount && (
                                <p className="error fs-12 mt-10">
                                  {congifErrors[index]?.maxAmount}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <lable className="filter_text fs">
                            Commission Type:
                          </lable>
                          <div className="mt-5">
                            <select
                              onChange={(e) => handleChange(e, index)}
                              name="feeType"
                              className="filter_select"
                            >
                              <option disabled selected>
                                SELECT TYPE
                              </option>
                              <option value="FLAT">FLAT</option>
                              <option value="PERCANTAGE">PERCANTAGE</option>
                            </select>
                          </div>
                          {congifErrors[index]?.feeType && (
                            <p className="error fs-12 mt-10">
                              {congifErrors[index]?.feeType}
                            </p>
                          )}
                        </div>
                        <div>
                          <lable className="filter_text fs">
                            Net Commission:
                          </lable>
                          <div className="mt-5">
                            <input
                              type="text"
                              name="fee"
                              value={counts.fee}
                              onChange={(e) => handleChange(e, index)}
                              className="filter_select"
                              placeholder="COMMISSION"
                            ></input>
                          </div>
                          {congifErrors[index]?.fee && (
                            <p className="error fs-12 mt-10">{congifErrors[index]?.fee}</p>
                          )}
                        </div>
                        <div className="mt-20  flex">
                        <p className="fs">Hierarchy</p>
                        <div
                          className={isActive ? "transform" : "transition"}
                          onClick={handleRange}
                        >
                          <i class="fa-solid fa-angle-down pointer"></i>
                        </div>
                        </div>
                       
                      </div>
                      

                      {showDrawer ? (
                        <div
                          className="mt-20 flex align-items"
                          style={{ transition: "all 15s ease-in " }}
                        >


                          <h5>HIERARCHY</h5>

                          <div className="container range">
                            {serviceForm.priceConfiguration[
                              index
                            ].hierarchyDistributions.map((role, Subindex) => {
                              return (
                                <div
                                  className=" flex gap-20 mt-10 "
                                  key={Subindex}
                                >
                                  <div>
                                    <lable className="filter_text fs">
                                      Role
                                    </lable>
                                    {/* <div>
                                      <input
                                        name="userType"
                                        value={role.userType}
                                        onChange={(e) =>
                                          handleChange(e, index, Subindex)
                                        }
                                        className="filter_select"
                                        type="text"
                                        placeholder="ROLE"
                                      ></input>
                                    </div> */}
                                    <div>
                                    <select 
                                     className="filter_select"
                                      name="userType"
                                    onChange={(e) =>
                                          handleChange(e, index, Subindex)
                                        }>
                                      <option selected disabled>Select Role</option>
                                      <option value="ADMIN">ADMIN</option>
                                      <option value="RETAILER">RETAILER</option>
                                      <option value="DISTRIBUTER">DISTRIBUTOR</option>
                                    </select>
                                    </div>
                                    
                                    {congifErrors[index]?.subErr[Subindex]?.userType && (
                                      <p className="error fs-12 mt-10">
                                        {congifErrors[index]?.subErr[Subindex]?.userType}
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <lable className="filter_text fs">
                                      Commission:
                                    </lable>
                                    <div>
                                      <input
                                        name="splitFee"
                                        value={role?.splitFee}
                                        onChange={(e) =>
                                          handleChange(e, index, Subindex)
                                        }
                                        className="filter_select"
                                        type="text"
                                        placeholder="COMMISSION"
                                      ></input>
                                    </div>
                                    {congifErrors[index]?.subErr[Subindex]?.splitFee && (
                                      <p className="error fs-12 mt-10">
                                        {congifErrors[index]?.subErr[Subindex]?.splitFee}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            <button
                              onClick={() => handleRole(index)}
                              className="role_btn mt-20"
                            >
                              +
                            </button>

                            {serviceForm.priceConfiguration[index]
                              .hierarchyDistributions.length > 1 ? (
                              <div>
                                <button
                                  onClick={() => handleRoleMinus(index)}
                                  className=" role_btn mt-20"
                                >
                                  -
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }):""}

             {range ? <div className="flex gap-20">
                <button onClick={(e)=>handleAddRange(e)} className="userlist_btn mt-20">
                  Range+
                </button>

                {serviceForm.priceConfiguration.length > 1 ? (
                  <button
                    onClick={handleMinusRange}
                    className="userlist_btn mt-20"
                  >
                    Range-
                  </button>
                ) : (
                  ""
                )}
              </div>:""}
          
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateServices}
              className="userlist_btn mt-20"
              style={{ width: "200px" }}
            >
              Create Configuration
            </button>
          </div>
        </div>
      )}

     
     
 
 
 
 
 
 
 
 
     {/* update pacakge */}
     
      {state?.confirguration.length > 0 && (
        <div className="createpkg_container padding_30">
          <div onClick={handleBack} className="flex align-items gap10 cursor">
            <i class="fa-solid fa-arrow-left-long"></i>
            <p>Back</p>
          </div>
          <h2>Update Package</h2>
          <div className="container_progress1">
            <div className="round round-adduser">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-1" />
              <div className="lable">Package Details</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-2 " />
              <div className="lable">Service Configuration</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-2" style={{ visibility: "hidden" }} />
              <div className="lable fs-20 bold">View Package</div>
            </div>{" "}
          </div>
          <div className="mt-20">
            <div className="mt-20">
              <h4>Service And Price Confguration</h4>
              <div className="flex gap-20">
                <div>
                  <lable className="filter_text fs">Service Name</lable>

                  <div className="mt-5">
                    <input
                      type="text"
                      value={state?.confirguration[0]?.serviceName}
                    ></input>
                  </div>
                  {errors.serviceName && (
                    <p className="error fs-12 mt-10">{errors.serviceName}</p>
                  )}
                </div>

                <div>
                  <lable className="filter_text fs">Service Model</lable>

                  <div className="mt-5">
                    <input
                      type="text"
                      value={state?.confirguration[0]?.serviceModel}
                    ></input>
                  </div>
                  {errors.serviceModel && (
                    <p className="error fs-12 mt-10">{errors.serviceModel}</p>
                  )}
                </div>
              </div>

              {state?.confirguration.length > 0 &&
                state?.confirguration[0]?.priceConfiguration?.map(
                  (counts, index) => {
                    const role ={}
                    return (
                      <>
                     <div className="container  mt-20" key={index}>
                     <div className="mt-5">
                    <input
                      type="text"
                      value={counts?.route}
                    ></input>
                  </div>
                        <div className="flex gap-20">
                       
                          <div>
                            <lable className="filter_text fs">
                              Range:{index + 1}
                            </lable>
                            <div className="flex mt-5 gap10">
                              <div>
                                <input
                                  type="number"
                                  name="minAmount"
                                  value={counts.minAmount}
                                  onChange={(e) => handleUpdateChange(e, index)}
                                  className="filter_select"
                                  placeholder="Min Amount"
                                  min={1}
                                  required
                                ></input>{" "}
                                {errors.minAmount && (
                                  <p className="error fs-12 mt-10">
                                    {errors.minAmount}
                                  </p>
                                )}
                                {/* {errors && errors.map((error,index)=>{
                            
                            return(
                              <>
                               
                              </>
                          

                            )
                          
                         
                          
                          }) } */}
                              </div>

                              <div>
                                <input
                                  type="number"
                                  name="maxAmount"
                                  value={counts.maxAmount}
                                  onChange={(e) => handleUpdateChange(e, index)}
                                  className="filter_select"
                                  placeholder="Max Amount"
                                  required
                                ></input>
                                {errors.maxAmount && (
                                  <p className="error fs-12 mt-10">
                                    {errors.maxAmount}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <lable className="filter_text fs">
                              Commission Type:
                            </lable>
                            <div className="mt-5">
                              <select
                                value={counts.feeType}
                                onChange={(e) => handleUpdateChange(e, index)}
                                name="feeType"
                                className="filter_select"
                              >
                                <option disabled selected>
                                  SELECT TYPE
                                </option>
                                <option value="FLAT">FLAT</option>
                                <option value="PERCANTAGE">PERCANTAGE</option>
                              </select>
                            </div>
                            {errors.feeType && (
                              <p className="error fs-12 mt-10">
                                {errors.feeType}
                              </p>
                            )}
                          </div>
                          <div>
                            <lable className="filter_text fs">
                              Net Commission:
                            </lable>
                            <div className="mt-5">
                              <input
                                type="text"
                                name="fee"
                                value={counts.fee}
                                onChange={(e) => handleUpdateChange(e, index)}
                                className="filter_select"
                                placeholder="Commission"
                              ></input>
                            </div>
                            {errors.fee && (
                              <p className="error fs-12 mt-10">{errors.fee}</p>
                            )}
                          </div>
                          <div
                            className={isActive ? "transform" : "transition"}
                            onClick={handleRange}
                          >
                            <i class="fa-solid fa-angle-down pointer"></i>
                          </div>
                        </div>

                        {showDrawer ? (
                          <div
                            className="mt-20 flex align-items"
                            style={{ transition: "all 15s ease-in " }}
                          >
                            <h5>Hierarchy</h5>
                            <div className="container range">
                              {
                              
                              counts?.hierarchyDistributions?.map((role, Subindex) => {
                                role = role;
                                return (
                                  <div
                                    className=" flex gap-20 mt-10 "
                                    key={Subindex}
                                  >
                                    <div>
                                      <lable className="filter_text fs">
                                        Role
                                      </lable>
                                      <div>
                                    <select 
                                     className="filter_select"
                                      name="userType"
                                    onChange={(e) =>
                                      handleUpdateChange(e, index, Subindex)
                                        }>
                                      <option selected disabled>Select Role</option>
                                      <option value="ADMIN">ADMIN</option>
                                      <option value="RETAILER">RETAILER</option>
                                      <option value="DISTRIBUTER">DISTRIBUTOR</option>
                                    </select>
                                    </div>
                                      {errors?.userType && (
                                        <p className="error fs-12 mt-10">
                                          {errors?.userType}
                                        </p>
                                      )}
                                    </div>
                                    <div>
                                      <lable className="filter_text fs">
                                        Commission:
                                      </lable>
                                      <div>
                                        <input
                                          name="splitFee"
                                          value={role.splitFee}
                                          onChange={(e) =>
                                            handleUpdateChange(e, index, Subindex)
                                          }
                                          className="filter_select"
                                          type="text"
                                          placeholder="Commision"
                                        ></input>
                                      </div>
                                      {errors.splitFee && (
                                        <p className="error fs-12 mt-10">
                                          {errors.splitFee}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                );
                              
                              
                              })}
                            </div>
                            <div>
                              <button
                                onClick={() => handleUpdateRole(index)}
                                className="role_btn mt-20"
                              >
                                +
                              </button>

                              {
                               counts?.hierarchyDistributions?.length > 1 ? (
                                <div>
                                  <button
                                    onClick={() => handleupdateRoleMinus(index)}
                                    className=" role_btn mt-20"
                                  >
                                    -
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      
                      <div style={{ textAlign: "center" }}>
            <button
              onClick={()=>handleUpdateConfiguration(counts,role)}
              className="userlist_btn mt-20"
              style={{ width: "200px" }}
            >
              Update Configuration
            </button>
          </div>

                      </>
                      
                    );
                  }
                )}

              <div className="flex gap-20">
                <button onClick={handleUpdateRange} className="userlist_btn mt-20">
                  Range+
                </button>

                {state?.confirguration[0]?.priceConfiguration.length > 1 ? (
                  <button
                    onClick={handleUpdateMinusRange}
                    className="userlist_btn mt-20"
                  >
                    Range-
                  </button>
                ) : (
                  ""
                )}
              </div>
         
         <button onClick={handleClick} className="userlist_btn mt-20">
          Skip
         </button>

            </div>
          </div>
          
        </div>
      )}
    </>
  );
};

export default Service;