import React, { useEffect, useRef, useState } from "react";
import "./styles/addCredit.scss";
import "../TicketManagement/style.scss";
import "../NewMember/RoleManagmentAndPermission/style.scss";
import { debitCreditList, getDebitCreditApprove } from "../../utils/api";
import Pagination from "../../Components/Pagination/Pagination";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import { AlertWrapper } from "../../assets/styles/AlerWrapper";
import DatePicker from "react-datepicker";
import { getDateFromDateObj, isEmpty, isNumber } from "../../utils/common";
import { TableWrapper } from "../../Components/styledConstant";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData1 from "../../assets/lotties/question.json";
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";
require("react-datepicker/dist/react-datepicker.css");

const DebitCreditList = ({ permission }) => {
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const [listDebitCredit, setlistDebitCredit] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectStatus, setSelectStatus] = useState("");
  const [transactionMode, setTransactionMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElement] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const [txnType, setTxnType] = useState("");
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [downloadLoader, setDownloadLoader] = useState("");
  const [updatedUserStatus, setUpdatedUserStatus] = useState({});
  const [userStatus, setUserStatus] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showfilter, setShowFilter] = useState("");
  const [merchantId, setmerchantId] = useState("");
  const [minAmont, setMinAmont] = useState("");
  const [maxAmont, setMaxAmont] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [approveId, setApproveId] = useState("");
  const [requestId, setRequestId] = useState("");
  const link = useRef();
  const location = useLocation();
  const userData = location.state;
  const [msg, setMsg] = useState(userData);

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
  };

  let _fromDate = "";
  let _toDate = "";

  if (fromDate !== "" && toDate !== "") {
    _fromDate = getDateFromDateObj(fromDate, "YYYY-MM-DD");
    _toDate = getDateFromDateObj(toDate, "YYYY-MM-DD");
  }

  const handleDebitCreditList = (e, key = "") => {
    setLoading(true);

    const param = {
      transactionType: txnType,
      fromDate: _fromDate,
      toDate: _toDate,
      //  txnId: "string",
      status: selectStatus,
      pagination: {
        pageNo: currPage,
        pageSize: pageSize,
      },
      merchantMobile: merchantId,
      maxAmount: maxAmont,
      minAmount: minAmont,
      transactionMode: transactionMode,
      transactionId: transactionId,
      approverId: approveId,
      requesterId: requestId,
    };

    if (key == "CSV" || key == "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      param.pagination.pageSize = totalElements;
    }
    if (key === "search") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    } else {
      setLoading(true);
    }
    if (key === "refresh") {
      dataRefresh();
      param.fromDate = "";
      param.merchantMobile = "";
      param.transactionType = "";
      param.toDate = "";
      param.minAmount = null;
      param.maxAmount = null;
      param.transactionId = "";
      param.approverId = "";
      param.requesterId = "";
      param.pagination.pageNo = 0;

      setCurrPage(0);
    }
    if (key === "PDF") {
      param.pagination.pageNo = 0;
      setCurrPage(0);
    }
    debitCreditList(param).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {
        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          debitCreditPdfData(resp?.data?.data?.content);
        } else {
          setlistDebitCredit(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElement(resp?.data?.data?.totalElements);
        }
      } else {
        setlistDebitCredit([]);
      }
    });
  };

  const debitCreditPdfData = (data = []) => {
    let _width = 400; // Width of the custom size
    const height = 297;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm", // units: mm, cm, in, px
      format: [_width, height],
      compress: true,
      putOnlyUsedFonts: true,
      precision: 16, // n
    });
    //Add image in PDF header
    const imgWidth = 25; // Adjust the width of the logo
    const imgHeight = 9; // Adjust the height of the logo
    const leftXPos = 14; // Adjust the X position
    const yPos = 2; // Adjust the Y position
    doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "Transaction List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];
    //
    data.forEach((item, index) => {
      const rowData = [
        index + 1,
        item.userId,
        item.txnUuid,
        item.transactionMode,
        item.createdDate,
        item.lastModifiedDate,
        item.transactionType,
        item.reason,
        item.amount,
        item.status,
      ]; // Customize as needed
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [
        [
          "S.No",
          "Merchant Id",
          "Transaction Id",
          "Transaction Mode",
          "Created Date",
          "Modify Date",
          "Type",
          "Reason",
          "Amount",
          "Status",
        ],
      ], // Customize table header
      body: tableData,
    });

    doc.save("DebitCreditList.pdf");
  };

  useEffect(() => {
    handleDebitCreditList();
  }, [selectStatus, currPage, txnType, transactionMode]);

  const handleApprove = (id, status) => {
    let msg = "";
    if (status == "APPROVED") {
      setUserStatus({
        msg: "You want to approve?",
        type: "APPROVE",
        place: "APPROVED",
      });
    } else {
      setUserStatus({ msg: "You want to Reject?", type: "REASON" });
    }

    setUpdatedUserStatus({ id, status });
  };

  const updateUserData = (userStatusUpdate) => {
    getDebitCreditApprove(userStatusUpdate?.id, userStatusUpdate?.status).then(
      (resp) => {
        if (resp?.data?.success && userStatusUpdate?.status == "APPROVED") {
          setShowMessage({
            errormsg: "Approved Successfully",
            type: "SUCCESS",
          });
        } else if (
          resp?.data?.success &&
          userStatusUpdate?.status == "REJECTED"
        ) {
          setShowMessage({ errormsg: "Reject Successfully", type: "REJECTED" });
        } else {
          let errormsg = "";
          if (resp?.data?.errorCodeList?.length > 0) {
            errormsg = resp?.data?.errorCodeList[0];
          } else {
            errormsg = resp?.data?.msg;
          }
          setShowMessage({ errormsg: errormsg, type: "errorbutton" });
        }
      }
    );
  };

  const statusRefessh = () => {
    updateUserData(updatedUserStatus);
    setUserStatus({});
  };

  const refrshSttausData = () => {
    setShowMessage(false);
    setUserStatus({});
    handleDebitCreditList(null);
  };

  const handleStatus = (e) => {
    setSelectStatus(e.target.value);
    setCurrPage(0);
    // handleDebitCreditList(e.target.value)
  };
  const handleTxnType = (e) => {
    setTxnType(e.target.value);
    setCurrPage(0);
  };

  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 5000);
    }
  }, [msg]);

  const dataRefresh = () => {
    setStartDate("");
    setEndDate("");
    setTxnType("");
    setSelectStatus("");
    setTransactionMode("");
    setmerchantId("");
    setMaxAmont("");
    setMinAmont("");
    setTransactionId("");
    setApproveId("");
    setRequestId("");
  };

  const handleDetailData = (e, key = "") => {
    const value = e.target.value;
    const name = e.target.name;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
      if (name === "merchantId") {
        setmerchantId(e.target.value);
      }
      if (name === "minAmont") {
        setMinAmont(e.target.value);
      }
      if (name === "maxAmont") {
        setMaxAmont(e.target.value);
      }
    }

    if (name == "transactionId") {
      setTransactionId(e.target.value);
    }
    if (name == "approveId") {
      setApproveId(e.target.value);
    }
    if (name == "requesteId") {
      setRequestId(e.target.value);
    }
  };

  return (
    <>
      <div>
        {msg && (
          <AlertWrapper className="success globle">
            <span className="close" onClick={() => setMsg("")}>
              ×
            </span>
            {msg}
          </AlertWrapper>
        )}
        <div className="" style={{ padding: "30px" }}>
          <div className="userlist_head">
            <div className="userlist_heading">
              <div className="userlist_left">
                <label>Debit/Credit List</label>
              </div>
              <div className="userlist_right">
                {downloadLoader !== "PDF" ? (
                  <button
                    className="pdf"
                    onClick={(e) => handleDebitCreditList(e, "PDF")}
                  >
                    PDF
                  </button>
                ) : (
                  <button className="pdf">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}

                {downloadLoader !== "CSV" ? (
                  <button
                    className="xls"
                    onClick={(e) => handleDebitCreditList(e, "CSV")}
                  >
                    CSV
                  </button>
                ) : (
                  <button className="xls">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="DebitCreditList.csv"
                ></CSVLink>
              </div>
              <div className="userlist_filter">
                <button
                  className="filter fs-16"
                  onClick={(e) =>
                    showfilter === "active"
                      ? setShowFilter("")
                      : setShowFilter("active")
                  }
                >
                  <i class="fa-solid fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <FilterWrapper
            style={{ height: "auto" }}
            className={`display_data ${showfilter}`}
          >
            <div className="filterBox">
              <div>
                <div className="flex align-items gap-15">
                  <div>
                    <input
                      type="text"
                      name="merchantId"
                      placeholder="Enter merchant id"
                      value={merchantId}
                      onChange={(e) => handleDetailData(e, "number")}
                      maxLength={10}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="minAmont"
                      placeholder="Enter min amount"
                      value={minAmont}
                      onChange={(e) => handleDetailData(e, "number")}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="maxAmont"
                      placeholder="Enter max amount"
                      value={maxAmont}
                      onChange={(e) => handleDetailData(e, "number")}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="transactionId"
                      placeholder="Enter transaction Id"
                      value={transactionId}
                      onChange={(e) => handleDetailData(e, "")}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="approveId"
                      placeholder="Enter approve Id"
                      value={approveId}
                      onChange={(e) => handleDetailData(e, "")}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="requestId"
                      placeholder="Enter request Id"
                      value={requestId}
                      onChange={(e) => handleDetailData(e, "")}
                    />
                  </div>
                  <div>
                    <div className="input-field">
                      <select
                        value={selectStatus}
                        onChange={handleStatus}
                        className="input_select_field"
                      >
                        <option value={""} disabled selected>
                          Select Status
                        </option>
                        <option value="PENDING">PENDING</option>
                        <option value="APPROVED">APPROVED</option>
                        <option value="REJECTED">REJECTED</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <div className="input-field">
                      <select
                        value={txnType}
                        defaultValue="DEBIT"
                        onChange={handleTxnType}
                        className="input_select_field"
                      >
                        <option
                          value={""}
                          onChange={(e) => setTxnType(e.target.value)}
                        >
                          Select Type
                        </option>
                        <option selected value="DEBIT">
                          DEBIT
                        </option>
                        <option value="CREDIT">CREDIT</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <div className="input-field">
                      <select
                        value={transactionMode}
                        className="input_select_field"
                        onChange={(e) => setTransactionMode(e.target.value)}
                      >
                        <option value={""}>Select Mode</option>
                        <option value="VIRTUAL ACCOUNT">VIRTUAL ACCOUNT</option>
                        <option value="UPI PAYMENT">UPI PAYMENT</option>
                        <option value="PREPID CARD">NET BANKING</option>
                        <option value="BBPS">BBPS</option>
                      </select>
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="From Date"
                        selected={fromDate}
                        selectsStart
                        startDate={fromDate}
                        endDate={toDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setStartDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="To Date"
                        selected={toDate}
                        selectsEnd
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setEndDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="button">
                    <button
                      className="userlist_btn"
                      onClick={(e) => handleDebitCreditList(e, "search")}
                    >
                      Search
                    </button>
                  </div>
                  {permission?.includes("CREDIT/DEDUCT") && (
                    <div className="button">
                      <Link to="/addcredit">
                        <button className="userlist_btn">
                          Add Debit/Credit
                        </button>
                      </Link>
                    </div>
                  )}
                  <div className="button">
                    <button
                      className="refresh-btn"
                      onClick={(e) => handleDebitCreditList(e, "refresh")}
                    >
                      <i className="fa fa-refresh"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FilterWrapper>

          {listDebitCredit && (
            <div className="table-loader">
              {loading && <LocalLoader className="tableLoader" />}
              <TableWrapper className="table-card-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Merchant Id</th>
                      <th>Transaction Id</th>
                      <th>Transaction Mode</th>
                      <th>Created Date</th>
                      <th>Modify Date</th>
                      <th>Type</th>
                      <th>Reason</th>
                      <th>Amount</th>
                      <th>Remark</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDebitCredit &&
                      listDebitCredit?.map((item, index) => {
                        const [date, time] = item.createdDate.split("T");
                        let newTime = time.slice(0, 8);

                        const [modifyDate, modifyTime] =
                          item.lastModifiedDate.split("T");
                        let newModifyTime = modifyTime.slice(0, 8);

                        return (
                          <tr key={item.transactionId}>
                            <td className="td-none">
                              <span className="card-th">S.No.</span>
                              <span>{currPage * pageSize + index + 1}</span>
                            </td>

                            <td>
                              <span className="card-th">Merchant Id</span>
                              <span>{item?.userId}</span>
                            </td>

                            <td>
                              <span className="card-th">Transaction Id</span>
                              <span>{item?.txnUuid || "-"}</span>
                            </td>
                            <td>
                              <span className="card-th">Transaction Mode</span>
                              <span>Net Banking</span>
                            </td>
                            <td>
                              <span className="card-th">Created Date</span>
                              <span>{date + " " + newTime}</span>
                            </td>
                            <td>
                              <span className="card-th">Modify Date</span>
                              <span>{modifyDate + " " + newModifyTime}</span>
                            </td>
                            <td>
                              <span className="card-th">Type</span>
                              <span
                                className={`${
                                  item?.transactionType === "DEBIT"
                                    ? " inactive_status"
                                    : "active_status"
                                }`}
                              >
                                {item?.transactionType}
                              </span>
                            </td>
                            <td>
                              <span className="card-th">Reason</span>
                              <span>{item?.reason}</span>
                            </td>
                            <td>
                              <span className="card-th">Amount</span>
                              <span>{item?.amount}</span>
                            </td>
                            <td>
                              <span className="card-th">Remark</span>
                              <span>{item?.remark}</span>
                            </td>
                            <td>
                              <span className="card-th">Status</span>
                              <span
                                className={`${
                                  item?.status === "APPROVED"
                                    ? "active_status"
                                    : "inactive_status"
                                }`}
                              >
                                {" "}
                                {item?.status}
                              </span>
                            </td>

                            {item.status === "PENDING" && (
                              <td>
                                <span className="card-th">Action</span>
                                <span>
                                  {item.status === "PENDING" && (
                                    <div className="flex">
                                      <button
                                        onClick={() =>
                                          handleApprove(
                                            item.txnUuid,
                                            "APPROVED"
                                          )
                                        }
                                        className="btn_action"
                                      >
                                        Approve
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleApprove(
                                            item.txnUuid,
                                            "REJECTED"
                                          )
                                        }
                                        className="btn_action"
                                        style={{ marginLeft: "7px" }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  )}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })}

                    {!loading &&
                      listDebitCredit &&
                      listDebitCredit.length < 1 && (
                        <tr>
                          <td
                            colSpan={6}
                            style={{ textAlign: "center", color: "red" }}
                          >
                            No data found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          )}
        </div>
        <div style={loading ? { opacity: "0" } : { opacity: "1" }}>
          {totalPages > 1 && (
            <Pagination
              items={listDebitCredit}
              itemsPerPage={pageSize}
              setitemsPerPage={setPageSize}
              currentPage={currPage}
              setcurrentPage={setCurrPage}
              totalPages={totalPages}
            />
          )}
        </div>

        {!isEmpty(userStatus) && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                // height: "270px",
              }}
            >
              <div className="mt-20">
                <Lottie
                  options={defaultOptions1}
                  height={80}
                  width={80}
                ></Lottie>
              </div>

              {userStatus.type === "REASON" && (
                <>
                  <div className="flex justify-center">
                    <label className="fs-18 fw-600">{userStatus.msg}</label>
                  </div>
                  <div className="flex justify-center">
                    <input
                      type="text"
                      placeholder="please enter reject reason"
                      style={{ width: "330px", backgroundColor: "#f0f0f0" }}
                    />
                  </div>
                </>
              )}

              {userStatus.type === "APPROVE" && (
                <>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      Are you sure!
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>{userStatus.msg}</label>
                  </div>
                </>
              )}
              <div className="flex justify-center gap-10 mt-30">
                <button
                  onClick={() => setUserStatus({})}
                  className="cancel_button"
                >
                  No, Cancel it
                </button>
                <button
                  onClick={() => statusRefessh()}
                  className="confirm_button"
                >
                  Yes, I am sure!
                </button>
              </div>
            </div>
          </ModalPopUp>
        )}
        {showMessage && (
          <ModalPopUp>
            <div
              className={
                `${showMessage.type} && ${showMessage.type === "errorbutton"}`
                  ? "error-popup"
                  : "modal-popup"
              }
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
              }}
            >
              <>
                {showMessage.type === "SUCCESS" && (
                  <>
                    <div>
                      <Lottie
                        options={defaultOptions2}
                        height={80}
                        width={80}
                      ></Lottie>
                    </div>

                    <div className="flex justify-center ">
                      <label className="fs25 blue_font fw-bold">Done!</label>
                    </div>
                  </>
                )}
                <div
                  className={`flex justify-center ${
                    showMessage.type === "errorbutton" ? "mt-30" : ""
                  }`}
                >
                  <label>{showMessage.errormsg}</label>
                </div>
                <div className="flex justify-center gap-10 mt-16">
                  {showMessage.type === "SUCCESS" && (
                    <button
                      className="confirm_button"
                      onClick={() => refrshSttausData()}
                    >
                      Close
                    </button>
                  )}

                  {(showMessage.type === "errorbutton" ||
                    showMessage.type === "REJECTED") && (
                    <button
                      className="error_button "
                      onClick={() => refrshSttausData("")}
                    >
                      OK
                    </button>
                  )}
                </div>
              </>
            </div>
          </ModalPopUp>
        )}
      </div>
    </>
  );
};
export default DebitCreditList;
