import styled from 'styled-components';

export const ModalWraper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	bottom: 0;
	background-color: #000000c4;
	/* background-color: rgba(0,0,0,.7); */
	display: flex;
	align-items: baseline;
	justify-content: center;
	overflow: auto;
	z-index: 1;
	.modal,
	.modal-success {
		border-radius: 2px;

		background: #fff url(${(props) => props.url}) 50% 0% no-repeat;
		background-size: 400px auto;

		width: ${(props) => (props.width ? props.width : '600px')};
		box-shadow: 0px 1px 99px #0000001a;
		position: relative;
		margin: 50px 0;
		padding: 35px 27px !important;
		.modal-header {
			display: flex;
			.logo {
				p {
					font-size: 10px;
					font-family: sans-serif;
					color: #000000;
					line-height: 15px;
				}
			}
			.buttons button {
				height: 32px;
				padding: 0 10px;
			}
		}
		.modal-title {
			font-size: 25px;
			color: #080f11;
			margin-bottom: 12px;
			h2 {
				margin-bottom: 8px;
				font-size: 20px;
			}
			.amount {
				font-size: 24px;
				color: #005090;
				margin-bottom: 8px;
			}
			.amount-text {
				font-size: 13px;
			}
		}
		.modal-body {
			.txn-daital-h {
				font: normal 600 14px Poppins;
				color: #012d5a;
				margin-bottom: 12px;
				position: relative;
			}
			.txn-desc {
				font-size: 13px;
				font-weight: 500;
				color: #000000;
				.txn-desc-right {
				     
					& > div {
						margin-bottom: 6px;
						display: flex;
						gap: 8px;
						
					}
					.heading {
						display: inline-block;
						width: 110px;
					}
					span{
						display: inline-block;
                        color: #333;
						
					}
				}
			}
				.txn-desc-left {
					//min-width: 220px;
					& > div {
						margin-bottom: 8px;
						display: flex;
						gap: 8px;
					}
					.date {
						text-align: end;
						font-size: 10px;
					}
					span{
					  display: block;
                      color: #333;
					}
				}
			
		}
		.modal-close {
			position: absolute;
			/* top: -16px;
			right: -16px; */
			top: 0;
			right: 0;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			//background: #fff;
		
			padding: 4px;
			z-index: 9;
			//box-shadow: 0 0 5px #aaa;
			cursor: pointer;
			svg{
				transition: all .3s;
			}
		
			&:hover{
				svg{
					fill: red;
					//transform: rotate( 180deg);
				}
			}
		}
	}
	.modal-body-aeps {
		.success {
			color: green;
			font-size: 13px;
			img {
				margin-right: 4px;
				margin-top: 4px;
			}
		}
	}
	.MiniStatementList {
		.txn-daital-h {
			margin-bottom: 4px !important;
		}
		.MiniStatement {
			border: 1px solid #e8e8e8;
			border-radius: 4px;
			height: 150px;
			overflow-y: auto;
			table {
				width: 100%;

				thead tr {
					background-color: #f7f8f9;
					color: #012d5a;
					th {
						padding: 8px;
						text-align: start;
						&:last-child {
							text-align: end;
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 4px 8px;
							&.red {
								color: red;
							}
							&.green {
								color: green;
							}

							&:last-child {
								text-align: end;
							}
						}
					}
				}
			}
		}
	}
	.modal {
		.modal-footer {
			margin-top: 20px;
		}
	}
	@media print {
		background-color: #fff;
		overflow-x: hidden;
		visibility: visible;
		.modal-close {
			display: none;
		}

		.MiniStatement {
			height: auto;
		}
		.buttons button {
			display: none;
		}
	}
`;