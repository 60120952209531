import styled from "styled-components";

export const InvoiceWrapper = styled.div`
.invoiceHeader{
    max-width: 94%;
    margin: auto;
}
  .invoiceWrapper {
    max-width: 600px;
    margin: 20px auto;
    padding: 35px 27px;
    background: #fff;
    box-shadow: 0px 2px 3px rgba(0, 41, 112, 0.0509803922);
    border: 1px solid rgba(0, 41, 112, 0.1411764706);
    border-radius: 10px;
    opacity: 1;

    .logo {
      & > div {
        line-height: 13px;
        font-size: 10px;
      }
    }
  }
`;
