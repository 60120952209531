import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/verification_code.json";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { aepsVerifyOtp,verificationAadhar } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { isEmpty } from "../../utils/common";

const OtpVerification = () => {
 
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendAgainParam, setSendAgainParam] = useState({});
  const [timer, setTimer] = useState(30);
  const location = useLocation();
  const navigate = useNavigate();

  const responsedata = location.state;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  //get OTP

  const sendOTP = async (e) => {
    let param;
    if(isEmpty(sendAgainParam)){
      param = {
        mobile: responsedata.userName,
        otp: otp,
        hash: responsedata.hash,
        otpReferenceID: responsedata.otpReferenceId,
      };
    }else{
      param = sendAgainParam;
    }
    

    setLoading(true);
    aepsVerifyOtp(param).then((resp) => {
      setLoading(false);
      if (resp.data.success === true) {
        Swal.fire({
          icon: "success",
          text: resp.data.msg,
          confirmButtonColor: "#34ed1c",
        });
        navigate("/usersList");
      } else {
        let msg = "";
        if (resp?.data?.errorCodeList.length > 0) {
          msg = resp?.data?.errorCodeList[0];
        } else {
          msg = resp?.data?.msg;
        }
        Swal.fire({
          icon: "error",
          text: msg,
          confirmButtonColor: "#ED1C24",
        });
      }
    });

  };

 

  const sendAgainOtp =()=>{
    setTimer(30);
    otp_timer();

    verificationAadhar(responsedata?.otpSendAgain).then((resp) => {
      setLoading(false);
      if (resp.data.success === true) {
        setLoading(false);
        setSendAgainParam({
          userName: responsedata.userName,
            hash: resp?.data?.data?.hash,
            otpReferenceId: resp?.data?.data?.otpReferenceID,
        })
      }
    });
  }

  
  
 const otp_timer = () =>{
  const myTimeout = setTimeout(() =>{
    if(timer > 0){
      setTimer(prevTimer => prevTimer - 1);
    otp_timer();
    }else{
      clearTimeout(myTimeout);
    }

  },1000)

 }
 
 useEffect(() =>{
  otp_timer();
 },[])

 
  return (
    <div className="main_container">
      <div className="container1">
        <label>Create New Retailer</label>
      </div>

      <div className="container_otp">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round round-addBank">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable">Bank Detail</div>
          </div>
          <div className="round round-verification">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable">ID Verification</div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">Otp Verification</div>
          </div>
        </div>

        <div className="container">
          <div className="container_lottie">
            <Lottie options={defaultOptions} height={190} width={190}></Lottie>
          </div>
        </div>
        <div className="container">
          <div className="msg">
            <label
              style={{ width: "100%", fontSize: "15px", marginLeft: "75px%" }}
            >
              please enter the otp sent to your mobile
            </label>
          </div>
          <div className="msg">
            {/* <span style={{marginLeft:"45%"}}
                    > {username}</span> */}
          </div>
        </div>
        <div className="container">
          <div className="otp_container">
            <OtpInput
              value={otp}
              inputStyle={{
                width: "50px",
                height: "50px",
                background: " #c8c7c7 0% 0% no-repeat padding-box",
                border: "5px",
                opacity: 1,
               
              }}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div className="container send-again-otp">
          <div className="timer">
           <p>Didn't get otp | {(timer >1)?<span>Resend in: {timer}</span>:
           <button onClick={sendAgainOtp}>Resend Otp</button>} </p>
                    
          </div>
        </div>
        <div className="container">
          
          <div className="create_container">
            <button
              className="submit_btn otp-button"
              onClick={() => {
                sendOTP();
                // navigate('/fingerprintmsg')
              }}
            >
              Sumbit
            </button>
          </div>
        </div>
      </div>
      {loading && <GloabalLoader />}
    </div>
  );
};
export default OtpVerification;
