import React, { useEffect, useRef, useState } from "react";
//import "./styles/addCredit.scss";
import "../TicketManagement/style.scss";
import "../NewMember/RoleManagmentAndPermission/style.scss";
import { debitCreditList, getDebitCreditApprove } from "../../utils/api";
import Pagination from "../../Components/Pagination/Pagination";
import Swal from "sweetalert2";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import { AlertWrapper } from "../../assets/styles/AlerWrapper";
import DatePicker from "react-datepicker";
import { getDateFromDateObj, isEmpty, isNumber } from "../../utils/common";
import { TableWrapper } from "../../Components/styledConstant";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp";
import modalpopup from "../../assets/images/modalPopubg.svg";
import animationData1 from "../../assets/lotties/question.json";
import animationData2 from "../../assets/lotties/done_msg.json";
import Lottie from "react-lottie";
require("react-datepicker/dist/react-datepicker.css");

const ManageFundRequest = ({ permission }) => {
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const [listDebitCredit, setlistDebitCredit] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [errors, setErrors] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectStatus, setSelectStatus] = useState("");
  const [transactionMode, setTransactionMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElement] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const [txnType, setTxnType] = useState("");
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [downloadLoader, setDownloadLoader] = useState("");
  const [updatedUserStatus, setUpdatedUserStatus] = useState({});
  const [userStatus, setUserStatus] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showfilter, setShowFilter] = useState("");
  const [merchantId, setmerchantId] = useState("");
  const [minAmont, setMinAmont] = useState("");
  const [maxAmont, setMaxAmont] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [approveId, setApproveId] = useState("");
  const [requestId, setRequestId] = useState("");
  const navigate = useNavigate();
  const link = useRef();
  const location = useLocation();
  const userData = location.state;
  const [msg, setMsg] = useState(userData);

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
  };

  let _fromDate = "";
  let _toDate = "";

  if (fromDate !== "" && toDate !== "") {
    _fromDate = getDateFromDateObj(fromDate, "YYYY-MM-DD");
    _toDate = getDateFromDateObj(toDate, "YYYY-MM-DD");
  }

  const handleDebitCreditList = (e, key = "") => {};

  useEffect(() => {
    handleDebitCreditList();
  }, [selectStatus, currPage, txnType, transactionMode]);

  const handleApprove = (id, status) => {
    let msg = "";
    if (status == "APPROVED") {
      setUserStatus({
        msg: "You want to approve?",
        type: "APPROVE",
        place: "APPROVED",
      });
    } else {
      setUserStatus({ msg: "You want to Reject?", type: "REASON" });
    }

    setUpdatedUserStatus({ id, status });
  };

  const updateUserData = (userStatusUpdate) => {
    getDebitCreditApprove(userStatusUpdate?.id, userStatusUpdate?.status).then(
      (resp) => {
        if (resp?.data?.success && userStatusUpdate?.status == "APPROVED") {
          setShowMessage({
            errormsg: "Approved Successfully",
            type: "SUCCESS",
          });
        } else if (
          resp?.data?.success &&
          userStatusUpdate?.status == "REJECTED"
        ) {
          setShowMessage({ errormsg: "Reject Successfully", type: "REJECTED" });
        } else {
          let errormsg = "";
          if (resp?.data?.errorCodeList?.length > 0) {
            errormsg = resp?.data?.errorCodeList[0];
          } else {
            errormsg = resp?.data?.msg;
          }
          setShowMessage({ errormsg: errormsg, type: "errorbutton" });
        }
      }
    );
  };

  const statusRefessh = () => {
    updateUserData(updatedUserStatus);
    setUserStatus({});
  };

  const refrshSttausData = () => {
    setShowMessage(false);
    setUserStatus({});
    handleDebitCreditList(null);
  };

  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 5000);
    }
  }, [msg]);

  return (
    <>
      <div>
        {msg && (
          <AlertWrapper className="success globle">
            <span className="close" onClick={() => setMsg("")}>
              ×
            </span>
            {msg}
          </AlertWrapper>
        )}
        <div className="" style={{ padding: "30px" }}>
          <div className="userlist_head">
            <div className="userlist_heading">
              <div className="userlist_left">
                <label>Manage Vendor Fund Request</label>
              </div>
              <div className="userlist_right">
                {downloadLoader !== "PDF" ? (
                  <button
                    className="pdf"
                    onClick={(e) => handleDebitCreditList(e, "PDF")}
                  >
                    PDF
                  </button>
                ) : (
                  <button className="pdf">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}

                {downloadLoader !== "CSV" ? (
                  <button
                    className="xls"
                    onClick={(e) => handleDebitCreditList(e, "CSV")}
                  >
                    CSV
                  </button>
                ) : (
                  <button className="xls">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="manage-vendor-fund-request.csv"
                ></CSVLink>
              </div>
              <div className="userlist_filter">
                <button
                  className="filter fs-16"
                  onClick={(e) =>
                    showfilter === "active"
                      ? setShowFilter("")
                      : setShowFilter("active")
                  }
                >
                  <i class="fa-solid fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <FilterWrapper
            style={{ height: "auto" }}
            className={`display_data ${showfilter}`}
          >
            <div className="filterBox">
              <div>
                <div className="flex align-items gap-15">
                  <div>
                    <div className="input-field">
                      <select
                        value={transactionMode}
                        className="input_select_field"
                        onChange={(e) => setTransactionMode(e.target.value)}
                      >
                        <option value={""}>Select Mode</option>
                        <option value="VIRTUAL ACCOUNT">VIRTUAL ACCOUNT</option>
                        <option value="UPI PAYMENT">UPI PAYMENT</option>
                        <option value="PREPID CARD">NET BANKING</option>
                        <option value="BBPS">BBPS</option>
                      </select>
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="From Date"
                        selected={fromDate}
                        selectsStart
                        startDate={fromDate}
                        endDate={toDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setStartDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="flex">
                      <DatePicker
                        showIcon
                        className="date-picker2 date_type"
                        placeholderText="To Date"
                        selected={toDate}
                        selectsEnd
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => setEndDate(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="button">
                    <button className="userlist_btn" onClick={(e) => {}}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FilterWrapper>

          {listDebitCredit && (
            <div className="table-loader">
              {loading && <LocalLoader className="tableLoader" />}
              <TableWrapper className="table-card-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Merchant Id</th>
                      <th>Transaction Id</th>
                      <th>Transaction Mode</th>
                      <th>Created Date</th>
                      <th>Modify Date</th>
                      <th>Type</th>
                      <th>Reason</th>
                      <th>Amount</th>
                      <th>Remark</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDebitCredit &&
                      listDebitCredit?.map((item, index) => {
                        const [date, time] = item.createdDate.split("T");
                        let newTime = time.slice(0, 8);

                        const [modifyDate, modifyTime] =
                          item.lastModifiedDate.split("T");
                        let newModifyTime = modifyTime.slice(0, 8);

                        return (
                          <tr key={item.transactionId}>
                            <td className="td-none">
                              <span className="card-th">S.No.</span>
                              <span>{currPage * pageSize + index + 1}</span>
                            </td>

                            <td>
                              <span className="card-th">Merchant Id</span>
                              <span>{item?.userId}</span>
                            </td>

                            <td>
                              <span className="card-th">Transaction Id</span>
                              <span>{item?.txnUuid || "-"}</span>
                            </td>
                            <td>
                              <span className="card-th">Transaction Mode</span>
                              <span>Net Banking</span>
                            </td>
                            <td>
                              <span className="card-th">Created Date</span>
                              <span>{date + " " + newTime}</span>
                            </td>
                            <td>
                              <span className="card-th">Modify Date</span>
                              <span>{modifyDate + " " + newModifyTime}</span>
                            </td>
                            <td>
                              <span className="card-th">Type</span>
                              <span
                                className={`${
                                  item?.transactionType === "DEBIT"
                                    ? " inactive_status"
                                    : "active_status"
                                }`}
                              >
                                {item?.transactionType}
                              </span>
                            </td>
                            <td>
                              <span className="card-th">Reason</span>
                              <span>{item?.reason}</span>
                            </td>
                            <td>
                              <span className="card-th">Amount</span>
                              <span>{item?.amount}</span>
                            </td>
                            <td>
                              <span className="card-th">Remark</span>
                              <span>{item?.remark}</span>
                            </td>
                            <td>
                              <span className="card-th">Status</span>
                              <span
                                className={`${
                                  item?.status === "APPROVED"
                                    ? "active_status"
                                    : "inactive_status"
                                }`}
                              >
                                {" "}
                                {item?.status}
                              </span>
                            </td>

                            {item.status === "PENDING" && (
                              <td>
                                <span className="card-th">Action</span>
                                <span>
                                  {item.status === "PENDING" && (
                                    <div className="flex">
                                      <button
                                        onClick={() =>
                                          handleApprove(
                                            item.txnUuid,
                                            "APPROVED"
                                          )
                                        }
                                        className="btn_action"
                                      >
                                        Approve
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleApprove(
                                            item.txnUuid,
                                            "REJECTED"
                                          )
                                        }
                                        className="btn_action"
                                        style={{ marginLeft: "7px" }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  )}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })}

                    {!loading &&
                      listDebitCredit &&
                      listDebitCredit.length < 1 && (
                        <tr>
                          <td
                            colSpan={6}
                            style={{ textAlign: "center", color: "red" }}
                          >
                            No data found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          )}
        </div>
        <div style={loading ? { opacity: "0" } : { opacity: "1" }}>
          {totalPages > 1 && (
            <Pagination
              items={listDebitCredit}
              itemsPerPage={pageSize}
              setitemsPerPage={setPageSize}
              currentPage={currPage}
              setcurrentPage={setCurrPage}
              totalPages={totalPages}
            />
          )}
        </div>

        {!isEmpty(userStatus) && (
          <ModalPopUp>
            <div
              className="modal-popup"
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                // height: "270px",
              }}
            >
              <div className="mt-20">
                <Lottie
                  options={defaultOptions1}
                  height={80}
                  width={80}
                ></Lottie>
              </div>

              {userStatus.type === "REASON" && (
                <>
                  <div className="flex justify-center">
                    <label className="fs-18 fw-600">{userStatus.msg}</label>
                  </div>
                  <div className="flex justify-center">
                    <input
                      type="text"
                      placeholder="please enter reject reason"
                      style={{ width: "330px", backgroundColor: "#f0f0f0" }}
                    />
                  </div>
                </>
              )}

              {userStatus.type === "APPROVE" && (
                <>
                  <div className="flex justify-center ">
                    <label className="fs25 blue_font fw-bold">
                      Are you sure!
                    </label>
                  </div>
                  <div className="flex justify-center">
                    <label>{userStatus.msg}</label>
                  </div>
                </>
              )}
              <div className="flex justify-center gap-10 mt-30">
                <button
                  onClick={() => setUserStatus({})}
                  className="cancel_button"
                >
                  No, Cancel it
                </button>
                <button
                  // onClick={() => setUpdatedUserStatus(true)}
                  onClick={() => statusRefessh()}
                  className="confirm_button"
                >
                  Yes, I am sure!
                </button>
              </div>
            </div>
          </ModalPopUp>
        )}
        {showMessage && (
          <ModalPopUp>
            <div
              className={
                `${showMessage.type} && ${showMessage.type === "errorbutton"}`
                  ? "error-popup"
                  : "modal-popup"
              }
              style={{
                backgroundImage: `url(${modalpopup})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "bottom",
                // height:"270px"
              }}
            >
              <>
                {showMessage.type === "SUCCESS" && (
                  <>
                    <div>
                      <Lottie
                        options={defaultOptions2}
                        height={80}
                        width={80}
                      ></Lottie>
                    </div>

                    <div className="flex justify-center ">
                      <label className="fs25 blue_font fw-bold">Done!</label>
                    </div>
                  </>
                )}
                <div
                  className={`flex justify-center ${
                    showMessage.type === "errorbutton" ? "mt-30" : ""
                  }`}
                >
                  <label>{showMessage.errormsg}</label>
                </div>
                <div className="flex justify-center gap-10 mt-16">
                  {showMessage.type === "SUCCESS" && (
                    <button
                      className="confirm_button"
                      onClick={() => refrshSttausData()}
                    >
                      Close
                    </button>
                  )}

                  {(showMessage.type === "errorbutton" ||
                    showMessage.type === "REJECTED") && (
                    <button
                      className="error_button "
                      onClick={() => refrshSttausData("")}
                    >
                      OK
                    </button>
                  )}
                </div>
              </>
            </div>
          </ModalPopUp>
        )}
      </div>
    </>
  );
};
export default ManageFundRequest;
