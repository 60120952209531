import React, { useState } from "react";
import "../Wallet/styles/addCredit.scss";
import "../TicketManagement/style.scss";
import "../NewMember/RoleManagmentAndPermission/style.scss";
import { createDebitCredit, searchUser } from "../../utils/api";
import {
  isAlphabet,
  isEmpty,
  isNumber,
  numberToWords,
} from "../../utils/common";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const PaymentReceive = () => {
  const initialValues = {
    userName: "",
    amount: "",
    remark: "",
    test: "",
  };
  const [debitCredit, setDebitCredit] = useState({ ...initialValues });
  const [errors, setErrors] = useState("");

  const [selectValue, setSelectValue] = useState("DEPOSIT");

  const [reason, setReason] = useState("");
  const [search, setSearch] = useState({});
  const navigate = useNavigate();
  let name, value;

  const handleInput = (e, key = "") => {
    name = e.target.name;
    value = e.target.value;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }
    if (name === "amount") {
      const regex = /^\d*\.?\d*$/;

      if (!regex.test(e.target.value)) {
        return;
      }
    }

    if (name === "userName" && value.length == 10) {
      searchUserData(value);
    }
    //  setSearch("")

    setDebitCredit({ ...debitCredit, [name]: value });
    setErrors((prev) => ({
      ...prev,
      userName: "",
    }));
  };

  const handleSubmit = () => {
    let error = {};
    if (isEmpty(debitCredit.amount)) {
      error.amount = "please Enter some Amount";
    }
    if (isEmpty(debitCredit.userName)) {
      error.userName = "please enter your username";
    } else if (debitCredit.userName.length < 10) {
      error.userName = "please enter 10 digit Mobile Number";
    } else {
      searchUserData(debitCredit.userName);
    }
    setErrors(error);
  };

  const searchUserData = (value) => {
    const valueRequired = true;
    searchUser(value, valueRequired).then((resp) => {
      if (resp?.data?.success) {
        setSearch(resp?.data?.data);

        if (
          !isEmpty(debitCredit.userName) &&
          debitCredit.userName.length === 10 &&
          !isEmpty(debitCredit.amount)
        ) {
          const params = {
            remark: debitCredit.remark,
            amount: debitCredit.amount,
            userId: debitCredit.userName,
            reason: reason,
            transactionType: selectValue,
            status: "SUCCESS",
            approvedBy: "",
            txnUuid: "",
          };
          Swal.fire({
            icon: "success",
            title:
              "Are You Sure ! You Want to " +
              [selectValue] +
              " " +
              [debitCredit.amount] +
              " Rs." +
              " " +
              " " +
              "</br>" +
              "Number: " +
              [debitCredit.userName],
            // timer: 2200,
            confirmButtonColor: "#00BAF2",
            showCancelButton: true,
          }).then((resp) => {
            if (resp.isConfirmed) {
              return createDebitCredit(params).then((response) => {
                if (response?.data?.success) {
                  navigate("/DebitCreditList", { state: response?.data?.msg });
                }
              });
            }
          });
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          userName: "No User Found",
        }));
        setSearch("");
      }
    });
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <div className="subadmin_main_container">
        <div
          className="subadmin_head flex space-between"
          style={{ width: "97%" }}
        >
          <strong>Deposit</strong>
        </div>

        <div className="subadmin_container">
          <div className="">
            <div className="pd-30">
              <div>
                <lable className="filter_text fs">UserName</lable>
                <div className="flex align-items form-container">
                  <div className="mg-top3">
                    <div className="mg-top3">
                      <input
                        type="text"
                        name="userName"
                        value={debitCredit.userName}
                        className="filter_select  fs"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => handleInput(e, "number")}
                        maxLength={10}
                      ></input>
                    </div>
                    {errors.userName && (
                      <p className="error fs-12 mt-10">{errors.userName}</p>
                    )}
                  </div>
                  {!isEmpty(search) && debitCredit.userName.length === 10 && (
                    <div className="flex row">
                      <lable className=" flex column fs-12 ">
                        Name:
                        <span className="blue_font fw-bold">
                          {search.firstName + " " + search.lastName}
                        </span>
                      </lable>
                      <label className="profile ml-15"></label>
                      <lable className="fs-12 flex column ml-15">
                        User Wallet Balance:
                        <span className=" fw-bold blue_font ml-5">
                          ₹ {search.mainBalance}
                        </span>
                      </lable>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ maxWidth: "fit-content" }}>
                <div className="flex form-container mg_top17">
                  <div>
                    <lable className="filter_text fs">Transaction Mode:</lable>
                    <div className="mg-top3">
                      <select
                        defaultValue="DEBIT"
                        className="filter_select fs  pd-10"
                      >
                        <option>TRANSACTION Mode</option>
                        <option selected value="DEBIT">
                          NET BANKING
                        </option>
                        <option value="CREDIT">CASH</option>
                        <option value="CREDIT">CARD PAYMENT</option>
                        <option value="CREDIT">CREDIT LIMIT</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <lable className="filter_text fs">Transaction Type</lable>
                    <div className="mg-top3 ">
                      <select
                        onChange={(e) => setSelectValue(e.target.value)}
                        className="filter_select fs pd-10"
                      >
                        <option value={"DEPOSIT"} disabled selected>
                          Deposit
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex form-container mg_top17">
                  <div className="flex gap-20">
                    <div className="m">
                      <lable className="filter_text fs">Select Reason</lable>
                      <div className="mg-top3">
                        <select
                          onChange={handleReason}
                          className="filter_select fs  pd-10"
                        >
                          <option disabled selected>
                            SELECT REASON
                          </option>
                          <option>SETTLEMENT CREDIT</option>
                          <option>SETTLEMENT DEBIT</option>
                          <option>DISPUTE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-20">
                    <div>
                      <lable className="filter_text fs">Amount</lable>
                      <div className="mg-top3">
                        <input
                          type="text"
                          name="amount"
                          value={debitCredit.amount}
                          onChange={(e) => handleInput(e)}
                          className="filter_select fs"
                          maxLength={6}
                        ></input>
                      </div>
                      <label className="fw-500">
                        {numberToWords(debitCredit.amount)}
                      </label>
                      {errors.amount && (
                        <p className="error fs-12 mt-10">{errors.amount}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <lable className="filter_text fs">Remark</lable>
                  <div className="mg-top3">
                    <input
                      name="remark"
                      value={debitCredit.remark}
                      onChange={(e) => handleInput(e, "")}
                      type="text"
                      className="filter_select"
                      style={{ width: "100%" }}
                    ></input>
                  </div>
                </div>
                <div>
                  <button
                    onClick={handleSubmit}
                    style={{
                      width: "100%",
                      height: "45px",
                      marginTop: "25px",
                      marginBottom: "20px",
                    }}
                    className="userlist_btn"
                  >
                    Proccess
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentReceive;
