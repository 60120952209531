import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Input from "../../Components/Input/Input";
import { useNavigate } from "react-router-dom";

import { addUser, searchUser } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import {
  IndianStates,
  allStatesInIndia,
  getDateFromDateObj,
  isAlphabet,
  isEmpty,
  isNumber,
} from "../../utils/common";
import DatePicker from "react-datepicker";
import { AlertWrapper } from "../../Components/Modal/style";
import { useSelector } from "react-redux";

require("react-datepicker/dist/react-datepicker.css");

const AddUserForm = () => {
  const initialValues = {
    role: "",
    authorities: [],
    tenantId: 0,
    qrCodeId: "",
    address1: "",
    address2: "",
    dob: "",
    phoneNumber: "",
    email: "",
    firstName: "",
    landmark: "",
    langKey: "en",
    lastName: "",
    // password: "",
    pincode: "",
    userName: "",
    screenCode: null,
    userType: "",
  };
  const [userData, setUserData] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(null);
  const [rasponseData, setResponseData] = useState({});
  const [message, setMessage] = useState({});
  const [confirmMsg, setConfirm] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [loginUserType, setLoginUserTye] = useState("");
  const [state, setState] = useState("");
  const [districts, setDistricts] = useState("");
  const [districtsList, setDistrictsList] = useState([]);

  const navigate = useNavigate();

  //DatePicker
  var maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleFormData = async (e) => {
    userData.dob = getDateFromDateObj(date, "DD/MM/YYYY");

    let error = {};
    if (userData.userType === "") {
      error.userType = "Please select user type";
    }
    if (userData.userName === "") {
      error.userName = "Please enter your username";
    } else if (userData.userName.length < 10) {
      error.userName = "Username can't be less than 10 digits";
    }
    if (userData.firstName === "") {
      error.firstName = "Please enter your first name";
    }
    if (userData.lastName === "") {
      error.lastName = "Please enter your last name";
    }

    if (userData.dob === "Invalid date") {
      error.dob = "Please enter your date of birth";
    } else {
      const date1 = new Date();
      const date2 = new Date(date);
      const timeDifferenceMs = date1 - date2;
      const daysDifference = timeDifferenceMs / (365.25 * 24 * 60 * 60 * 1000);
      if (daysDifference < 18) {
        error.dob = "User's age can't be less than 18.";
      } else if (daysDifference > 60) {
        error.dob = "User's age can't be greater than 60.";
      }
    }

    if (userData.phoneNumber === "") {
      error.phoneNumber = "Please enter your phone number";
    } else if (userData.phoneNumber.length < 10) {
      error.phoneNumber = "Phone number can't be less than 10 digits";
    }
    if (userData.email === "") {
      error.email = "Please enter your email";
    } else if (!isValidEmail(userData.email)) {
      error.email = "Please enter valid email";
    }

    if (userData.address1 === "") {
      error.address1 = "Please enter your houseno/building name.";
    } else if (userData.address1.length < 5) {
      error.address1 = "Houseno/Buildingname can't be less than 5 letters";
    }

    if (state === "") {
      error.state = "Please select state";
    }

    if (userData.pincode === "") {
      error.pincode = "Please enter your pincode";
    } else if (userData.pincode.length < 6) {
      error.pincode = "Pincode can't be less than 6 digits";
    }

    if (districts === "") {
      error.districts = "Please select district";
    }

    setErrors(error);

    if (isEmpty(error)) {
      setDisableBtn(true);
      setLoading(true);
      const addr = `${userData.address2} ,${state}, ${districts}`;
      const params = userData;
      params.address2 = addr;
      addUser(params).then((resp) => {
        if (resp?.data?.success === true) {
          setConfirm({
            msg: "User registered successfully.",
            screenCode: "",
            type: "ok",
            no: false,
          });
          setMessage({ msg: "User registered successfully.", type: "success" });

          if (resp?.data?.data.userType !== "ROLE_RETAILER") {
            setTimeout(() => {
              navigate("/usersList");
              setDisableBtn(false);
              setLoading(false);
            }, 2000);
          } else {
            setTimeout(() => {
              navigate("/select-package", { state: resp?.data?.data });
              setDisableBtn(false);
              setLoading(false);
            }, 2200);
          }
        } else {
          setLoading(false);
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }
          setMessage({ msg: msg, type: "error" });
          setDisableBtn(false);
        }
      });
    }
  };

  const handleInput = (e, key = "") => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setErrors({});
    if (key === "number") {
      value = e.target.value.trim();
    }

    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }
    if (name === "userType") {
      let arr = [];
      arr.push(value);
      const _userData = userData;
      _userData.authorities = arr;
      setUserData(_userData);
    }

    setUserData({ ...userData, [name]: value });
  };

  const checkStatus = () => {
    if (isEmpty(userData.userName)) {
      setErrors((prev) => ({
        ...prev,
        userName: "Please enter your username",
      }));
      setConfirm("");
      return;
    } else if ((userData?.userName).length < 10) {
      setErrors((prev) => ({
        ...prev,
        userName: "Username can't be less than 10 digit",
      }));
      setConfirm("");

      return;
    } else {
      setLoading(true);
      searchUser(userData.userName).then((resp) => {
        if (resp?.data?.success) {
          setResponseData(resp?.data?.data);
          setLoading(false);

          const typeUser =
            resp?.data?.data?.userType === "ROLE_RETAILER"
              ? "Retailer"
              : resp?.data?.data?.userType === "ROLE_ADMIN"
              ? "Admin"
              : resp?.data?.data?.userType === "ROLE_SUBADMIN"
              ? "Sub Admin"
              : resp?.data?.data?.userType === "ROLE_DISTRIBUTOR"
              ? "Distributor"
              : "";
          if (resp?.data?.data?.userType !== "ROLE_RETAILER") {
            setConfirm({
              msg: "This username is already registered as " + typeUser + ".",
              screenCode: resp?.data?.data?.screenCode,
              type: "ok",
            });

            return;
          }
          if (resp?.data?.data?.screenCode === "USER_REGISTRATION") {
            setConfirm({
              msg: `Package selection are pending. Click "YES" for complete process.`,
              screenCode: resp?.data?.data?.screenCode,
              type: "redirect",
              no: true,
            });
          } else if (resp?.data?.data?.screenCode === "AEPS_ONBOARDING") {
            setConfirm({
              msg: "This username is already registered as " + typeUser + ".",
              screenCode: resp?.data?.data?.screenCode,
              type: "ok",
            });
          } else if (resp?.data?.data?.screenCode === "USER_KYC") {
            setConfirm({
              msg: `Otp verification is pending. Click "YES" for complete process.`,
              screenCode: resp?.data?.data?.screenCode,
              type: "redirect",
              no: true,
            });
          } else if (resp?.data?.data?.screenCode === "PACKAGE_ASSIGNED") {
            setConfirm({
              msg: `Pending Bank Details. Click "YES" for complete process.`,
              //  msg: "This user  select package are already done you can otp verification for user onboarding",
              screenCode: resp?.data?.data?.screenCode,
              type: "redirect",
              no: true,
            });
          }
        } else {
          setLoading(false);
          setConfirm({
            msg: "User not found you can register this user.",
            screenCode: "",
            type: "ok",
            no: false,
          });
        }
      });
    }
  };

  const confirmHandler = (screenCode, type = "") => {
    if (type === "redirect") {
      const userData = {
        email: rasponseData.email,
        userName: rasponseData.userName,
        uuid: rasponseData.uuid,
      };
      if (screenCode === "USER_REGISTRATION") {
        navigate("/select-package", { state: userData });
      } else if (screenCode === "USER_KYC") {
        navigate("/Verification", { state: { userData: userData, skip: "" } });
      } else if (screenCode === "PACKAGE_ASSIGNED") {
        navigate("/bankdetails", { state: userData });
      }
    }

    if (type === "yes") {
      setConfirm({});
    }
  };
  useEffect(() => {
    if (!isEmpty(message)) {
      setTimeout(() => {
        setMessage({});
      }, 4000);
    }
  });

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (loginUserType === "") {
      setLoginUserTye(user?.userType);
    }
  }, [user]);

  const stateHandler = (e) => {
    setErrors({});
    setState(e.target.value);

    IndianStates.map((val) => {
      if (val?.name === e.target.value) {
        setDistrictsList(val.districts);
      }
    });
  };

  return (
    <>
      {!isEmpty(message) && (
        <AlertWrapper className={`${message.type} globle`}>
          <span className="close" onClick={() => setMessage({})}>
            ×
          </span>
          {message.msg}
        </AlertWrapper>
      )}
      <div className="main_container">
        <div className="container1">
          {/* <label>Create New Retailer</label> */}
          <label>Create New User</label>
        </div>
        <div
          className={`container_form ${
            userData.userType !== "ROLE_RETAILER" && ""
          }`}
        >
          {userData.userType === "ROLE_RETAILER" && (
            <div className="container_progress1">
              <div className="round">
                <div>
                  <input type="checkbox" checked id="checkbox" />
                  <label htmlFor="checkbox" className="active"></label>
                </div>
                <div className="bar bar-1" />
                <div className="lable">Retailer Detail</div>
              </div>
              <div className="round">
                <div>
                  <input type="checkbox" checked id="checkbox" />
                  <label htmlFor="checkbox"></label>
                </div>
                <div className="bar bar-2" />
                <div className="lable">Select Package</div>
              </div>
              <div className="round">
                <div>
                  <input type="checkbox" checked id="checkbox" />
                  <label htmlFor="checkbox"></label>
                </div>
                <div className="bar bar-3" />
                <div className="lable">Bank Detail</div>
              </div>{" "}
              <div className="round">
                <div>
                  <input type="checkbox" checked id="checkbox" />
                  <label htmlFor="checkbox"></label>
                </div>
                <div className="bar bar-4" />
                <div className="lable lable-last">ID Verification</div>
              </div>
            </div>
          )}
          <div className="shadowBox">
            <div className="">
              <div className="">
                <h3>Personal Details</h3>
              </div>
            </div>

            <div className="role role_responsive">
              <div className="">
                <div className="labelname">
                  <label>User Type</label>
                  <span className="required"> *</span>
                </div>
                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={userData?.userType}
                    name="userType"
                    onChange={handleInput}
                  >
                    <option value={""}>User Type</option>
                    {loginUserType === "ROLE_ADMIN" && (
                      <>
                        <option value="ROLE_ADMIN">Admin</option>
                        <option value="ROLE_SUBADMIN">SubAdmin</option>
                      </>
                    )}
                    {(loginUserType === "ROLE_ADMIN" ||
                      loginUserType === "ROLE_SUBADMIN") && (
                      <option value="ROLE_DISTRIBUTOR">Distributor</option>
                    )}
                    <option value="ROLE_RETAILER">Retailer</option>
                  </select>
                  {errors.userType && (
                    <p className="error fs-12 mt-10">{errors.userType}</p>
                  )}
                </div>
              </div>
              <div className="">
                <div className="labelname">
                  <label>Username</label>
                  <span className="required"> *</span>
                </div>
                <Input
                  type="text"
                  maxLength={10}
                  placeholder="Enter your registered mobile number"
                  name="userName"
                  value={userData?.userName}
                  onChange={(e) => handleInput(e, "number")}
                  className="searchUser"
                  click={checkStatus}
                />
                {errors.userName && (
                  <p className="error fs-12 mt-10">{errors.userName}</p>
                )}
              </div>
            </div>
            {!isEmpty(confirmMsg) && (
              <div className="confirmBox flex justify-end">
                <div
                  className={`confirmText ${confirmMsg?.error ? "error" : ""}`}
                >
                  {confirmMsg.msg}
                </div>
                <div className="confirmButton flex">
                  {confirmMsg.type === "redirect" ? (
                    <button
                      onClick={() =>
                        confirmHandler(confirmMsg?.screenCode, "redirect")
                      }
                    >
                      Yes
                    </button>
                  ) : confirmMsg.type === "ok" ? (
                    <button onClick={() => confirmHandler("", "yes")}>
                      OK
                    </button>
                  ) : (
                    ""
                  )}
                  {confirmMsg.no && (
                    <button onClick={() => setConfirm({})}>No</button>
                  )}
                </div>
              </div>
            )}

            <div className="role role_responsive">
              <div className="retailer">
                <div className="labelname">
                  <label>First Name</label>
                  <span className="required"> *</span>
                </div>
                <div>
                  <Input
                    className="inputtype"
                    type="text"
                    placeholder="Enter your first name"
                    name="firstName"
                    value={userData.firstName}
                    onChange={(e) => handleInput(e, "text")}
                  />
                  {errors.firstName && (
                    <p className="error fs-12 mt-10">{errors.firstName}</p>
                  )}
                </div>
              </div>
              <div className="retailer">
                <div className="labelname">
                  <label>Last Name</label>
                  <span className="required"> *</span>
                </div>
                <Input
                  className="inputtype"
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  value={userData.lastName}
                  onChange={(e) => handleInput(e, "text")}
                />
                {errors.lastName && (
                  <p className="error fs-12 mt-10">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="role role_responsive">
              <div className="retailer">
                <div className="labelname">
                  <label>DOB</label>
                  <span className="required"> *</span>
                </div>

                <DatePicker
                  showIcon
                  className="flex justify-center colr"
                  placeholderText="Enter your DOB"
                  selected={date}
                  valueDefault={null}
                  value={date}
                  maxDate={maxBirthdayDate}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  // yearDropdownMin={new Date()}
                  showYearDropdown
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(date) => {
                    setDate((userData.dob = date));
                  }}
                />

                {errors.dob && (
                  <p className="error fs-12 mt-10">{errors.dob}</p>
                )}
              </div>
            </div>
          </div>
          <div className="shadowBox">
            <div className=" ">
              <div className="">
                <h3>Contact Details</h3>
              </div>
            </div>
            <div className="role role_responsive ">
              <div className="retailer">
                <div className="labelname">
                  <label>Contact Number</label>
                  <span className="required"> *</span>
                </div>

                <Input
                  type="text"
                  maxLength={10}
                  placeholder="Enter mobile number"
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  onChange={(e) => handleInput(e, "number")}
                />
                {errors.phoneNumber && (
                  <p className="error fs-12 mt-10">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="retailer">
                <div className="labelname">
                  <label>Email Address</label>
                  <span className="required"> *</span>
                </div>
                <Input
                  type="text"
                  placeholder="Enter email address"
                  name="email"
                  value={userData.email}
                  onChange={(e) => handleInput(e, "email")}
                />
                {errors.email && (
                  <p className="error fs-12 mt-10">{errors.email}</p>
                )}
              </div>
            </div>
          </div>
          {/* Address Details */}
          <div className="shadowBox">
            <div className="">
              <div className="">
                <h3>Address Details</h3>
              </div>
            </div>
            <div className="role role_responsive">
              <div className="retailer">
                <div className="labelname">
                  <label>Building/House No. </label>
                  <span className="required"> *</span>
                </div>
                <Input
                  type="text"
                  placeholder="Enter building/house no."
                  name="address1"
                  value={userData.address1}
                  onChange={(e) => handleInput(e, "address")}
                />
                {errors.address1 && (
                  <p className="error fs-12 mt-10">{errors.address1}</p>
                )}
              </div>
              <div className="retailer">
                <div className="labelname">
                  <label>Road Name/Area/Colony</label>
                  <span className="required"> </span>
                </div>
                <Input
                  type="text"
                  placeholder="Enter road name/area/colony"
                  name="address2"
                  value={userData.address2}
                  onChange={(e) => handleInput(e, "address")}
                />
                {errors.address1 && (
                  <p className="error fs-12 mt-10">{errors.address2}</p>
                )}
              </div>
            </div>
            <div className="role role_responsive">
              <div className="retailer">
                <div className="labelname">
                  <label>Landmark</label>
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Enter landmark"
                    name="landmark"
                    value={userData.landmark}
                    onChange={(e) => handleInput(e, "address")}
                  />
                </div>
              </div>
              <div className="retailer">
                <div className="labelname">
                  <label>State</label>
                  <span className="required"> *</span>
                </div>
                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={state}
                    name="state"
                    onChange={stateHandler}
                  >
                    <option value={""}>Select State</option>
                    {IndianStates &&
                      IndianStates.map((val, index) => {
                        return (
                          <option key={index} value={val.name}>
                            {val.name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.userType && (
                    <p className="error fs-12 mt-10">{errors.state}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="role role_responsive">
              <div className="retailer">
                <div className="labelname">
                  <label>District</label>
                  <span className="required"> *</span>
                </div>
                <div className="input-field">
                  <select
                    className="input_select_field"
                    value={districts}
                    name="District"
                    onChange={(e) => {
                      setDistricts(e.target.value);
                      setErrors({});
                    }}
                  >
                    <option value={""}>Select District</option>
                    {!isEmpty(districtsList) &&
                      districtsList.map((val) => {
                        return <option value={val}>{val}</option>;
                      })}
                  </select>
                  {errors.districts && (
                    <p className="error fs-12 mt-10">{errors.districts}</p>
                  )}
                </div>
              </div>
              <div className="retailer">
                <div className="labelname">
                  <label>Pincode</label>
                  <span className="required"> *</span>
                </div>
                <Input
                  type="text"
                  placeholder="Enter pincode"
                  name="pincode"
                  maxLength={6}
                  value={userData.pincode}
                  onChange={(e) => handleInput(e, "number")}
                />
                {errors.pincode && (
                  <p className="error fs-12 mt-10">{errors.pincode}</p>
                )}
              </div>
            </div>
            <div className="role role_responsive">
              <div className="retailer">
                <button
                  className="submit_btn "
                  disabled={disableBtn}
                  onClick={() => {
                    handleFormData();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <>{loading && <GloabalLoader />}</>
      </div>
    </>
  );
};
export default AddUserForm;
