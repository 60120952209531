import moment from "moment";
import logo  from '../assets/images/logo_text.png'
import jsPDF from "jspdf";
export function timeout(func, time, dispatch) {
  setTimeout(
    function () {
      if (dispatch == null) func();
      else {
        dispatch(func());
      }
    }.bind(this),
    time
  );
}

export function getCurrentDate(formate) {
  var date = new Date();
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  if (formate == "yyyy-mm-dd") {
    return year + "-" + month + "-" + day;
  }else{
    return `${day}-${month}-${year}`;
  }

 
}

export function getDateInFormat(dateInString, dateFormat) {
  if (isEmpty(dateInString)) {
    return null;
  }
  var temp = moment(dateInString).format(dateFormat).split(" ");
  return temp[0];
}

export function getDateFromDateObj(dateObj, dateFormat) {
  let date = moment(dateObj).format(dateFormat);
  return date;
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  var day = result.getDate();
  var month = result.getMonth() + 1;
  var year = result.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + day;
}

export function diffDays(startDate, endDate) {
  var start = moment(startDate);
  var end = moment(endDate);
  return end.diff(start, "days");
}

export function saveAuthToken(item, value) {
  sessionStorage.setItem(item, JSON.stringify(value));
  //setAuthorizationToken(value);
}

export function getAuthToken(type) {
  const token = JSON.parse(sessionStorage.getItem("token"));
  return type
    ? `Bearer ${sessionStorage.getItem(type)}`
    : `Bearer ${token?.id_token}`;
}

export function saveUserData(userData) {
  const time = new Date().getTime().toString();
  sessionStorage.setItem("userData", JSON.stringify(userData));
  sessionStorage.setItem("userData_time", time);
}

export function getUserData() {
  return JSON.parse(sessionStorage.getItem("userData"));
}

export function clearAuthToken() {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("api-token");
  // setAuthorizationToken(false);
}

export function isAuthenticated(response) {
  if (response && response.data) {
    if (response.data.id_token && response.data.api_token) {
      saveAuthToken("token", response.data.id_token);
      saveAuthToken("api-token", response.data.api_token);
      return true;
    }
    return false;
  } else if (!isEmpty(getAuthToken())) {
    return true;
  }

  const isAuthenticated = !isEmpty(getAuthToken());
  return isAuthenticated;
}

export function isEmpty(obj) {
  let isEmpty = false;
  const type = typeof obj;

  isEmpty = isEmpty || !obj;
  isEmpty = isEmpty || type === "undefined"; // if it is undefined
  isEmpty = isEmpty || obj === null; // if it is null
  isEmpty = isEmpty || (type === "string" && obj === ""); // if the string is empty
  isEmpty = isEmpty || obj === false || obj === 0; // if boolean value returns false
  isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
  isEmpty = isEmpty || (type === "object" && Object.keys(obj).length === 0); // if object is empty

  return isEmpty;
}

export const numberToWords = (number) => {

	if(!isEmpty(number)){
		
		const numArr = number?.toString()?.split(".")
	
		const intNumber = numArr[0];
		let flotNum = numArr[1]
		

	
	   
	if(flotNum?.length === 1 && flotNum > 0 ){
        flotNum = flotNum*10;
	}
	// Array of words for numbers from 0 to 19
	const words = [
		'Zero',
		'One',
		'Two',
		'Three',
		'Four',
		'Five',
		'Six',
		'Seven',
		'Eight',
		'Nine',
		'Ten',
		'Eleven',
		'Twelve',
		'Thirteen',
		'Fourteen',
		'Fifteen',
		'Sixteen',
		'Seventeen',
		'Eighteen',
		'Nineteen',
	];

	// Array of tens words
	const tensWords = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

	// Handle numbers from 0 to 99
	const convertToWords = (num) => {
		
		if (num < 20) {
			return words[parseInt(num)];
		} else {
			const tens = Math.floor(num / 10);
			const units = num % 10;

			if (units === 0) {
				return tensWords[tens];
			} else {
				return tensWords[tens] + ' ' + words[Math.floor(units)];
			}
		}
	};

	// Handle numbers above 99
	const convertNumber = (num) => {
		if (num === 0) {
			return 'Zero';
		}
		let words = '';
		if (num >= 10000000) {
			words += convertNumber(Math.floor(num / 10000000)) + ' Crore ';
			num %= 10000000;
		}
		if (num >= 100000) {
			words += convertNumber(Math.floor(num / 100000)) + ' Lakh ';
			num %= 100000;
		}
		if (num >= 1000) {
			words += convertNumber(Math.floor(num / 1000)) + ' Thousand ';
			num %= 1000;
		}
		if (num >= 100) {
			words += convertToWords(Math.floor(num / 100)) + ' Hundred ';
			num %= 100;
		}
		if (num > 0) {
			words += convertToWords(num);
		}
		return words.trim();
	};


	if(flotNum > 0){
		return convertNumber(intNumber)+ " Rupees And " + convertNumber(flotNum) + " Paise Only";
	}else{
		return convertNumber(intNumber)+ " Rupees Only";
	}

	
}
};

export function isEmail(value) {
  return value.match(
    /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
  );
}

// check if value is gstin
export function isGSTIN(value) {
  let val = value.trim();
  return (
    val.match(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[A-Z\d]{1}[A-Z\d]{1}$/) ||
    val === "XXXXXXXXXXXXXXX"
  );
}

// check if value is pinCode
export function isPinCode(value) {
  return value.match(/^[1-9][0-9]{5}$/);
}

// check if the value is positive number
export function isNumeric(value) {
  return value.match(/^\d+$/);
}

// check if the value is integer or float
export function isNumber(value, integerOnly = true) {
  if (integerOnly) {
    return value.match(/^\s*[+-]?\d+\s*$/);
  }

  return value.match(/^\s*[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*$/);
}

export function isFloatNumber(value) {
  return String(value).match(/^\s*[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*$/);
}

// check if the strinc contains alphabets (spaces) only
export function isAlphabet(value, allowSpaces = true) {
  if (allowSpaces) {
    return value.match(/^[a-zA-Z\s]+$/);
  }

  return value.match(/^[a-zA-Z]+$/);
}

export function isName(value) {
  return value.match(/^[a-zA-Z0-9,./\-' ]*$/);
}

// check if the string contains pattern(bookingid) only
export function isIdPattern(value) {
  return value.match(/^[a-zA-Z0-9]+$/);
}

export function stopSpaceToBeFirst(e) {
  var charCode = !e.charCode ? e.which : e.charCode;
  if (charCode == 32) {
    e.preventDefault();
  }
}

export function getObjectValue(obj, key, defaultValue = null) {
  let enumerator = obj;
  let property = key;

  if (isEnumerable(enumerator) && keyExists(property, enumerator)) {
    return enumerator[property];
  }

  const dotLastIndex = property.lastIndexOf(".");

  if (dotLastIndex >= 0) {
    const withoutLastKey = property.substr(0, dotLastIndex);
    enumerator = getObjectValue(enumerator, withoutLastKey, defaultValue);
    property = property.substr(dotLastIndex + 1);
  }

  if (isEnumerable(enumerator)) {
    return keyExists(property, enumerator)
      ? enumerator[property]
      : defaultValue;
  }
  return defaultValue;
}

export function isEnumerable(obj) {
  let isEnumerable = false;

  if (Array.isArray(obj) || obj instanceof Object) {
    isEnumerable = true;
  }

  return isEnumerable;
}

export const httpStatusCodes = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export function getObjectValueIfEmpty(obj, key, defaultValue = null) {
  const value = getObjectValue(obj, key);

  if (isEmpty(value)) {
    return defaultValue;
  }
  return value;
}

export function clone(oldObject, newObject) {
  return { ...oldObject, ...newObject };
}

export function keyExists(key, obj) {
  if (
    (Array.isArray(obj) && key in obj) ||
    (obj instanceof Object && Object.prototype.hasOwnProperty.call(obj, key))
  ) {
    return true;
  }

  return false;
}

export function toBuffer(ab) {
  var buffer = new Buffer(ab.byteLength);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i];
  }
  return buffer;
}

export function checkValidity(value, rules) {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = !isEmpty(value) && isValid;
  }

  if (rules.minLength && !isEmpty(value)) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength && !isEmpty(value)) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail && !isEmpty(value)) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isMobile && !isEmpty(value)) {
    const pattern = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric && !isEmpty(value)) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNotZero && !isEmpty(value)) {
    const pattern = /^[-]?\d+$/;
    isValid = value != 0 ? pattern.test(value) && isValid : false;
  }

  if (rules.isNotMinusOne && !isEmpty(value)) {
    const pattern = /^[-]?\d+$/;
    isValid = value != -1 ? pattern.test(value) && isValid : false;
  }

  if (rules.isBid && !isEmpty(value)) {
    const pattern = /^[a-zA-Z0-9]+$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isName && !isEmpty(value)) {
    const pattern = /^[a-zA-Z0-9,./\-' ]*$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isDecimal && !isEmpty(value)) {
    const pattern = /^[0-1]\d*(\.\d+)?$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isOBF && !isEmpty(value)) {
    // const pattern = /^[0-1]\d*(\.\d+)?$/;
    const pattern = /^[.]?[0-9]\d*(\.\d+)?$/;
    isValid = value <= 1 ? pattern.test(value) && isValid : false;
  }
  if (rules.isGSTIN && !isEmpty(value)) {
    const pattern = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[A-Z\d]{1}[A-Z\d]{1}$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isPinCode && !isEmpty(value)) {
    const pattern = /^[1-9][0-9]{5}$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
}

export function loadScript(src) {
  return new Promise(function (resolve, reject) {
    let script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.head.append(script);
  });
}

export function printUserName(userData) {
  return (userData && userData.firstName + " " + userData.lastName) || "";
}

export function printUserNameShort(userData) {
  return (userData && userData.firstName[0] + "" + userData.lastName[0]) || "";
}

export function removeOverlay() {
  if (document.querySelector(".modal-backdrop.fade.show")) {
    document.querySelector(".modal-backdrop.fade.show").remove();
    document.body.classList.remove("modal-open");
  }
}

export function addOverlay() {
  let elemDiv = document.createElement("div");
  elemDiv.setAttribute("class", "modal-backdrop fade show");
  document.body.appendChild(elemDiv);
  document.body.classList.add("modal-open");
}

export function autoFocusOTPForm(event) {
  const { maxLength, value, name } = event.target;
  const [fieldName, fieldIndex] = name.split("-");
  let fieldIntIndex = parseInt(fieldIndex);
  if (value.length == maxLength) {
    const nextfield = document.querySelector(
      `input[name=otp-${fieldIntIndex + 1}]`
    );
    if (nextfield !== null) {
      nextfield.focus();
    }
  }
}

export function getUserPermissions(login) {
  if (login && login.userData && login.userData.apiPermission) {
    return login.userData.apiPermission;
  }
  return "";
}

export function printPage() {
  if (typeof window) {
    window.print();
  }
}

export function hideMessage(message, setMessage) {
  if (message) {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function getQueryParams(query) {
  const queryArray = query.split("?")[1].split("&");
  const queryParams = {};
  for (let i = 0; i < queryArray.length; i++) {
    const [key, val] = queryArray[i].split("=");
    queryParams[key] = val || true;
  }
  return queryParams;
}

export function numberToCurrency(value) {
  if (!value) return "₹ 0";
  let v = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `₹ ${v}`;
}

const numberReg = /^[0-9]+$/;
export  function inputNumber(val){
  
  if (!numberReg.test(val) && val) {
    return false;
  }else{
    return true;
  }
}


 export  const allStatesInIndia = [
  "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
  "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
  "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
  "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep", "Delhi", "Puducherry"
];


 export const IndianStates = [
  {
    name: 'Andaman and Nicobar Island (UT)',
    districts: [
       'Nicobar',
      'North and Middle Andaman',
       'South Andaman',
    ],
  },
   {
    name: 'Andhra Pradesh',
    districts: [
       'Anantapur',
       'Chittoor',
       'East Godavari',
       'Guntur',
       'Krishna',
       'Kurnool',
       'Prakasam',
       'Srikakulam',
       'Sri Potti Sriramulu Nellore',
       'Visakhapatnam',
       'Vizianagaram',
       'West Godavari',
       'YSR District, Kadapa (Cuddapah)',
],
  },
   {
    name: 'Arunachal Pradesh',
    districts: [
       'Anjaw',
       'Changlang',
       'Dibang Valley',
       'East Kameng',
       'East Siang',
       'Kra Daadi',
       'Kurung Kumey',
       'Lohit',
       'Longding',
       'Lower Dibang Valley',
       'Lower Siang',
       'Lower Subansiri',
       'Namsai',
       'Papum Pare',
       'Siang',
       'Tawang',
       'Tirap',
       'Upper Siang',
       'Upper Subansiri',
       'West Kameng',
       'West Siang',
],
  },
  {
    name: 'Assam',
    districts: [
       'Baksa',
       'Barpeta',
       'Biswanath',
       'Bongaigaon',
       'Cachar',
       'Charaideo',
       'Chirang',
       'Darrang',
       'Dhemaji',
       'Dhubri',
       'Dibrugarh',
       'Dima Hasao (North Cachar Hills)',
       'Goalpara',
       'Golaghat',
       'Hailakandi',
       'Hojai',
       'Jorhat',
       'Kamrup',
       'Kamrup Metropolitan',
       'Karbi Anglong',
       'Karimganj',
       'Kokrajhar',
       'Lakhimpur',
       'Majuli',
       'Morigaon',
       'Nagaon',
       'Nalbari',
       'Sivasagar',
       'Sonitpur',
       'South Salamara-Mankachar',
       'Tinsukia',
       'Udalguri',
       'West Karbi Anglong',
],
  },
   {
    name: 'Bihar',
    districts: [
       'Araria',
       'Arwal',
       'Aurangabad',
       'Banka',
       'Begusarai',
       'Bhagalpur',
       'Bhojpur',
       'Buxar',
       'Darbhanga',
      'East Champaran (Motihari)',
      'Gaya',
      'Gopalganj',
      'Jamui',
      'Jehanabad',
      'Kaimur (Bhabua)',
      'Katihar',
      'Khagaria',
      'Kishanganj',
      'Lakhisarai',
      'Madhepura',
      'Madhubani',
      'Munger (Monghyr)',
      'Muzaffarpur',
      'Nalanda',
      'Nawada',
      'Patna',
      'Purnia (Purnea)',
      'Rohtas',
      'Saharsa',
      'Samastipur',
      'Saran',
      'Sheikhpura',
      'Sheohar',
      'Sitamarhi',
      'Siwan',
      'Supaul',
      'Vaishali',
      'West Champaran',
    ],
  },
   {
    name: 'Chandigarh',
    districts: [
      'Chandigarh',
    ],
  },
   {
    name: 'Chhattisgarh',
    districts: [
       'Balod',
       'Baloda Bazar',
       'Balrampur',
       'Bastar',
       'Bemetara',
       'Bijapur',
       'Bilaspur',
       'Dantewada (South Bastar)',
       'Dhamtari',
       'Durg',
       'Gariyaband',
       'Janjgir-Champa',
       'Jashpur',
       'Kabirdham (Kawardha)',
       'Kanker (North Bastar)',
       'Kondagaon',
       'Korba',
       'Korea (Koriya)',
       'Mahasamund',
       'Mungeli',
       'Narayanpur',
       'Raigarh',
       'Raipur',
       'Rajnandgaon',
       'Sukma',
       'Surajpur  ',
       'Surguja',
    ],
  },
   {
    name: 'Dadra and Nagar Haveli (UT)',
    districts: [
       'Dadra & Nagar Haveli',
    ],
  },
   {
    name: 'Daman and Diu (UT)',
    districts: [
       'Daman',
       'Diu',
    ],
  },
   {
    name: 'Delhi (NCT)',
    districts: [
       'Central Delhi',
       'East Delhi',
       'New Delhi',
       'North Delhi',
       'North East  Delhi',
       'North West  Delhi',
       'Shahdara',
       'South Delhi',
      'South East Delhi',
       'South West  Delhi',
       'West Delhi',
    ],
  },
 {
    name: 'Goa',
    districts: [
       'North Goa',
       'South Goa',
    ],
  },
 {
    name: 'Gujarat',
    districts: [
       'Ahmedabad',
       'Amreli',
       'Anand',
       'Aravalli',
       'Banaskantha (Palanpur)',
       'Bharuch',
       'Bhavnagar',
       'Botad',
       'Chhota Udepur',
       'Dahod',
       'Dangs (Ahwa)',
       'Devbhoomi Dwarka',
       'Gandhinagar',
       'Gir Somnath',
       'Jamnagar',
       'Junagadh',
       'Kachchh',
       'Kheda (Nadiad)',
       'Mahisagar',
       'Mehsana',
       'Morbi',
       'Narmada (Rajpipla)',
       'Navsari',
       'Panchmahal (Godhra)',
       'Patan',
       'Porbandar',
       'Rajkot',
       'Sabarkantha (Himmatnagar)',
       'Surat',
       'Surendranagar',
       'Tapi (Vyara)',
       'Vadodara',
       'Valsad',
    ],
  },
   {
    name: 'Haryana',
    districts: [
       'Ambala',
       'Bhiwani',
       'Charkhi Dadri',
       'Faridabad',
       'Fatehabad',
       'Gurgaon',
       'Hisar',
       'Jhajjar',
       'Jind',
       'Kaithal',
       'Karnal',
       'Kurukshetra',
       'Mahendragarh',
       'Mewat',
       'Palwal',
       'Panchkula',
       'Panipat',
       'Rewari',
       'Rohtak',
       'Sirsa',
       'Sonipat',
       'Yamunanagar',
    ],
  },
  {
    name: 'Himachal Pradesh',
    districts: [
       'Bilaspur',
       'Chamba',
       'Hamirpur',
       'Kangra',
       'Kinnaur',
       'Kullu',
       'Lahaul & Spiti',
       'Mandi',
       'Shimla',
       'Sirmaur (Sirmour)',
       'Solan',
       'Una',
    ],
  },
 {
    name: 'Jammu and Kashmir',
    districts: [
       'Anantnag',
       'Bandipore',
       'Baramulla',
       'Budgam',
       'Doda',
       'Ganderbal',
       'Jammu',
       'Kargil',
       'Kathua',
       'Kishtwar',
       'Kulgam',
       'Kupwara',
       'Leh',
       'Poonch',
       'Pulwama',
       'Rajouri',
       'Ramban',
       'Reasi',
       'Samba',
       'Shopian',
       'Srinagar',
       'Udhampur',
],
  },
  {
    name: 'Jharkhand',
    districts: [
       'Bokaro',
       'Chatra',
       'Deoghar',
       'Dhanbad',
       'Dumka',
       'East Singhbhum',
       'Garhwa',
       'Giridih',
       'Godda',
       'Gumla',
       'Hazaribag',
       'Jamtara',
       'Khunti',
       'Koderma',
       'Latehar',
       'Lohardaga',
       'Pakur',
       'Palamu',
       'Ramgarh',
       'Ranchi',
       'Sahibganj',
       'Seraikela-Kharsawan',
       'Simdega',
       'West Singhbhum',
    ],
  },
   {
    name: 'Karnataka',
    districts: [
       'Bagalkot',
       'Ballari (Bellary)',
       'Belagavi (Belgaum)',
       'Bengaluru (Bangalore) Rural',
       'Bengaluru (Bangalore) Urban',
       'Bidar',
       'Chamarajanagar',
       'Chikballapur',
       'Chikkamagaluru (Chikmagalur)',
       'Chitradurga',
       'Dakshina Kannada',
       'Davangere',
       'Dharwad',
       'Gadag',
       'Hassan',
       'Haveri',
       'Kalaburagi (Gulbarga)',
       'Kodagu',
       'Kolar',
       'Koppal',
       'Mandya',
       'Mysuru (Mysore)',
       'Raichur',
       'Ramanagara',
       'Shivamogga (Shimoga)',
       'Tumakuru (Tumkur)',
       'Udupi',
       'Uttara Kannada (Karwar)',
       'Vijayapura (Bijapur)',
       'Yadgir',
    ],
  },
   {
    name: 'Kerala',
    districts: [
       'Alappuzha',
       'Ernakulam',
       'Idukki',
       'Kannur',
       'Kasaragod',
       'Kollam',
       'Kottayam',
       'Kozhikode',
       'Malappuram',
       'Palakkad',
       'Pathanamthitta',
       'Thiruvananthapuram',
       'Thrissur',
       'Wayanad',
    ],
  },
   {
    name: 'Lakshadweep (UT)',
    districts: [
       'Lakshadweep',
    ],
  },
   {
    name: 'Madhya Pradesh',
    districts: [
     'Agar Malwa',
     'Alirajpur',
     'Anuppur',
     'Ashoknagar',
     'Balaghat',
     'Barwani',
     'Betul',
     'Bhind',
     'Bhopal',
       'Burhanpur',
       'Chhatarpur',
       'Chhindwara',
       'Damoh',
       'Datia',
       'Dewas',
       'Dhar',
       'Dindori',
       'Guna',
       'Gwalior',
       'Harda',
       'Hoshangabad',
       'Indore',
       'Jabalpur',
       'Jhabua',
       'Katni',
       'Khandwa',
       'Khargone',
       'Mandla',
       'Mandsaur',
       'Morena',
       'Narsinghpur',
       'Neemuch',
       'Panna',
       'Raisen',
       'Rajgarh',
       'Ratlam',
       'Rewa',
       'Sagar',
       'Satna',
       'Sehore',
       'Seoni',
       'Shahdol',
       'Shajapur',
       'Sheopur',
       'Shivpuri',
       'Sidhi',
       'Singrauli',
       'Tikamgarh',
       'Ujjain',
       'Umaria',
       'Vidisha',
    ],
  },
   {
    name: 'Maharashtra',
    districts: [
       'Ahmednagar',
       'Akola',
       'Amravati',
       'Aurangabad',
       'Beed',
       'Bhandara',
       'Buldhana',
       'Chandrapur',
       'Dhule',
       'Gadchiroli',
       'Gondia',
       'Hingoli',
       'Jalgaon',
       'Jalna',
       'Kolhapur',
       'Latur',
       'Mumbai City',
       'Mumbai Suburban',
       'Nagpur',
       'Nanded',
       'Nandurbar',
       'Nashik',
       'Osmanabad',
       'Palghar',
       'Parbhani',
       'Pune',
       'Raigad',
       'Ratnagiri',
       'Sangli',
       'Satara',
       'Sindhudurg',
       'Solapur',
       'Thane',
       'Wardha',
       'Washim',
       'Yavatmal',
    ],
  },
   {
    name: 'Manipur',
    districts: [
       'Bishnupur',
       'Chandel',
       'Churachandpur',
       'Imphal East',
       'Imphal West',
       'Jiribam',
       'Kakching',
       'Kamjong',
       'Kangpokpi',
       'Noney',
       'Pherzawl',
       'Senapati',
       'Tamenglong',
       'Tengnoupal',
       'Thoubal',
       'Ukhrul',
    ],
  },
   {
    name: 'Meghalaya',
    districts: [
       'East Garo Hills',
       'East Jaintia Hills',
       'East Khasi Hills',
       'North Garo Hills',
       'Ri Bhoi',
       'South Garo Hills',
       'South West Garo Hills ',
       'South West Khasi Hills',
       'West Garo Hills',
       'West Jaintia Hills',
       'West Khasi Hills',
],
  },
 {
    name: 'Mizoram',
    districts: [
       'Aizawl',
       'Champhai',
       'Kolasib',
       'Lawngtlai',
       'Lunglei',
       'Mamit',
       'Saiha',
       'Serchhip',
    ],
  },
 {
    name: 'Nagaland',
    districts: [
      'Dimapur',
      'Kiphire',
      'Kohima',
      'Longleng',
      'Mokokchung',
      'Mon',
      'Peren',
      'Phek',
      'Tuensang',
       'Wokha',
       'Zunheboto',
    ],
  },
   {
    name: 'Odisha',
    districts: [
       'Angul',
       'Balangir',
       'Balasore',
       'Bargarh',
       'Bhadrak',
       'Boudh',
       'Cuttack',
       'Deogarh',
       'Dhenkanal',
       'Gajapati',
       'Ganjam',
       'Jagatsinghapur',
       'Jajpur',
       'Jharsuguda',
       'Kalahandi',
       'Kandhamal',
       'Kendrapara',
       'Kendujhar (Keonjhar)',
       'Khordha',
       'Koraput',
       'Malkangiri',
       'Mayurbhanj',
       'Nabarangpur',
       'Nayagarh',
       'Nuapada',
       'Puri',
       'Rayagada',
       'Sambalpur',
       'Sonepur',
       'Sundargarh',
    ],
  },
   {
    name: 'Puducherry (UT)',
    districts: [
       'Karaikal',
       'Mahe',
       'Pondicherry',
       'Yanam',
    ],
  },
   {
    name: 'Punjab',
    districts: [
     'Amritsar',
     'Barnala',
     'Bathinda',
     'Faridkot',
     'Fatehgarh Sahib',
     'Fazilka',
     'Ferozepur',
     'Gurdaspur',
     'Hoshiarpur',
       'Jalandhar',
       'Kapurthala',
       'Ludhiana',
       'Mansa',
       'Moga',
       'Muktsar',
       'Nawanshahr (Shahid Bhagat Singh Nagar)',
       'Pathankot',
       'Patiala',
       'Rupnagar',
       'Sahibzada Ajit Singh Nagar (Mohali)',
       'Sangrur',
       'Tarn Taran',
    ],
  },
   {
    name: 'Rajasthan',
    districts: [
       'Ajmer',
       'Alwar',
       'Banswara',
       'Baran',
       'Barmer',
       'Bharatpur',
       'Bhilwara',
       'Bikaner',
       'Bundi',
       'Chittorgarh',
       'Churu',
       'Dausa',
       'Dholpur',
       'Dungarpur',
       'Hanumangarh',
       'Jaipur',
       'Jaisalmer',
       'Jalore',
       'Jhalawar',
       'Jhunjhunu',
       'Jodhpur',
       'Karauli',
       'Kota',
       'Nagaur',
       'Pali',
       'Pratapgarh',
       'Rajsamand',
       'Sawai Madhopur',
       'Sikar',
       'Sirohi',
       'Sri Ganganagar',
       'Tonk',
       'Udaipur',
    ],
  },
 {
    name: 'Sikkim',
    districts: [
       'East Sikkim',
       'North Sikkim',
       'South Sikkim',
       'West Sikkim',
    ],
  },
 {
    name: 'Tamil Nadu',
    districts: [
       'Ariyalur',
       'Chennai',
       'Coimbatore',
       'Cuddalore',
       'Dharmapuri',
       'Dindigul',
       'Erode',
       'Kanchipuram',
       'Kanyakumari',
      'Karur',
      'Krishnagiri',
      'Madurai',
      'Nagapattinam',
      'Namakkal',
      'Nilgiris',
      'Perambalur',
      'Pudukkottai',
      'Ramanathapuram',
      'Salem',
      'Sivaganga',
      'Thanjavur',
      'Theni',
      'Thoothukudi (Tuticorin)',
      'Tiruchirappalli',
      'Tirunelveli',
      'Tiruppur',
      'Tiruvallur',
      'Tiruvannamalai',
      'Tiruvarur',
      'Vellore',
      'Viluppuram',
      'Virudhunagar',
    ],
  },
 {
    name: 'Telangana',
    districts: [
      'Adilabad',
      'Bhadradri Kothagudem',
      'Hyderabad',
      'Jagtial',
      'Jangaon',
      'Jayashankar Bhoopalpally',
      'Jogulamba Gadwal',
      'Kamareddy',
      'Karimnagar',
       'Khammam',
       'Komaram Bheem Asifabad',
       'Mahabubabad',
       'Mahabubnagar',
       'Mancherial',
       'Medak',
       'Medchal',
       'Nagarkurnool',
       'Nalgonda',
       'Nirmal',
       'Nizamabad',
       'Peddapalli',
       'Rajanna Sircilla',
       'Rangareddy',
       'Sangareddy',
       'Siddipet',
       'Suryapet',
       'Vikarabad',
       'Wanaparthy',
       'Warangal (Rural)',
       'Warangal (Urban)',
       'Yadadri Bhuvanagiri',
    ],
  },
{
    name: 'Tripura',
    districts: [
     'Dhalai',
     'Gomati',
     'Khowai',
     'North Tripura',
     'Sepahijala',
     'South Tripura',
     'Unakoti',
     'West Tripura',
    ],
  },
 {
    name: 'Uttarakhand',
    districts: [
       'Almora',
       'Bageshwar',
       'Chamoli',
       'Champawat',
       'Dehradun',
       'Haridwar',
       'Nainital',
       'Pauri Garhwal',
       'Pithoragarh',
       'Rudraprayag',
       'Tehri Garhwal',
       'Udham Singh Nagar',
       'Uttarkashi',
    ],
  },
    {
    'name': 'Uttar Pradesh',
    'districts': [
       'Agra',
       'Aligarh',
       'Allahabad',
       'Ambedkar Nagar',
       'Amethi (Chatrapati Sahuji Mahraj Nagar)',
       'Amroha (J.P. Nagar)',
       'Auraiya',
       'Azamgarh',
       'Baghpat',
      'Bahraich',
      'Ballia',
      'Balrampur',
      'Banda',
      'Barabanki',
      'Bareilly',
      'Basti',
      'Bhadohi',
      'Bijnor',
      'Budaun',
      'Bulandshahr',
      'Chandauli',
      'Chitrakoot',
      'Deoria',
      'Etah',
      'Etawah',
      'Faizabad',
      'Farrukhabad',
      'Fatehpur',
      'Firozabad',
      'Gautam Buddha Nagar',
      'Ghaziabad',
      'Ghazipur',
      'Gonda',
      'Gorakhpur',
      'Hamirpur',
      'Hapur (Panchsheel Nagar)',
      'Hardoi',
      'Hathras',
      'Jalaun',
      'Jaunpur',
      'Jhansi',
      'Kannauj',
      'Kanpur Dehat',
      'Kanpur Nagar',
      'Kanshiram Nagar (Kasganj)',
      'Kaushambi',
      'Kushinagar (Padrauna)',
      'Lakhimpur - Kheri',
      'Lalitpur',
      'Lucknow',
      'Maharajganj',
      'Mahoba',
      'Mainpuri',
      'Mathura',
      'Mau',
      'Meerut',
      'Mirzapur',
      'Moradabad',
      'Muzaffarnagar',
      'Pilibhit',
      'Pratapgarh',
      'RaeBareli',
      'Rampur',
      'Saharanpur',
      'Sambhal (Bhim Nagar)',
      'Sant Kabir Nagar',
      'Shahjahanpur',
      'Shamali (Prabuddh Nagar)',
      'Shravasti',
      'Siddharth Nagar',
      'Sitapur',
      'Sonbhadra',
      'Sultanpur',
      'Unnao',
      'Varanasi',
    ],
  },
   {
    'name': 'West Bengal',
    'districts': [
       'Alipurduar',
       'Bankura',
       'Birbhum',
       'Cooch Behar',
       'Dakshin Dinajpur (South Dinajpur)',
       'Darjeeling',
       'Hooghly',
       'Howrah',
       'Jalpaiguri',
       'Jhargram',
       'Kalimpong',
       'Kolkata',
       'Malda',
       'Murshidabad',
       'Nadia',
       'North 24 Parganas',
       'Paschim Medinipur (West Medinipur)',
       'Paschim (West) Burdwan (Bardhaman)',
       'Purba Burdwan (Bardhaman)',
       'Purba Medinipur (East Medinipur)',
       'Purulia',
       'South 24 Parganas',
       'Uttar Dinajpur (North Dinajpur)',
    ],
  },
];



export const pdfData = (data = [], header = [{}], text = 'list',width="a4") => {
	let _width = 210; // Width of the custom size
    const height = 297; 
	if(width !== "a4"){
		_width = width;
	}



	const doc = new jsPDF({
		orientation: 'landscape', 
  unit: 'mm', // units: mm, cm, in, px
  format:  [_width, height],
  compress: true,
  putOnlyUsedFonts: true,
  precision: 16, // n
	});
	const imgWidth = 25;
	const imgHeight = 10;
	const leftXPos = 14;
	const yPos = 2;
	doc.addImage(logo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);
	const formattedDate = getCurrentDate();

	const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
	// Get the width of the PDF
	const pdfWidth = doc.internal.pageSize.width;
	const yPos1 = 10;
	// Calculate the X position for center alignment
	const centerXPos = (pdfWidth - textWidth) / 2;
	doc.text(text, centerXPos, yPos1);

	// Calculate the width of the date text
	const dateWidth = (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

	const rightXPos = pdfWidth - dateWidth - 15;
	doc.text(formattedDate, rightXPos, yPos1);

	// Create a table from API data

	if (!isEmpty(header)) {
		doc.autoTable({
			head: header,
			body: data,
		});
	} else {
		doc.autoTable({
			body: data,
		});
	}

	doc.save(`${text}.pdf`);
};

