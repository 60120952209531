import styled from "styled-components";

export const ModalWrapper = styled.div `
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color:rgb(225 225 225 / 14%);
    .modal-dialog {
        position: relative;
        top: 15%;
        left: 6%;
        max-width: 1198px;
        margin: 50px auto;
        transform: translateY(0);
        transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        overflow: hidden;
        background: #F9F9F9;
        border-radius: 10px solid black ;
    }
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-clip: padding-box;
       // border: 1px solid var(--ip-border-color);
        outline: 0;
    }
    .modal-header {
        display: flex;
        // align-items: center;
        background: #fff;
        justify-content: space-between;
        padding: 20px 40px;
        .modal-title{
            font-size: 20px;
            color:#063151;
            font-weight: 700;
            display: flex;
            align-items: center;
            .icon{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color:#fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
				
                font-weight: 400;
                background-color: #FF6600;
            }
        }
        
    }



`
export const AlertWrapper = styled.div`
	padding: 10px 25px 10px 10px;
	position: relative;
	margin-bottom: 10px;
	border: 1px solid transparent;
	border-radius: 5px;

	&.error {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}

	&.success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}
	&.globle {
		position: fixed;
		//width: 50%;
		max-width: 70%;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		z-index: 11111;
		font-size: 14px;
		animation: mymove 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

		.close {
			font-size: 18px;
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
		}
	}
	&.error.globle {
		background-color: #fee;
		border: 1px solid #ffb8b8;
		color: #a66;
	}
	&.success.globle {
		background-color: #efe;
		border: 1px solid #ded;
		color: #9a9;
	}
	.close {
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%);
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 0.6;
		&:hover {
			color: #000;
			text-decoration: none;
			cursor: pointer;
			filter: alpha(opacity=50);
			opacity: 0.5;
		}
	}
	@keyframes mymove {
		from {
			top: 0px;
		}
		to {
			top: 50px;
		}
	}
	@media only screen and (max-width: 767px) {
		&.globle {
			width: calc(100% - 50px);
		}
	}

`;

